import { createTheme, ThemeOptions } from '@material-ui/core/styles';
declare module '@material-ui/core/styles/createTheme' {}

export interface Theme {}

export const adminTheme = createTheme({
  //@ts-ignore
  header: {
    backgroundColor: 'rgba(0, 0, 0, 1)',
    logoImage: `url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.2543' cy='13.5112' r='11.7543' stroke='white' stroke-width='3'/%3E%3Ccircle cx='13.2542' cy='13.5115' r='2.63848' fill='white' stroke='white' stroke-width='3'/%3E%3C/svg%3E%0A")`,
    titleColor: 'rgba(256, 256, 256, 1)',
    tabs: {
      activeBackgroundColor: 'rgba(59, 59, 59)',
      activeColor: '#EEEEEE',
      unActiveColor: '#EEEEEE',
    },
  },
  selects: {
    menu: {
      border: '2px solid rgba(255, 255, 255, 0.2)',
      color: '#fff',
    },
  },
});

export const shipOwnerTheme = createTheme({
  //@ts-ignore
  header: {
    backgroundColor: 'rgba(256, 256, 256, 1)',
    logoImage: `url("data:image/svg+xml,%3Csvg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='13.2543' cy='13.5112' r='11.7543' stroke='black' stroke-width='3'/%3E%3Ccircle cx='13.2542' cy='13.5115' r='2.63848' fill='%23131313' stroke='black' stroke-width='3'/%3E%3C/svg%3E%0A")`,
    titleColor: 'rgba(0, 0, 0, 1)',
    tabs: {
      activeBackgroundColor: '#EEEEEE',
      activeColor: '#131313',
      unActiveColor: 'rgba(19, 19, 19, 0.4)',
    },
  },
  selects: {
    menu: {
      border: '2px solid rgba(0, 0, 0, 0.3)',
      color: '#131313',
    },
  },
});
