import type { IntlConfig } from 'react-intl';
import React, { useState, useEffect } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';
import { useReactiveVar } from '@apollo/client';

import { LOCALE } from 'src/utils/constants';
import { localeVar } from 'src/apollo/cache/cache';
import en from 'src/static/locales/en.json';
import ru from 'src/static/locales/ru.json';
import { setCookie } from './cookies';

interface IntlProviderProps {
  locale: IntlConfig['locale'];
  messages: IntlConfig['messages'];
}

export const getMessages = (locale: string) => {
  if (locale === 'en') return en;
  if (locale === 'ru') return ru;
  return ru;
};

const cache = createIntlCache();
export const IntlProvider: React.FC<IntlProviderProps> = ({ locale, messages, children }) => {
  const [intl, setIntl] = useState(createIntl({ locale, messages }, cache));
  const cacheLocale = useReactiveVar(localeVar);

  useEffect(() => {
    const updateLocaleInformation = async () => {
      const nextMessages = await getMessages(cacheLocale);
      setIntl(createIntl({ locale: cacheLocale, messages: nextMessages }, cache));
      setCookie(LOCALE, cacheLocale);
    };
    updateLocaleInformation();
  }, [cacheLocale]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};
