import type { FC, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { BootstrapInput, EndInputAdornment } from 'src/components/Filter/Filter';
import { Button } from 'src/components/buttons';
import { VesselsTable } from '../VesselsTable/VesselsTable';
import { I18nText } from 'src/components/i18n';
import { NAVIGATION_STATUSES } from 'src/utils/constants/ships';
import { FORM_FIELDS } from 'src/utils/constants';
import { Label } from 'src/components/forms';
import { DateInput, NumberInput, Input } from 'src/components/inputs';
import { getInputProps } from 'src/utils/helpers';

import type { SearchVesselsInPortValues } from './useVesselsInPort/useVesselsInPort';
import { SendInvitesButton } from '../SendInvitesButton';
import { useVesselsInPort } from './useVesselsInPort/useVesselsInPort';

export const VesselsInPort: FC<{ setShowInvitePopup: Dispatch<SetStateAction<boolean>> }> = ({
  setShowInvitePopup,
}) => {
  const [portName, setPortName] = useState<string>('');

  const {
    classes,
    loading,
    vessels,
    formik,
    ports,
    getPorts,
    dowloandVesselsXlsx,
    setSelectedPort,
    selectedPort,
    inviteStatus,
  } = useVesselsInPort();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.PORT_NAME}`} />
          </Label>
          <Input
            value={portName}
            onChange={event => {
              setPortName(event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box alignItems="center" display="flex" height="100%">
            <Button
              disabled={!portName || loading}
              buttonVariant="second"
              fullWidth
              onClick={() => {
                setSelectedPort(null);
                getPorts({
                  variables: {
                    name: portName,
                  },
                });
              }}
            >
              <I18nText path="button.searchPorts" />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Divider />

      {!!ports.length && !loading && (
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Box mb={2} mt={2}>
              <Typography variant="h5">
                <I18nText path="text.selectPort" />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gridGap="15px" flexWrap="wrap">
              {ports.map((port: any) => (
                <Box>
                  <Button
                    disabled={loading}
                    buttonVariant={
                      selectedPort?.['PORT_ID'] === port['PORT_ID'] ? 'main' : 'second'
                    }
                    onClick={() => {
                      setSelectedPort(port);
                    }}
                  >
                    {port['PORT_NAME']}
                  </Button>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      )}

      {!!portName && selectedPort && (
        <form onSubmit={formik.handleSubmit} className={classes.formContrainer}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} className={classes.gridItemDesktop}>
              <Label>
                <I18nText path={`field.${FORM_FIELDS.DWT_MIN}`} />
              </Label>

              <NumberInput
                {...getInputProps<SearchVesselsInPortValues>(formik, FORM_FIELDS.DWT_MIN)}
                value={formik.values[FORM_FIELDS.DWT_MIN]}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={3} className={classes.gridItemDesktop}>
              <Label>
                <I18nText path={`field.${FORM_FIELDS.DWT_MAX}`} />
              </Label>

              <NumberInput
                {...getInputProps<SearchVesselsInPortValues>(formik, FORM_FIELDS.DWT_MAX)}
                value={formik.values[FORM_FIELDS.DWT_MAX]}
                onChange={formik.handleChange}
              />
            </Grid>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={12} sm={3} className={classes.gridItemDesktop}>
                <Label>
                  <I18nText path="field.period" /> &nbsp; <I18nText path="menuLabel.from" />
                </Label>

                <DateInput
                  {...getInputProps<SearchVesselsInPortValues>(formik, FORM_FIELDS.START_DATE)}
                  value={formik.values[FORM_FIELDS.START_DATE]}
                  onChange={date => {
                    formik.setFieldTouched(FORM_FIELDS.START_DATE, false);
                    formik.setFieldValue(FORM_FIELDS.START_DATE, date);
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={3} className={classes.gridItemDesktop}>
                <Label>
                  <I18nText path="menuLabel.untill" />
                </Label>

                <DateInput
                  {...getInputProps<SearchVesselsInPortValues>(formik, FORM_FIELDS.END_DATE)}
                  value={formik.values[FORM_FIELDS.END_DATE]}
                  onChange={date => {
                    formik.setFieldTouched(FORM_FIELDS.END_DATE, false);
                    formik.setFieldValue(FORM_FIELDS.END_DATE, date);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItemDesktop}>
                <Label>
                  <I18nText path="field.status" />
                </Label>
                <Box className={classes.selectWrapper}>
                  <Select
                    className={classes.select}
                    multiple
                    name="status"
                    value={formik.values.status}
                    onChange={event => {
                      formik.setFieldTouched('status', false);
                      formik.setFieldValue('status', event.target.value);
                    }}
                    input={
                      <BootstrapInput
                        endAdornment={
                          <EndInputAdornment
                            isSelect
                            classes={classes}
                            cond={!!formik.values.status.length}
                            onClick={() => {
                              formik.setFieldValue('status', []);
                            }}
                          />
                        }
                      />
                    }
                    renderValue={selected => (
                      <Box className={classes.chips}>
                        {(selected as string[]).map((value, index) => (
                          <Box key={value} className={classes.chip}>
                            {value}
                            {index + 1 !== (selected as string[]).length && ', '}
                          </Box>
                        ))}
                      </Box>
                    )}
                  >
                    {NAVIGATION_STATUSES.map((status: any, index: number) => (
                      <MenuItem key={index} value={status}>
                        <Checkbox
                          color="primary"
                          checked={formik.values.status.includes(status as never)}
                        />
                        <ListItemText primary={status} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Box alignItems="center" display="flex" height="100%">
                  <Button
                    disabled={!Object.values(formik.values).every(value => !!value)}
                    type="submit"
                    fullWidth
                    buttonVariant="main"
                  >
                    <I18nText path="button.checkPort" />
                  </Button>
                </Box>
              </Grid>
              <Grid xs={12} sm={3} item alignItems="center">
                <Box alignItems="center" display="flex" height="100%">
                  <Button fullWidth disabled={!vessels?.length} onClick={dowloandVesselsXlsx}>
                    <I18nText path="button.downloadReport" />
                  </Button>
                </Box>
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </form>
      )}

      <Grid container>
        <Grid item xs={12} sm={12}>
          <Box mt={3}>
            <Typography variant="h4">
              <I18nText path="text.result" />{' '}
              {selectedPort && (
                <>
                  <I18nText path="text.in" /> {selectedPort['PORT_NAME']}{' '}
                </>
              )}
              <SendInvitesButton
                disabled={!vessels?.length}
                setShowInvitePopup={setShowInvitePopup}
                vessels={vessels}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box>
        {loading ? (
          <Box className={classes.message}>
            <I18nText path="text.loading" />
          </Box>
        ) : (
          <>
            {!!vessels.length && (
              <Box>
                <VesselsTable
                  vessels={vessels}
                  inviteStatus={inviteStatus}
                  setShowInvitePopup={setShowInvitePopup}
                />
              </Box>
            )}
            {!vessels.length && (
              <Box className={classes.message}>
                <I18nText path="text.noSearchResult" />
              </Box>
            )}
          </>
        )}
      </Box>
    </>
  );
};
