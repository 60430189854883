import React from 'react';

import { Header } from 'src/components/layout';

import { ShipownerOrderList } from './ShipownerOrderList';

export const ShipownerOrdersPage = () => {
  return (
    <div>
      <Header type={'shipwownerNavigation'} />
      <ShipownerOrderList />
    </div>
  );
};
