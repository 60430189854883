import { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { getAuthHeaders, protocol } from 'src/apollo/client/client';
import {
  GetMonthlyPlanFullDocument,
  useCreateMonthlyPlanItemMutation,
  useDeleteMonthlyPlanItemMutation,
  useGetMonthlyPlanFullQuery,
  useGetPortsQuery,
  useUpdateMonthlyPlanItemMutation,
} from 'src/gql';
import { useBoolean } from 'src/hooks';
import { backendHostname } from 'src/utils/constants';
import {
  downloadFile,
  setErrorNotification,
  setSuccessMesageNotification,
} from 'src/utils/helpers';

export const useMonthlyPlanPage = () => {
  const { id } = useParams<{ id?: string }>();
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [isDownloading, { on: onIsDownloading, off: offIsDownloading }] = useBoolean(false);
  const { data: monthlyPlanData, loading: monthlyPlanLoading } = useGetMonthlyPlanFullQuery({
    variables: { id: id! },
    skip: !id,
  });

  const { data: portsData } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [createMonthlyPlanItem, loading] = useCreateMonthlyPlanItemMutation({
    refetchQueries: [GetMonthlyPlanFullDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Item added');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const [updateMonthlyPlanItem] = useUpdateMonthlyPlanItemMutation({
    refetchQueries: [GetMonthlyPlanFullDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Item updated');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const [deleteMonthlyPlanItem] = useDeleteMonthlyPlanItemMutation({
    refetchQueries: [GetMonthlyPlanFullDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Item deleted');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const downloadReport = useCallback(
    async (month: string) => {
      try {
        onIsDownloading();

        const reportUrl = `${protocol}//${backendHostname}/reports/monthlyPlans/generate`;

        const response = await fetch(reportUrl, {
          method: 'POST',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            ...getAuthHeaders(),
          } as HeadersInit,
          body: JSON.stringify({
            month,
          }),
        });

        if (!response.ok) {
          setErrorNotification('Failed to download file');
          return;
        }

        await downloadFile(response, `Plan_Report_${month}.xlsx`);

        return response;
      } finally {
        offIsDownloading();
      }
    },
    [onIsDownloading, offIsDownloading],
  );

  return {
    showForm,
    setShowForm,
    showEditForm,
    setShowEditForm,
    createMonthlyPlanItem,
    updateMonthlyPlanItem,
    deleteMonthlyPlanItem,
    monthlyPlan: monthlyPlanData?.monthlyPlan,
    portsData,
    loading: monthlyPlanLoading,
    isDownloading,
    downloadReport,
  };
};
