import type { FC, Dispatch, SetStateAction } from 'react';
import { isNil, pick, values } from 'ramda';

import { SHIP_TABLE_COLUMNS } from 'src/utils/helpers';
import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { MarineTrafficVesselInfo } from 'src/utils/types';

import { useVesselsTableRow } from './useVesselsTableRow/useVesselsTableRow';
import { inviteVar } from 'src/apollo/cache/cache';

interface VesselsTableRowProps {
  vessel: MarineTrafficVesselInfo;
  isSelected?: boolean;
  setShowInvitePopup: Dispatch<SetStateAction<boolean>>;
  invited: boolean;
}

export const VesselsTableRow: FC<VesselsTableRowProps> = ({
  isSelected,
  vessel,
  setShowInvitePopup,
  invited,
}) => {
  const { classes, rowRef } = useVesselsTableRow({
    vessel,
    isSelected,
  });

  return (
    <div className={classes.dataRow} ref={rowRef}>
      {SHIP_TABLE_COLUMNS.map((column, index) => (
        <div key={index} className={classes.dataColumn}>
          {vessel[column]}
        </div>
      ))}
      <div className={classes.dataInviteColumn}>
        {invited ? (
          <div className={classes.dataColumn}>
            <I18nText path="inviteLabel.sent" />
          </div>
        ) : (
          (!!vessel['OWNER_EMAIL'] || !!vessel['MANAGER_EMAIL']) && (
            <Button
              onClick={() => {
                inviteVar({
                  emails: values(pick(['OWNER_EMAIL', 'MANAGER_EMAIL'], vessel)).filter(
                    (el): el is NonNullable<typeof el> => !isNil(el),
                  ),
                  mode: 'single',
                });
                setShowInvitePopup(true);
              }}
            >
              <I18nText path="button.invite" />
            </Button>
          )
        )}
      </div>
    </div>
  );
};
