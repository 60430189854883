export const ORDER_STATUSES = {
  NOT_STARTED: 0,
  ACTIVE: 1,
  CLOSED: 2,
  WINNER_SELECTED: 3,
  ONGOING: 4,
  FINISHED: 5,
  ARCHIVED: 6,
};

export const DEFAULT_ORDER_FIELDS = {
  canTime: new Date(Date.now()),
  layTime: new Date(Date.now()),
  startTime: new Date(Date.now()),
  closeTime: new Date(Date.now()),
  type: 0,
  cargo: '',
  comment: '',
  quantityMax: 0,
  quantityMin: 0,
  stowageFactorUnit: 0,
  wog: '',
  loadingPorts: [{ indexNumber: 1, portName: '', rate: 0 }],
  dischargingPorts: [{ indexNumber: 1, portName: '', rate: 0 }],
};

export const OPERATION_TYPES = {
  EDIT: 'edit',
  COPY: 'copy',
  CREATE: 'create',
};

export const VESSEL_TYPES = {
  EDIT: 'edit',
  CREATE: 'create',
};

export const ORDER_BY = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const ORDER_COLUMN = {
  CAN_TIME: 'CAN_TIME',
  DISCHARGING_PORTS: 'DISCHARGING_PORTS',
  ID: 'ID',
  LAY_TIME: 'LAY_TIME',
  LOADING_PORTS: 'LOADING_PORTS',
  QUANTITY_MAX: 'QUANTITY_MAX',
  QUANTITY_MIN: 'QUANTITY_MIN',
  STATUS: 'STATUS',
};
