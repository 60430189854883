import React from 'react';
import { Header } from 'src/components/layout';
import { ShipownerOfferList } from './ShipownerOfferList';

export const ShipownerOrderPage = () => {
  return (
    <div>
      <Header type={'shipwownerOrder'} />
      <ShipownerOfferList />
    </div>
  );
};
