import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { PhoneInput } from 'src/components/inputs';
import { useRegisterMutation } from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';

const registrationSchema = yup.object().shape({
  companyName: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  mobile: yup.string().required(),
});

interface FormFields {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
}
const formFields: { name: keyof FormFields; label: string }[] = [
  { name: 'companyName', label: 'field.companyName' },
  { name: 'firstName', label: 'field.firstName' },
  { name: 'lastName', label: 'field.lastName' },
  { name: 'email', label: 'field.email' },
];

interface RegistrationFormProps {
  setShowRegistration: Function;
}

export const RegistrationForm: FC<RegistrationFormProps> = ({ setShowRegistration }) => {
  const classes = useStyles();
  const [register, { loading }] = useRegisterMutation({
    errorPolicy: 'all',
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: ({ register }) => {
      if (!register.success && register.reason) {
        setErrorNotification(register.reason);
        return;
      }
      if (register.success && register.user) {
        setSuccessMesageNotification(`Password was sent in email ${register.user.email}`);
        setShowRegistration(false);
      }
    },
  });
  const formik = useFormik({
    initialValues: {
      companyName: '',
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      accept: false,
    },
    onSubmit: values => {
      if (!values.accept) {
        setErrorNotification('The terms and conditions must be accepted');
        return;
      }
      register({
        variables: {
          companyName: values.companyName,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobile: values.mobile,
        },
      });
    },
    validationSchema: registrationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {formFields.map(({ name, label }, index) => (
        <Grid key={index} container alignItems="center" spacing={3} direction="row">
          <Grid item xs={12} sm={2} alignContent="flex-end">
            <InputLabel className={classes.label}>
              <I18nText path={label} />
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              variant="outlined"
              name={name}
              disabled={loading}
              value={formik.values[name]}
              onChange={event => {
                formik.setFieldTouched(name, false);
                formik.setFieldValue(name, event.target.value);
              }}
              error={!!formik.errors[name] && !!formik.touched[name]}
              helperText={!!formik.errors[name] && !!formik.touched[name] && formik.errors[name]}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
      ))}
      <Grid container alignItems="center" spacing={3} direction="row">
        <Grid item xs={12} sm={2} alignContent="flex-end">
          <InputLabel className={classes.label}>
            <I18nText path="field.mobile" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={10}>
          <div className={classes.textField}>
            <PhoneInput
              value={formik.values.mobile}
              onChange={(phone: string) => {
                formik.setFieldTouched('mobile', false);
                formik.setFieldValue('mobile', phone);
              }}
              error={!!formik.errors.mobile && !!formik.touched.mobile}
            />
            {!!formik.errors.mobile && !!formik.touched.mobile && (
              <span className={classes.helperText}>{formik.errors.mobile}</span>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid className={classes.buttons} container alignItems="center" spacing={2} direction="row">
        <Grid className={classes.acceptTextContainer} item xs={12} sm={7}>
          <Checkbox
            size="medium"
            disabled={loading}
            color="primary"
            checked={formik.values.accept}
            onChange={() => {
              formik.setFieldTouched('accept', false);
              formik.setFieldValue('accept', !formik.values.accept);
            }}
            className={classes.checkBox}
            name={'accept'}
          />
          <div className={classes.acceptText}>
            <I18nText path="text.acceptEULA" />
          </div>
        </Grid>
        <Grid className={classes.buttonRegisterContainer} item xs={12} sm={5}>
          <Button disabled={loading} type="submit">
            <I18nText path="button.register" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  phoneInput: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',

    width: '100%',
  },
  buttons: {
    marginTop: '20px',
  },
  buttonForgotPassword: {
    color: '#2A39BE',
    fontSize: '16px',
  },
  buttonRegisterContainer: {
    textAlign: 'end',
  },
  buttonRegister: {
    fontSize: '16px',
    padding: '10px 58px',
    background: '#2A39BE',
    opacity: '0.8',
    border: '2px solid #2A39BE',
    boxShadow: '0px 0px 25px rgba(42, 57, 190, 0.44)',
    borderRadius: '8px',
    marginRight: '10px',
    textTransform: 'none',
  },
  acceptTextContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  acceptText: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '14px',
    lineHeight: '20px',
    marginLeft: '9px',
  },
  checkBox: {
    borderRadius: '5px',
    transform: 'scale(1.3)',
  },
  helperText: {
    marginLeft: '14px',
    marginRight: '14px',
    color: ' #f44336',
    fontSize: '0.75rem',
    fontFamily: 'Roboto',
  },
}));
