import React from 'react';

import { Header } from 'src/components/layout';
import { OfferList } from './OfferList';

export const OrderPage = () => {
  return (
    <div>
      <Header type={'orders'} />
      <OfferList />
    </div>
  );
};
