import type { FC } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

import type { ButtonProps } from '../Button/Button';
import { Button } from '../Button/Button';

import { useStyles } from './LinkButton.style';

interface LinkButtonProps extends ButtonProps {
  to: LinkProps['to'];
}

export const LinkButton: FC<LinkButtonProps> = ({ to, children, ...props }) => {
  const classes = useStyles();

  return (
    <Link to={to} className={classes.link}>
      <Button {...props}>{children}</Button>
    </Link>
  );
};
