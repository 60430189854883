import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { isDesktop, isMobile } from 'react-device-detect';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { PopupDetails } from 'src/components/popups/PopupDetails/PopupDetails';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { useQuery } from 'src/hooks/useQuery';
import { useGetMyOffersForOrderQuery, useOrderQuery } from 'src/gql';
import { LIMIT, SHIPOWNER_ORDERS } from 'src/utils/constants';
import { ShipownerOfferListItem } from './ShipownerOfferListItem';
import { ShipownerOfferListMobileItem } from './ShipownerOfferListMobileItem';

export const ShipownerOfferList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const { orderId } = useParams<any>();
  const [showPopup, setShowPopup] = useState(query.get('fromEmail') === 'true');
  const {
    loading: orderLoading,
    data: orderData,
    error,
  } = useOrderQuery({
    variables: { id: orderId! },
  });
  const { loading, data, fetchMore } = useGetMyOffersForOrderQuery({
    fetchPolicy: 'cache-and-network',
    variables: { limit: LIMIT, offset: 0, orderId: orderId! },
  });
  const onLoadMore = useCallback(async () => {
    await fetchMore({
      //@ts-ignore
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          orders: [...prev.myOffersForOrder.offers],
        };
      },
      variables: {
        limit: LIMIT,
        offset: data ? data.myOffersForOrder.offers.length : 0,
      },
    });
  }, [data?.myOffersForOrder.offers, fetchMore]);

  return (
    <div>
      <div className={classes.panelTools}>
        <Container>
          <Grid container>
            <Grid xs={12} item className={classes.buttonWrapper}>
              <Button
                className={classes.backButton}
                onClick={() => navigate(SHIPOWNER_ORDERS)}
                startIcon={<ArrowBackIosIcon />}
              >
                <I18nText path="button.back" />
              </Button>
              <Button onClick={() => navigate(`/shipowner/offer/create/${orderId}`)}>
                <I18nText path="button.newOffer" />
              </Button>
              <Button buttonVariant="light" onClick={() => setShowPopup(true)}>
                <I18nText path="button.details" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        {/* {!data?.orders.orders.length && <EmptyText text={'lol kek'} />} */}
        {isDesktop && (
          <Grid container className={classes.tableHeaderWrapper}>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.number" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.carrier" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.proposal" />
            </Grid>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.freightRate" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.eta" />
            </Grid>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.draft" />
            </Grid>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.deadWeight" />
            </Grid>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.demRate" /> <I18nText path="units.usd" />
            </Grid>
          </Grid>
        )}
        {isDesktop &&
          data?.myOffersForOrder.offers.map(offer => (
            <ShipownerOfferListItem key={offer.id} offer={offer} />
          ))}
        {isMobile &&
          data?.myOffersForOrder.offers.map(offer => (
            <ShipownerOfferListMobileItem key={offer.id} offer={offer} />
          ))}
      </Container>
      {data?.myOffersForOrder.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={loading} onClick={() => onLoadMore()}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
      {showPopup && orderData?.order && !error && (
        <PopupDetails
          showPopup={showPopup}
          setShowPopup={() => {
            setShowPopup(false);
          }}
          //@ts-ignore
          details={orderData.order}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 75px',
  },
  tableHeaderWrapper: {
    padding: '0px 15px',
    marginBottom: '18px',
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  backButton: {
    marginRight: '15px',
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    color: '#000000',
    textAlign: 'center',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '15px',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
}));
