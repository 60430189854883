import React, { FC, ReactNode } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

interface PopupProps {
  children: ReactNode;
  handleClose: () => void;
  title: string;
}

export const Popup: FC<PopupProps> = ({ children, handleClose, title }) => {
  const classes = useStyles();
  const content = (
    <div className={classes.popupWrapper}>
      <div className={classes.closeButton} onClick={handleClose} />
      {title && <div className={classes.title}>{title}</div>}
      <div> {children}</div>
    </div>
  );

  if (isDesktop) {
    return (
      <Dialog PaperProps={{ className: classes.desktopPopup }} onClose={handleClose} open={true}>
        {content}
      </Dialog>
    );
  }

  if (isMobile) {
    return <div className={classes.mobilePopup}> {content}</div>;
  }

  throw new Error();
};

const useStyles = makeStyles(() => ({
  desktopPopup: {
    minWidth: '350px',
    maxWidth: '650px',
    borderRadius: '15px',
    padding: '45px',
  },
  mobilePopup: {
    position: 'fixed',
    overflowY: 'scroll',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    padding: '45px',
  },
  popupWrapper: {
    position: 'relative',
  },
  title: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '30px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    right: '0',
    width: '22px',
    height: '23px',
    backgroundSize: '22 23',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='22' height='23' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.7407 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.44783' y1='1.29289' x2='31.4478' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
}));
