import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { useSignInMutation } from 'src/gql';
import { setCookie, setErrorNotification } from 'src/utils/helpers';
import { AUTH_TOKEN } from 'src/utils/constants/cookies';
import { userVar } from 'src/apollo/cache/cache';
import { relink } from 'src/apollo/client/client';

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

interface FormFields {
  email: string;
  password: string;
}
const formFields: { name: keyof FormFields; label: string; type: string }[] = [
  { name: 'email', label: 'field.email', type: 'text' },
  { name: 'password', label: 'field.password', type: 'password' },
];

export const SignInForm = () => {
  const classes = useStyles();
  const [signIn, { loading }] = useSignInMutation({
    errorPolicy: 'all',
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: ({ signIn }) => {
      if (signIn.token && signIn.user) {
        setCookie(AUTH_TOKEN, signIn.token);
        userVar(signIn.user);
        relink();
      }
      if (!signIn.success && signIn.reason) {
        setErrorNotification(signIn.reason);
      }
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      signIn({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {formFields.map(({ name, label, type }) => (
        <Grid container alignItems="center" spacing={4} direction="row">
          <Grid item xs={12} sm={2}>
            <InputLabel className={classes.label}>
              <I18nText path={label} />
            </InputLabel>
          </Grid>
          <Grid item xs={12} sm={10}>
            <TextField
              id="outlined-basic"
              className={classes.textField}
              variant="outlined"
              name={name}
              type={type}
              disabled={loading}
              value={formik.values[name]}
              onChange={event => {
                formik.setFieldTouched(name, false);
                formik.setFieldValue(name, event.target.value);
              }}
              error={!!formik.errors[name] && !!formik.touched[name]}
              helperText={!!formik.errors[name] && !!formik.touched[name] && formik.errors[name]}
              InputProps={{
                className: classes.input,
              }}
            />
          </Grid>
        </Grid>
      ))}

      <Grid container alignItems="center" spacing={1} direction="row" className={classes.buttons}>
        <Grid item xs={12} sm={6}>
          {/* <Link
            className={classes.buttonForgotPassword}
            component="button"
            variant="body2"
            underline="always"
          >
            <I18nText path="button.forgotPassword" />
          </Link> */}
        </Grid>

        <Grid className={classes.buttonSignInContainer} item xs={12} sm={6}>
          <Button type="submit" disabled={loading}>
            <I18nText path="button.signin" />
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  buttons: {
    marginTop: '50px',
  },
  buttonForgotPassword: {
    color: '#2A39BE',
    fontSize: '16px',
  },
  buttonSignInContainer: {
    textAlign: 'end',
  },
}));
