import { useEffect, useMemo, useState } from 'react';
import { Maybe, useMarineTrafficJobCompletedSubscription } from 'src/gql';

export interface Params<T extends (...args: any) => any> {
  mutationHook: T;
  jobIdExtractor(data: any): Maybe<string>;
}

export function useMarineTrafficJob<T extends (...args: any) => any>({
  mutationHook,
  jobIdExtractor,
}: Params<T>) {
  const [jobId, setJobId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [mutation] = mutationHook({
    onCompleted(data: any) {
      const jobId = jobIdExtractor(data);

      if (jobId) {
        setJobId(jobId.toString());
        setLoading(true);
      }
    },
  });

  const subscription = useMarineTrafficJobCompletedSubscription({
    variables: {
      jobId: jobId!,
    },
    skip: !jobId,
    onSubscriptionData({ subscriptionData }) {
      setLoading(false);
    },
  });

  const result = useMemo(
    () => subscription.data?.marineTrafficJobCompleted?.result || {},
    [subscription.data],
  );

  const inviteStatus = useMemo<Record<string, boolean>>(
    () => subscription?.data?.marineTrafficJobCompleted?.inviteStatus || {},
    [subscription.data],
  );

  return {
    loading,
    mutation,
    subscription,
    result,
    inviteStatus,
  };
}
