import React, { FC } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';

import { GetMonthlyPlanFullQuery } from 'src/gql';
import { MonthlyPlanItemMobile } from 'src/pages/monthlyPlan/components/MonthlyPlanItemMobile/MonthlyPlanItemMobile';
import { MonthlyPlanItemDesktop } from './MonthlyPlanItemDesktop/MonthlyPlanItemDesktop';

interface MonthlyPlanItemProps {
  item: NonNullable<GetMonthlyPlanFullQuery['monthlyPlan']>['items'][number];
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  deleteMonthlyPlanItem: any;
  index: number;
}

export const MonthlyPlanItem: FC<MonthlyPlanItemProps> = ({
  item,
  index,
  loading,
  setShowEditForm,
  deleteMonthlyPlanItem,
}) => {
  return (
    <>
      {isMobile && (
        <MonthlyPlanItemMobile
          item={item}
          index={index + 1}
          loading={loading}
          setShowEditForm={setShowEditForm}
          deleteMonthlyPlanItem={deleteMonthlyPlanItem}
        />
      )}
      {isDesktop && (
        <MonthlyPlanItemDesktop
          item={item}
          index={index + 1}
          loading={loading}
          setShowEditForm={setShowEditForm}
          deleteMonthlyPlanItem={deleteMonthlyPlanItem}
        />
      )}
    </>
  );
};
