import React, { FC } from 'react';

import { I18nText } from 'src/components/i18n';
import { Order } from 'src/gql';
import { Button } from 'src/components/buttons';
import { PopupWrapper } from 'src/components/popups/PopupWrapper/PopupWrapper';
import { useRatingPopup } from '../useRatingPopup/useRatingPopup';
import { RateItem } from '../RateItem/RateItem';

interface OrderRatingPopupProps {
  order: Order;
  open: boolean;
  handleClose: () => void;
}

export const OrderRatingPopup: FC<OrderRatingPopupProps> = ({ open, handleClose, order }) => {
  const {
    classes,
    setRateState,
    rateParams,
    rateState,
    loading,
    upsertShipownerOrderScoreMutation,
  } = useRatingPopup({ order, handleClose });
  return (
    <PopupWrapper classes={classes} open={open} handleClose={handleClose}>
      <div className={classes.orderRatingPopup}>
        <div className={classes.closeButton} onClick={handleClose} />
        <div className={classes.popupTitle}>
          <I18nText path="text.rateOrder" />
        </div>

        <div>
          {rateParams.map(({ param, title }) => (
            <RateItem
              title={title}
              param={param}
              rateState={rateState}
              setRateState={setRateState}
            />
          ))}
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            disabled={loading}
            fullWidth
            buttonVariant="main"
            onClick={async () =>
              await upsertShipownerOrderScoreMutation({
                variables: {
                  data: {
                    ...rateState,
                    shipownerId: order.winnerShipownerId as string,
                    orderId: order.id as string,
                  },
                },
              })
            }
          >
            <I18nText path="button.rate" />
          </Button>
        </div>
      </div>
    </PopupWrapper>
  );
};
