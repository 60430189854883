import React, { FC } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import { makeStyles } from '@material-ui/core';
import { DashboardFullGrowthData } from 'src/gql';
import { I18nText } from 'src/components/i18n';
import { localeVar } from 'src/apollo/cache/cache';

interface SummaryCardType {
  type: 'week' | 'month' | 'quarter';
}

interface SummaryCardProps {
  type: SummaryCardType['type'];
  growth: DashboardFullGrowthData;
}

const SUMMARY_CARD_TITLES: { [key in SummaryCardType['type']]: string } = {
  week: 'menuLabel.week',
  month: 'menuLabel.month',
  quarter: 'menuLabel.quarter',
};

const getDate = (type: SummaryCardType['type']) => {
  const locale = localeVar();
  if (type === 'week') {
    return `${dayjs().locale(locale).startOf('isoWeek').format('DD MMM')} -  ${dayjs()
      .locale(locale)
      .endOf('isoWeek')
      .format('DD MMM')} `;
  }
  if (type === 'month') {
    return dayjs().locale(locale).startOf('month').format('MMM');
  }
  if (type === 'quarter') {
    return `Q${dayjs().startOf('month').quarter()}`;
  }
  return;
};

const getPercent = (current: number, prev: number): number =>
  Number((((current - prev) / (prev === 0 ? 1 : prev)) * 100).toFixed(2));

export const SummaryCard: FC<SummaryCardProps> = ({ growth, type }) => {
  const classes = useStyles();
  const tonnagePercent = getPercent(
    growth[type].tonnage.currentValue,
    growth[type].tonnage.previuosValue,
  );

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardTitle}>
        <I18nText path={SUMMARY_CARD_TITLES[type]} />{' '}
        <span className={classes.date}>{getDate(type)}</span>
      </div>
      <div className={classes.tonnage}>
        {growth[type].tonnage.currentValue} <I18nText path="text.tonns" />
      </div>
      {!!growth[type].tonnage.previuosValue && (
        <div className={tonnagePercent >= 0 ? classes.tonnageGrowth : classes.tonnageDecline}>
          {tonnagePercent >= 0 ? '+' : '-'}
          {Math.abs(tonnagePercent)}%
        </div>
      )}
      {!growth[type].tonnage.previuosValue && <div className={classes.tonnageDash}>-</div>}
      <div className={classes.infoWrapper}>
        <div>
          <div className={classes.infoLabel}>
            <I18nText path="label.freights" />
          </div>
          <div className={classes.infoData}>
            {growth[type].orders.currentValue}{' '}
            {!!growth[type].orders.growth && (
              <span
                className={
                  growth[type].orders.growth > 0 ? classes.infoDataGrowth : classes.infoDataDecline
                }
              >
                {growth[type].orders.growth > 0 && '+'}
                {growth[type].orders.growth}
              </span>
            )}
            {!growth[type].orders.growth && <span className={classes.infoDataDash}>-</span>}
          </div>
        </div>
        <div>
          <div className={classes.infoLabel}>
            <I18nText path="label.newVessels" />
          </div>
          <div className={classes.infoData}>
            {growth[type].ships.currentValue}{' '}
            {!!growth[type].ships.growth && (
              <span
                className={
                  growth[type].ships.growth > 0 ? classes.infoDataGrowth : classes.infoDataDecline
                }
              >
                {growth[type].ships.growth > 0 && '+'}
                {growth[type].ships.growth}
              </span>
            )}
            {!growth[type].ships.growth && <span className={classes.infoDataDash}>-</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: '16px',
    background: 'white',
    borderRadius: '16px',
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: '16px',
  },
  date: { fontSize: '20px', color: 'rgba(0, 0, 0, 0.3)' },
  tonnage: {
    fontSize: '40px',
    fontWeight: 600,
    marginBottom: '4px',
  },
  tonnageDecline: {
    fontSize: '20px',
    color: '#BD0000',
    marginBottom: '20px',
    '&::after': {
      display: 'inline-block',
      marginLeft: '10px',
      marginBottom: '3px',
      content: '" "',
      width: '10px',
      height: '10px',
      backgroundSize: '10px 10px',
      backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.34315 1.86974L7 7.5266L12.6569 1.86975' stroke='%23BD0000' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
    },
  },
  tonnageGrowth: {
    fontSize: '20px',
    color: '#00BD2A',
    marginBottom: '20px',
    '&::after': {
      display: 'inline-block',
      marginLeft: '10px',
      marginBottom: '3px',
      content: '" "',
      width: '10px',
      height: '10px',
      backgroundSize: '10px 10px',
      backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.34315 7.86951L7 2.21266L12.6569 7.86951' stroke='%2300BD2A' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
    },
  },
  tonnageDash: {
    fontSize: '20px',
    color: '#00000',
    marginBottom: '20px',
  },
  infoWrapper: {
    display: 'flex',
    gap: '76px',
  },
  infoLabel: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.3)',
  },
  infoData: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0)',
  },
  infoDataGrowth: { fontSize: '16px', color: '#00BD2A' },
  infoDataDecline: { fontSize: '16px', color: '#BD0000' },
  infoDataDash: { fontSize: '16px', color: '#00000' },
}));
