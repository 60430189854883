import React, { FC, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import {
  useUpsertShipownerOrderScoreMutation,
  Order,
  GetOrdersDocument,
  GetOrderOffersDocument,
  useMarkFinishedOrderMutation,
} from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';

export type RateParam = 'calmness' | 'estimationLoyalty' | 'informing' | 'punctuality';
export const rateRange = [1, 2, 3, 4, 5];
export const rateParams: {
  param: RateParam;
  title: string;
}[] = [
  { param: 'calmness', title: 'menuLabel.calmness' },
  { param: 'estimationLoyalty', title: 'menuLabel.loyalty' },
  { param: 'informing', title: 'menuLabel.informing' },
  { param: 'punctuality', title: 'menuLabel.punctuality' },
];

interface useRatingPopupParams {
  order: Order;
  handleClose: () => void;
}

export const useRatingPopup = ({ handleClose, order }: useRatingPopupParams) => {
  const classes = useStyles();
  const [rateState, setRateState] = useState<{
    [key in RateParam]: number;
  }>({
    calmness: !!order?.orderRating?.calmness ? order.orderRating.calmness : 3,
    estimationLoyalty: !!order?.orderRating?.estimationLoyalty
      ? order.orderRating.estimationLoyalty
      : 3,
    informing: !!order?.orderRating?.informing ? order.orderRating.informing : 3,
    punctuality: !!order?.orderRating?.punctuality ? order.orderRating.punctuality : 3,
  });

  const [
    upsertShipownerOrderScoreMutation,
    { loading: upsertShipownerOrderScoreMutationLoading },
  ] = useUpsertShipownerOrderScoreMutation({
    refetchQueries: [GetOrdersDocument, GetOrderOffersDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Rate successful');
      handleClose();
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const [markFinishedOrder, { loading: markFinishedOrderLoading }] = useMarkFinishedOrderMutation({
    refetchQueries: [GetOrdersDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Order is finished');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  return {
    classes,
    rateRange,
    rateParams,
    rateState,
    setRateState,
    markFinishedOrder,
    upsertShipownerOrderScoreMutation,
    loading: markFinishedOrderLoading || upsertShipownerOrderScoreMutationLoading,
  };
};

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '950px',
    borderRadius: '15px',
  },
  orderRatingPopup: {
    position: 'relative',
    padding: '50px 45px',
  },
  popupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
  rateTitle: {
    marginBottom: '5px',
    fontSize: '15px',
  },
  rateItem: {
    marginBottom: '15px',
  },
  rateRange: {
    display: 'flex',
    gap: '5px',
  },
  buttonWrapper: {
    marginTop: '50px',
  },
}));
