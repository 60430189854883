import { Container, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { monthlyPlanItemVar } from 'src/apollo/cache/cache';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { GetMonthlyPlanFullQuery } from 'src/gql';

interface MonthlyPlanItemDesktopProps {
  item: NonNullable<GetMonthlyPlanFullQuery['monthlyPlan']>['items'][number];
  setShowEditForm: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  deleteMonthlyPlanItem: any;
  index: number;
}

export const MonthlyPlanItemDesktop: FC<MonthlyPlanItemDesktopProps> = ({
  item,
  index,
  loading,
  setShowEditForm,
  deleteMonthlyPlanItem,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.orderContainer}>
      <Grid container>
        <Grid
          xs={12}
          sm={6}
          item
          className={classes.idWrapper}
          //@ts-ignore
          justifyContent={'flex-start'}
        >
          <div className={classes.label}>
            <span className={classes.indexNumber}>{index}</span>
          </div>
        </Grid>
        <Grid item className={classes.buttonsWrapper} xs={6} justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              monthlyPlanItemVar(item);
              setShowEditForm(true);
            }}
            disabled={loading}
            buttonVariant="second"
          >
            <I18nText path="button.edit" />
          </Button>

          <Button
            onClick={() => {
              deleteMonthlyPlanItem({
                variables: {
                  id: item.id,
                },
              });
            }}
            disabled={loading}
            buttonVariant="main"
          >
            <I18nText path="button.delete" />
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item className={classes.gridItem} xs={3} md={2}>
          <div className={classes.label}>
            <I18nText path="menuLabel.ports" />
          </div>

          <div className={classes.portsWrapper}>
            {item.ports.map(port => port.name).join(', ')}
          </div>
        </Grid>
        <Grid item className={classes.gridItem} xs={3} md={2}>
          <div className={classes.label}>
            <I18nText path="field.coalMark" />
          </div>

          <div>{item.coalMark}</div>
        </Grid>
        <Grid item className={classes.gridItem} xs={3} md={2}>
          <div className={classes.label}>
            <I18nText path="field.plannedTonnage" />
          </div>

          <div>{item.plannedTonnage}</div>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  orderContainer: {
    position: 'relative',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  month: { fontSize: '14px' },
  label: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '16px',
  },
  portsWrapper: {
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
  },
  buttonsWrapper: {
    minWidth: '340px',
    fontSize: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  idWrapper: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center',
  },
}));
