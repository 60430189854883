import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { Popup } from 'src/components/popups/Popup/Popup';
import { I18nSelect } from 'src/components/i18n/I18nSelect/I18nSelect';
import { localeVar } from 'src/apollo/cache/cache';

export const LanguagePopup: FC<any> = ({ handleClose }) => {
  const intl = useIntl();

  return (
    <Popup handleClose={handleClose} title={intl.formatMessage({ id: 'text.langSelect' })}>
      <I18nSelect
        onClick={language => {
          localeVar(language.code);
          handleClose();
        }}
      />
    </Popup>
  );
};
