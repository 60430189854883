import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';

import { Header } from 'src/components/layout';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { InvitePopup } from 'src/components/popups/InvitePopup/InvitePopup';
import { useReactiveVar } from '@apollo/client';
import { inviteVar } from 'src/apollo/cache/cache';

import { VesselsInArea } from './components/VesselsInArea/VesselsInArea';
import { VesselsInPort } from './components/VesselsInPort/VesselsInPort';

export const MarineTrafficPage = () => {
  const classes = useStyles();

  const [showMap, setShowMap] = useState(true);
  const [showPort, setShowPort] = useState(false);
  const [showInvitePopup, setShowInvitePopup] = useState(false);

  const { emails, mode } = useReactiveVar(inviteVar);

  return (
    <div>
      <Header type={'orders'} />
      <div className={classes.panelTools}>
        <Container>
          <Grid container>
            <Grid xs={12} item className={classes.buttonsWrapper}>
              <Button
                buttonVariant={!showMap ? 'second' : 'main'}
                onClick={() => {
                  setShowMap(true);
                  setShowPort(false);
                }}
              >
                <I18nText path="menuLabel.map" />
              </Button>
              <Button
                buttonVariant={showMap ? 'second' : 'main'}
                onClick={() => {
                  setShowMap(false);
                  setShowPort(true);
                }}
              >
                <I18nText path="menuLabel.incomingInPort" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      {showMap && (
        <Container>
          <VesselsInArea setShowInvitePopup={setShowInvitePopup} />
        </Container>
      )}
      {showPort && (
        <Container>
          <VesselsInPort setShowInvitePopup={setShowInvitePopup} />
        </Container>
      )}
      <InvitePopup
        emails={emails}
        mode={mode}
        open={showInvitePopup}
        handleClose={() => setShowInvitePopup(false)}
      />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
  },
  panelTools: {
    margin: '30px 0 55px',
  },
}));
