import React, { FC } from 'react';
import Countdown from 'react-countdown';

const twoDigis = (n: number) => `0${n}`.slice(-2);

const renderer = ({ days, hours, minutes, seconds }: any) => {
  return (
    <span>
      {days > 0 ? `${days}d ` : ''} {twoDigis(hours)}:{twoDigis(minutes)}:{twoDigis(seconds)}
    </span>
  );
};

export const DateTimer: FC<any> = ({ time }) => {
  return (
    <>
      <Countdown date={time} autoStart precision={3} intervalDelay={0} renderer={renderer} />
    </>
  );
};
