import dayjs from 'dayjs';
import type { OpUnitType } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);
dayjs.extend(isoWeek);

export const currentDate = () => {
  return dayjs();
};

export const getDDMMYYYY = (date: string | number) => {
  return dayjs(+date).format('DD.MM.YYYY');
};

export const getHHMMSS = (date: string | number) => {
  return dayjs(+date).format('HH:mm:ss');
};

export const subtractTimeFromNow = (time: number, unit: OpUnitType) => {
  return dayjs().subtract(time, unit);
};

export const formatToDDMMYYYY = (date: string) => {
  return dayjs(date).format('DD/MM/YYYY');
};

export const formatToYYYYMMDD = (date: string) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const formatToYYYYMM = (date: string) => {
  return dayjs(date).format('YYYY-MM');
};
