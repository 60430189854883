import React, { FC } from 'react';
import { useNavigate } from 'react-router';

import { Grid, makeStyles } from '@material-ui/core';

import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { MonthlyPlan } from 'src/gql';

interface MonthlyPlansItemProps {
  monthlyPlan: Partial<MonthlyPlan>;
  deleteMonthlyPlan: any;
}

export const MonthlyPlansItem: FC<MonthlyPlansItemProps> = ({
  monthlyPlan,
  deleteMonthlyPlan,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Grid xs={12} sm={6} md={4} item>
      <div key={monthlyPlan.month} className={classes.orderContainer}>
        <div className={classes.label}>
          <I18nText path="menuLabel.month" />
        </div>
        <div className={classes.month}>{monthlyPlan.month}</div>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={() => {
              navigate(`/monthly-plans/${monthlyPlan.id}`);
            }}
            buttonVariant="second"
          >
            <I18nText path="button.open" />
          </Button>
          {/* <Button
            onClick={() => {
              deleteMonthlyPlan({
                variables: {
                  id: monthlyPlan.id,
                },
              });
            }}
            buttonVariant="light"
          >
            <I18nText path="button.delete" />
          </Button> */}
        </div>
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  orderContainer: {
    position: 'relative',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  month: { fontSize: '14px' },
  label: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px',
  },
}));
