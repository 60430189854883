import React, { FC } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';

import { Order } from 'src/gql';
import { RequiredNotNull } from 'src/utils/types';
import * as ROUTES from 'src/utils/constants/routes';
import { useOrderListItem } from '../useOrderListItem/useOrderListItem';
import { OrderListItemMobile } from './OrderListItemMobile/OrderListItemMobile';
import { OrderListItemDesktop } from './OrderListItemDesktop/OrderListItemDesktop';

interface OrderListItemProps {
  order: RequiredNotNull<Order>;
  setShowDetailsPopup: () => void;
  setShowMetadataPopup?: () => void;
}

export const OrderListItem: FC<OrderListItemProps> = ({
  order,
  setShowDetailsPopup,
  setShowMetadataPopup,
}) => {
  const { navigateTo } = useOrderListItem();

  const menuOptions = [
    {
      title: { path: 'button.edit' },
      onClick: () => navigateTo(ROUTES.ORDERS_EDIT, order.id),
    },
    {
      title: { path: 'button.copy' },
      onClick: () => navigateTo(ROUTES.ORDERS_COPY, order.id),
    },
    {
      title: { path: 'button.metadata' },
      onClick: () => !!setShowMetadataPopup && setShowMetadataPopup(),
    },
  ];

  return (
    <>
      {isMobile && (
        <OrderListItemMobile
          menuOptions={menuOptions}
          order={order}
          setShowDetailsPopup={setShowDetailsPopup}
        />
      )}
      {isDesktop && (
        <OrderListItemDesktop
          menuOptions={menuOptions}
          order={order}
          setShowDetailsPopup={setShowDetailsPopup}
        />
      )}
    </>
  );
};
