import React, { useCallback, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { I18nText } from 'src/components/i18n';
import {
  GetMyVesselsDocument,
  useCreateVesselMutation,
  useEditVesselMutation,
  useGetMyVesselsQuery,
} from 'src/gql';
import { LIMIT, MY_VESSELS_VARIABLES, VESSEL_TYPES } from 'src/utils/constants';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { Button } from 'src/components/buttons';
import { VesselFormPopup } from 'src/components/popups/VesselFormPopup/VesselFormPopup';
import { ShipownerVesselItem } from './ShipownerVesselItem';
import { ShipownerVesselMobileItem } from './ShipownerVesselMobileItem';
import { mergeDeepRight } from 'ramda';

export const ShipownerVesselList = () => {
  const classes = useStyles();
  const [showVesselForm, setShowVesselForm] = useState(false);
  const [showEditVesselForm, setEditShowVesselForm] = useState(false);
  const { loading, error, data, fetchMore, refetch } = useGetMyVesselsQuery({
    fetchPolicy: 'no-cache',
    variables: { limit: LIMIT, offset: 0 },
  });
  const [createVessel, { loading: formLoading }] = useCreateVesselMutation({
    errorPolicy: 'all',
    refetchQueries: [{ query: GetMyVesselsDocument, variables: MY_VESSELS_VARIABLES }],
    onCompleted: () => {
      setSuccessMesageNotification('Vessel created');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const [editVessel, { loading: editFormLoading }] = useEditVesselMutation({
    errorPolicy: 'all',
    onCompleted: () => {
      setSuccessMesageNotification('Vessel edited');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const onLoadMore = useCallback(async () => {
    await fetchMore({
      //@ts-ignore
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }

        return mergeDeepRight(prev, fetchMoreResult);
      },
      variables: {
        limit: LIMIT,
        offset: data ? data.myVessels.vessels.length : 0,
      },
    });
  }, [data?.myVessels.vessels, fetchMore]);

  return (
    <div>
      <div className={classes.panelTools}>
        <Container>
          <Grid container>
            <Grid xs={12} item>
              <Button onClick={() => setShowVesselForm(true)}>
                <I18nText path="button.addShip" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        {/* {!data?.orders.orders.length && <EmptyText text={'lol kek'} />} */}
        {isDesktop && (
          <Grid container className={classes.tableHeaderWrapper}>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.name" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.constructionYear" />
            </Grid>
            <Grid xs={1} item className={classes.tableHeader}>
              <I18nText path="menuLabel.imo" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.deadWeightTonn" />
            </Grid>
            <Grid xs={2} item className={classes.tableHeader}>
              <I18nText path="menuLabel.draftM" />
            </Grid>
          </Grid>
        )}
        {isDesktop &&
          data?.myVessels.vessels.map((vessel, index) => (
            <ShipownerVesselItem
              vessel={vessel}
              setEditShowVesselForm={setEditShowVesselForm}
              refetch={refetch}
            />
          ))}
        {isMobile &&
          data?.myVessels.vessels.map((vessel, index) => (
            <ShipownerVesselMobileItem
              vessel={vessel}
              setEditShowVesselForm={setEditShowVesselForm}
              refetch={refetch}
            />
          ))}
      </Container>
      {data?.myVessels.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={loading} onClick={() => onLoadMore()}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
      {showVesselForm && (
        <VesselFormPopup
          open={showVesselForm}
          handleClose={() => setShowVesselForm(false)}
          mutation={(params: any) => {
            createVessel(params);
            refetch({});
          }}
          type={VESSEL_TYPES.CREATE}
          loading={formLoading}
        />
      )}

      {showEditVesselForm && (
        <VesselFormPopup
          open={showEditVesselForm}
          handleClose={() => setEditShowVesselForm(false)}
          mutation={(params: any) => {
            editVessel(params);
            refetch({});
          }}
          type={VESSEL_TYPES.EDIT}
          loading={editFormLoading}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 75px',
  },
  fieldOnlyActiveWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  tableHeaderWrapper: {
    padding: '0px 15px',
    marginBottom: '18px',
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
}));
