import { FC, ChangeEventHandler } from 'react';
import CurrencyInput from 'react-currency-input-field';

import TextField from '@material-ui/core/TextField';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import { useFormClasses } from 'src/hooks/useFormClasses';

interface NumberInputProps extends BaseTextFieldProps {
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  error?: boolean;
  name?: string;
  decimalsLimit?: number;
  allowDecimals?: boolean;
  [key: string]: any;
}

export const NumberInput: FC<NumberInputProps> = ({
  value,
  onChange,
  name,
  decimalsLimit,
  allowDecimals,
  ...props
}) => {
  const { classes } = useFormClasses();

  return (
    <TextField
      className={classes.textField}
      variant="outlined"
      name={name}
      value={value}
      onChange={onChange}
      {...props}
      inputProps={{ decimalsLimit, name, allowDecimals }}
      InputProps={{
        inputComponent: NumberFormatCustom,
        className: classes.input,
        ...props.InputProps,
      }}
    />
  );
};

function NumberFormatCustom(props: any) {
  const {
    inputRef,
    onChange,
    allowDecimals = true,
    decimalsLimit = 2,
    name,
    decimalSeparator = '.',
    ...other
  } = props;

  return (
    <CurrencyInput
      {...other}
      id={name}
      name={name}
      allowDecimals={allowDecimals}
      decimalsLimit={decimalsLimit}
      getInputRef={inputRef}
      decimalSeparator={decimalSeparator}
      onValueChange={(value, name) =>
        onChange({
          target: { value, name },
        })
      }
    />
  );
}
