import React, { FC } from 'react';

import { Container, Grid, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Button } from 'src/components/buttons';
import { Header } from 'src/components/layout';
import { useNavigate, useParams } from 'react-router';
import { I18nText } from 'src/components/i18n';
import { MonthlyPlanItem } from './components/MonthlyPlanItem';
import { useMonthlyPlanPage } from './hooks/useMonthlyPlanPage';
import { MonthlyPlanFormPopup } from 'src/components/popups/MontlyPlanFormPopup/MonthlyPlanFormPopup';
import { OPERATION_TYPES } from 'src/utils/constants';

export const MonthlyPlanPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    showForm,
    setShowForm,
    showEditForm,
    setShowEditForm,
    createMonthlyPlanItem,
    updateMonthlyPlanItem,
    deleteMonthlyPlanItem,
    monthlyPlan,
    portsData,
    loading,
    downloadReport,
    isDownloading,
  } = useMonthlyPlanPage();

  return (
    <div>
      <Header type={'orders'} />
      {monthlyPlan && (
        <div className={classes.panelTools}>
          <Container>
            <Grid container spacing={1}>
              <Grid xs={12} md={1} item className={classes.month}>
                <div>{monthlyPlan.month}</div>
              </Grid>
              <Grid xs={4} md={1} item>
                <Button
                  fullWidth
                  startIcon={<ArrowBackIosIcon />}
                  buttonVariant="dark"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <I18nText path="button.back" />
                </Button>
              </Grid>
              <Grid xs={8} md={3} item>
                <Button
                  fullWidth
                  buttonVariant="main"
                  onClick={() => {
                    setShowForm(true);
                  }}
                >
                  <I18nText path="button.addPlanItem" />
                </Button>
              </Grid>
              <Grid xs={12} md={3} item>
                <Button
                  fullWidth
                  buttonVariant="main"
                  disabled={!monthlyPlan || isDownloading}
                  onClick={() => {
                    downloadReport(monthlyPlan.month);
                  }}
                >
                  <I18nText path="button.downloadPlanReport" />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}

      {monthlyPlan && (
        <Container>
          {monthlyPlan.items.map((item, index) => (
            <MonthlyPlanItem
              key={item.id}
              item={item}
              index={index}
              loading={loading}
              setShowEditForm={setShowEditForm}
              deleteMonthlyPlanItem={deleteMonthlyPlanItem}
            />
          ))}
        </Container>
      )}

      {showForm && (
        <MonthlyPlanFormPopup
          open={showForm}
          handleClose={() => {
            setShowForm(false);
          }}
          mutation={createMonthlyPlanItem}
          ports={portsData?.ports}
          loading={loading}
          type={OPERATION_TYPES.CREATE}
        />
      )}
      {showEditForm && (
        <MonthlyPlanFormPopup
          open={showEditForm}
          handleClose={() => {
            setShowEditForm(false);
          }}
          mutation={updateMonthlyPlanItem}
          ports={portsData?.ports}
          loading={loading}
          type={OPERATION_TYPES.EDIT}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
  },
  panelTools: {
    margin: '30px 0 55px',
  },
  month: { fontSize: '18px', display: 'flex', alignItems: 'center' },
}));
