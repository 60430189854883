import React, { FC, memo, useMemo } from 'react';

interface EmbedMapProps {
  latitude?: string | number;
  longitude?: string | number;
  fleet?: string;
  fleet_id: string;
  mtembedcode?: string;
  zoom?: number;
  trackvessel?: number | string;
  trackimo?: number | string;
  trackshipid?: number | string;
  width?: string;
  height?: string;
  border?: string;
  language?: string;
  shownames?: 'true' | 'false';
  maptype?: string;
  vtypes?: string;
  showmenu?: 'true | false';
  remember?: 'true | false';
  retriggerValue?: number;
}

export const EmbedMap = memo<EmbedMapProps>(
  ({ width, height, border, retriggerValue, ...props }) => {
    const iframe = useMemo(() => {
      let {
        fleet,
        fleet_id,
        latitude,
        longitude,
        mtembedcode,
        trackimo,
        trackvessel,
        trackshipid,
        zoom,
        language,
        maptype,
        shownames,
        remember,
        showmenu,
        vtypes,
      } = props;

      let finalSrc: string;

      const src =
        'https:' == document.location.protocol
          ? 'https://www.marinetraffic.com/'
          : 'http://www.marinetraffic.com/';

      if (
        latitude === undefined &&
        longitude === undefined &&
        ((fleet !== undefined && fleet != '') || (fleet_id !== undefined && fleet_id != ''))
      ) {
        latitude = 0;
        longitude = 0;
      }

      if (typeof mtembedcode != 'undefined') {
        let overridenLatLon = '';

        if (
          latitude !== undefined &&
          latitude != '' &&
          longitude !== undefined &&
          longitude != ''
        ) {
          if (zoom === undefined) {
            zoom = 3;
          }

          overridenLatLon =
            '/zoom:' +
            (zoom === undefined ? '' : zoom) +
            '/centery:' +
            (latitude === undefined ? '' : latitude) +
            '/centerx:' +
            (longitude === undefined ? '' : longitude);
        }

        finalSrc =
          src +
          (language === undefined ? 'en' : language) +
          '/ais/customembed' +
          overridenLatLon +
          '/mmsi:' +
          (trackvessel === undefined ? '0' : trackvessel) +
          (trackimo !== undefined ? '/trackimo:' + trackimo : '9451094') +
          '/mtembedcode:' +
          mtembedcode;
      } else {
        finalSrc =
          src +
          (language === undefined ? 'en' : language) +
          '/ais/embed' +
          '/zoom:' +
          (zoom === undefined ? '3' : zoom) +
          '/centery:' +
          (latitude === undefined ? '36' : latitude) +
          '/centerx:' +
          (longitude === undefined ? '23' : longitude) +
          '/maptype:' +
          (maptype === undefined ? '4' : maptype) +
          '/shownames:' +
          (shownames === undefined ? 'false' : shownames) +
          '/mmsi:' +
          (trackvessel === undefined ? '0' : trackvessel) +
          '/trackimo:' +
          (trackimo === undefined ? '0' : trackimo) +
          '/shipid:' +
          (trackshipid === undefined ? '0' : trackshipid) +
          '/fleet_id:' +
          (fleet_id === undefined ? '' : fleet_id) +
          '/vtypes:' +
          (vtypes === undefined ? '' : vtypes) +
          '/showmenu:' +
          (showmenu === undefined ? '' : showmenu) +
          '/remember:' +
          (remember === undefined ? 'false' : remember);
      }

      return (
        <iframe
          name="marinetraffic"
          width={width ?? '550'}
          height={height ?? 300}
          scrolling="no"
          frameBorder={border ?? '0'}
          src={finalSrc}
          key={`map-frame-${retriggerValue}`}
        >
          Browser does not support embedded objects.
          <br />
          Visit directly <a href="http://www.marinetraffic.com/">www.marinetraffic.com</a>
        </iframe>
      );
    }, [props, retriggerValue]);

    return iframe;
  },
);
