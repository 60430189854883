import React, { FC } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';

import { GetPortsQuery, useGetPortsQuery } from 'src/gql';

interface Props {
  classes: any;
  onChange(newPort: string): void;
  name: string;
  value: string;
}

const filter = createFilterOptions<GetPortsQuery['ports'][number]>();

export const PortAutocomplete: FC<Props> = ({ classes, onChange, value }) => {
  const { data } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });
  return (
    <Autocomplete
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: params.inputValue,
            portName: params.inputValue,
          } as any);
        }

        return filtered;
      }}
      onChange={(event, newValue: any) => {
        if (typeof newValue === 'string') {
          onChange(newValue);
        } else if (newValue) {
          if (newValue.inputValue) {
            return onChange(newValue.inputValue);
          }

          onChange(newValue.name ?? newValue.portName);
        }
      }}
      value={value}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={data?.ports ?? []}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') {
          return option;
        }

        return option.inputValue ?? option.name ?? option.portName;
      }}
      renderOption={(option: any) => option.name ?? option.portName}
      freeSolo
      renderInput={({ InputProps, ...rest }) => (
        <TextField
          {...rest}
          variant="outlined"
          InputProps={{
            ...InputProps,
            className: `${classes.input} ${InputProps.className}`,
          }}
        />
      )}
    />
  );
};
