import { ROUTES_FOR_ROLES } from 'src/App.routes';
import { COMMON_ADMIN_ROLES, COMPANY_ROLES, UserRole } from '../constants/roles';

export const checkRouteAvailable = (route: string, userRole: UserRole) =>
  ROUTES_FOR_ROLES[userRole].some(el => el.route === route);

export const checkCompanyAccount = (roles: string[]) => !roles.includes(UserRole.shipowner);

export const checkAdminAccount = (roles: string[]) =>
  roles.some(role => COMMON_ADMIN_ROLES.includes(role));

export const checkShareholderAccount = (roles: string[]) => roles.includes(UserRole.shareholder);

export const getRoleTitle = (role: string, locale: string) =>
  COMPANY_ROLES.find(el => el.name === role)?.label?.[locale];
