import { useMemo, useState } from 'react';
import { pathOr } from 'ramda';
import { useFormik } from 'formik';
import xlsx from 'json-as-xlsx';
import * as yup from 'yup';

import { useMarineTrafficJob } from 'src/hooks';
import { useMtGetExpectedArrivalsMutation, useMtSearchPortsMutation } from 'src/gql';
import { filterShipsByType } from 'src/utils/helpers/ships';
import { formatToYYYYMMDD, getVesselsXlsxObject } from 'src/utils/helpers';
import { FORM_FIELDS } from 'src/utils/constants';
import { MarineTrafficVesselInfo } from 'src/utils/types';

import { useStyles } from '../VesselsInPort.style';

export interface SearchVesselsInPortValues {
  [FORM_FIELDS.DWT_MIN]: string;
  [FORM_FIELDS.DWT_MAX]: string;
  [FORM_FIELDS.START_DATE]: Date;
  [FORM_FIELDS.END_DATE]: Date;
  [FORM_FIELDS.STATUS]: $TSFixMe;
}

const initialSearchVesselsInPortValues = {
  [FORM_FIELDS.DWT_MIN]: '',
  [FORM_FIELDS.DWT_MAX]: '',
  [FORM_FIELDS.START_DATE]: new Date(),
  [FORM_FIELDS.END_DATE]: new Date(),
  [FORM_FIELDS.STATUS]: [],
};

const searchVesselsInPortSchema = yup.object().shape({
  [FORM_FIELDS.DWT_MIN]: yup.string().required(),
  [FORM_FIELDS.DWT_MAX]: yup.string().required(),
  [FORM_FIELDS.START_DATE]: yup.date().required(),
  [FORM_FIELDS.END_DATE]: yup.date().required(),
});

export const useVesselsInPort = () => {
  const classes = useStyles();
  const [selectedPort, setSelectedPort] = useState<any | null>(null);

  const {
    result: portResult,
    loading: portLoading,
    mutation: portMutation,
  } = useMarineTrafficJob({
    mutationHook: useMtSearchPortsMutation,
    jobIdExtractor: pathOr(null, ['mtSearchPorts', 'jobId']),
  });

  const {
    result: shipResult,
    loading: shipLoading,
    mutation: shipMutation,
    inviteStatus,
  } = useMarineTrafficJob({
    mutationHook: useMtGetExpectedArrivalsMutation,
    jobIdExtractor: pathOr(null, ['mtGetExpectedArrivals', 'jobId']),
  });

  const formik = useFormik({
    initialValues: initialSearchVesselsInPortValues,
    validationSchema: searchVesselsInPortSchema,
    onSubmit: values => {
      shipMutation({
        variables: {
          portId: selectedPort['PORT_ID'],
          portName: selectedPort['PORT_NAME'],
          dwtMin: +values[FORM_FIELDS.DWT_MIN],
          dwtMax: +values[FORM_FIELDS.DWT_MAX],
          startDate: formatToYYYYMMDD(values[FORM_FIELDS.START_DATE].toDateString()),
          endDate: formatToYYYYMMDD(values[FORM_FIELDS.END_DATE].toDateString()),
          status: values[FORM_FIELDS.STATUS],
        },
      });
    },
  });

  const vessels = useMemo<MarineTrafficVesselInfo[] | undefined>(
    () => shipResult.result && filterShipsByType(shipResult.result, 'Cargo'),
    [shipResult.result],
  );

  const vesselsXlsxObject = getVesselsXlsxObject(vessels);
  const dowloandVesselsXlsx = () => xlsx(vesselsXlsxObject.data, vesselsXlsxObject.settings);

  return {
    classes,
    dowloandVesselsXlsx,
    formik,
    getPorts: portMutation,
    ports: portResult.ports || [],
    vessels: vessels ?? [],
    loading: shipLoading || portLoading,
    setSelectedPort,
    selectedPort,
    inviteStatus,
  };
};
