import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';

import { OfferListItem } from './OfferListItem';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { Offer } from 'src/gql';
import { ORDERS, ORDER_STATUSES } from 'src/utils/constants';
import { useOfferList } from './useOfferList/useOfferList';
import {
  OrderRatingCompletePopup,
  ReplyPopup,
  NewClosingTimePopup,
  OrderRatingPopup,
} from 'src/components/popups';

export const OfferList: FC = () => {
  const {
    classes,
    markOngoingOrder,
    markOfferAsWinner,
    loadMoreOrderOffers,
    order,
    orderOffers,
    loading,
  } = useOfferList();
  const { id } = useParams<any>();
  const navigate = useNavigate();
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [showNewTimePopup, setShowNewTimePopup] = useState(false);
  const [showRatingCompletePopup, setShowRatingCompletePopup] = useState<boolean>(false);
  const [showRatingPopup, setShowRatingPopup] = useState<boolean>(false);

  return (
    <div>
      <div className={classes.panelTools}>
        <Container>
          <Grid container spacing={3}>
            <Grid xs={12} sm={2} item className={classes.buttonWrapper}>
              <Button
                buttonVariant="dark"
                onClick={() => navigate(-1)}
                startIcon={<ArrowBackIosIcon />}
              >
                <I18nText path="button.back" />
              </Button>
            </Grid>
            <Grid xs={12} sm={5} item className={classes.buttonWrapper}>
              <Button fullWidth onClick={() => setShowReplyPopup(true)}>
                <I18nText path="button.replyAll" />
              </Button>
              <Button fullWidth buttonVariant="second" onClick={() => setShowNewTimePopup(true)}>
                <I18nText path="button.newClosingTime" />
              </Button>
            </Grid>
            <Grid xs={12} sm={3} item className={classes.freightText}>
              {!!order && (
                <I18nText path="text.tenderNo" paramValues={{ orderNumber: order.indexNumber }} />
              )}
            </Grid>
            {order?.state === ORDER_STATUSES.WINNER_SELECTED && (
              <Grid xs={12} sm={2} item className={classes.startButtonWrapper}>
                <Button
                  fullWidth
                  buttonVariant="main"
                  onClick={() => markOngoingOrder({ variables: { orderId: id! } })}
                >
                  <I18nText path="button.start" />
                </Button>
              </Grid>
            )}
            {!!order?.state && order?.state > ORDER_STATUSES.ONGOING && (
              <Grid xs={12} sm={2} item className={classes.startButtonWrapper}>
                <Button onClick={() => setShowRatingPopup(true)} buttonVariant="second">
                  <I18nText path="button.rate" />
                </Button>
              </Grid>
            )}
            {order?.state === ORDER_STATUSES.ONGOING && (
              <Grid xs={12} sm={2} item className={classes.startButtonWrapper}>
                <Button onClick={() => setShowRatingCompletePopup(true)} buttonVariant="second">
                  <I18nText path="button.complete" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Container>
      </div>

      {!!order?.state &&
        order?.state <= ORDER_STATUSES.WINNER_SELECTED &&
        orderOffers?.offers.map(offer => (
          <OfferListItem
            key={offer.id}
            isWinner={order?.winnerOffer?.id === offer.id}
            offer={offer as Offer}
            markOfferAsWinner={markOfferAsWinner}
          />
        ))}

      {!!order?.state &&
        order?.state >= ORDER_STATUSES.ONGOING &&
        orderOffers?.offers.map(offer => (
          <OfferListItem
            key={offer.id}
            isWinner={order?.winnerOffer?.id === offer.id}
            offer={offer as Offer}
          />
        ))}

      {orderOffers?.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={loading} onClick={() => loadMoreOrderOffers()}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
      {showRatingPopup && !!order && (
        <OrderRatingPopup
          order={order}
          open={showRatingPopup}
          handleClose={() => setShowRatingPopup(false)}
        />
      )}
      {showRatingCompletePopup && !!order && (
        <OrderRatingCompletePopup
          order={order}
          open={showRatingCompletePopup}
          handleClose={() => setShowRatingCompletePopup(false)}
        />
      )}
      {showReplyPopup && (
        <ReplyPopup open={showReplyPopup} handleClose={() => setShowReplyPopup(false)} />
      )}
      {showNewTimePopup && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <NewClosingTimePopup
            open={showNewTimePopup}
            order={order}
            handleClose={() => setShowNewTimePopup(false)}
          />
        </MuiPickersUtilsProvider>
      )}
    </div>
  );
};
