import { useEffect, useRef, useState } from 'react';
import { Feature, Map, MapBrowserEvent, MapEvent, View } from 'ol';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import OlVector from 'ol/layer/Vector';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';

import { MarineTrafficVesselInfo, VesselWithLatLon } from 'src/utils/types';
import { usePrevious } from 'src/hooks';
import { equals } from 'ramda';

const circleStyle = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({ color: '#ff2025aa' }),
    stroke: new Stroke({ color: '#cc342eaa', width: 1 }),
  }),
});

interface useAreaPickerParams {
  setArea(data: any): void;
  vessels: MarineTrafficVesselInfo[];
  setSelectedVessel(vessel: MarineTrafficVesselInfo): void;
}

export const useAreaPicker = ({ vessels, setSelectedVessel, setArea }: useAreaPickerParams) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<Map | null>(null);

  const featuresVectorSource = useRef(new VectorSource({ features: [] })).current;
  const featuresVectorLayer = useRef(
    new OlVector({
      source: featuresVectorSource,
    }),
  ).current;

  const prevVessels = usePrevious(vessels);

  useEffect(() => {
    if (!mapRef.current) return;

    let options = {
      view: new View({
        center: [-11000000, 4600000],
        zoom: 1,
      }),
      layers: [],
      controls: [],
      overlays: [],
    };
    let mapObject = new Map(options);

    mapObject.setTarget(mapRef.current);
    setMap(mapObject);

    return () => mapObject.setTarget(undefined);
  }, []);

  useEffect(() => {
    if (!vessels || (prevVessels && equals(prevVessels, vessels))) return;

    featuresVectorSource.clear();

    const features = vessels
      .filter((vessel): vessel is VesselWithLatLon => !!vessel.LON && !!vessel.LAT)
      .map<Feature<Geometry>>(vessel => {
        const feature = new Feature<Geometry>({
          geometry: new Point(fromLonLat([parseFloat(vessel.LON), parseFloat(vessel.LAT)])),
          vessel,
        });
        feature.setStyle(circleStyle);

        return feature;
      });

    featuresVectorSource.addFeatures(features);
  }, [vessels]);

  useEffect(() => {
    if (!map) {
      return;
    }

    map.addLayer(featuresVectorLayer);

    const onClick = (event: MapBrowserEvent<UIEvent>) => {
      const featuresAtPixel = map.getFeaturesAtPixel(event.pixel);

      if (!featuresAtPixel.length) return;
      const [feature] = featuresAtPixel;

      // scroll to vessel
      const vessel = feature.getProperties().vessel;
      if (!vessel) return;

      setSelectedVessel(vessel);
    };

    map.on('singleclick', onClick);

    const moveEndHandler = () => {
      const view = map.getView();

      const raw = view.calculateExtent(map.getSize());
      const leftTop = raw.slice(0, 2);
      const rightBottom = raw.slice(2);

      const poly: number[][] = [
        leftTop,
        [rightBottom[0], leftTop[1]],
        rightBottom,
        [leftTop[0], rightBottom[1]],
      ];
      const lonLat = poly.map(elem => toLonLat(elem));
      const latLng = lonLat.map(elem => elem.reverse());
      setArea(latLng);
    };

    map.on('moveend', moveEndHandler);

    return () => {
      if (map) {
        map.un('moveend', moveEndHandler);
        map.un('singleclick', onClick);
        map.removeLayer(featuresVectorLayer);
      }
    };
  }, [map]);

  return { map, mapRef };
};
