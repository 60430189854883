import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  selectWrapper: {
    width: '100%',
    height: '56px',
    display: 'flex',
    position: 'relative',
  },
  select: {
    '& .MuiSelect-select:focus': {
      borderRadius: '8px',
      backgroundColor: 'rgba(256, 256, 256)',
    },
    minWidth: '99%',
    maxWidth: '300px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
  deleteButton: {
    cursor: 'pointer',
    width: '15px',
    height: '14px',
    backgroundSize: '15 14',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.7139' y1='1.00655' x2='14.3537' y2='13.6463' stroke='%23FF0000'/%3E%3Cline y1='-0.5' x2='17.8753' y2='-0.5' transform='matrix(-0.707107 0.707107 0.707107 0.707107 13.9995 1.36011)' stroke='%23FF0000'/%3E%3C/svg%3E%0A")`,
  },
}));
