import React, { useState } from 'react';
import { isDesktop } from 'react-device-detect';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { I18nText } from 'src/components/i18n';
import { SignInForm } from 'src/pages/auth/components/SignInForm';
import { RegistrationForm } from './components/RegistrationForm';

import backgroundUrl from '../../assets/bg.jpg';

export const AuthPage = () => {
  const classes = useStyles();
  const [isShowRegistration, setShowRegistration] = useState(false);

  return (
    <div className={classes.container}>
      <div className={isDesktop ? classes.formContainer : classes.formContainerMobile}>
        <div className={classes.tabs}>
          <Grid
            className={!isShowRegistration ? classes.leftTab : classes.leftUnActiveTab}
            item
            xs={6}
            onClick={() => setShowRegistration(false)}
          >
            <I18nText path="button.signin" />
          </Grid>

          <Grid
            className={isShowRegistration ? classes.rightTab : classes.rightUnActiveTab}
            item
            xs={6}
            onClick={() => setShowRegistration(true)}
          >
            <I18nText path="button.register" />
          </Grid>
        </div>
        <div className={classes.form}>
          <Grid container alignContent="center" alignItems="center">
            <Grid item xs={12}>
              {isShowRegistration ? (
                <RegistrationForm setShowRegistration={setShowRegistration} />
              ) : (
                <SignInForm />
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: '100%',
    width: '100%',
    minHeight: 'calc(var(--vh, 1vh) * 100)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundImage: `url(${backgroundUrl})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  title: {
    color: 'white',
    position: 'absolute',
    top: '5%',
    left: '2%',
  },
  formContainer: {
    opacity: '1',
    width: '588px',
  },
  tabs: {
    borderRadius: '12px 12px 0 0',
    fontSize: '21px',
    display: 'flex',
    boxShadow: 'none',
    textAlign: 'center',
  },
  leftTabAdminPage: {
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(256,256,256, 0.9)',
    color: '#000',
    borderRadius: '12px 12px 0 0',
  },
  leftTab: {
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(256,256,256, 0.9)',
    color: '#000',
    borderRadius: '12px 0 0 0',
  },
  rightTab: {
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(256,256,256, 0.9)',
    color: '#000',
    borderRadius: '0 12px 0 0',
  },
  leftUnActiveTab: {
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(256,256,256, 0.3)',
    color: '#FFFFFF',
    borderRadius: '12px 0 0 0',
  },
  rightUnActiveTab: {
    cursor: 'pointer',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(256,256,256, 0.3)',
    color: '#FFFFFF',
    borderRadius: '0 12px 0 0',
  },
  form: {
    backgroundColor: 'rgba(256,256,256, 0.9)',
    padding: '64px 42px 45px',
    borderRadius: '0 0 12px 12px',
  },
  input: {
    width: '100%',
  },
  formContainerMobile: {
    maxWidth: '100%',
    width: '100%',
    margin: '15px',
  },
}));
