import React, { FC, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { find, propEq } from 'ramda';

import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { GetMyVesselsDocument, Port, useCreateVesselMutation, OfferRate } from 'src/gql';
import { Button } from 'src/components/buttons';
import {
  setErrorNotification,
  setSuccessMesageNotification,
  getDDMMYYYY,
} from 'src/utils/helpers';
import { I18nText } from 'src/components/i18n';
import { MoneyInput } from 'src/components/inputs';
import { VesselFormPopup } from 'src/components/popups/VesselFormPopup/VesselFormPopup';
import { initialVessel } from 'src/apollo/cache/cache';
import { MY_VESSELS_VARIABLES, OPERATION_TYPES, VESSEL_TYPES } from 'src/utils/constants';
import { useOfferDetails } from 'src/hooks/useOfferDetails';

const offerSchema = yup.object().shape({
  eta: yup.date().required(),
  estimatedQuantity: yup.number().required(),
  vessel: yup.mixed().required(),
});

export const OfferForm: FC<any> = ({ vessels, user, mutation, type, orderId, offer, order }) => {
  const [showVesselForm, setShowVesselForm] = useState(false);

  const [createVessel, { loading: formLoading }] = useCreateVesselMutation({
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: GetMyVesselsDocument,
        variables: MY_VESSELS_VARIABLES,
      },
    ],
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: data => {
      setSuccessMesageNotification('Vessel successfully added');
    },
  });
  const formik = useFormik({
    initialValues: {
      vessel: offer?.vesselId ? find<any>(propEq('id', offer.vesselId), vessels) : initialVessel,
      eta: !!offer ? new Date(+offer.eta) : new Date(),
      estimatedQuantity: !!offer ? offer.estimatedQuantity : 0,
      rates: !!offer
        ? [
            ...offer.rates.map((port: any) => ({
              portId: port.portId,
              demRate: port.demRate,
              freightRate: port.freightRate,
            })),
            ...order.dischargingPorts
              .filter((port: any) => !offer.rates.some((el: any) => el.portId === port.portId))
              .map((port: any) => ({
                portId: port.portId,
              })),
          ]
        : order.dischargingPorts.map((port: any) => ({ portId: port.portId })),
    },
    onSubmit: values => {
      mutation({
        variables: {
          offer: {
            estimatedQuantity: values.estimatedQuantity,
            vesselId: values.vessel.id,
            eta: values.eta,
            orderId,
            id: offer && offer.id,
          },
          rates: values.rates.map((rate: OfferRate) => ({
            ...rate,
            demRate: parseFloat(`${rate.demRate}`),
            freightRate: parseFloat(`${rate.freightRate}`),
          })),
        },
      });
    },
    validationSchema: offerSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { offerDetails } = useOfferDetails({
    offer: { ...offer, ...formik.values },
  });
  const classes = useStyles();

  return (
    <Container className={classes.pageContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container className={classes.formContainer}>
            <Grid item lg={7} xs={12}>
              <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={2}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.carrier" />
                  </InputLabel>
                </Grid>
                <Grid item xs={3} className={classes.dataPlaceholder}>
                  {user.companyName}
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={2} sm={2}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.vessel" />
                  </InputLabel>
                </Grid>
                <Grid item xs={10} sm={7}>
                  <Select
                    className={classes.select}
                    variant="outlined"
                    name={'vessel'}
                    error={!!formik.errors.vessel && !!formik.touched.vessel}
                    value={formik.values.vessel}
                    onChange={event => {
                      formik.setFieldTouched('vessel', false);
                      formik.setFieldValue('vessel', event.target.value);
                    }}
                  >
                    {vessels?.map((vessel: any) => (
                      <MenuItem key={vessel.id} value={vessel}>
                        {vessel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <InputLabel
                    className={classes.addNewVessel}
                    onClick={() => setShowVesselForm(true)}
                  >
                    <I18nText path="text.addNewVessel" />
                  </InputLabel>
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={4} sm={3}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.deadweight" />
                  </InputLabel>
                </Grid>
                <Grid item xs={8} sm={2} className={classes.dataPlaceholder}>
                  {!formik.values.vessel.deadweight ? (
                    <I18nText path="placeholder.selectShip" />
                  ) : (
                    formik.values.vessel.deadweight
                  )}
                </Grid>
                <Grid item xs={4} sm={1}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.imo" />
                  </InputLabel>
                </Grid>
                <Grid item xs={8} sm={3} className={classes.dataPlaceholder}>
                  {!formik.values.vessel.imo ? (
                    <I18nText path="placeholder.selectShip" />
                  ) : (
                    formik.values.vessel.imo
                  )}
                </Grid>
                <Grid item xs={4} sm={1}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.draft" />
                  </InputLabel>
                </Grid>
                <Grid item xs={8} sm={2} className={classes.dataPlaceholder}>
                  {!formik.values.vessel.draft ? (
                    <I18nText path="placeholder.selectShip" />
                  ) : (
                    formik.values.vessel.draft
                  )}
                </Grid>
              </Grid>

              <div className={classes.separationLine} />

              <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={2} sm={2}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.eta" />
                  </InputLabel>
                </Grid>
                <Grid item xs={10} sm={4}>
                  <KeyboardDatePicker
                    margin="normal"
                    inputVariant="outlined"
                    name="startTime"
                    format="dd.MM.yyyy"
                    error={!!formik.errors.eta && !!formik.touched.eta}
                    value={formik.values.eta}
                    InputProps={{
                      className: classes.input,
                    }}
                    onChange={date => {
                      formik.setFieldTouched('eta', false);
                      formik.setFieldValue('eta', date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                <Grid item xs={3} sm={2}>
                  <InputLabel className={classes.label}>
                    <I18nText path="field.estimatedQuantity" />
                  </InputLabel>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <div className={classes.preLabel}>
                    <I18nText path="placeholder.about" />
                  </div>
                </Grid>
                <Grid item xs={7} sm={4}>
                  <TextField
                    variant="outlined"
                    name={'estimatedQuantity'}
                    value={formik.values.estimatedQuantity}
                    error={!!formik.errors.estimatedQuantity && !!formik.touched.estimatedQuantity}
                    onChange={event => {
                      formik.setFieldTouched('estimatedQuantity', false);
                      formik.setFieldValue(
                        'estimatedQuantity',
                        +event.target.value.replace(/\D/, ''),
                      );
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </Grid>
              </Grid>

              {order.dischargingPorts.map((port: any) => (
                <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
                  <Grid item xs={2} sm={2}>
                    <InputLabel className={classes.label}>{port.portName}</InputLabel>
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <InputLabel className={classes.label}>
                      <I18nText path="field.freightRate" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <div className={classes.preLabel}>$</div>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <MoneyInput
                      name={`${port.portName}-freightRate`}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.rates.find((el: any) => el.portId === port.portId)
                          ?.freightRate
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'rates',
                          formik.values.rates.map((rate: any) => {
                            if (rate.portId === port.portId) {
                              return { ...rate, freightRate: event.target.value };
                            }

                            return rate;
                          }),
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <InputLabel className={classes.label}>
                      <I18nText path="field.demRate" />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <div className={classes.preLabel}>$</div>
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <MoneyInput
                      name={`${port.portName}-demRate`}
                      onBlur={formik.handleBlur}
                      value={
                        formik.values.rates.find((el: any) => el.portId === port.portId)?.demRate
                      }
                      onChange={event => {
                        formik.setFieldValue(
                          'rates',
                          formik.values.rates.map((rate: any) => {
                            if (rate.portId === port.portId) {
                              return { ...rate, demRate: event.target.value };
                            }

                            return rate;
                          }),
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item lg={2} xs={12} />
            <Grid item lg={3} xs={12} className={classes.rightContainer}>
              <Grid container>
                <div className={classes.tabs}>
                  <Grid className={classes.rightTab} item xs={6}>
                    <I18nText path="field.summary" />
                  </Grid>
                </div>
                <Grid item xs={12} className={classes.tabsContentContainer}>
                  <div className={classes.summary}>{offerDetails}</div>
                </Grid>
              </Grid>
              <div className={classes.buttonWrapepr}>
                <Button type="submit">
                  {type === OPERATION_TYPES.CREATE && <I18nText path="button.create" />}
                  {type === OPERATION_TYPES.EDIT && <I18nText path="button.edit" />}
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>

      {showVesselForm && (
        <VesselFormPopup
          open={showVesselForm}
          handleClose={() => setShowVesselForm(false)}
          mutation={createVessel}
          loading={formLoading}
          type={VESSEL_TYPES.CREATE}
        />
      )}
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  quantityInput: {
    width: '100%',
  },
  commentInput: {
    width: '100%',
    height: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  separationLine: {
    opacity: 0.1,
    border: '1px solid #000000',
  },
  tabs: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    fontSize: '21px',
    textAlign: 'center',
  },
  rightTab: {
    fontSize: '16px',
    color: ' #2A39BE',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
  },
  nextButtonWrapepr: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
  },
  pageContainer: {
    marginTop: '60px',
    marginBottom: '60px',
  },
  tabsContentContainer: {
    margin: '22px 0 200px',
  },
  summary: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  buttonWrapepr: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
  rightContainer: {
    position: 'relative',
  },
  dataPlaceholder: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  preLabel: {
    textAlign: 'right',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  gridContainer: {
    marginBottom: '25px',
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  addNewVessel: {
    cursor: 'pointer',
    fontSize: '16px',
    textDecoration: 'underline',
    color: '#2A39BE',
  },
}));
