import { useState } from 'react';
import {
  GetMonthlyPlansDocument,
  useCreateMonthlyPlanMutation,
  useDeleteMonthlyPlanMutation,
  useGetMonthlyPlansQuery,
} from 'src/gql';
import {
  currentDate,
  setErrorNotification,
  setSuccessMesageNotification,
} from 'src/utils/helpers';

export const useMonthlyPlansPage = () => {
  const [month, setMonth] = useState<any>(currentDate());
  const [createMonthlyPlan] = useCreateMonthlyPlanMutation({
    refetchQueries: [GetMonthlyPlansDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Plan created');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const [deleteMonthlyPlan] = useDeleteMonthlyPlanMutation({
    errorPolicy: 'all',
    refetchQueries: [GetMonthlyPlansDocument],
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: () => {
      setSuccessMesageNotification('Plan deleted');
    },
  });
  const { data: monthlyPlansData } = useGetMonthlyPlansQuery();

  return {
    month,
    setMonth,
    createMonthlyPlan,
    deleteMonthlyPlan,
    monthlyPlans: monthlyPlansData?.monthlyPlans || [],
  };
};
