import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type AdminsPaginated = {
  __typename?: 'AdminsPaginated';
  admins: Array<User>;
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Cargo = {
  __typename?: 'Cargo';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Chart = {
  __typename?: 'Chart';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  powerBiQuery?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type ChartPlotData = {
  __typename?: 'ChartPlotData';
  plotData: Scalars['JSON'];
};

export enum CoalMark {
  Fat = 'fat',
  Gas = 'gas',
  GasFat = 'gasFat',
}

export type CreateAdminInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  roles: Array<Scalars['String']>;
};

export type CreateOfferInput = {
  estimatedQuantity: Scalars['Float'];
  eta: Scalars['String'];
  orderId: Scalars['ID'];
  vesselId: Scalars['ID'];
};

export type CreateOrderInput = {
  canTime: Scalars['String'];
  cargo: Scalars['String'];
  closeTime: Scalars['String'];
  comment: Scalars['String'];
  layTime: Scalars['String'];
  quantityMax: Scalars['Int'];
  quantityMin: Scalars['Int'];
  startTime?: Maybe<Scalars['String']>;
  stowageFactorUnit: Scalars['Int'];
  type: Scalars['Int'];
  wog: Scalars['String'];
};

export type CreatePortInput = {
  name: Scalars['String'];
};

export type DashboardData = {
  __typename?: 'DashboardData';
  activeOrders: Array<Order>;
  activeOrdersTotal: Scalars['Int'];
};

export type DashboardFullGrowthData = {
  __typename?: 'DashboardFullGrowthData';
  month: PeriodGrowthDataTotal;
  quarter: PeriodGrowthDataTotal;
  week: PeriodGrowthDataTotal;
};

export type EditAdminInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Scalars['String']>>;
};

export type EditOfferInput = {
  estimatedQuantity?: Maybe<Scalars['Float']>;
  eta?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderId?: Maybe<Scalars['ID']>;
  vesselId?: Maybe<Scalars['ID']>;
};

export type EditOrderInput = {
  canTime?: Maybe<Scalars['String']>;
  cargo?: Maybe<Scalars['String']>;
  closeTime?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  layTime?: Maybe<Scalars['String']>;
  quantityMax?: Maybe<Scalars['Int']>;
  quantityMin?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
  stowageFactorUnit?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['Int']>;
  wog?: Maybe<Scalars['String']>;
};

export type EditOrderMetadataInput = {
  blDate?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  coalMark?: Maybe<CoalMark>;
  demPrice?: Maybe<Scalars['Float']>;
  dischargingPortId?: Maybe<Scalars['ID']>;
  freightPrice?: Maybe<Scalars['Float']>;
  loadingPortId?: Maybe<Scalars['ID']>;
  paymentPostponementDays?: Maybe<Scalars['Int']>;
  paymentPostponementEnd?: Maybe<Scalars['String']>;
  tonnage?: Maybe<Scalars['Int']>;
};

export type EditPortInput = {
  name: Scalars['String'];
};

export type EditPortPointInput = {
  indexNumber: Scalars['Int'];
  portName: Scalars['String'];
  rate: Scalars['Int'];
};

export type EditVesselInput = {
  constructionYear?: Maybe<Scalars['Int']>;
  deadweight?: Maybe<Scalars['Float']>;
  draft?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  imo?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type FinishedOrder = {
  __typename?: 'FinishedOrder';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  managerId: Scalars['ID'];
  order?: Maybe<Order>;
  orderId: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
  shipowner?: Maybe<Order>;
  shipownerId: Scalars['ID'];
};

export type InviteOperatorsResult = {
  __typename?: 'InviteOperatorsResult';
  success: Scalars['Boolean'];
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type MarineTrafficAuthResult = {
  __typename?: 'MarineTrafficAuthResult';
  authAs: Scalars['JSON'];
  authSuccess: Scalars['Boolean'];
};

export type MarineTrafficJobCompleteResult = {
  __typename?: 'MarineTrafficJobCompleteResult';
  inviteStatus?: Maybe<Scalars['JSON']>;
  result: Scalars['JSON'];
};

export type MarineTrafficJobStartResult = {
  __typename?: 'MarineTrafficJobStartResult';
  jobId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type MarineTrafficJobsStatus = {
  __typename?: 'MarineTrafficJobsStatus';
  count: Scalars['JSON'];
  jobs: Array<Scalars['JSON']>;
};

export type MarineTrafficSomeJobCompletedResult = {
  __typename?: 'MarineTrafficSomeJobCompletedResult';
  inviteStatus?: Maybe<Scalars['JSON']>;
  jobId: Scalars['String'];
  result: Scalars['JSON'];
};

export type MonthlyPlan = {
  __typename?: 'MonthlyPlan';
  id: Scalars['ID'];
  items: Array<MonthlyPlanItem>;
  month: Scalars['String'];
};

export type MonthlyPlanInput = {
  month: Scalars['String'];
};

export type MonthlyPlanItem = {
  __typename?: 'MonthlyPlanItem';
  coalMark: CoalMark;
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  planId: Scalars['ID'];
  plannedTonnage: Scalars['Int'];
  portIds: Array<Scalars['ID']>;
  ports: Array<Port>;
};

export type MonthlyPlanItemInput = {
  coalMark: CoalMark;
  planId: Scalars['ID'];
  plannedTonnage: Scalars['Int'];
  portIds: Array<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  changeOrderClosingTime: Order;
  createAdmin: User;
  createChart: Chart;
  createMonthlyPlan: MonthlyPlan;
  createMonthlyPlanItem: MonthlyPlanItem;
  createOffer: Offer;
  createOrder: Order;
  createPort: Port;
  createShipownerOrderScore: ShipownerOrderScore;
  createVessel: Vessel;
  deleteAdmin: User;
  deleteChart: Chart;
  deleteFinishedOrder: FinishedOrder;
  deleteMonthlyPlan: MonthlyPlan;
  deleteMonthlyPlanItem: MonthlyPlanItem;
  deleteVessel: Vessel;
  editAdmin: User;
  editOffer: Offer;
  editOrder: Order;
  editVessel: Vessel;
  inviteOperators: InviteOperatorsResult;
  markFinishedOrder: Order;
  markOfferAsWinner: Offer;
  markOngoingOrder: Order;
  mtGetExpectedArrivals: MarineTrafficJobStartResult;
  mtGetVesselData: MarineTrafficJobStartResult;
  mtGetVesselsInArea: MarineTrafficJobStartResult;
  mtPerformAuth: MarineTrafficAuthResult;
  mtSearchPorts: MarineTrafficJobStartResult;
  mtToggleVesselInFleet: MarineTrafficJobStartResult;
  register: RegistrationResponse;
  renamePort: Port;
  replyAll: ReplyAllResponse;
  resetPassword: ResetPasswordResponse;
  resetPasswordForUser: ResetPasswordForUserResult;
  setFinishedOrderRating: Order;
  setNewPassword: SetNewPasswordResponse;
  signIn: LoginResponse;
  updateChart: Chart;
  updateFinishedOrder: FinishedOrder;
  updateMonthlyPlan: MonthlyPlan;
  updateMonthlyPlanItem: MonthlyPlanItem;
  updateOrderMetadata: Order;
  updateOrderVoyageData: Order;
  updateShipownerOrderScore: ShipownerOrderScore;
  upsertShipownerOrderScore: ShipownerOrderScore;
};

export type MutationChangeOrderClosingTimeArgs = {
  id: Scalars['ID'];
  newCloseTime: Scalars['String'];
};

export type MutationCreateAdminArgs = {
  data: CreateAdminInput;
};

export type MutationCreateMonthlyPlanArgs = {
  data: MonthlyPlanInput;
};

export type MutationCreateMonthlyPlanItemArgs = {
  data: MonthlyPlanItemInput;
};

export type MutationCreateOfferArgs = {
  offer: CreateOfferInput;
  rates?: Maybe<Array<OfferRateInput>>;
};

export type MutationCreateOrderArgs = {
  dischargingPorts: Array<PortPointInput>;
  loadingPorts: Array<PortPointInput>;
  order: CreateOrderInput;
};

export type MutationCreatePortArgs = {
  data: CreatePortInput;
};

export type MutationCreateShipownerOrderScoreArgs = {
  data: ShipownerOrderScoreInput;
};

export type MutationCreateVesselArgs = {
  vessel: VesselInput;
};

export type MutationDeleteAdminArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteChartArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFinishedOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMonthlyPlanArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMonthlyPlanItemArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteVesselArgs = {
  id: Scalars['ID'];
};

export type MutationEditAdminArgs = {
  data: EditAdminInput;
  id: Scalars['ID'];
};

export type MutationEditOfferArgs = {
  offer: EditOfferInput;
  rates?: Maybe<Array<OfferRateInput>>;
};

export type MutationEditOrderArgs = {
  dischargingPorts?: Maybe<Array<EditPortPointInput>>;
  loadingPorts?: Maybe<Array<EditPortPointInput>>;
  order: EditOrderInput;
};

export type MutationEditVesselArgs = {
  vessel: EditVesselInput;
};

export type MutationInviteOperatorsArgs = {
  emails: Array<Scalars['String']>;
  inviteText: Scalars['String'];
};

export type MutationMarkFinishedOrderArgs = {
  orderId: Scalars['ID'];
  rating?: Maybe<Scalars['Int']>;
};

export type MutationMarkOfferAsWinnerArgs = {
  offerId: Scalars['ID'];
};

export type MutationMarkOngoingOrderArgs = {
  orderId: Scalars['ID'];
};

export type MutationMtGetExpectedArrivalsArgs = {
  dwtMax: Scalars['Int'];
  dwtMin: Scalars['Int'];
  endDate?: Maybe<Scalars['String']>;
  portId: Scalars['String'];
  portName: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']>>;
};

export type MutationMtGetVesselDataArgs = {
  imo?: Maybe<Scalars['Int']>;
};

export type MutationMtGetVesselsInAreaArgs = {
  dwtMax?: Maybe<Scalars['Int']>;
  dwtMin?: Maybe<Scalars['Int']>;
  lat: Array<Scalars['Float']>;
  lon: Array<Scalars['Float']>;
  status?: Maybe<Array<Scalars['String']>>;
};

export type MutationMtSearchPortsArgs = {
  name: Scalars['String'];
};

export type MutationMtToggleVesselInFleetArgs = {
  fleetName: Scalars['String'];
  imo?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
};

export type MutationRegisterArgs = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
};

export type MutationRenamePortArgs = {
  data: EditPortInput;
  portId: Scalars['ID'];
};

export type MutationReplyAllArgs = {
  orderId: Scalars['ID'];
  text: Scalars['String'];
};

export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};

export type MutationResetPasswordForUserArgs = {
  adminPassword: Scalars['String'];
  email: Scalars['String'];
};

export type MutationSetFinishedOrderRatingArgs = {
  orderId: Scalars['ID'];
  rating: Scalars['Int'];
};

export type MutationSetNewPasswordArgs = {
  newPassword: Scalars['String'];
  passwordResetId: Scalars['ID'];
};

export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationUpdateChartArgs = {
  data: UpdateChartInput;
  id: Scalars['ID'];
};

export type MutationUpdateFinishedOrderArgs = {
  data: UpdateFinishedOrderInput;
  id: Scalars['ID'];
};

export type MutationUpdateMonthlyPlanArgs = {
  data: UpdateMonthlyPlanInput;
  id: Scalars['ID'];
};

export type MutationUpdateMonthlyPlanItemArgs = {
  data: UpdateMonthlyPlanItemInput;
  id: Scalars['ID'];
};

export type MutationUpdateOrderMetadataArgs = {
  data: EditOrderMetadataInput;
  orderId: Scalars['ID'];
};

export type MutationUpdateOrderVoyageDataArgs = {
  orderId: Scalars['ID'];
};

export type MutationUpdateShipownerOrderScoreArgs = {
  data: UpdateShipownerOrderScoreInput;
};

export type MutationUpsertShipownerOrderScoreArgs = {
  data: ShipownerOrderScoreInput;
};

export type Offer = {
  __typename?: 'Offer';
  carrier: Scalars['String'];
  createdAt: Scalars['String'];
  deadweight: Scalars['Float'];
  demRate: Scalars['Float'];
  draft: Scalars['Float'];
  estimatedQuantity: Scalars['Float'];
  eta?: Maybe<Scalars['String']>;
  freightRate: Scalars['Float'];
  id: Scalars['ID'];
  indexNumber: Scalars['Int'];
  order: Order;
  orderId: Scalars['ID'];
  proposal: Scalars['String'];
  rates: Array<OfferRate>;
  rating?: Maybe<ShipownerRating>;
  updatedAt: Scalars['String'];
  userId: Scalars['ID'];
  vessel?: Maybe<Vessel>;
  vesselId: Scalars['ID'];
};

export type OfferRate = {
  __typename?: 'OfferRate';
  createdAt: Scalars['String'];
  demRate: Scalars['Float'];
  freightRate: Scalars['Float'];
  offer?: Maybe<Offer>;
  offerId: Scalars['ID'];
  port?: Maybe<Port>;
  portId: Scalars['ID'];
};

export type OfferRateInput = {
  demRate: Scalars['Float'];
  freightRate: Scalars['Float'];
  portId: Scalars['ID'];
};

export type OffersPaginated = {
  __typename?: 'OffersPaginated';
  hasNextPage: Scalars['Boolean'];
  offers: Array<Offer>;
  total: Scalars['Int'];
};

export type Order = {
  __typename?: 'Order';
  blDate?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  canTime?: Maybe<Scalars['String']>;
  cargo: Scalars['String'];
  cargoId: Scalars['ID'];
  closeTime?: Maybe<Scalars['String']>;
  coalMark: CoalMark;
  comment: Scalars['String'];
  createdAt: Scalars['String'];
  demPrice: Scalars['Float'];
  dischargingPort?: Maybe<Port>;
  dischargingPortId?: Maybe<Scalars['ID']>;
  dischargingPorts: Array<PortPoint>;
  freightPrice: Scalars['Float'];
  id: Scalars['ID'];
  indexNumber: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isOfferMade: Scalars['Boolean'];
  layTime?: Maybe<Scalars['String']>;
  loadingPort?: Maybe<Port>;
  loadingPortId?: Maybe<Scalars['ID']>;
  loadingPorts: Array<PortPoint>;
  orderRating?: Maybe<ShipownerOrderScore>;
  owner?: Maybe<User>;
  paymentPostponementDays?: Maybe<Scalars['Int']>;
  paymentPostponementEnd?: Maybe<Scalars['String']>;
  quantityMax: Scalars['Int'];
  quantityMin: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  shipowner?: Maybe<User>;
  startTime?: Maybe<Scalars['String']>;
  state: Scalars['Int'];
  stowageFactorUnit: Scalars['Int'];
  tonnage: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['String'];
  userId: Scalars['ID'];
  voyageData: Scalars['JSON'];
  winnerOffer?: Maybe<Offer>;
  winnerOfferId?: Maybe<Scalars['ID']>;
  winnerShipownerId?: Maybe<Scalars['ID']>;
  wog: Scalars['String'];
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type OrderFilters = {
  canTime?: Maybe<Scalars['String']>;
  cargo?: Maybe<Scalars['String']>;
  dischargingPorts?: Maybe<Array<Scalars['String']>>;
  layTime?: Maybe<Scalars['String']>;
  loadingPorts?: Maybe<Array<Scalars['String']>>;
  onlyActive?: Maybe<Scalars['Boolean']>;
  quantityMax?: Maybe<Scalars['Int']>;
  quantityMin?: Maybe<Scalars['Int']>;
};

export type OrderForReport = {
  __typename?: 'OrderForReport';
  blDate?: Maybe<Scalars['String']>;
  buyer?: Maybe<Scalars['String']>;
  canTime?: Maybe<Scalars['String']>;
  cargo: Scalars['String'];
  cargoId: Scalars['ID'];
  closeTime?: Maybe<Scalars['String']>;
  coalMark: CoalMark;
  comment: Scalars['String'];
  createdAt: Scalars['String'];
  demPrice: Scalars['Float'];
  dischargingPort?: Maybe<Port>;
  dischargingPortId?: Maybe<Scalars['ID']>;
  dischargingPorts: Array<PortPoint>;
  freightPrice: Scalars['Float'];
  id: Scalars['ID'];
  indexNumber: Scalars['Int'];
  isActive: Scalars['Boolean'];
  isFinished: Scalars['Boolean'];
  isOfferMade: Scalars['Boolean'];
  layTime?: Maybe<Scalars['String']>;
  loadingPort?: Maybe<Port>;
  loadingPortId?: Maybe<Scalars['ID']>;
  loadingPorts: Array<PortPoint>;
  offer?: Maybe<Offer>;
  orderRating?: Maybe<ShipownerOrderScore>;
  owner?: Maybe<User>;
  paymentPostponementDays?: Maybe<Scalars['Int']>;
  paymentPostponementEnd?: Maybe<Scalars['String']>;
  quantityMax: Scalars['Int'];
  quantityMin: Scalars['Int'];
  rating?: Maybe<Scalars['Int']>;
  shipowner?: Maybe<User>;
  startTime?: Maybe<Scalars['String']>;
  state: Scalars['Int'];
  stowageFactorUnit: Scalars['Int'];
  tonnage: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['String'];
  userId: Scalars['ID'];
  vessel?: Maybe<Vessel>;
  voyageData: Scalars['JSON'];
  winnerOffer?: Maybe<Offer>;
  winnerOfferId?: Maybe<Scalars['ID']>;
  winnerShipownerId?: Maybe<Scalars['ID']>;
  wog: Scalars['String'];
};

export type OrderSorting = {
  column?: Maybe<OrderSortingColumn>;
  orderBy?: Maybe<OrderBy>;
};

export enum OrderSortingColumn {
  CanTime = 'CAN_TIME',
  DischargingPorts = 'DISCHARGING_PORTS',
  Id = 'ID',
  LayTime = 'LAY_TIME',
  LoadingPorts = 'LOADING_PORTS',
  QuantityMax = 'QUANTITY_MAX',
  QuantityMin = 'QUANTITY_MIN',
  Status = 'STATUS',
}

export type OrderUpdatedData = {
  __typename?: 'OrderUpdatedData';
  id: Scalars['ID'];
};

export type OrdersPaginated = {
  __typename?: 'OrdersPaginated';
  hasNextPage: Scalars['Boolean'];
  orders: Array<Order>;
  total: Scalars['Int'];
};

export type PeriodGrowthDataItem = {
  __typename?: 'PeriodGrowthDataItem';
  currentValue: Scalars['Float'];
  growth: Scalars['Float'];
  previuosValue: Scalars['Float'];
};

export type PeriodGrowthDataTotal = {
  __typename?: 'PeriodGrowthDataTotal';
  orders: PeriodGrowthDataItem;
  ships: PeriodGrowthDataItem;
  tonnage: PeriodGrowthDataItem;
};

export type PlanReportData = {
  __typename?: 'PlanReportData';
  month: Scalars['String'];
  reports: Array<PlanReportForMark>;
  text: Scalars['String'];
  totalDemurrage: Scalars['Float'];
};

export type PlanReportForMark = {
  __typename?: 'PlanReportForMark';
  coalMark: CoalMark;
  orders: Array<OrderForReport>;
  plannedTonnage: Scalars['Float'];
  ports: Array<Port>;
  tonnage: Scalars['Float'];
};

export type Port = {
  __typename?: 'Port';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type PortPoint = {
  __typename?: 'PortPoint';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  indexNumber: Scalars['Int'];
  orderId: Scalars['ID'];
  portId: Scalars['ID'];
  portName: Scalars['String'];
  rate: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['String'];
};

export type PortPointInput = {
  indexNumber: Scalars['Int'];
  portName: Scalars['String'];
  rate: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  admin: User;
  admins: AdminsPaginated;
  cargos: Array<Cargo>;
  chart?: Maybe<Chart>;
  chartData: ChartPlotData;
  charts: Array<Chart>;
  dashboardData: DashboardData;
  dashboardFullGrowthData: DashboardFullGrowthData;
  finishedOrder?: Maybe<FinishedOrder>;
  finishedOrders: Array<FinishedOrder>;
  jobsStatus: MarineTrafficJobsStatus;
  me: User;
  monthlyPlan?: Maybe<MonthlyPlan>;
  monthlyPlanItem?: Maybe<MonthlyPlanItem>;
  monthlyPlanItems: Array<MonthlyPlanItem>;
  monthlyPlans: Array<MonthlyPlan>;
  myOffers: OffersPaginated;
  myOffersForOrder: OffersPaginated;
  myVessels: VesselsPaginated;
  offer: Offer;
  offerRates: Array<OfferRate>;
  order: Order;
  orderByImo?: Maybe<Order>;
  orderOffers: OffersPaginated;
  orders: OrdersPaginated;
  ordersForShipowners: OrdersPaginated;
  periodGrowthData: PeriodGrowthDataTotal;
  planReportData: PlanReportData;
  ports: Array<Port>;
  reportData: ReportResult;
  reports: ReportData;
  shipownerOrderScores: Array<ShipownerOrderScore>;
  shipownersReportData: ShipownerReportResult;
};

export type QueryAdminArgs = {
  id: Scalars['ID'];
};

export type QueryAdminsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  roles?: Maybe<Array<Scalars['String']>>;
};

export type QueryChartArgs = {
  id: Scalars['ID'];
};

export type QueryChartDataArgs = {
  period: Scalars['String'];
};

export type QueryFinishedOrderArgs = {
  id: Scalars['ID'];
};

export type QueryMonthlyPlanArgs = {
  id: Scalars['ID'];
};

export type QueryMonthlyPlanItemArgs = {
  id: Scalars['ID'];
};

export type QueryMyOffersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryMyOffersForOrderArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderId: Scalars['ID'];
};

export type QueryMyVesselsArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type QueryOfferArgs = {
  id: Scalars['ID'];
};

export type QueryOfferRatesArgs = {
  offerId: Scalars['ID'];
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderByImoArgs = {
  imo: Scalars['Int'];
};

export type QueryOrderOffersArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderId: Scalars['ID'];
};

export type QueryOrdersArgs = {
  filters?: Maybe<OrderFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  onlyActive?: Maybe<Scalars['Boolean']>;
  sorting?: Maybe<OrderSorting>;
};

export type QueryOrdersForShipownersArgs = {
  filters?: Maybe<OrderFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  onlyActive?: Maybe<Scalars['Boolean']>;
  sorting?: Maybe<OrderSorting>;
};

export type QueryPeriodGrowthDataArgs = {
  period: Scalars['String'];
};

export type QueryPlanReportDataArgs = {
  month: Scalars['String'];
};

export type QueryReportDataArgs = {
  endDate?: Maybe<Scalars['String']>;
  managerIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['String'];
};

export type QueryReportsArgs = {
  endDate?: Maybe<Scalars['String']>;
  managerIds?: Maybe<Array<Scalars['ID']>>;
  startDate: Scalars['String'];
};

export type QueryShipownersReportDataArgs = {
  orderBy: OrderBy;
  sortBy: Scalars['String'];
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  password?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type ReplyAllResponse = {
  __typename?: 'ReplyAllResponse';
  success: Scalars['Boolean'];
};

export type ReportAggregatedData = {
  __typename?: 'ReportAggregatedData';
  freightPriceAverage: Scalars['Float'];
  successfulOrdersPercentage: Scalars['Float'];
  tonnageTotal: Scalars['Int'];
};

export type ReportData = {
  __typename?: 'ReportData';
  data: Scalars['JSON'];
};

export type ReportResult = {
  __typename?: 'ReportResult';
  aggregatedData: ReportAggregatedData;
  orders: Array<OrderForReport>;
};

export type ResetPasswordForUserResult = {
  __typename?: 'ResetPasswordForUserResult';
  password: Scalars['String'];
  user: User;
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  success: Scalars['Boolean'];
};

export type SetNewPasswordResponse = {
  __typename?: 'SetNewPasswordResponse';
  reason?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type ShipownerForReport = {
  __typename?: 'ShipownerForReport';
  companyName: Scalars['String'];
  createdAt: Scalars['String'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isPlatformAdmin: Scalars['Boolean'];
  isShareholder: Scalars['Boolean'];
  isShipowner: Scalars['Boolean'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  rating: ShipownerRating;
  roles: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type ShipownerOrderScore = {
  __typename?: 'ShipownerOrderScore';
  average: Scalars['Float'];
  calmness: Scalars['Int'];
  createdAt: Scalars['String'];
  estimationLoyalty: Scalars['Int'];
  informing: Scalars['Int'];
  order?: Maybe<Order>;
  orderId: Scalars['ID'];
  punctuality: Scalars['Int'];
  shipowner?: Maybe<User>;
  shipownerId: Scalars['ID'];
  updatedAt: Scalars['String'];
};

export type ShipownerOrderScoreInput = {
  calmness: Scalars['Int'];
  estimationLoyalty: Scalars['Int'];
  informing: Scalars['Int'];
  orderId: Scalars['ID'];
  punctuality: Scalars['Int'];
  shipownerId: Scalars['ID'];
};

export type ShipownerRating = {
  __typename?: 'ShipownerRating';
  average?: Maybe<Scalars['Float']>;
  ordersCount: Scalars['Int'];
  score?: Maybe<ShipownerScoreValue>;
  successfulCount: Scalars['Int'];
  value: Scalars['Float'];
};

export type ShipownerReportResult = {
  __typename?: 'ShipownerReportResult';
  shipowners: Array<ShipownerForReport>;
};

export type ShipownerScoreValue = {
  __typename?: 'ShipownerScoreValue';
  calmness: Scalars['Float'];
  estimationLoyalty: Scalars['Float'];
  informing: Scalars['Float'];
  punctuality: Scalars['Float'];
};

export type Subscription = {
  __typename?: 'Subscription';
  marineTrafficJobCompleted: MarineTrafficJobCompleteResult;
  marineTrafficSomeJobCompleted: MarineTrafficSomeJobCompletedResult;
  newOrderAdded: Order;
  offerCreated: Offer;
  offerDeleted: Offer;
  offerUpdated: Offer;
  orderUpdated: OrderUpdatedData;
};

export type SubscriptionMarineTrafficJobCompletedArgs = {
  jobId: Scalars['String'];
};

export type SubscriptionOfferCreatedArgs = {
  orderId: Scalars['ID'];
};

export type SubscriptionOfferDeletedArgs = {
  orderId: Scalars['ID'];
};

export type SubscriptionOfferUpdatedArgs = {
  offerId: Scalars['ID'];
};

export type UpdateChartInput = {
  powerBiQuery?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateFinishedOrderInput = {
  rating?: Maybe<Scalars['Int']>;
};

export type UpdateMonthlyPlanInput = {
  month?: Maybe<Scalars['String']>;
};

export type UpdateMonthlyPlanItemInput = {
  coalMark?: Maybe<CoalMark>;
  planId?: Maybe<Scalars['ID']>;
  plannedTonnage?: Maybe<Scalars['Int']>;
  portIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateShipownerOrderScoreInput = {
  calmness: Scalars['Int'];
  estimationLoyalty: Scalars['Int'];
  informing: Scalars['Int'];
  orderId: Scalars['ID'];
  punctuality: Scalars['Int'];
  shipownerId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  companyName: Scalars['String'];
  createdAt: Scalars['String'];
  deleted: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isAdmin: Scalars['Boolean'];
  isPlatformAdmin: Scalars['Boolean'];
  isShareholder: Scalars['Boolean'];
  isShipowner: Scalars['Boolean'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
  rating: ShipownerRating;
  roles: Array<Scalars['String']>;
  updatedAt: Scalars['String'];
};

export type Vessel = {
  __typename?: 'Vessel';
  constructionYear: Scalars['Int'];
  createdAt: Scalars['String'];
  deadweight: Scalars['Float'];
  draft: Scalars['Float'];
  id: Scalars['ID'];
  imo: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['String'];
  userId: Scalars['ID'];
};

export type VesselInput = {
  constructionYear: Scalars['Int'];
  deadweight: Scalars['Float'];
  draft: Scalars['Float'];
  imo: Scalars['Int'];
  name: Scalars['String'];
};

export type VesselsPaginated = {
  __typename?: 'VesselsPaginated';
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
  vessels: Array<Vessel>;
};

export type MonthlyPlanInfoFragment = { __typename?: 'MonthlyPlan' } & Pick<
  MonthlyPlan,
  'id' | 'month'
>;

export type MonthlyPlanItemInfoFragment = { __typename?: 'MonthlyPlanItem' } & Pick<
  MonthlyPlanItem,
  'id' | 'planId' | 'coalMark' | 'plannedTonnage'
> & { ports: Array<{ __typename?: 'Port' } & Pick<Port, 'id' | 'name'>> };

export type MtJobStartResultFragment = { __typename?: 'MarineTrafficJobStartResult' } & Pick<
  MarineTrafficJobStartResult,
  'jobId' | 'success'
>;

export type OfferFragmentFragment = { __typename?: 'Offer' } & Pick<
  Offer,
  | 'id'
  | 'indexNumber'
  | 'carrier'
  | 'createdAt'
  | 'updatedAt'
  | 'userId'
  | 'proposal'
  | 'freightRate'
  | 'deadweight'
  | 'vesselId'
  | 'orderId'
  | 'eta'
  | 'draft'
  | 'estimatedQuantity'
  | 'demRate'
> & {
    order: { __typename?: 'Order' } & OrderFragmentFragment;
    rating?: Maybe<{ __typename?: 'ShipownerRating' } & ShipownerRatingFragmentFragment>;
    rates: Array<{ __typename?: 'OfferRate' } & OfferRateFragmentFragment>;
    vessel?: Maybe<
      { __typename?: 'Vessel' } & Pick<
        Vessel,
        | 'id'
        | 'imo'
        | 'constructionYear'
        | 'createdAt'
        | 'deadweight'
        | 'userId'
        | 'updatedAt'
        | 'draft'
        | 'name'
      >
    >;
  };

export type OfferRateFragmentFragment = { __typename?: 'OfferRate' } & Pick<
  OfferRate,
  'createdAt' | 'demRate' | 'freightRate' | 'offerId' | 'portId'
> & { port?: Maybe<{ __typename?: 'Port' } & PortFragmentFragment> };

export type OrderFragmentFragment = { __typename?: 'Order' } & Pick<
  Order,
  | 'id'
  | 'quantityMin'
  | 'quantityMax'
  | 'type'
  | 'cargo'
  | 'stowageFactorUnit'
  | 'wog'
  | 'layTime'
  | 'canTime'
  | 'startTime'
  | 'closeTime'
  | 'comment'
  | 'isActive'
  | 'isFinished'
  | 'indexNumber'
  | 'rating'
  | 'cargoId'
  | 'createdAt'
  | 'demPrice'
  | 'freightPrice'
  | 'isOfferMade'
  | 'state'
  | 'tonnage'
  | 'updatedAt'
  | 'userId'
  | 'coalMark'
  | 'voyageData'
  | 'winnerShipownerId'
  | 'dischargingPortId'
  | 'loadingPortId'
  | 'buyer'
  | 'blDate'
  | 'paymentPostponementEnd'
  | 'paymentPostponementDays'
> & {
    orderRating?: Maybe<
      { __typename?: 'ShipownerOrderScore' } & Pick<
        ShipownerOrderScore,
        | 'average'
        | 'calmness'
        | 'createdAt'
        | 'estimationLoyalty'
        | 'informing'
        | 'orderId'
        | 'punctuality'
        | 'shipownerId'
        | 'updatedAt'
      >
    >;
    loadingPorts: Array<
      { __typename?: 'PortPoint' } & Pick<
        PortPoint,
        | 'createdAt'
        | 'orderId'
        | 'portId'
        | 'updatedAt'
        | 'type'
        | 'id'
        | 'indexNumber'
        | 'portName'
        | 'rate'
      >
    >;
    dischargingPorts: Array<
      { __typename?: 'PortPoint' } & Pick<
        PortPoint,
        | 'createdAt'
        | 'orderId'
        | 'portId'
        | 'updatedAt'
        | 'type'
        | 'id'
        | 'indexNumber'
        | 'portName'
        | 'rate'
      >
    >;
  };

export type PortFragmentFragment = { __typename?: 'Port' } & Pick<
  Port,
  'createdAt' | 'id' | 'name' | 'updatedAt'
>;

export type ShipownerRatingFragmentFragment = { __typename?: 'ShipownerRating' } & Pick<
  ShipownerRating,
  'average' | 'ordersCount' | 'successfulCount' | 'value'
> & {
    score?: Maybe<
      { __typename?: 'ShipownerScoreValue' } & Pick<
        ShipownerScoreValue,
        'calmness' | 'estimationLoyalty' | 'informing' | 'punctuality'
      >
    >;
  };

export type UserFragmentFragment = { __typename?: 'User' } & Pick<
  User,
  | 'companyName'
  | 'createdAt'
  | 'email'
  | 'firstName'
  | 'id'
  | 'isAdmin'
  | 'isShipowner'
  | 'isShareholder'
  | 'isPlatformAdmin'
  | 'lastName'
  | 'mobile'
  | 'roles'
  | 'updatedAt'
>;

export type VesselFragmentFragment = { __typename?: 'Vessel' } & Pick<
  Vessel,
  'id' | 'name' | 'deadweight' | 'draft' | 'imo' | 'constructionYear'
>;

export type ChangeOrderClosingTimeMutationVariables = Exact<{
  id: Scalars['ID'];
  newCloseTime: Scalars['String'];
}>;

export type ChangeOrderClosingTimeMutation = { __typename?: 'Mutation' } & {
  changeOrderClosingTime: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type CreateAdminMutationVariables = Exact<{
  data: CreateAdminInput;
}>;

export type CreateAdminMutation = { __typename?: 'Mutation' } & {
  createAdmin: { __typename?: 'User' } & UserFragmentFragment;
};

export type CreateOfferMutationVariables = Exact<{
  offer: CreateOfferInput;
  rates?: Maybe<Array<OfferRateInput> | OfferRateInput>;
}>;

export type CreateOfferMutation = { __typename?: 'Mutation' } & {
  createOffer: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type CreateOrderMutationVariables = Exact<{
  order: CreateOrderInput;
  dischargingPorts: Array<PortPointInput> | PortPointInput;
  loadingPorts: Array<PortPointInput> | PortPointInput;
}>;

export type CreateOrderMutation = { __typename?: 'Mutation' } & {
  createOrder: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type CreatePortMutationVariables = Exact<{
  data: CreatePortInput;
}>;

export type CreatePortMutation = { __typename?: 'Mutation' } & {
  createPort: { __typename?: 'Port' } & PortFragmentFragment;
};

export type CreateVesselMutationVariables = Exact<{
  vessel: VesselInput;
}>;

export type CreateVesselMutation = { __typename?: 'Mutation' } & {
  createVessel: { __typename?: 'Vessel' } & VesselFragmentFragment;
};

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAdminMutation = { __typename?: 'Mutation' } & {
  deleteAdmin: { __typename?: 'User' } & Pick<User, 'id'>;
};

export type DeleteVesselMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteVesselMutation = { __typename?: 'Mutation' } & {
  deleteVessel: { __typename?: 'Vessel' } & Pick<Vessel, 'id'>;
};

export type EditAdminMutationVariables = Exact<{
  data: EditAdminInput;
  id: Scalars['ID'];
}>;

export type EditAdminMutation = { __typename?: 'Mutation' } & {
  editAdmin: { __typename?: 'User' } & UserFragmentFragment;
};

export type EditOfferMutationVariables = Exact<{
  offer: EditOfferInput;
  rates: Array<OfferRateInput> | OfferRateInput;
}>;

export type EditOfferMutation = { __typename?: 'Mutation' } & {
  editOffer: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type EditOrderMutationVariables = Exact<{
  order: EditOrderInput;
  dischargingPorts: Array<EditPortPointInput> | EditPortPointInput;
  loadingPorts: Array<EditPortPointInput> | EditPortPointInput;
}>;

export type EditOrderMutation = { __typename?: 'Mutation' } & {
  editOrder: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type EditVesselMutationVariables = Exact<{
  vessel: EditVesselInput;
}>;

export type EditVesselMutation = { __typename?: 'Mutation' } & {
  editVessel: { __typename?: 'Vessel' } & Pick<Vessel, 'id'> & VesselFragmentFragment;
};

export type InviteOperatorsMutationVariables = Exact<{
  emails: Array<Scalars['String']> | Scalars['String'];
  inviteText: Scalars['String'];
}>;

export type InviteOperatorsMutation = { __typename?: 'Mutation' } & {
  inviteOperators: { __typename?: 'InviteOperatorsResult' } & Pick<
    InviteOperatorsResult,
    'success'
  >;
};

export type MarkFinishedOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type MarkFinishedOrderMutation = { __typename?: 'Mutation' } & {
  markFinishedOrder: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type MarkOfferAsWinnerMutationVariables = Exact<{
  offerId: Scalars['ID'];
}>;

export type MarkOfferAsWinnerMutation = { __typename?: 'Mutation' } & {
  markOfferAsWinner: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type MarkOngoingOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type MarkOngoingOrderMutation = { __typename?: 'Mutation' } & {
  markOngoingOrder: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type CreateMonthlyPlanMutationVariables = Exact<{
  data: MonthlyPlanInput;
}>;

export type CreateMonthlyPlanMutation = { __typename?: 'Mutation' } & {
  createMonthlyPlan: { __typename?: 'MonthlyPlan' } & MonthlyPlanInfoFragment;
};

export type CreateMonthlyPlanItemMutationVariables = Exact<{
  data: MonthlyPlanItemInput;
}>;

export type CreateMonthlyPlanItemMutation = { __typename?: 'Mutation' } & {
  createMonthlyPlanItem: { __typename?: 'MonthlyPlanItem' } & MonthlyPlanItemInfoFragment;
};

export type DeleteMonthlyPlanMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMonthlyPlanMutation = { __typename?: 'Mutation' } & {
  deleteMonthlyPlan: { __typename?: 'MonthlyPlan' } & Pick<MonthlyPlan, 'id'>;
};

export type DeleteMonthlyPlanItemMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMonthlyPlanItemMutation = { __typename?: 'Mutation' } & {
  deleteMonthlyPlanItem: { __typename?: 'MonthlyPlanItem' } & Pick<MonthlyPlanItem, 'id'>;
};

export type UpdateMonthlyPlanMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateMonthlyPlanInput;
}>;

export type UpdateMonthlyPlanMutation = { __typename?: 'Mutation' } & {
  updateMonthlyPlan: { __typename?: 'MonthlyPlan' } & MonthlyPlanInfoFragment;
};

export type UpdateMonthlyPlanItemMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateMonthlyPlanItemInput;
}>;

export type UpdateMonthlyPlanItemMutation = { __typename?: 'Mutation' } & {
  updateMonthlyPlanItem: { __typename?: 'MonthlyPlanItem' } & MonthlyPlanItemInfoFragment;
};

export type MtGetExpectedArrivalsMutationVariables = Exact<{
  dwtMin: Scalars['Int'];
  dwtMax: Scalars['Int'];
  portId: Scalars['String'];
  portName: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type MtGetExpectedArrivalsMutation = { __typename?: 'Mutation' } & {
  mtGetExpectedArrivals: { __typename?: 'MarineTrafficJobStartResult' } & MtJobStartResultFragment;
};

export type MtGetVesselsInAreaMutationVariables = Exact<{
  lat: Array<Scalars['Float']> | Scalars['Float'];
  lon: Array<Scalars['Float']> | Scalars['Float'];
  dwtMax?: Maybe<Scalars['Int']>;
  dwtMin?: Maybe<Scalars['Int']>;
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type MtGetVesselsInAreaMutation = { __typename?: 'Mutation' } & {
  mtGetVesselsInArea: { __typename?: 'MarineTrafficJobStartResult' } & MtJobStartResultFragment;
};

export type MtSearchPortsMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type MtSearchPortsMutation = { __typename?: 'Mutation' } & {
  mtSearchPorts: { __typename?: 'MarineTrafficJobStartResult' } & MtJobStartResultFragment;
};

export type RegisterMutationVariables = Exact<{
  companyName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobile: Scalars['String'];
}>;

export type RegisterMutation = { __typename?: 'Mutation' } & {
  register: { __typename?: 'RegistrationResponse' } & Pick<
    RegistrationResponse,
    'success' | 'reason' | 'password' | 'token'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'companyName'
          | 'createdAt'
          | 'email'
          | 'firstName'
          | 'id'
          | 'isAdmin'
          | 'isShipowner'
          | 'lastName'
          | 'mobile'
          | 'roles'
          | 'updatedAt'
        >
      >;
    };
};

export type RenamePortMutationVariables = Exact<{
  data: EditPortInput;
  portId: Scalars['ID'];
}>;

export type RenamePortMutation = { __typename?: 'Mutation' } & {
  renamePort: { __typename?: 'Port' } & PortFragmentFragment;
};

export type ReplyAllOffersMutationVariables = Exact<{
  orderId: Scalars['ID'];
  text: Scalars['String'];
}>;

export type ReplyAllOffersMutation = { __typename?: 'Mutation' } & {
  replyAll: { __typename?: 'ReplyAllResponse' } & Pick<ReplyAllResponse, 'success'>;
};

export type SetFinishedOrderRatingMutationVariables = Exact<{
  orderId: Scalars['ID'];
  rating: Scalars['Int'];
}>;

export type SetFinishedOrderRatingMutation = { __typename?: 'Mutation' } & {
  setFinishedOrderRating: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type SetNewPasswordMutationVariables = Exact<{
  passwordResetId: Scalars['ID'];
  newPassword: Scalars['String'];
}>;

export type SetNewPasswordMutation = { __typename?: 'Mutation' } & {
  setNewPassword: { __typename?: 'SetNewPasswordResponse' } & Pick<
    SetNewPasswordResponse,
    'success' | 'reason'
  >;
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignInMutation = { __typename?: 'Mutation' } & {
  signIn: { __typename?: 'LoginResponse' } & Pick<
    LoginResponse,
    'success' | 'reason' | 'token'
  > & {
      user?: Maybe<
        { __typename?: 'User' } & Pick<
          User,
          | 'companyName'
          | 'createdAt'
          | 'email'
          | 'firstName'
          | 'id'
          | 'isAdmin'
          | 'isShipowner'
          | 'lastName'
          | 'mobile'
          | 'roles'
          | 'updatedAt'
        >
      >;
    };
};

export type UpdateOrderMetadataMutationVariables = Exact<{
  orderId: Scalars['ID'];
  data: EditOrderMetadataInput;
}>;

export type UpdateOrderMetadataMutation = { __typename?: 'Mutation' } & {
  updateOrderMetadata: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type UpsertShipownerOrderScoreMutationVariables = Exact<{
  data: ShipownerOrderScoreInput;
}>;

export type UpsertShipownerOrderScoreMutation = { __typename?: 'Mutation' } & {
  upsertShipownerOrderScore: { __typename?: 'ShipownerOrderScore' } & Pick<
    ShipownerOrderScore,
    | 'average'
    | 'calmness'
    | 'createdAt'
    | 'estimationLoyalty'
    | 'informing'
    | 'orderId'
    | 'punctuality'
    | 'shipownerId'
    | 'updatedAt'
  > & { shipowner?: Maybe<{ __typename?: 'User' } & UserFragmentFragment> };
};

export type GetAdminsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  roles?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type GetAdminsQuery = { __typename?: 'Query' } & {
  admins: { __typename?: 'AdminsPaginated' } & Pick<AdminsPaginated, 'total' | 'hasNextPage'> & {
      admins: Array<{ __typename?: 'User' } & UserFragmentFragment>;
    };
};

export type GetCargosQueryVariables = Exact<{ [key: string]: never }>;

export type GetCargosQuery = { __typename?: 'Query' } & {
  cargos: Array<{ __typename?: 'Cargo' } & Pick<Cargo, 'id' | 'name'>>;
};

export type GetDashboardDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardDataQuery = { __typename?: 'Query' } & {
  dashboardData: { __typename?: 'DashboardData' } & Pick<DashboardData, 'activeOrdersTotal'> & {
      activeOrders: Array<{ __typename?: 'Order' } & OrderFragmentFragment>;
    };
};

export type GetDashboardGrowthDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardGrowthDataQuery = { __typename?: 'Query' } & {
  dashboardFullGrowthData: { __typename?: 'DashboardFullGrowthData' } & {
    quarter: { __typename?: 'PeriodGrowthDataTotal' } & {
      ships: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      orders: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      tonnage: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
    };
    week: { __typename?: 'PeriodGrowthDataTotal' } & {
      ships: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      orders: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      tonnage: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
    };
    month: { __typename?: 'PeriodGrowthDataTotal' } & {
      ships: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      orders: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
      tonnage: { __typename?: 'PeriodGrowthDataItem' } & Pick<
        PeriodGrowthDataItem,
        'previuosValue' | 'currentValue' | 'growth'
      >;
    };
  };
};

export type GetMyOffersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type GetMyOffersQuery = { __typename?: 'Query' } & {
  myOffers: { __typename?: 'OffersPaginated' } & Pick<OffersPaginated, 'hasNextPage' | 'total'> & {
      offers: Array<{ __typename?: 'Offer' } & OfferFragmentFragment>;
    };
};

export type GetMyOffersForOrderQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderId: Scalars['ID'];
}>;

export type GetMyOffersForOrderQuery = { __typename?: 'Query' } & {
  myOffersForOrder: { __typename?: 'OffersPaginated' } & Pick<
    OffersPaginated,
    'hasNextPage' | 'total'
  > & { offers: Array<{ __typename?: 'Offer' } & OfferFragmentFragment> };
};

export type GetMyVesselsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;

export type GetMyVesselsQuery = { __typename?: 'Query' } & {
  myVessels: { __typename?: 'VesselsPaginated' } & Pick<
    VesselsPaginated,
    'hasNextPage' | 'total'
  > & { vessels: Array<{ __typename?: 'Vessel' } & VesselFragmentFragment> };
};

export type GetOfferQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetOfferQuery = { __typename?: 'Query' } & {
  offer: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OrderQuery = { __typename?: 'Query' } & {
  order: { __typename?: 'Order' } & {
    winnerOffer?: Maybe<{ __typename?: 'Offer' } & OfferFragmentFragment>;
  } & OrderFragmentFragment;
};

export type GetOrderByImoQueryVariables = Exact<{
  imo: Scalars['Int'];
}>;

export type GetOrderByImoQuery = { __typename?: 'Query' } & {
  orderByImo?: Maybe<
    { __typename?: 'Order' } & {
      winnerOffer?: Maybe<{ __typename?: 'Offer' } & OfferFragmentFragment>;
      loadingPort?: Maybe<{ __typename?: 'Port' } & Pick<Port, 'id' | 'name'>>;
      dischargingPort?: Maybe<{ __typename?: 'Port' } & Pick<Port, 'id' | 'name'>>;
    } & OrderFragmentFragment
  >;
};

export type GetOrderOffersQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderId: Scalars['ID'];
}>;

export type GetOrderOffersQuery = { __typename?: 'Query' } & {
  orderOffers: { __typename?: 'OffersPaginated' } & Pick<
    OffersPaginated,
    'hasNextPage' | 'total'
  > & { offers: Array<{ __typename?: 'Offer' } & OfferFragmentFragment> };
};

export type GetOrdersQueryVariables = Exact<{
  onlyActive: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filters: OrderFilters;
  sorting: OrderSorting;
}>;

export type GetOrdersQuery = { __typename?: 'Query' } & {
  orders: { __typename?: 'OrdersPaginated' } & Pick<OrdersPaginated, 'hasNextPage' | 'total'> & {
      orders: Array<{ __typename?: 'Order' } & Pick<Order, 'isOfferMade'> & OrderFragmentFragment>;
    };
};

export type GetOrdersForShipownersQueryVariables = Exact<{
  onlyActive: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  filters: OrderFilters;
  sorting: OrderSorting;
}>;

export type GetOrdersForShipownersQuery = { __typename?: 'Query' } & {
  ordersForShipowners: { __typename?: 'OrdersPaginated' } & Pick<
    OrdersPaginated,
    'hasNextPage' | 'total'
  > & {
      orders: Array<{ __typename?: 'Order' } & Pick<Order, 'isOfferMade'> & OrderFragmentFragment>;
    };
};

export type GetPeriodGrowthQueryVariables = Exact<{
  period: Scalars['String'];
}>;

export type GetPeriodGrowthQuery = { __typename?: 'Query' } & {
  periodGrowthData: { __typename?: 'PeriodGrowthDataTotal' } & {
    orders: { __typename?: 'PeriodGrowthDataItem' } & Pick<
      PeriodGrowthDataItem,
      'currentValue' | 'previuosValue' | 'growth'
    >;
    ships: { __typename?: 'PeriodGrowthDataItem' } & Pick<
      PeriodGrowthDataItem,
      'currentValue' | 'previuosValue' | 'growth'
    >;
    tonnage: { __typename?: 'PeriodGrowthDataItem' } & Pick<
      PeriodGrowthDataItem,
      'currentValue' | 'previuosValue' | 'growth'
    >;
  };
};

export type GetPortsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPortsQuery = { __typename?: 'Query' } & {
  ports: Array<{ __typename?: 'Port' } & Pick<Port, 'id' | 'name'>>;
};

export type GetReportsQueryVariables = Exact<{
  endDate: Scalars['String'];
  managerIds?: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  startDate: Scalars['String'];
}>;

export type GetReportsQuery = { __typename?: 'Query' } & {
  reports: { __typename?: 'ReportData' } & Pick<ReportData, 'data'>;
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = { __typename?: 'Query' } & {
  me: { __typename?: 'User' } & UserFragmentFragment;
};

export type GetMonthlyPlanQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMonthlyPlanQuery = { __typename?: 'Query' } & {
  monthlyPlan?: Maybe<{ __typename?: 'MonthlyPlan' } & MonthlyPlanInfoFragment>;
};

export type GetMonthlyPlanFullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMonthlyPlanFullQuery = { __typename?: 'Query' } & {
  monthlyPlan?: Maybe<
    { __typename?: 'MonthlyPlan' } & {
      items: Array<{ __typename?: 'MonthlyPlanItem' } & MonthlyPlanItemInfoFragment>;
    } & MonthlyPlanInfoFragment
  >;
};

export type GetMonthlyPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetMonthlyPlansQuery = { __typename?: 'Query' } & {
  monthlyPlans: Array<{ __typename?: 'MonthlyPlan' } & MonthlyPlanInfoFragment>;
};

export type MarineTrafficJobCompletedSubscriptionVariables = Exact<{
  jobId: Scalars['String'];
}>;

export type MarineTrafficJobCompletedSubscription = { __typename?: 'Subscription' } & {
  marineTrafficJobCompleted: { __typename?: 'MarineTrafficJobCompleteResult' } & Pick<
    MarineTrafficJobCompleteResult,
    'result' | 'inviteStatus'
  >;
};

export type NewOrderAddedSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewOrderAddedSubscription = { __typename?: 'Subscription' } & {
  newOrderAdded: { __typename?: 'Order' } & OrderFragmentFragment;
};

export type OfferCreatedSubscriptionVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type OfferCreatedSubscription = { __typename?: 'Subscription' } & {
  offerCreated: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type OfferDeletedSubscriptionVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type OfferDeletedSubscription = { __typename?: 'Subscription' } & {
  offerDeleted: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export type OfferUpdatedSubscriptionVariables = Exact<{
  offerId: Scalars['ID'];
}>;

export type OfferUpdatedSubscription = { __typename?: 'Subscription' } & {
  offerUpdated: { __typename?: 'Offer' } & OfferFragmentFragment;
};

export const MonthlyPlanInfoFragmentDoc = gql`
  fragment MonthlyPlanInfo on MonthlyPlan {
    id
    month
  }
`;
export const MonthlyPlanItemInfoFragmentDoc = gql`
  fragment MonthlyPlanItemInfo on MonthlyPlanItem {
    id
    planId
    coalMark
    plannedTonnage
    ports {
      id
      name
    }
  }
`;
export const MtJobStartResultFragmentDoc = gql`
  fragment MtJobStartResult on MarineTrafficJobStartResult {
    jobId
    success
  }
`;
export const OrderFragmentFragmentDoc = gql`
  fragment OrderFragment on Order {
    id
    quantityMin
    quantityMax
    type
    cargo
    stowageFactorUnit
    wog
    layTime
    canTime
    startTime
    closeTime
    comment
    isActive
    isFinished
    indexNumber
    rating
    cargoId
    createdAt
    demPrice
    orderRating {
      average
      calmness
      createdAt
      estimationLoyalty
      informing
      orderId
      punctuality
      shipownerId
      updatedAt
    }
    freightPrice
    isOfferMade
    state
    tonnage
    updatedAt
    userId
    loadingPorts {
      createdAt
      orderId
      portId
      updatedAt
      type
      id
      indexNumber
      portName
      rate
    }
    dischargingPorts {
      createdAt
      orderId
      portId
      updatedAt
      type
      id
      indexNumber
      portName
      rate
    }
    coalMark
    voyageData
    winnerShipownerId
    dischargingPortId
    loadingPortId
    buyer
    blDate
    paymentPostponementEnd
    paymentPostponementDays
  }
`;
export const ShipownerRatingFragmentFragmentDoc = gql`
  fragment ShipownerRatingFragment on ShipownerRating {
    average
    ordersCount
    successfulCount
    value
    score {
      calmness
      estimationLoyalty
      informing
      punctuality
    }
  }
`;
export const PortFragmentFragmentDoc = gql`
  fragment PortFragment on Port {
    createdAt
    id
    name
    updatedAt
  }
`;
export const OfferRateFragmentFragmentDoc = gql`
  fragment OfferRateFragment on OfferRate {
    createdAt
    demRate
    freightRate
    offerId
    port {
      ...PortFragment
    }
    portId
  }
  ${PortFragmentFragmentDoc}
`;
export const OfferFragmentFragmentDoc = gql`
  fragment OfferFragment on Offer {
    id
    indexNumber
    carrier
    createdAt
    order {
      ...OrderFragment
    }
    updatedAt
    userId
    proposal
    freightRate
    deadweight
    vesselId
    orderId
    eta
    draft
    rating {
      ...ShipownerRatingFragment
    }
    rates {
      ...OfferRateFragment
    }
    estimatedQuantity
    demRate
    vessel {
      id
      imo
      constructionYear
      createdAt
      deadweight
      userId
      updatedAt
      draft
      name
    }
  }
  ${OrderFragmentFragmentDoc}
  ${ShipownerRatingFragmentFragmentDoc}
  ${OfferRateFragmentFragmentDoc}
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    companyName
    createdAt
    email
    firstName
    id
    isAdmin
    isShipowner
    isShareholder
    isPlatformAdmin
    lastName
    mobile
    roles
    updatedAt
  }
`;
export const VesselFragmentFragmentDoc = gql`
  fragment VesselFragment on Vessel {
    id
    name
    deadweight
    draft
    imo
    constructionYear
  }
`;
export const ChangeOrderClosingTimeDocument = gql`
  mutation ChangeOrderClosingTime($id: ID!, $newCloseTime: String!) {
    changeOrderClosingTime(id: $id, newCloseTime: $newCloseTime) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type ChangeOrderClosingTimeMutationFn = Apollo.MutationFunction<
  ChangeOrderClosingTimeMutation,
  ChangeOrderClosingTimeMutationVariables
>;

/**
 * __useChangeOrderClosingTimeMutation__
 *
 * To run a mutation, you first call `useChangeOrderClosingTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrderClosingTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrderClosingTimeMutation, { data, loading, error }] = useChangeOrderClosingTimeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newCloseTime: // value for 'newCloseTime'
 *   },
 * });
 */
export function useChangeOrderClosingTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeOrderClosingTimeMutation,
    ChangeOrderClosingTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeOrderClosingTimeMutation,
    ChangeOrderClosingTimeMutationVariables
  >(ChangeOrderClosingTimeDocument, options);
}
export type ChangeOrderClosingTimeMutationHookResult = ReturnType<
  typeof useChangeOrderClosingTimeMutation
>;
export type ChangeOrderClosingTimeMutationResult =
  Apollo.MutationResult<ChangeOrderClosingTimeMutation>;
export type ChangeOrderClosingTimeMutationOptions = Apollo.BaseMutationOptions<
  ChangeOrderClosingTimeMutation,
  ChangeOrderClosingTimeMutationVariables
>;
export const CreateAdminDocument = gql`
  mutation createAdmin($data: CreateAdminInput!) {
    createAdmin(data: $data) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateAdminMutationFn = Apollo.MutationFunction<
  CreateAdminMutation,
  CreateAdminMutationVariables
>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(
    CreateAdminDocument,
    options,
  );
}
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateAdminMutation,
  CreateAdminMutationVariables
>;
export const CreateOfferDocument = gql`
  mutation CreateOffer($offer: CreateOfferInput!, $rates: [OfferRateInput!]) {
    createOffer(offer: $offer, rates: $rates) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;
export type CreateOfferMutationFn = Apollo.MutationFunction<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;

/**
 * __useCreateOfferMutation__
 *
 * To run a mutation, you first call `useCreateOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOfferMutation, { data, loading, error }] = useCreateOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      rates: // value for 'rates'
 *   },
 * });
 */
export function useCreateOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOfferMutation, CreateOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOfferMutation, CreateOfferMutationVariables>(
    CreateOfferDocument,
    options,
  );
}
export type CreateOfferMutationHookResult = ReturnType<typeof useCreateOfferMutation>;
export type CreateOfferMutationResult = Apollo.MutationResult<CreateOfferMutation>;
export type CreateOfferMutationOptions = Apollo.BaseMutationOptions<
  CreateOfferMutation,
  CreateOfferMutationVariables
>;
export const CreateOrderDocument = gql`
  mutation CreateOrder(
    $order: CreateOrderInput!
    $dischargingPorts: [PortPointInput!]!
    $loadingPorts: [PortPointInput!]!
  ) {
    createOrder(order: $order, dischargingPorts: $dischargingPorts, loadingPorts: $loadingPorts) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type CreateOrderMutationFn = Apollo.MutationFunction<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *      dischargingPorts: // value for 'dischargingPorts'
 *      loadingPorts: // value for 'loadingPorts'
 *   },
 * });
 */
export function useCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
    CreateOrderDocument,
    options,
  );
}
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateOrderMutation,
  CreateOrderMutationVariables
>;
export const CreatePortDocument = gql`
  mutation CreatePort($data: CreatePortInput!) {
    createPort(data: $data) {
      ...PortFragment
    }
  }
  ${PortFragmentFragmentDoc}
`;
export type CreatePortMutationFn = Apollo.MutationFunction<
  CreatePortMutation,
  CreatePortMutationVariables
>;

/**
 * __useCreatePortMutation__
 *
 * To run a mutation, you first call `useCreatePortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortMutation, { data, loading, error }] = useCreatePortMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePortMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePortMutation, CreatePortMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePortMutation, CreatePortMutationVariables>(
    CreatePortDocument,
    options,
  );
}
export type CreatePortMutationHookResult = ReturnType<typeof useCreatePortMutation>;
export type CreatePortMutationResult = Apollo.MutationResult<CreatePortMutation>;
export type CreatePortMutationOptions = Apollo.BaseMutationOptions<
  CreatePortMutation,
  CreatePortMutationVariables
>;
export const CreateVesselDocument = gql`
  mutation CreateVessel($vessel: VesselInput!) {
    createVessel(vessel: $vessel) {
      ...VesselFragment
    }
  }
  ${VesselFragmentFragmentDoc}
`;
export type CreateVesselMutationFn = Apollo.MutationFunction<
  CreateVesselMutation,
  CreateVesselMutationVariables
>;

/**
 * __useCreateVesselMutation__
 *
 * To run a mutation, you first call `useCreateVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVesselMutation, { data, loading, error }] = useCreateVesselMutation({
 *   variables: {
 *      vessel: // value for 'vessel'
 *   },
 * });
 */
export function useCreateVesselMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateVesselMutation, CreateVesselMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateVesselMutation, CreateVesselMutationVariables>(
    CreateVesselDocument,
    options,
  );
}
export type CreateVesselMutationHookResult = ReturnType<typeof useCreateVesselMutation>;
export type CreateVesselMutationResult = Apollo.MutationResult<CreateVesselMutation>;
export type CreateVesselMutationOptions = Apollo.BaseMutationOptions<
  CreateVesselMutation,
  CreateVesselMutationVariables
>;
export const DeleteAdminDocument = gql`
  mutation deleteAdmin($id: ID!) {
    deleteAdmin(id: $id) {
      id
    }
  }
`;
export type DeleteAdminMutationFn = Apollo.MutationFunction<
  DeleteAdminMutation,
  DeleteAdminMutationVariables
>;

/**
 * __useDeleteAdminMutation__
 *
 * To run a mutation, you first call `useDeleteAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminMutation, { data, loading, error }] = useDeleteAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAdminMutation, DeleteAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdminMutation, DeleteAdminMutationVariables>(
    DeleteAdminDocument,
    options,
  );
}
export type DeleteAdminMutationHookResult = ReturnType<typeof useDeleteAdminMutation>;
export type DeleteAdminMutationResult = Apollo.MutationResult<DeleteAdminMutation>;
export type DeleteAdminMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdminMutation,
  DeleteAdminMutationVariables
>;
export const DeleteVesselDocument = gql`
  mutation DeleteVessel($id: ID!) {
    deleteVessel(id: $id) {
      id
    }
  }
`;
export type DeleteVesselMutationFn = Apollo.MutationFunction<
  DeleteVesselMutation,
  DeleteVesselMutationVariables
>;

/**
 * __useDeleteVesselMutation__
 *
 * To run a mutation, you first call `useDeleteVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVesselMutation, { data, loading, error }] = useDeleteVesselMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVesselMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteVesselMutation, DeleteVesselMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteVesselMutation, DeleteVesselMutationVariables>(
    DeleteVesselDocument,
    options,
  );
}
export type DeleteVesselMutationHookResult = ReturnType<typeof useDeleteVesselMutation>;
export type DeleteVesselMutationResult = Apollo.MutationResult<DeleteVesselMutation>;
export type DeleteVesselMutationOptions = Apollo.BaseMutationOptions<
  DeleteVesselMutation,
  DeleteVesselMutationVariables
>;
export const EditAdminDocument = gql`
  mutation editAdmin($data: EditAdminInput!, $id: ID!) {
    editAdmin(data: $data, id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type EditAdminMutationFn = Apollo.MutationFunction<
  EditAdminMutation,
  EditAdminMutationVariables
>;

/**
 * __useEditAdminMutation__
 *
 * To run a mutation, you first call `useEditAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAdminMutation, { data, loading, error }] = useEditAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAdminMutation, EditAdminMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAdminMutation, EditAdminMutationVariables>(
    EditAdminDocument,
    options,
  );
}
export type EditAdminMutationHookResult = ReturnType<typeof useEditAdminMutation>;
export type EditAdminMutationResult = Apollo.MutationResult<EditAdminMutation>;
export type EditAdminMutationOptions = Apollo.BaseMutationOptions<
  EditAdminMutation,
  EditAdminMutationVariables
>;
export const EditOfferDocument = gql`
  mutation EditOffer($offer: EditOfferInput!, $rates: [OfferRateInput!]!) {
    editOffer(offer: $offer, rates: $rates) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;
export type EditOfferMutationFn = Apollo.MutationFunction<
  EditOfferMutation,
  EditOfferMutationVariables
>;

/**
 * __useEditOfferMutation__
 *
 * To run a mutation, you first call `useEditOfferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOfferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOfferMutation, { data, loading, error }] = useEditOfferMutation({
 *   variables: {
 *      offer: // value for 'offer'
 *      rates: // value for 'rates'
 *   },
 * });
 */
export function useEditOfferMutation(
  baseOptions?: Apollo.MutationHookOptions<EditOfferMutation, EditOfferMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditOfferMutation, EditOfferMutationVariables>(
    EditOfferDocument,
    options,
  );
}
export type EditOfferMutationHookResult = ReturnType<typeof useEditOfferMutation>;
export type EditOfferMutationResult = Apollo.MutationResult<EditOfferMutation>;
export type EditOfferMutationOptions = Apollo.BaseMutationOptions<
  EditOfferMutation,
  EditOfferMutationVariables
>;
export const EditOrderDocument = gql`
  mutation EditOrder(
    $order: EditOrderInput!
    $dischargingPorts: [EditPortPointInput!]!
    $loadingPorts: [EditPortPointInput!]!
  ) {
    editOrder(order: $order, dischargingPorts: $dischargingPorts, loadingPorts: $loadingPorts) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type EditOrderMutationFn = Apollo.MutationFunction<
  EditOrderMutation,
  EditOrderMutationVariables
>;

/**
 * __useEditOrderMutation__
 *
 * To run a mutation, you first call `useEditOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrderMutation, { data, loading, error }] = useEditOrderMutation({
 *   variables: {
 *      order: // value for 'order'
 *      dischargingPorts: // value for 'dischargingPorts'
 *      loadingPorts: // value for 'loadingPorts'
 *   },
 * });
 */
export function useEditOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<EditOrderMutation, EditOrderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditOrderMutation, EditOrderMutationVariables>(
    EditOrderDocument,
    options,
  );
}
export type EditOrderMutationHookResult = ReturnType<typeof useEditOrderMutation>;
export type EditOrderMutationResult = Apollo.MutationResult<EditOrderMutation>;
export type EditOrderMutationOptions = Apollo.BaseMutationOptions<
  EditOrderMutation,
  EditOrderMutationVariables
>;
export const EditVesselDocument = gql`
  mutation EditVessel($vessel: EditVesselInput!) {
    editVessel(vessel: $vessel) {
      id
      ...VesselFragment
    }
  }
  ${VesselFragmentFragmentDoc}
`;
export type EditVesselMutationFn = Apollo.MutationFunction<
  EditVesselMutation,
  EditVesselMutationVariables
>;

/**
 * __useEditVesselMutation__
 *
 * To run a mutation, you first call `useEditVesselMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVesselMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVesselMutation, { data, loading, error }] = useEditVesselMutation({
 *   variables: {
 *      vessel: // value for 'vessel'
 *   },
 * });
 */
export function useEditVesselMutation(
  baseOptions?: Apollo.MutationHookOptions<EditVesselMutation, EditVesselMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditVesselMutation, EditVesselMutationVariables>(
    EditVesselDocument,
    options,
  );
}
export type EditVesselMutationHookResult = ReturnType<typeof useEditVesselMutation>;
export type EditVesselMutationResult = Apollo.MutationResult<EditVesselMutation>;
export type EditVesselMutationOptions = Apollo.BaseMutationOptions<
  EditVesselMutation,
  EditVesselMutationVariables
>;
export const InviteOperatorsDocument = gql`
  mutation inviteOperators($emails: [String!]!, $inviteText: String!) {
    inviteOperators(emails: $emails, inviteText: $inviteText) {
      success
    }
  }
`;
export type InviteOperatorsMutationFn = Apollo.MutationFunction<
  InviteOperatorsMutation,
  InviteOperatorsMutationVariables
>;

/**
 * __useInviteOperatorsMutation__
 *
 * To run a mutation, you first call `useInviteOperatorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteOperatorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteOperatorsMutation, { data, loading, error }] = useInviteOperatorsMutation({
 *   variables: {
 *      emails: // value for 'emails'
 *      inviteText: // value for 'inviteText'
 *   },
 * });
 */
export function useInviteOperatorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteOperatorsMutation,
    InviteOperatorsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteOperatorsMutation, InviteOperatorsMutationVariables>(
    InviteOperatorsDocument,
    options,
  );
}
export type InviteOperatorsMutationHookResult = ReturnType<typeof useInviteOperatorsMutation>;
export type InviteOperatorsMutationResult = Apollo.MutationResult<InviteOperatorsMutation>;
export type InviteOperatorsMutationOptions = Apollo.BaseMutationOptions<
  InviteOperatorsMutation,
  InviteOperatorsMutationVariables
>;
export const MarkFinishedOrderDocument = gql`
  mutation MarkFinishedOrder($orderId: ID!) {
    markFinishedOrder(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type MarkFinishedOrderMutationFn = Apollo.MutationFunction<
  MarkFinishedOrderMutation,
  MarkFinishedOrderMutationVariables
>;

/**
 * __useMarkFinishedOrderMutation__
 *
 * To run a mutation, you first call `useMarkFinishedOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFinishedOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFinishedOrderMutation, { data, loading, error }] = useMarkFinishedOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMarkFinishedOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkFinishedOrderMutation,
    MarkFinishedOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkFinishedOrderMutation, MarkFinishedOrderMutationVariables>(
    MarkFinishedOrderDocument,
    options,
  );
}
export type MarkFinishedOrderMutationHookResult = ReturnType<typeof useMarkFinishedOrderMutation>;
export type MarkFinishedOrderMutationResult = Apollo.MutationResult<MarkFinishedOrderMutation>;
export type MarkFinishedOrderMutationOptions = Apollo.BaseMutationOptions<
  MarkFinishedOrderMutation,
  MarkFinishedOrderMutationVariables
>;
export const MarkOfferAsWinnerDocument = gql`
  mutation MarkOfferAsWinner($offerId: ID!) {
    markOfferAsWinner(offerId: $offerId) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;
export type MarkOfferAsWinnerMutationFn = Apollo.MutationFunction<
  MarkOfferAsWinnerMutation,
  MarkOfferAsWinnerMutationVariables
>;

/**
 * __useMarkOfferAsWinnerMutation__
 *
 * To run a mutation, you first call `useMarkOfferAsWinnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOfferAsWinnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOfferAsWinnerMutation, { data, loading, error }] = useMarkOfferAsWinnerMutation({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useMarkOfferAsWinnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkOfferAsWinnerMutation,
    MarkOfferAsWinnerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkOfferAsWinnerMutation, MarkOfferAsWinnerMutationVariables>(
    MarkOfferAsWinnerDocument,
    options,
  );
}
export type MarkOfferAsWinnerMutationHookResult = ReturnType<typeof useMarkOfferAsWinnerMutation>;
export type MarkOfferAsWinnerMutationResult = Apollo.MutationResult<MarkOfferAsWinnerMutation>;
export type MarkOfferAsWinnerMutationOptions = Apollo.BaseMutationOptions<
  MarkOfferAsWinnerMutation,
  MarkOfferAsWinnerMutationVariables
>;
export const MarkOngoingOrderDocument = gql`
  mutation MarkOngoingOrder($orderId: ID!) {
    markOngoingOrder(orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type MarkOngoingOrderMutationFn = Apollo.MutationFunction<
  MarkOngoingOrderMutation,
  MarkOngoingOrderMutationVariables
>;

/**
 * __useMarkOngoingOrderMutation__
 *
 * To run a mutation, you first call `useMarkOngoingOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOngoingOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOngoingOrderMutation, { data, loading, error }] = useMarkOngoingOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useMarkOngoingOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkOngoingOrderMutation,
    MarkOngoingOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkOngoingOrderMutation, MarkOngoingOrderMutationVariables>(
    MarkOngoingOrderDocument,
    options,
  );
}
export type MarkOngoingOrderMutationHookResult = ReturnType<typeof useMarkOngoingOrderMutation>;
export type MarkOngoingOrderMutationResult = Apollo.MutationResult<MarkOngoingOrderMutation>;
export type MarkOngoingOrderMutationOptions = Apollo.BaseMutationOptions<
  MarkOngoingOrderMutation,
  MarkOngoingOrderMutationVariables
>;
export const CreateMonthlyPlanDocument = gql`
  mutation CreateMonthlyPlan($data: MonthlyPlanInput!) {
    createMonthlyPlan(data: $data) {
      ...MonthlyPlanInfo
    }
  }
  ${MonthlyPlanInfoFragmentDoc}
`;
export type CreateMonthlyPlanMutationFn = Apollo.MutationFunction<
  CreateMonthlyPlanMutation,
  CreateMonthlyPlanMutationVariables
>;

/**
 * __useCreateMonthlyPlanMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyPlanMutation, { data, loading, error }] = useCreateMonthlyPlanMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMonthlyPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMonthlyPlanMutation,
    CreateMonthlyPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMonthlyPlanMutation, CreateMonthlyPlanMutationVariables>(
    CreateMonthlyPlanDocument,
    options,
  );
}
export type CreateMonthlyPlanMutationHookResult = ReturnType<typeof useCreateMonthlyPlanMutation>;
export type CreateMonthlyPlanMutationResult = Apollo.MutationResult<CreateMonthlyPlanMutation>;
export type CreateMonthlyPlanMutationOptions = Apollo.BaseMutationOptions<
  CreateMonthlyPlanMutation,
  CreateMonthlyPlanMutationVariables
>;
export const CreateMonthlyPlanItemDocument = gql`
  mutation createMonthlyPlanItem($data: MonthlyPlanItemInput!) {
    createMonthlyPlanItem(data: $data) {
      ...MonthlyPlanItemInfo
    }
  }
  ${MonthlyPlanItemInfoFragmentDoc}
`;
export type CreateMonthlyPlanItemMutationFn = Apollo.MutationFunction<
  CreateMonthlyPlanItemMutation,
  CreateMonthlyPlanItemMutationVariables
>;

/**
 * __useCreateMonthlyPlanItemMutation__
 *
 * To run a mutation, you first call `useCreateMonthlyPlanItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMonthlyPlanItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMonthlyPlanItemMutation, { data, loading, error }] = useCreateMonthlyPlanItemMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMonthlyPlanItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMonthlyPlanItemMutation,
    CreateMonthlyPlanItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMonthlyPlanItemMutation, CreateMonthlyPlanItemMutationVariables>(
    CreateMonthlyPlanItemDocument,
    options,
  );
}
export type CreateMonthlyPlanItemMutationHookResult = ReturnType<
  typeof useCreateMonthlyPlanItemMutation
>;
export type CreateMonthlyPlanItemMutationResult =
  Apollo.MutationResult<CreateMonthlyPlanItemMutation>;
export type CreateMonthlyPlanItemMutationOptions = Apollo.BaseMutationOptions<
  CreateMonthlyPlanItemMutation,
  CreateMonthlyPlanItemMutationVariables
>;
export const DeleteMonthlyPlanDocument = gql`
  mutation deleteMonthlyPlan($id: ID!) {
    deleteMonthlyPlan(id: $id) {
      id
    }
  }
`;
export type DeleteMonthlyPlanMutationFn = Apollo.MutationFunction<
  DeleteMonthlyPlanMutation,
  DeleteMonthlyPlanMutationVariables
>;

/**
 * __useDeleteMonthlyPlanMutation__
 *
 * To run a mutation, you first call `useDeleteMonthlyPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonthlyPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonthlyPlanMutation, { data, loading, error }] = useDeleteMonthlyPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMonthlyPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMonthlyPlanMutation,
    DeleteMonthlyPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMonthlyPlanMutation, DeleteMonthlyPlanMutationVariables>(
    DeleteMonthlyPlanDocument,
    options,
  );
}
export type DeleteMonthlyPlanMutationHookResult = ReturnType<typeof useDeleteMonthlyPlanMutation>;
export type DeleteMonthlyPlanMutationResult = Apollo.MutationResult<DeleteMonthlyPlanMutation>;
export type DeleteMonthlyPlanMutationOptions = Apollo.BaseMutationOptions<
  DeleteMonthlyPlanMutation,
  DeleteMonthlyPlanMutationVariables
>;
export const DeleteMonthlyPlanItemDocument = gql`
  mutation deleteMonthlyPlanItem($id: ID!) {
    deleteMonthlyPlanItem(id: $id) {
      id
    }
  }
`;
export type DeleteMonthlyPlanItemMutationFn = Apollo.MutationFunction<
  DeleteMonthlyPlanItemMutation,
  DeleteMonthlyPlanItemMutationVariables
>;

/**
 * __useDeleteMonthlyPlanItemMutation__
 *
 * To run a mutation, you first call `useDeleteMonthlyPlanItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMonthlyPlanItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMonthlyPlanItemMutation, { data, loading, error }] = useDeleteMonthlyPlanItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMonthlyPlanItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMonthlyPlanItemMutation,
    DeleteMonthlyPlanItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMonthlyPlanItemMutation, DeleteMonthlyPlanItemMutationVariables>(
    DeleteMonthlyPlanItemDocument,
    options,
  );
}
export type DeleteMonthlyPlanItemMutationHookResult = ReturnType<
  typeof useDeleteMonthlyPlanItemMutation
>;
export type DeleteMonthlyPlanItemMutationResult =
  Apollo.MutationResult<DeleteMonthlyPlanItemMutation>;
export type DeleteMonthlyPlanItemMutationOptions = Apollo.BaseMutationOptions<
  DeleteMonthlyPlanItemMutation,
  DeleteMonthlyPlanItemMutationVariables
>;
export const UpdateMonthlyPlanDocument = gql`
  mutation UpdateMonthlyPlan($id: ID!, $data: UpdateMonthlyPlanInput!) {
    updateMonthlyPlan(id: $id, data: $data) {
      ...MonthlyPlanInfo
    }
  }
  ${MonthlyPlanInfoFragmentDoc}
`;
export type UpdateMonthlyPlanMutationFn = Apollo.MutationFunction<
  UpdateMonthlyPlanMutation,
  UpdateMonthlyPlanMutationVariables
>;

/**
 * __useUpdateMonthlyPlanMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyPlanMutation, { data, loading, error }] = useUpdateMonthlyPlanMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMonthlyPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMonthlyPlanMutation,
    UpdateMonthlyPlanMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMonthlyPlanMutation, UpdateMonthlyPlanMutationVariables>(
    UpdateMonthlyPlanDocument,
    options,
  );
}
export type UpdateMonthlyPlanMutationHookResult = ReturnType<typeof useUpdateMonthlyPlanMutation>;
export type UpdateMonthlyPlanMutationResult = Apollo.MutationResult<UpdateMonthlyPlanMutation>;
export type UpdateMonthlyPlanMutationOptions = Apollo.BaseMutationOptions<
  UpdateMonthlyPlanMutation,
  UpdateMonthlyPlanMutationVariables
>;
export const UpdateMonthlyPlanItemDocument = gql`
  mutation UpdateMonthlyPlanItem($id: ID!, $data: UpdateMonthlyPlanItemInput!) {
    updateMonthlyPlanItem(id: $id, data: $data) {
      ...MonthlyPlanItemInfo
    }
  }
  ${MonthlyPlanItemInfoFragmentDoc}
`;
export type UpdateMonthlyPlanItemMutationFn = Apollo.MutationFunction<
  UpdateMonthlyPlanItemMutation,
  UpdateMonthlyPlanItemMutationVariables
>;

/**
 * __useUpdateMonthlyPlanItemMutation__
 *
 * To run a mutation, you first call `useUpdateMonthlyPlanItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMonthlyPlanItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMonthlyPlanItemMutation, { data, loading, error }] = useUpdateMonthlyPlanItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMonthlyPlanItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMonthlyPlanItemMutation,
    UpdateMonthlyPlanItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMonthlyPlanItemMutation, UpdateMonthlyPlanItemMutationVariables>(
    UpdateMonthlyPlanItemDocument,
    options,
  );
}
export type UpdateMonthlyPlanItemMutationHookResult = ReturnType<
  typeof useUpdateMonthlyPlanItemMutation
>;
export type UpdateMonthlyPlanItemMutationResult =
  Apollo.MutationResult<UpdateMonthlyPlanItemMutation>;
export type UpdateMonthlyPlanItemMutationOptions = Apollo.BaseMutationOptions<
  UpdateMonthlyPlanItemMutation,
  UpdateMonthlyPlanItemMutationVariables
>;
export const MtGetExpectedArrivalsDocument = gql`
  mutation MtGetExpectedArrivals(
    $dwtMin: Int!
    $dwtMax: Int!
    $portId: String!
    $portName: String!
    $startDate: String
    $endDate: String
    $status: [String!]
  ) {
    mtGetExpectedArrivals(
      dwtMin: $dwtMin
      dwtMax: $dwtMax
      portId: $portId
      portName: $portName
      startDate: $startDate
      endDate: $endDate
      status: $status
    ) {
      ...MtJobStartResult
    }
  }
  ${MtJobStartResultFragmentDoc}
`;
export type MtGetExpectedArrivalsMutationFn = Apollo.MutationFunction<
  MtGetExpectedArrivalsMutation,
  MtGetExpectedArrivalsMutationVariables
>;

/**
 * __useMtGetExpectedArrivalsMutation__
 *
 * To run a mutation, you first call `useMtGetExpectedArrivalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMtGetExpectedArrivalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mtGetExpectedArrivalsMutation, { data, loading, error }] = useMtGetExpectedArrivalsMutation({
 *   variables: {
 *      dwtMin: // value for 'dwtMin'
 *      dwtMax: // value for 'dwtMax'
 *      portId: // value for 'portId'
 *      portName: // value for 'portName'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMtGetExpectedArrivalsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MtGetExpectedArrivalsMutation,
    MtGetExpectedArrivalsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MtGetExpectedArrivalsMutation, MtGetExpectedArrivalsMutationVariables>(
    MtGetExpectedArrivalsDocument,
    options,
  );
}
export type MtGetExpectedArrivalsMutationHookResult = ReturnType<
  typeof useMtGetExpectedArrivalsMutation
>;
export type MtGetExpectedArrivalsMutationResult =
  Apollo.MutationResult<MtGetExpectedArrivalsMutation>;
export type MtGetExpectedArrivalsMutationOptions = Apollo.BaseMutationOptions<
  MtGetExpectedArrivalsMutation,
  MtGetExpectedArrivalsMutationVariables
>;
export const MtGetVesselsInAreaDocument = gql`
  mutation MtGetVesselsInArea(
    $lat: [Float!]!
    $lon: [Float!]!
    $dwtMax: Int
    $dwtMin: Int
    $status: [String!]
  ) {
    mtGetVesselsInArea(lat: $lat, lon: $lon, dwtMax: $dwtMax, dwtMin: $dwtMin, status: $status) {
      ...MtJobStartResult
    }
  }
  ${MtJobStartResultFragmentDoc}
`;
export type MtGetVesselsInAreaMutationFn = Apollo.MutationFunction<
  MtGetVesselsInAreaMutation,
  MtGetVesselsInAreaMutationVariables
>;

/**
 * __useMtGetVesselsInAreaMutation__
 *
 * To run a mutation, you first call `useMtGetVesselsInAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMtGetVesselsInAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mtGetVesselsInAreaMutation, { data, loading, error }] = useMtGetVesselsInAreaMutation({
 *   variables: {
 *      lat: // value for 'lat'
 *      lon: // value for 'lon'
 *      dwtMax: // value for 'dwtMax'
 *      dwtMin: // value for 'dwtMin'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMtGetVesselsInAreaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MtGetVesselsInAreaMutation,
    MtGetVesselsInAreaMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MtGetVesselsInAreaMutation, MtGetVesselsInAreaMutationVariables>(
    MtGetVesselsInAreaDocument,
    options,
  );
}
export type MtGetVesselsInAreaMutationHookResult = ReturnType<
  typeof useMtGetVesselsInAreaMutation
>;
export type MtGetVesselsInAreaMutationResult = Apollo.MutationResult<MtGetVesselsInAreaMutation>;
export type MtGetVesselsInAreaMutationOptions = Apollo.BaseMutationOptions<
  MtGetVesselsInAreaMutation,
  MtGetVesselsInAreaMutationVariables
>;
export const MtSearchPortsDocument = gql`
  mutation MtSearchPorts($name: String!) {
    mtSearchPorts(name: $name) {
      ...MtJobStartResult
    }
  }
  ${MtJobStartResultFragmentDoc}
`;
export type MtSearchPortsMutationFn = Apollo.MutationFunction<
  MtSearchPortsMutation,
  MtSearchPortsMutationVariables
>;

/**
 * __useMtSearchPortsMutation__
 *
 * To run a mutation, you first call `useMtSearchPortsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMtSearchPortsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mtSearchPortsMutation, { data, loading, error }] = useMtSearchPortsMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useMtSearchPortsMutation(
  baseOptions?: Apollo.MutationHookOptions<MtSearchPortsMutation, MtSearchPortsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MtSearchPortsMutation, MtSearchPortsMutationVariables>(
    MtSearchPortsDocument,
    options,
  );
}
export type MtSearchPortsMutationHookResult = ReturnType<typeof useMtSearchPortsMutation>;
export type MtSearchPortsMutationResult = Apollo.MutationResult<MtSearchPortsMutation>;
export type MtSearchPortsMutationOptions = Apollo.BaseMutationOptions<
  MtSearchPortsMutation,
  MtSearchPortsMutationVariables
>;
export const RegisterDocument = gql`
  mutation Register(
    $companyName: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $mobile: String!
  ) {
    register(
      companyName: $companyName
      email: $email
      firstName: $firstName
      lastName: $lastName
      mobile: $mobile
    ) {
      success
      reason
      password
      user {
        companyName
        createdAt
        email
        firstName
        id
        isAdmin
        isShipowner
        lastName
        mobile
        roles
        updatedAt
      }
      token
    }
  }
`;
export type RegisterMutationFn = Apollo.MutationFunction<
  RegisterMutation,
  RegisterMutationVariables
>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      companyName: // value for 'companyName'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      mobile: // value for 'mobile'
 *   },
 * });
 */
export function useRegisterMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(
    RegisterDocument,
    options,
  );
}
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<
  RegisterMutation,
  RegisterMutationVariables
>;
export const RenamePortDocument = gql`
  mutation RenamePort($data: EditPortInput!, $portId: ID!) {
    renamePort(data: $data, portId: $portId) {
      ...PortFragment
    }
  }
  ${PortFragmentFragmentDoc}
`;
export type RenamePortMutationFn = Apollo.MutationFunction<
  RenamePortMutation,
  RenamePortMutationVariables
>;

/**
 * __useRenamePortMutation__
 *
 * To run a mutation, you first call `useRenamePortMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenamePortMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renamePortMutation, { data, loading, error }] = useRenamePortMutation({
 *   variables: {
 *      data: // value for 'data'
 *      portId: // value for 'portId'
 *   },
 * });
 */
export function useRenamePortMutation(
  baseOptions?: Apollo.MutationHookOptions<RenamePortMutation, RenamePortMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RenamePortMutation, RenamePortMutationVariables>(
    RenamePortDocument,
    options,
  );
}
export type RenamePortMutationHookResult = ReturnType<typeof useRenamePortMutation>;
export type RenamePortMutationResult = Apollo.MutationResult<RenamePortMutation>;
export type RenamePortMutationOptions = Apollo.BaseMutationOptions<
  RenamePortMutation,
  RenamePortMutationVariables
>;
export const ReplyAllOffersDocument = gql`
  mutation ReplyAllOffers($orderId: ID!, $text: String!) {
    replyAll(text: $text, orderId: $orderId) {
      success
    }
  }
`;
export type ReplyAllOffersMutationFn = Apollo.MutationFunction<
  ReplyAllOffersMutation,
  ReplyAllOffersMutationVariables
>;

/**
 * __useReplyAllOffersMutation__
 *
 * To run a mutation, you first call `useReplyAllOffersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyAllOffersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyAllOffersMutation, { data, loading, error }] = useReplyAllOffersMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useReplyAllOffersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReplyAllOffersMutation,
    ReplyAllOffersMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplyAllOffersMutation, ReplyAllOffersMutationVariables>(
    ReplyAllOffersDocument,
    options,
  );
}
export type ReplyAllOffersMutationHookResult = ReturnType<typeof useReplyAllOffersMutation>;
export type ReplyAllOffersMutationResult = Apollo.MutationResult<ReplyAllOffersMutation>;
export type ReplyAllOffersMutationOptions = Apollo.BaseMutationOptions<
  ReplyAllOffersMutation,
  ReplyAllOffersMutationVariables
>;
export const SetFinishedOrderRatingDocument = gql`
  mutation SetFinishedOrderRating($orderId: ID!, $rating: Int!) {
    setFinishedOrderRating(rating: $rating, orderId: $orderId) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type SetFinishedOrderRatingMutationFn = Apollo.MutationFunction<
  SetFinishedOrderRatingMutation,
  SetFinishedOrderRatingMutationVariables
>;

/**
 * __useSetFinishedOrderRatingMutation__
 *
 * To run a mutation, you first call `useSetFinishedOrderRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFinishedOrderRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFinishedOrderRatingMutation, { data, loading, error }] = useSetFinishedOrderRatingMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useSetFinishedOrderRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetFinishedOrderRatingMutation,
    SetFinishedOrderRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetFinishedOrderRatingMutation,
    SetFinishedOrderRatingMutationVariables
  >(SetFinishedOrderRatingDocument, options);
}
export type SetFinishedOrderRatingMutationHookResult = ReturnType<
  typeof useSetFinishedOrderRatingMutation
>;
export type SetFinishedOrderRatingMutationResult =
  Apollo.MutationResult<SetFinishedOrderRatingMutation>;
export type SetFinishedOrderRatingMutationOptions = Apollo.BaseMutationOptions<
  SetFinishedOrderRatingMutation,
  SetFinishedOrderRatingMutationVariables
>;
export const SetNewPasswordDocument = gql`
  mutation SetNewPassword($passwordResetId: ID!, $newPassword: String!) {
    setNewPassword(passwordResetId: $passwordResetId, newPassword: $newPassword) {
      success
      reason
    }
  }
`;
export type SetNewPasswordMutationFn = Apollo.MutationFunction<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;

/**
 * __useSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewPasswordMutation, { data, loading, error }] = useSetNewPasswordMutation({
 *   variables: {
 *      passwordResetId: // value for 'passwordResetId'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useSetNewPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNewPasswordMutation,
    SetNewPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetNewPasswordMutation, SetNewPasswordMutationVariables>(
    SetNewPasswordDocument,
    options,
  );
}
export type SetNewPasswordMutationHookResult = ReturnType<typeof useSetNewPasswordMutation>;
export type SetNewPasswordMutationResult = Apollo.MutationResult<SetNewPasswordMutation>;
export type SetNewPasswordMutationOptions = Apollo.BaseMutationOptions<
  SetNewPasswordMutation,
  SetNewPasswordMutationVariables
>;
export const SignInDocument = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      success
      reason
      user {
        companyName
        createdAt
        email
        firstName
        id
        isAdmin
        isShipowner
        lastName
        mobile
        roles
        updatedAt
      }
      token
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const UpdateOrderMetadataDocument = gql`
  mutation UpdateOrderMetadata($orderId: ID!, $data: EditOrderMetadataInput!) {
    updateOrderMetadata(orderId: $orderId, data: $data) {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;
export type UpdateOrderMetadataMutationFn = Apollo.MutationFunction<
  UpdateOrderMetadataMutation,
  UpdateOrderMetadataMutationVariables
>;

/**
 * __useUpdateOrderMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMetadataMutation, { data, loading, error }] = useUpdateOrderMetadataMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateOrderMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrderMetadataMutation,
    UpdateOrderMetadataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrderMetadataMutation, UpdateOrderMetadataMutationVariables>(
    UpdateOrderMetadataDocument,
    options,
  );
}
export type UpdateOrderMetadataMutationHookResult = ReturnType<
  typeof useUpdateOrderMetadataMutation
>;
export type UpdateOrderMetadataMutationResult = Apollo.MutationResult<UpdateOrderMetadataMutation>;
export type UpdateOrderMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrderMetadataMutation,
  UpdateOrderMetadataMutationVariables
>;
export const UpsertShipownerOrderScoreDocument = gql`
  mutation upsertShipownerOrderScore($data: ShipownerOrderScoreInput!) {
    upsertShipownerOrderScore(data: $data) {
      average
      calmness
      createdAt
      estimationLoyalty
      informing
      orderId
      punctuality
      shipowner {
        ...UserFragment
      }
      shipownerId
      updatedAt
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpsertShipownerOrderScoreMutationFn = Apollo.MutationFunction<
  UpsertShipownerOrderScoreMutation,
  UpsertShipownerOrderScoreMutationVariables
>;

/**
 * __useUpsertShipownerOrderScoreMutation__
 *
 * To run a mutation, you first call `useUpsertShipownerOrderScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertShipownerOrderScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertShipownerOrderScoreMutation, { data, loading, error }] = useUpsertShipownerOrderScoreMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpsertShipownerOrderScoreMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertShipownerOrderScoreMutation,
    UpsertShipownerOrderScoreMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertShipownerOrderScoreMutation,
    UpsertShipownerOrderScoreMutationVariables
  >(UpsertShipownerOrderScoreDocument, options);
}
export type UpsertShipownerOrderScoreMutationHookResult = ReturnType<
  typeof useUpsertShipownerOrderScoreMutation
>;
export type UpsertShipownerOrderScoreMutationResult =
  Apollo.MutationResult<UpsertShipownerOrderScoreMutation>;
export type UpsertShipownerOrderScoreMutationOptions = Apollo.BaseMutationOptions<
  UpsertShipownerOrderScoreMutation,
  UpsertShipownerOrderScoreMutationVariables
>;
export const GetAdminsDocument = gql`
  query GetAdmins($limit: Int!, $offset: Int!, $roles: [String!]) {
    admins(limit: $limit, offset: $offset, roles: $roles) {
      admins {
        ...UserFragment
      }
      total
      hasNextPage
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetAdminsQuery__
 *
 * To run a query within a React component, call `useGetAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      roles: // value for 'roles'
 *   },
 * });
 */
export function useGetAdminsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAdminsQuery, GetAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminsQuery, GetAdminsQueryVariables>(GetAdminsDocument, options);
}
export function useGetAdminsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAdminsQuery, GetAdminsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminsQuery, GetAdminsQueryVariables>(GetAdminsDocument, options);
}
export type GetAdminsQueryHookResult = ReturnType<typeof useGetAdminsQuery>;
export type GetAdminsLazyQueryHookResult = ReturnType<typeof useGetAdminsLazyQuery>;
export type GetAdminsQueryResult = Apollo.QueryResult<GetAdminsQuery, GetAdminsQueryVariables>;
export const GetCargosDocument = gql`
  query GetCargos {
    cargos {
      id
      name
    }
  }
`;

/**
 * __useGetCargosQuery__
 *
 * To run a query within a React component, call `useGetCargosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCargosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCargosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCargosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCargosQuery, GetCargosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCargosQuery, GetCargosQueryVariables>(GetCargosDocument, options);
}
export function useGetCargosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCargosQuery, GetCargosQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCargosQuery, GetCargosQueryVariables>(GetCargosDocument, options);
}
export type GetCargosQueryHookResult = ReturnType<typeof useGetCargosQuery>;
export type GetCargosLazyQueryHookResult = ReturnType<typeof useGetCargosLazyQuery>;
export type GetCargosQueryResult = Apollo.QueryResult<GetCargosQuery, GetCargosQueryVariables>;
export const GetDashboardDataDocument = gql`
  query GetDashboardData {
    dashboardData {
      activeOrders {
        ...OrderFragment
      }
      activeOrdersTotal
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetDashboardDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDashboardDataQuery, GetDashboardDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardDataQuery, GetDashboardDataQueryVariables>(
    GetDashboardDataDocument,
    options,
  );
}
export function useGetDashboardDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardDataQuery, GetDashboardDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardDataQuery, GetDashboardDataQueryVariables>(
    GetDashboardDataDocument,
    options,
  );
}
export type GetDashboardDataQueryHookResult = ReturnType<typeof useGetDashboardDataQuery>;
export type GetDashboardDataLazyQueryHookResult = ReturnType<typeof useGetDashboardDataLazyQuery>;
export type GetDashboardDataQueryResult = Apollo.QueryResult<
  GetDashboardDataQuery,
  GetDashboardDataQueryVariables
>;
export const GetDashboardGrowthDataDocument = gql`
  query getDashboardGrowthData {
    dashboardFullGrowthData {
      quarter {
        ships {
          previuosValue
          currentValue
          growth
        }
        orders {
          previuosValue
          currentValue
          growth
        }
        tonnage {
          previuosValue
          currentValue
          growth
        }
      }
      week {
        ships {
          previuosValue
          currentValue
          growth
        }
        orders {
          previuosValue
          currentValue
          growth
        }
        tonnage {
          previuosValue
          currentValue
          growth
        }
      }
      month {
        ships {
          previuosValue
          currentValue
          growth
        }
        orders {
          previuosValue
          currentValue
          growth
        }
        tonnage {
          previuosValue
          currentValue
          growth
        }
      }
    }
  }
`;

/**
 * __useGetDashboardGrowthDataQuery__
 *
 * To run a query within a React component, call `useGetDashboardGrowthDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardGrowthDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardGrowthDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardGrowthDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardGrowthDataQuery,
    GetDashboardGrowthDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardGrowthDataQuery, GetDashboardGrowthDataQueryVariables>(
    GetDashboardGrowthDataDocument,
    options,
  );
}
export function useGetDashboardGrowthDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardGrowthDataQuery,
    GetDashboardGrowthDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDashboardGrowthDataQuery, GetDashboardGrowthDataQueryVariables>(
    GetDashboardGrowthDataDocument,
    options,
  );
}
export type GetDashboardGrowthDataQueryHookResult = ReturnType<
  typeof useGetDashboardGrowthDataQuery
>;
export type GetDashboardGrowthDataLazyQueryHookResult = ReturnType<
  typeof useGetDashboardGrowthDataLazyQuery
>;
export type GetDashboardGrowthDataQueryResult = Apollo.QueryResult<
  GetDashboardGrowthDataQuery,
  GetDashboardGrowthDataQueryVariables
>;
export const GetMyOffersDocument = gql`
  query GetMyOffers($limit: Int!, $offset: Int!) {
    myOffers(limit: $limit, offset: $offset) {
      offers {
        ...OfferFragment
      }
      hasNextPage
      total
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useGetMyOffersQuery__
 *
 * To run a query within a React component, call `useGetMyOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOffersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMyOffersQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyOffersQuery, GetMyOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOffersQuery, GetMyOffersQueryVariables>(
    GetMyOffersDocument,
    options,
  );
}
export function useGetMyOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyOffersQuery, GetMyOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOffersQuery, GetMyOffersQueryVariables>(
    GetMyOffersDocument,
    options,
  );
}
export type GetMyOffersQueryHookResult = ReturnType<typeof useGetMyOffersQuery>;
export type GetMyOffersLazyQueryHookResult = ReturnType<typeof useGetMyOffersLazyQuery>;
export type GetMyOffersQueryResult = Apollo.QueryResult<
  GetMyOffersQuery,
  GetMyOffersQueryVariables
>;
export const GetMyOffersForOrderDocument = gql`
  query GetMyOffersForOrder($limit: Int!, $offset: Int!, $orderId: ID!) {
    myOffersForOrder(limit: $limit, offset: $offset, orderId: $orderId) {
      offers {
        ...OfferFragment
      }
      hasNextPage
      total
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useGetMyOffersForOrderQuery__
 *
 * To run a query within a React component, call `useGetMyOffersForOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOffersForOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOffersForOrderQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetMyOffersForOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyOffersForOrderQuery,
    GetMyOffersForOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOffersForOrderQuery, GetMyOffersForOrderQueryVariables>(
    GetMyOffersForOrderDocument,
    options,
  );
}
export function useGetMyOffersForOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOffersForOrderQuery,
    GetMyOffersForOrderQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOffersForOrderQuery, GetMyOffersForOrderQueryVariables>(
    GetMyOffersForOrderDocument,
    options,
  );
}
export type GetMyOffersForOrderQueryHookResult = ReturnType<typeof useGetMyOffersForOrderQuery>;
export type GetMyOffersForOrderLazyQueryHookResult = ReturnType<
  typeof useGetMyOffersForOrderLazyQuery
>;
export type GetMyOffersForOrderQueryResult = Apollo.QueryResult<
  GetMyOffersForOrderQuery,
  GetMyOffersForOrderQueryVariables
>;
export const GetMyVesselsDocument = gql`
  query GetMyVessels($limit: Int!, $offset: Int!) {
    myVessels(limit: $limit, offset: $offset) {
      vessels {
        ...VesselFragment
      }
      hasNextPage
      total
    }
  }
  ${VesselFragmentFragmentDoc}
`;

/**
 * __useGetMyVesselsQuery__
 *
 * To run a query within a React component, call `useGetMyVesselsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyVesselsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyVesselsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetMyVesselsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMyVesselsQuery, GetMyVesselsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyVesselsQuery, GetMyVesselsQueryVariables>(
    GetMyVesselsDocument,
    options,
  );
}
export function useGetMyVesselsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyVesselsQuery, GetMyVesselsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyVesselsQuery, GetMyVesselsQueryVariables>(
    GetMyVesselsDocument,
    options,
  );
}
export type GetMyVesselsQueryHookResult = ReturnType<typeof useGetMyVesselsQuery>;
export type GetMyVesselsLazyQueryHookResult = ReturnType<typeof useGetMyVesselsLazyQuery>;
export type GetMyVesselsQueryResult = Apollo.QueryResult<
  GetMyVesselsQuery,
  GetMyVesselsQueryVariables
>;
export const GetOfferDocument = gql`
  query getOffer($id: ID!) {
    offer(id: $id) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useGetOfferQuery__
 *
 * To run a query within a React component, call `useGetOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOfferQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOfferQuery(
  baseOptions: Apollo.QueryHookOptions<GetOfferQuery, GetOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, options);
}
export function useGetOfferLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOfferQuery, GetOfferQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOfferQuery, GetOfferQueryVariables>(GetOfferDocument, options);
}
export type GetOfferQueryHookResult = ReturnType<typeof useGetOfferQuery>;
export type GetOfferLazyQueryHookResult = ReturnType<typeof useGetOfferLazyQuery>;
export type GetOfferQueryResult = Apollo.QueryResult<GetOfferQuery, GetOfferQueryVariables>;
export const OrderDocument = gql`
  query Order($id: ID!) {
    order(id: $id) {
      ...OrderFragment
      winnerOffer {
        ...OfferFragment
      }
    }
  }
  ${OrderFragmentFragmentDoc}
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(
  baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export function useOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
}
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const GetOrderByImoDocument = gql`
  query GetOrderByImo($imo: Int!) {
    orderByImo(imo: $imo) {
      ...OrderFragment
      winnerOffer {
        ...OfferFragment
      }
      loadingPort {
        id
        name
      }
      dischargingPort {
        id
        name
      }
    }
  }
  ${OrderFragmentFragmentDoc}
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useGetOrderByImoQuery__
 *
 * To run a query within a React component, call `useGetOrderByImoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByImoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByImoQuery({
 *   variables: {
 *      imo: // value for 'imo'
 *   },
 * });
 */
export function useGetOrderByImoQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderByImoQuery, GetOrderByImoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderByImoQuery, GetOrderByImoQueryVariables>(
    GetOrderByImoDocument,
    options,
  );
}
export function useGetOrderByImoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByImoQuery, GetOrderByImoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderByImoQuery, GetOrderByImoQueryVariables>(
    GetOrderByImoDocument,
    options,
  );
}
export type GetOrderByImoQueryHookResult = ReturnType<typeof useGetOrderByImoQuery>;
export type GetOrderByImoLazyQueryHookResult = ReturnType<typeof useGetOrderByImoLazyQuery>;
export type GetOrderByImoQueryResult = Apollo.QueryResult<
  GetOrderByImoQuery,
  GetOrderByImoQueryVariables
>;
export const GetOrderOffersDocument = gql`
  query GetOrderOffers($limit: Int!, $offset: Int!, $orderId: ID!) {
    orderOffers(limit: $limit, offset: $offset, orderId: $orderId) {
      offers {
        ...OfferFragment
      }
      hasNextPage
      total
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useGetOrderOffersQuery__
 *
 * To run a query within a React component, call `useGetOrderOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderOffersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderOffersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrderOffersQuery, GetOrderOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrderOffersQuery, GetOrderOffersQueryVariables>(
    GetOrderOffersDocument,
    options,
  );
}
export function useGetOrderOffersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrderOffersQuery, GetOrderOffersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrderOffersQuery, GetOrderOffersQueryVariables>(
    GetOrderOffersDocument,
    options,
  );
}
export type GetOrderOffersQueryHookResult = ReturnType<typeof useGetOrderOffersQuery>;
export type GetOrderOffersLazyQueryHookResult = ReturnType<typeof useGetOrderOffersLazyQuery>;
export type GetOrderOffersQueryResult = Apollo.QueryResult<
  GetOrderOffersQuery,
  GetOrderOffersQueryVariables
>;
export const GetOrdersDocument = gql`
  query GetOrders(
    $onlyActive: Boolean!
    $limit: Int!
    $offset: Int!
    $filters: OrderFilters!
    $sorting: OrderSorting!
  ) {
    orders(
      onlyActive: $onlyActive
      limit: $limit
      offset: $offset
      filters: $filters
      sorting: $sorting
    ) {
      orders {
        ...OrderFragment
        isOfferMade
      }
      hasNextPage
      total
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export function useGetOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
}
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetOrdersForShipownersDocument = gql`
  query GetOrdersForShipowners(
    $onlyActive: Boolean!
    $limit: Int!
    $offset: Int!
    $filters: OrderFilters!
    $sorting: OrderSorting!
  ) {
    ordersForShipowners(
      onlyActive: $onlyActive
      limit: $limit
      offset: $offset
      filters: $filters
      sorting: $sorting
    ) {
      orders {
        ...OrderFragment
        isOfferMade
      }
      hasNextPage
      total
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useGetOrdersForShipownersQuery__
 *
 * To run a query within a React component, call `useGetOrdersForShipownersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersForShipownersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersForShipownersQuery({
 *   variables: {
 *      onlyActive: // value for 'onlyActive'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetOrdersForShipownersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOrdersForShipownersQuery,
    GetOrdersForShipownersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrdersForShipownersQuery, GetOrdersForShipownersQueryVariables>(
    GetOrdersForShipownersDocument,
    options,
  );
}
export function useGetOrdersForShipownersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrdersForShipownersQuery,
    GetOrdersForShipownersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrdersForShipownersQuery, GetOrdersForShipownersQueryVariables>(
    GetOrdersForShipownersDocument,
    options,
  );
}
export type GetOrdersForShipownersQueryHookResult = ReturnType<
  typeof useGetOrdersForShipownersQuery
>;
export type GetOrdersForShipownersLazyQueryHookResult = ReturnType<
  typeof useGetOrdersForShipownersLazyQuery
>;
export type GetOrdersForShipownersQueryResult = Apollo.QueryResult<
  GetOrdersForShipownersQuery,
  GetOrdersForShipownersQueryVariables
>;
export const GetPeriodGrowthDocument = gql`
  query getPeriodGrowth($period: String!) {
    periodGrowthData(period: $period) {
      orders {
        currentValue
        previuosValue
        growth
      }
      ships {
        currentValue
        previuosValue
        growth
      }
      tonnage {
        currentValue
        previuosValue
        growth
      }
    }
  }
`;

/**
 * __useGetPeriodGrowthQuery__
 *
 * To run a query within a React component, call `useGetPeriodGrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeriodGrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeriodGrowthQuery({
 *   variables: {
 *      period: // value for 'period'
 *   },
 * });
 */
export function useGetPeriodGrowthQuery(
  baseOptions: Apollo.QueryHookOptions<GetPeriodGrowthQuery, GetPeriodGrowthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeriodGrowthQuery, GetPeriodGrowthQueryVariables>(
    GetPeriodGrowthDocument,
    options,
  );
}
export function useGetPeriodGrowthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeriodGrowthQuery, GetPeriodGrowthQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeriodGrowthQuery, GetPeriodGrowthQueryVariables>(
    GetPeriodGrowthDocument,
    options,
  );
}
export type GetPeriodGrowthQueryHookResult = ReturnType<typeof useGetPeriodGrowthQuery>;
export type GetPeriodGrowthLazyQueryHookResult = ReturnType<typeof useGetPeriodGrowthLazyQuery>;
export type GetPeriodGrowthQueryResult = Apollo.QueryResult<
  GetPeriodGrowthQuery,
  GetPeriodGrowthQueryVariables
>;
export const GetPortsDocument = gql`
  query GetPorts {
    ports {
      id
      name
    }
  }
`;

/**
 * __useGetPortsQuery__
 *
 * To run a query within a React component, call `useGetPortsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPortsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPortsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPortsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPortsQuery, GetPortsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPortsQuery, GetPortsQueryVariables>(GetPortsDocument, options);
}
export function useGetPortsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPortsQuery, GetPortsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPortsQuery, GetPortsQueryVariables>(GetPortsDocument, options);
}
export type GetPortsQueryHookResult = ReturnType<typeof useGetPortsQuery>;
export type GetPortsLazyQueryHookResult = ReturnType<typeof useGetPortsLazyQuery>;
export type GetPortsQueryResult = Apollo.QueryResult<GetPortsQuery, GetPortsQueryVariables>;
export const GetReportsDocument = gql`
  query GetReports($endDate: String!, $managerIds: [ID!], $startDate: String!) {
    reports(endDate: $endDate, managerIds: $managerIds, startDate: $startDate) {
      data
    }
  }
`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      managerIds: // value for 'managerIds'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useGetReportsQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
}
export function useGetReportsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(
    GetReportsDocument,
    options,
  );
}
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetUserDocument = gql`
  query getUser {
    me {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetMonthlyPlanDocument = gql`
  query GetMonthlyPlan($id: ID!) {
    monthlyPlan(id: $id) {
      ...MonthlyPlanInfo
    }
  }
  ${MonthlyPlanInfoFragmentDoc}
`;

/**
 * __useGetMonthlyPlanQuery__
 *
 * To run a query within a React component, call `useGetMonthlyPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyPlanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMonthlyPlanQuery(
  baseOptions: Apollo.QueryHookOptions<GetMonthlyPlanQuery, GetMonthlyPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyPlanQuery, GetMonthlyPlanQueryVariables>(
    GetMonthlyPlanDocument,
    options,
  );
}
export function useGetMonthlyPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyPlanQuery, GetMonthlyPlanQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonthlyPlanQuery, GetMonthlyPlanQueryVariables>(
    GetMonthlyPlanDocument,
    options,
  );
}
export type GetMonthlyPlanQueryHookResult = ReturnType<typeof useGetMonthlyPlanQuery>;
export type GetMonthlyPlanLazyQueryHookResult = ReturnType<typeof useGetMonthlyPlanLazyQuery>;
export type GetMonthlyPlanQueryResult = Apollo.QueryResult<
  GetMonthlyPlanQuery,
  GetMonthlyPlanQueryVariables
>;
export const GetMonthlyPlanFullDocument = gql`
  query GetMonthlyPlanFull($id: ID!) {
    monthlyPlan(id: $id) {
      ...MonthlyPlanInfo
      items {
        ...MonthlyPlanItemInfo
      }
    }
  }
  ${MonthlyPlanInfoFragmentDoc}
  ${MonthlyPlanItemInfoFragmentDoc}
`;

/**
 * __useGetMonthlyPlanFullQuery__
 *
 * To run a query within a React component, call `useGetMonthlyPlanFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyPlanFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyPlanFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMonthlyPlanFullQuery(
  baseOptions: Apollo.QueryHookOptions<GetMonthlyPlanFullQuery, GetMonthlyPlanFullQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyPlanFullQuery, GetMonthlyPlanFullQueryVariables>(
    GetMonthlyPlanFullDocument,
    options,
  );
}
export function useGetMonthlyPlanFullLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMonthlyPlanFullQuery,
    GetMonthlyPlanFullQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonthlyPlanFullQuery, GetMonthlyPlanFullQueryVariables>(
    GetMonthlyPlanFullDocument,
    options,
  );
}
export type GetMonthlyPlanFullQueryHookResult = ReturnType<typeof useGetMonthlyPlanFullQuery>;
export type GetMonthlyPlanFullLazyQueryHookResult = ReturnType<
  typeof useGetMonthlyPlanFullLazyQuery
>;
export type GetMonthlyPlanFullQueryResult = Apollo.QueryResult<
  GetMonthlyPlanFullQuery,
  GetMonthlyPlanFullQueryVariables
>;
export const GetMonthlyPlansDocument = gql`
  query GetMonthlyPlans {
    monthlyPlans {
      ...MonthlyPlanInfo
    }
  }
  ${MonthlyPlanInfoFragmentDoc}
`;

/**
 * __useGetMonthlyPlansQuery__
 *
 * To run a query within a React component, call `useGetMonthlyPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMonthlyPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMonthlyPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMonthlyPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMonthlyPlansQuery, GetMonthlyPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMonthlyPlansQuery, GetMonthlyPlansQueryVariables>(
    GetMonthlyPlansDocument,
    options,
  );
}
export function useGetMonthlyPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMonthlyPlansQuery, GetMonthlyPlansQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMonthlyPlansQuery, GetMonthlyPlansQueryVariables>(
    GetMonthlyPlansDocument,
    options,
  );
}
export type GetMonthlyPlansQueryHookResult = ReturnType<typeof useGetMonthlyPlansQuery>;
export type GetMonthlyPlansLazyQueryHookResult = ReturnType<typeof useGetMonthlyPlansLazyQuery>;
export type GetMonthlyPlansQueryResult = Apollo.QueryResult<
  GetMonthlyPlansQuery,
  GetMonthlyPlansQueryVariables
>;
export const MarineTrafficJobCompletedDocument = gql`
  subscription MarineTrafficJobCompleted($jobId: String!) {
    marineTrafficJobCompleted(jobId: $jobId) {
      result
      inviteStatus
    }
  }
`;

/**
 * __useMarineTrafficJobCompletedSubscription__
 *
 * To run a query within a React component, call `useMarineTrafficJobCompletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMarineTrafficJobCompletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarineTrafficJobCompletedSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useMarineTrafficJobCompletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    MarineTrafficJobCompletedSubscription,
    MarineTrafficJobCompletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    MarineTrafficJobCompletedSubscription,
    MarineTrafficJobCompletedSubscriptionVariables
  >(MarineTrafficJobCompletedDocument, options);
}
export type MarineTrafficJobCompletedSubscriptionHookResult = ReturnType<
  typeof useMarineTrafficJobCompletedSubscription
>;
export type MarineTrafficJobCompletedSubscriptionResult =
  Apollo.SubscriptionResult<MarineTrafficJobCompletedSubscription>;
export const NewOrderAddedDocument = gql`
  subscription NewOrderAdded {
    newOrderAdded {
      ...OrderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useNewOrderAddedSubscription__
 *
 * To run a query within a React component, call `useNewOrderAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewOrderAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewOrderAddedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewOrderAddedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewOrderAddedSubscription,
    NewOrderAddedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewOrderAddedSubscription, NewOrderAddedSubscriptionVariables>(
    NewOrderAddedDocument,
    options,
  );
}
export type NewOrderAddedSubscriptionHookResult = ReturnType<typeof useNewOrderAddedSubscription>;
export type NewOrderAddedSubscriptionResult = Apollo.SubscriptionResult<NewOrderAddedSubscription>;
export const OfferCreatedDocument = gql`
  subscription OfferCreated($orderId: ID!) {
    offerCreated(orderId: $orderId) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useOfferCreatedSubscription__
 *
 * To run a query within a React component, call `useOfferCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferCreatedSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOfferCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OfferCreatedSubscription,
    OfferCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OfferCreatedSubscription, OfferCreatedSubscriptionVariables>(
    OfferCreatedDocument,
    options,
  );
}
export type OfferCreatedSubscriptionHookResult = ReturnType<typeof useOfferCreatedSubscription>;
export type OfferCreatedSubscriptionResult = Apollo.SubscriptionResult<OfferCreatedSubscription>;
export const OfferDeletedDocument = gql`
  subscription OfferDeleted($orderId: ID!) {
    offerDeleted(orderId: $orderId) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useOfferDeletedSubscription__
 *
 * To run a query within a React component, call `useOfferDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferDeletedSubscription({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOfferDeletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OfferDeletedSubscription,
    OfferDeletedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OfferDeletedSubscription, OfferDeletedSubscriptionVariables>(
    OfferDeletedDocument,
    options,
  );
}
export type OfferDeletedSubscriptionHookResult = ReturnType<typeof useOfferDeletedSubscription>;
export type OfferDeletedSubscriptionResult = Apollo.SubscriptionResult<OfferDeletedSubscription>;
export const OfferUpdatedDocument = gql`
  subscription OfferUpdated($offerId: ID!) {
    offerUpdated(offerId: $offerId) {
      ...OfferFragment
    }
  }
  ${OfferFragmentFragmentDoc}
`;

/**
 * __useOfferUpdatedSubscription__
 *
 * To run a query within a React component, call `useOfferUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOfferUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfferUpdatedSubscription({
 *   variables: {
 *      offerId: // value for 'offerId'
 *   },
 * });
 */
export function useOfferUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OfferUpdatedSubscription,
    OfferUpdatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<OfferUpdatedSubscription, OfferUpdatedSubscriptionVariables>(
    OfferUpdatedDocument,
    options,
  );
}
export type OfferUpdatedSubscriptionHookResult = ReturnType<typeof useOfferUpdatedSubscription>;
export type OfferUpdatedSubscriptionResult = Apollo.SubscriptionResult<OfferUpdatedSubscription>;
