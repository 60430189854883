import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { I18nText } from 'src/components/i18n';
import { PortAutocomplete } from './PortAutocomplete';

export const OrderFormSecondStep: FC<any> = ({
  loadingPorts,
  setLoadingPorts,
  dischargingPorts,
  setDischargingPorts,
}) => {
  const classes = useStyles();
  function handlePortChange(event: any, port: any, ports: any[], setPorts: Function) {
    const newPorts = [...ports];

    if (event.target.name === 'rate') {
      newPorts.splice(port.indexNumber - 1, 1, {
        ...port,
        [event.target.name]: +event.target.value.replace(/\D/, ''),
      });
    } else {
      newPorts.splice(port.indexNumber - 1, 1, {
        ...port,
        [event.target.name]: event.target.value,
      });
    }

    setPorts(newPorts);
  }

  const onPortAutocompleteChange = (
    newPort: string,
    port: any,
    ports: any[],
    setPorts: Function,
  ) => {
    const newPorts = [...ports];

    newPorts.splice(port.indexNumber - 1, 1, {
      ...port,
      portName: newPort,
    });

    setPorts(newPorts);
  };

  return (
    <>
      <div className={classes.upperBlock}>
        <div className={classes.labelContainer}>
          <div className={classes.labelWrapper}>
            <I18nText variant="h4" path="menuLabel.loading" />
          </div>
          <Fab
            onClick={() =>
              setLoadingPorts([
                ...loadingPorts,
                { indexNumber: loadingPorts.length + 1, portName: '', rate: 0 },
              ])
            }
            size="small"
            color="primary"
            aria-label="add"
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        </div>
        <Grid container className={classes.tableHeaderWrapper} spacing={3}>
          <Grid xs={1} sm={1} item className={classes.tableHeader}>
            #
          </Grid>
          <Grid xs={5} sm={4} item className={classes.tableHeader}>
            Port
          </Grid>
          <Grid xs={4} sm={4} item className={classes.tableHeader}>
            Rate
          </Grid>
        </Grid>
        {loadingPorts.map((loadingPort: any) => (
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={1} sm={1} key={loadingPort.indexNumber}>
              {loadingPort.indexNumber}
            </Grid>
            <Grid item xs={5} sm={4}>
              <PortAutocomplete
                classes={classes}
                onChange={newPort =>
                  onPortAutocompleteChange(newPort, loadingPort, loadingPorts, setLoadingPorts)
                }
                name="portName"
                value={loadingPort}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                variant="outlined"
                name={'rate'}
                value={loadingPort.rate}
                onChange={event =>
                  handlePortChange(event, loadingPort, loadingPorts, setLoadingPorts)
                }
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            {loadingPorts.length !== 1 && (
              <Grid item xs={1}>
                <div
                  className={classes.deleteButton}
                  onClick={() => {
                    const newPorts = loadingPorts
                      .filter(
                        (filterPort: any) => filterPort.indexNumber !== loadingPort.indexNumber,
                      )
                      .map((newPort: any, index: number) => ({
                        ...newPort,
                        indexNumber: index + 1,
                      }));
                    setLoadingPorts(newPorts);
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </div>
      <div className={classes.lowerBlock}>
        <div className={classes.labelContainer}>
          <div className={classes.labelWrapper}>
            <I18nText variant="h4" path="menuLabel.discharging" />
          </div>
          <Fab
            onClick={() =>
              setDischargingPorts([
                ...dischargingPorts,
                { indexNumber: dischargingPorts.length + 1, portName: '', rate: 0 },
              ])
            }
            size="small"
            color="primary"
            aria-label="add"
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        </div>
        <Grid container className={classes.tableHeaderWrapper} spacing={3}>
          <Grid xs={1} sm={1} item className={classes.tableHeader}>
            #
          </Grid>
          <Grid xs={5} sm={4} item className={classes.tableHeader}>
            Port
          </Grid>
          <Grid xs={4} sm={4} item className={classes.tableHeader}>
            Rate
          </Grid>
        </Grid>

        {dischargingPorts.map((dischargingPort: any) => (
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs={1} sm={1} key={dischargingPort.indexNumber}>
              {dischargingPort.indexNumber}
            </Grid>
            <Grid item xs={5} sm={4}>
              <PortAutocomplete
                classes={classes}
                onChange={newPort =>
                  onPortAutocompleteChange(
                    newPort,
                    dischargingPort,
                    dischargingPorts,
                    setDischargingPorts,
                  )
                }
                name="portName"
                value={dischargingPort}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <TextField
                variant="outlined"
                name={'rate'}
                value={dischargingPort.rate}
                onChange={event =>
                  handlePortChange(event, dischargingPort, dischargingPorts, setDischargingPorts)
                }
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
            {dischargingPorts.length !== 1 && (
              <Grid item xs={1}>
                <div
                  className={classes.deleteButton}
                  onClick={() => {
                    const newPorts = dischargingPorts
                      .filter(
                        (filterPort: any) =>
                          filterPort.indexNumber !== dischargingPort.indexNumber,
                      )
                      .map((newPort: any, index: number) => ({
                        ...newPort,
                        indexNumber: index + 1,
                      }));
                    setDischargingPorts(newPorts);
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles(() => ({
  upperBlock: {
    marginBottom: '10px',
  },
  lowerBlock: {
    marginTop: '80px',
    marginBottom: '10px',
  },
  labelWrapper: {
    color: 'black',
    textAlign: 'start',
  },
  labelContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '22.5px',
  },
  commentInput: {
    width: '100%',
    height: '100%',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  fab: { marginLeft: '20px' },
  tableHeaderWrapper: {
    marginBottom: '25.5px',
  },
  tableHeader: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  deleteButton: {
    cursor: 'pointer',
    width: '15px',
    height: '14px',
    backgroundSize: '15 14',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.7139' y1='1.00655' x2='14.3537' y2='13.6463' stroke='%23FF0000'/%3E%3Cline y1='-0.5' x2='17.8753' y2='-0.5' transform='matrix(-0.707107 0.707107 0.707107 0.707107 13.9995 1.36011)' stroke='%23FF0000'/%3E%3C/svg%3E%0A")`,
  },
}));
