import {
  useGetDashboardDataQuery,
  useGetDashboardGrowthDataQuery,
  useGetPortsQuery,
} from 'src/gql';
import { MINUTE } from 'src/utils/constants';

const POLLING_INTERVAL_MS = MINUTE * 5;

export const useSummaryPage = () => {
  const {
    data: dashboardData,
    loading: dashboardLoading,
    refetch: dashboardRefetch,
  } = useGetDashboardDataQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: POLLING_INTERVAL_MS,
  });
  const {
    data: growthData,
    loading: growthLoading,
    refetch: growthRefetch,
  } = useGetDashboardGrowthDataQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: POLLING_INTERVAL_MS,
  });
  const { data: portsData } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });

  return {
    dashboard: dashboardData?.dashboardData,
    dashboardRefetch,
    loading: growthLoading || dashboardLoading,
    growth: growthData?.dashboardFullGrowthData,
    growthRefetch,
    ports: portsData?.ports,
  };
};
