export const SELECT_TYPES = {
  COAL: 'coal',
  PORTS: 'ports',
} as const;

export const FORM_FIELDS = {
  BUYER: 'buyer',
  PAYMENT_POSTPONEMENT_DAYS: 'paymentPostponementDays',
  PAYMENT_POSTPONEMENT_END: 'paymentPostponementEnd',
  TONNAGE: 'tonnage',
  BL_DATE: 'blDate',
  DEM_PRICE: 'demPrice',
  DISCHARGING_PORT_ID: 'dischargingPortId',
  LOADING_PORT_ID: 'loadingPortId',
  FREIGHT_PRICE: 'freightPrice',
  COAL_MARK: 'coalMark',
  STATUS: 'status',
  DWT_MAX: 'dwtMax',
  DWT_MIN: 'dwtMin',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  PORT_NAME: 'portName',
} as const;
