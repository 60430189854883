import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'flex',
    marginBottom: '20px',
  },
  gridItemDesktop: { position: 'relative' },
  gridItemMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
  },
  portSelectContainer: {
    display: 'flex',
  },
  buttonWrapper: {
    marginLeft: '5px',
  },
  field: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '10px 7px',
    // height: '15px',
  },
  searchContainer: {
    display: 'flex',
    gap: '15px',
  },
  fieldsWrapper: {
    '& .MuiInputBase-root': { backgroundColor: '#fff' },
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    marginRight: '5px',
  },
  formContrainer: {
    margin: '15px 0',
  },

  selectWrapper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    position: 'relative',
  },
  select: {
    '& .MuiSelect-select:focus': {
      borderRadius: '8px',
      backgroundColor: 'rgba(256, 256, 256)',
    },
    minWidth: '99%',
    maxWidth: '500px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
  message: {
    marginBottom: '35px',
  },
}));
