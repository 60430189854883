import React, { FC } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { isDesktop } from 'react-device-detect';
import { FormikProps } from 'formik';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { I18nText } from 'src/components/i18n';
import { GetReportsQuery } from 'src/gql';
import { Button } from 'src/components/buttons';
import {
  downloadFile,
  formatToDDMMYYYY,
  setErrorNotification,
  subtractTimeFromNow,
} from 'src/utils/helpers';
import { backendHostname } from 'src/utils/constants';
import { getAuthHeaders, protocol } from 'src/apollo/client/client';

const initialState = {
  startDate: subtractTimeFromNow(5, 'year').toISOString(),
  endDate: new Date().toISOString(),
};

interface ReportFilterProps {
  formik: FormikProps<{ startDate: string; endDate: string }>;
  refetch: (
    variables?:
      | Partial<{
          endDate: string;
          managerIds?: string | string[] | undefined;
          startDate: string;
        }>
      | undefined,
  ) => Promise<ApolloQueryResult<GetReportsQuery>>;
}

export const ReportFilter: FC<ReportFilterProps> = ({ formik, refetch }) => {
  const classes = useStyles();

  const filter = (values: any) => {
    refetch({
      ...values,
    });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={1}>
        <Grid xs={12} sm={1} item className={classes.titleWrapper}>
          <I18nText path="field.period" />
        </Grid>
        <Grid xs={12} sm={2} item>
          <KeyboardDatePicker
            inputVariant="outlined"
            format="dd.MM.yyyy"
            inputProps={{
              className: classes.field,
            }}
            className={classes.fieldsWrapper}
            value={!!formik.values.startDate ? formik.values.startDate : null}
            name="startDate"
            onChange={date => {
              formik.setFieldTouched('startDate', false);
              formik.setFieldValue('startDate', date);
              filter({ ...formik.values, startDate: date });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change Date',
            }}
          />
        </Grid>
        <Grid xs={12} sm={2} item>
          <KeyboardDatePicker
            format="dd.MM.yyyy"
            inputVariant="outlined"
            name="endDate"
            inputProps={{
              className: classes.field,
            }}
            className={classes.fieldsWrapper}
            value={!!formik.values.endDate ? formik.values.endDate : null}
            onChange={date => {
              formik.setFieldTouched('endDate', false);
              formik.setFieldValue('endDate', date);
              filter({ ...formik.values, endDate: date });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change Date',
            }}
          />
        </Grid>

        {isDesktop && (
          <Grid xs={12} sm={5} item className={classes.buttonWrapper}>
            <Button
              onClick={() => {
                getReport(formik.values);
              }}
            >
              <I18nText path="button.downloadReport"></I18nText>
            </Button>
          </Grid>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export const getReport = async (data: typeof initialState) => {
  const reportUrl = `${protocol}//${backendHostname}/reports/generate`;

  const response = await fetch(reportUrl, {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...getAuthHeaders(),
    } as HeadersInit,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    setErrorNotification('Failed to download file');
    return;
  }

  await downloadFile(
    response,
    `Report ${formatToDDMMYYYY(data.startDate)}-${formatToDDMMYYYY(data.endDate)}.xlsx`,
  );

  return response;
};

const useStyles = makeStyles(() => ({
  button: {
    marginRight: '15px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titleWrapper: {
    fontWeight: 500,
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  fieldsWrapper: {
    width: '100%',
    '& .MuiInputBase-root': { backgroundColor: '#fff' },
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    marginRight: '5px',
  },
  field: {
    padding: '13.5px 15px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
}));
