import React, { useCallback, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { I18nText } from 'src/components/i18n';
import {
  useCreatePortMutation,
  useGetPortsQuery,
  useRenamePortMutation,
  CreatePortDocument,
} from 'src/gql';
import { VESSEL_TYPES } from 'src/utils/constants';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { Button } from 'src/components/buttons';
import { PortItem } from './PortItem';
import { PortFormPopup } from 'src/components/popups';
import { initialPort, portVar } from 'src/apollo/cache/cache';

export const PortsList = () => {
  const classes = useStyles();
  const [showPortForm, setShowPortForm] = useState(false);
  const [showEditPortForm, setEditShowPortForm] = useState(false);
  const { data, refetch } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [createPort, { loading: formLoading }] = useCreatePortMutation({
    errorPolicy: 'all',
    refetchQueries: [CreatePortDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Port created');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const [renamePort, { loading: editFormLoading }] = useRenamePortMutation({
    errorPolicy: 'all',
    refetchQueries: [CreatePortDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Port edited');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  return (
    <div>
      <div className={classes.panelTools}>
        <Container>
          <Grid container>
            <Grid xs={12} item>
              <Button onClick={() => setShowPortForm(true)}>
                <I18nText path="button.addPort" />
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container>
        <Grid container>
          {data?.ports.map((port, index) => (
            <Grid xs={12} sm={6} item spacing={2}>
              <PortItem key={index} port={port} setEditShowPortForm={setEditShowPortForm} />
            </Grid>
          ))}
        </Grid>
      </Container>
      {showPortForm && (
        <PortFormPopup
          open={showPortForm}
          handleClose={() => {
            portVar(initialPort);
            setShowPortForm(false);
          }}
          mutation={async (params: any) => {
            await createPort(params);
            refetch();
          }}
          type={VESSEL_TYPES.CREATE}
          loading={formLoading}
        />
      )}

      {showEditPortForm && (
        <PortFormPopup
          open={showEditPortForm}
          handleClose={() => {
            portVar(initialPort);
            setEditShowPortForm(false);
          }}
          mutation={async (params: any) => {
            await renamePort(params);
            refetch();
          }}
          type={VESSEL_TYPES.EDIT}
          loading={editFormLoading}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 75px',
  },
  fieldOnlyActiveWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  tableHeaderWrapper: {
    padding: '0px 15px',
    marginBottom: '18px',
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
}));
