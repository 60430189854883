import React, { FC, ChangeEventHandler } from 'react';

import { BaseTextFieldProps } from '@material-ui/core/TextField';
import TextField from '@material-ui/core/TextField';
import { useFormClasses } from 'src/hooks/useFormClasses';

interface InputProps extends BaseTextFieldProps {
  value: string | number;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name?: string;
  [key: string]: any;
}

export const Input: FC<InputProps> = ({ value, onChange, name, ...props }) => {
  const { classes } = useFormClasses();

  return (
    <TextField
      className={classes.textField}
      variant="outlined"
      name={name}
      value={value}
      onChange={onChange}
      {...props}
      InputProps={{
        className: classes.input,
        ...props.InputProps,
      }}
    />
  );
};
