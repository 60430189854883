import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  titleColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#999999',
    fontSize: '12px',
    width: '130px',
    zIndex: 100,
  },
  titleInviteColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#999999',
    fontSize: '12px',
    width: '150px',
    zIndex: 100,
  },
  columnContainer: {
    width: 'fit-content',
    gap: '15px',
    display: 'flex',
    justifyContent: 'start',
    padding: '15px 16px',
    position: 'sticky',
    zIndex: 100,
    top: '0',
    backgroundColor: '#EEEEEE',
    border: '2px solid #EEEEEE',
    borderRadius: '0 0 10px 10px',
  },
  tableContainer: {
    width: '100%',
    maxHeight: '1000px',
    overflow: 'scroll',
  },
}));
