import React, { useState, FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { isEmpty, map, omit } from 'ramda';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { PortPoint, useGetCargosQuery } from 'src/gql/';
import { getDDMMYYYY, setErrorNotification } from 'src/utils/helpers';
import { OPERATION_TYPES } from 'src/utils/constants';
import { OrderFormFirstStep } from './OrderFormFirstStep';
import { OrderFormSecondStep } from './OrderFormSecondStep';
import { useOrderDetails } from 'src/hooks';
import { getPorts } from 'src/utils/helpers/ports';

const orderSchema = yup.object().shape({
  canTime: yup.string().required(),
  layTime: yup.string().required(),
  // startTime: yup.string().required(),
  closeTime: yup.string().required(),
  type: yup.string().required(),
  cargo: yup.string().required(),
  quantityMax: yup.number().required(),
  quantityMin: yup.number().required(),
  stowageFactorUnit: yup.number().required(),
  wog: yup.string().required(),
});

export const OrderForm: FC<any> = ({ stepNumber, setStepNumber, order, mutation, type }) => {
  const classes = useStyles();
  const [showSummary, setShowSummary] = useState(false);
  const {
    loadingPorts: orderLoadingPorts,
    dischargingPorts: orderDischargingPorts,
    ...restOrder
  } = order;
  const [loadingPorts, setLoadingPorts] = useState(getPorts(orderLoadingPorts, type));
  const [dischargingPorts, setDischargingPorts] = useState(getPorts(orderDischargingPorts, type));

  const { data } = useGetCargosQuery({});
  const formik = useFormik({
    initialValues: {
      canTime: new Date(+restOrder.canTime),
      layTime: new Date(+restOrder.layTime),
      // startTime: new Date(+restOrder.startTime),
      closeTime: new Date(+restOrder.closeTime),
      type: restOrder.type,
      cargo: restOrder.cargo,
      comment: restOrder.comment,
      quantityMax: restOrder.quantityMax,
      quantityMin: restOrder.quantityMin,
      stowageFactorUnit: restOrder.stowageFactorUnit,
      wog: restOrder.wog,
    },
    onSubmit: values => {
      if (stepNumber === 1) {
        setStepNumber(2);
        return;
      }

      const isLoadingPortsEmpty =
        loadingPorts.length === 1 && !loadingPorts[0].portName && !loadingPorts[0].rate;
      const isDischargingPortsEmpty =
        dischargingPorts.length === 1 &&
        !dischargingPorts[0].portName &&
        !dischargingPorts[0].rate;

      if (isLoadingPortsEmpty || isDischargingPortsEmpty) {
        setErrorNotification('Loading, Discharging ports fields is empty');
        return;
      }
      const data: any = type === OPERATION_TYPES.EDIT ? { ...values, id: order.id } : values;

      mutation({
        variables: {
          order: data,
          loadingPorts: map(omit(['id']), loadingPorts),
          dischargingPorts: map(omit(['id']), dischargingPorts),
        },
      });
    },
    validationSchema: orderSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const { orderDetails } = useOrderDetails({
    order: { ...formik.values, loadingPorts, dischargingPorts } as any,
  });

  return (
    <Container className={classes.pageContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container className={classes.formContainer}>
            <Grid item lg={7} xs={12}>
              {stepNumber === 1 && <OrderFormFirstStep formik={formik} cargos={data?.cargos} />}
              {stepNumber === 2 && (
                <OrderFormSecondStep
                  formik={formik}
                  loadingPorts={loadingPorts}
                  setLoadingPorts={setLoadingPorts}
                  dischargingPorts={dischargingPorts}
                  setDischargingPorts={setDischargingPorts}
                />
              )}
            </Grid>
            <Grid item lg={2} xs={12} />
            <Grid item lg={3} xs={12} className={classes.rightContainer}>
              <Grid container>
                <div className={classes.tabs}>
                  <Grid
                    className={!showSummary ? classes.leftTab : classes.leftUnActiveTab}
                    item
                    xs={6}
                    onClick={() => setShowSummary(false)}
                  >
                    <I18nText path="field.comment" />
                  </Grid>
                  <Grid
                    className={showSummary ? classes.rightTab : classes.rightUnActiveTab}
                    item
                    xs={6}
                    onClick={() => setShowSummary(true)}
                  >
                    <I18nText path="field.summary" />
                  </Grid>
                </div>
                <Grid item xs={12} className={classes.tabsContentContainer}>
                  {showSummary ? (
                    <div className={classes.summary}>{orderDetails}</div>
                  ) : (
                    <TextField
                      className={classes.quantityInput}
                      variant="outlined"
                      name="comment"
                      onChange={event => {
                        formik.setFieldTouched('comment', false);
                        formik.setFieldValue('comment', event.target.value);
                      }}
                      error={!!formik.errors.comment && !!formik.touched.comment}
                      value={formik.values.comment}
                      multiline
                      placeholder="Type your comment here"
                      rows={20}
                      maxRows={20}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
              <div className={classes.buttonWrapepr}>
                {stepNumber === 1 && (
                  <div className={classes.nextButtonWrapepr}>
                    <Button
                      onClick={async () => {
                        await formik.submitForm();
                      }}
                    >
                      <I18nText path="button.next" />
                    </Button>
                  </div>
                )}
                {stepNumber === 2 && (
                  <>
                    <div className={classes.nextButtonWrapepr}>
                      <Button onClick={() => setStepNumber(1)} buttonVariant="second">
                        <I18nText path="button.back" />
                      </Button>

                      <Button type="submit">
                        {type === OPERATION_TYPES.CREATE && <I18nText path="button.create" />}
                        {type === OPERATION_TYPES.COPY && <I18nText path="button.copy" />}
                        {type === OPERATION_TYPES.EDIT && <I18nText path="button.edit" />}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </form>
      </MuiPickersUtilsProvider>
    </Container>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  quantityInput: {
    width: '100%',
  },
  commentInput: {
    width: '100%',
    height: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  separationLine: {
    opacity: 0.1,
    border: '1px solid #000000',
  },
  tabs: {
    width: '100%',
    cursor: 'pointer',
    borderRadius: '8px',
    fontSize: '21px',
    display: 'flex',
    textAlign: 'center',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
  },
  leftTab: {
    fontSize: '16px',
    color: ' #2A39BE',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
  },
  rightTab: {
    fontSize: '16px',
    color: ' #2A39BE',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
  },
  leftUnActiveTab: {
    fontSize: '16px',
    color: ' #2A39BE',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  rightUnActiveTab: {
    fontSize: '16px',
    color: ' #2A39BE',
    height: '48px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  nextButtonWrapepr: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '100px',
    gap: '15px',
  },
  pageContainer: {
    marginTop: '60px',
    marginBottom: '60px',
  },
  tabsContentContainer: {
    margin: '22px 0 200px',
  },
  summary: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  buttonWrapepr: {
    position: 'absolute',
    right: '0',
    bottom: '0',
  },
  rightContainer: {
    position: 'relative',
  },
}));
