import type { FC, ReactNode, MouseEvent } from 'react';

import type { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import MuiButton from '@material-ui/core/Button';

import { useStyles } from './Button.style';

export interface ButtonProps extends MuiButtonProps {
  buttonVariant?: 'main' | 'second' | 'dark' | 'light';
}

export const Button: FC<ButtonProps> = ({
  onClick,
  children,
  buttonVariant = 'main',
  className,
  ...props
}) => {
  const classes = useStyles();
  const buttonClass = {
    main: classes.main,
    second: classes.second,
    dark: classes.dark,
    light: classes.light,
  };

  return (
    <MuiButton
      {...props}
      className={`${buttonClass[buttonVariant]} ${className}`}
      variant="contained"
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
};
