import React, { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import { I18nText } from 'src/components/i18n';
import { useReplyAllOffersMutation } from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { Button } from 'src/components/buttons';
import { PopupWrapper } from 'src/components/popups/PopupWrapper/PopupWrapper';
import { useIntl } from 'react-intl';

const replySchema = yup.object().shape({
  text: yup.string().required(),
});

export const ReplyPopup: FC<any> = ({ open, handleClose }) => {
  const classes = useStyles();
  const { id } = useParams<any>();
  const intl = useIntl();
  const [replyAll, { loading }] = useReplyAllOffersMutation({
    onCompleted: () => {
      setSuccessMesageNotification('Reply succesfully sent');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const formik = useFormik({
    initialValues: {
      text: '',
    },
    onSubmit: values => {
      replyAll({
        variables: {
          text: values.text,
          orderId: id!,
        },
      });
      handleClose();
    },
    validationSchema: replySchema,
  });

  return (
    <PopupWrapper classes={classes} open={open} handleClose={handleClose}>
      <div className={classes.replyPopup}>
        <div className={classes.closeButton} onClick={handleClose} />
        <div className={classes.popupTitle}>
          <I18nText path="text.replyAll" />
        </div>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container alignItems="center" spacing={3} direction="row">
              <Grid item xs={12}>
                <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  variant="outlined"
                  name={'text'}
                  disabled={loading}
                  value={formik.values.text}
                  onChange={event => {
                    formik.setFieldTouched('text', false);
                    formik.setFieldValue('text', event.target.value);
                  }}
                  error={!!formik.errors.text && !!formik.touched.text}
                  helperText={!!formik.errors.text && !!formik.touched.text && formik.errors.text}
                  placeholder={intl.formatMessage({ id: 'text.replyPlaceholder' })}
                  multiline
                  rows={10}
                  maxRows={10}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" className={classes.buttons}>
              <Grid item className={classes.buttonContainer} xs={12}>
                <Button type="submit" disabled={loading}>
                  <I18nText path="button.replyAll" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </div>
    </PopupWrapper>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    minWidth: '650px',
    borderRadius: '51px',
  },
  replyPopup: {
    position: 'relative',
    padding: '50px 15px',
  },
  popupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  mobilePopup: {
    position: 'fixed',
    width: '100%',
    overflowY: 'scroll',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'white',
  },
}));
