import { map, prop } from 'ramda';
import React from 'react';
import { useMemo } from 'react';
import { I18nText } from 'src/components/i18n';
import { Order } from 'src/gql';
import { OrderType, StowageFactorUnit, STOWAGE_UNITS } from 'src/utils/constants';
import { getDDMMYYYY } from 'src/utils/helpers';

const portMap = map(prop('portName'));
const rateMap = map(prop('rate'));

export function useOrderDetails({
  order,
  order: {
    type,
    quantityMin,
    quantityMax,
    loadingPorts,
    dischargingPorts,
    cargo,
    layTime,
    canTime,
    stowageFactorUnit,
    wog,
  },
}: {
  order: Order;
}) {
  const orderDetails = useMemo(() => {
    if (!order?.quantityMin) {
      return null;
    }

    return (
      <>
        <div>
          abt {quantityMin} - {quantityMax} {OrderType[type]} {cargo}
        </div>
        <div>
          {portMap(loadingPorts).join(' + ')} / {portMap(dischargingPorts).join(' + ')}
        </div>
        <div>
          {rateMap(loadingPorts).join(' + ')} / {rateMap(dischargingPorts).join(' + ')}
        </div>
        <div>
          {!!layTime &&
            getDDMMYYYY((typeof layTime === 'string' ? new Date(+layTime) : layTime).getTime())}
          {!!canTime && (
            <>
              {' - '}
              {getDDMMYYYY((typeof canTime === 'string' ? new Date(+canTime) : canTime).getTime())}
            </>
          )}
        </div>
        <div>Stowage factor: {STOWAGE_UNITS[stowageFactorUnit as StowageFactorUnit]}</div>
        <div>abt {wog} WOG</div>
        <br />
        <div>Comment:</div>
        <div style={{ whiteSpace: 'pre-wrap' }}>{order.comment}</div>
      </>
    );
  }, [order]);

  return {
    orderDetails,
  };
}
