export enum OrderType {
  moloo = 0,
  chopt,
}

export enum StowageFactorUnit {
  cubicFeetTonn = 0,
  cubicMetersTonn = 1,
}

export const STOWAGE_UNITS: Record<StowageFactorUnit, string> = {
  [StowageFactorUnit.cubicFeetTonn]: 'ft³/tonne',
  [StowageFactorUnit.cubicMetersTonn]: 'm³/tonne',
};

export const IS_DEV = process.env.REACT_APP_NODE_ENV === 'dev';

export const hostnameDev = 'sb.elgacargo.com';
// export const hostnameDev = 'localhost:4030';

export const hostnameProd = `backend.elgacargo.com`;
export const backendHostname = IS_DEV ? hostnameDev : hostnameProd;

export const ElgaFleets = {
  'elga-dev': '2085163',
  'elga-prod': '2085161',
} as const;

export const ELGA_FLEET_ID = IS_DEV ? ElgaFleets['elga-dev'] : ElgaFleets['elga-prod'];

console.log({ ELGA_FLEET_ID });
