import React, { FC } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { User } from 'src/gql';
import { RequiredNotNull } from 'src/utils/types';
import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { getDDMMYYYY } from 'src/utils/helpers';
import { useIntl } from 'react-intl';
import { getRoleTitle } from 'src/utils/helpers/roles';

interface AccountListItemProps {
  account: RequiredNotNull<User>;
  deleteAdmin: any;
  index: number;
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>;
  disabled: boolean;
}

export const AccountListItem: FC<AccountListItemProps> = ({
  account,
  deleteAdmin,
  setEditingIndex,
  disabled,
  index,
}) => {
  const classes = useStyles();
  const { locale } = useIntl();

  return (
    <Container>
      <div className={disabled ? classes.orderContainerDisabled : classes.orderContainer}>
        <Grid container alignItems="center">
          <Grid
            xs={12}
            sm={6}
            item
            className={classes.idWrapper}
            //@ts-ignore
            justifyContent={'flex-start'}
          >
            <div className={classes.nameWrapper}>
              <div>{`${account.firstName} ${account.lastName}`}</div>
            </div>
          </Grid>
          {isDesktop && (
            <Grid item className={classes.buttonsWrapper} xs={6} justifyContent={'flex-end'}>
              <Button
                buttonVariant="light"
                disabled={disabled}
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?')) {
                    deleteAdmin({
                      variables: {
                        id: account.id,
                      },
                    });
                  }
                }}
              >
                <I18nText path="button.delete" />
              </Button>

              <Button
                onClick={() => {
                  setEditingIndex(index);
                }}
                disabled={disabled}
                buttonVariant="second"
              >
                <I18nText path="button.edit" />
              </Button>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <Grid container>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.name" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div>{`${account.firstName} ${account.lastName}`}</div>
            </Grid>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.email" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div className={classes.dataWrapper}>
                <div>{account.email}</div>
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.role" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div className={classes.rolesWrapper}>
                {account.roles.map((role: string, index: number) => (
                  <div>{getRoleTitle(role, locale)}</div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {isDesktop && (
          <Grid container>
            <Grid item className={classes.gridItem} xs={3} md={2}>
              <div className={classes.label}>
                <I18nText path="menuLabel.registrationDate" />
              </div>

              <div className={classes.dataWrapper}>
                <div className={classes.data}>{getDDMMYYYY(account.createdAt)}</div>
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={4} md={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.email" />
              </div>

              <div className={classes.dataWrapper}>
                <div>{account.email}</div>
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={3} md={2}>
              <div className={classes.label}>
                <I18nText path="menuLabel.role" />
              </div>

              <div className={classes.rolesWrapper}>
                {account.roles.map((role: string) => (
                  <div>{getRoleTitle(role, locale)}</div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid container className={classes.buttonContainer}>
            <Grid item className={classes.buttonsWrapper} xs={12} justifyContent={'flex-end'}>
              <Button
                buttonVariant="light"
                disabled={disabled}
                onClick={() => {
                  if (window.confirm('Are you sure you wish to delete this item?')) {
                    deleteAdmin({
                      variables: {
                        id: account.id,
                      },
                    });
                  }
                }}
              >
                <I18nText path="button.delete" />
              </Button>

              <Button
                onClick={() => {
                  setEditingIndex(index);
                }}
                disabled={disabled}
                buttonVariant="second"
              >
                <I18nText path="button.edit" />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  orderContainer: {
    position: 'relative',
    margin: '0 auto',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  orderContainerDisabled: {
    position: 'relative',
    margin: '0 auto',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
    opacity: '0.3',
  },
  buttonContainer: {
    marginTop: '36px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '16px',
  },
  nameWrapper: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  rolesWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataWrapper: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
  },
  idWrapper: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center',
  },
  data: {
    color: '#000',
    fontSize: '15px',
  },
  statusWrapper: {
    marginLeft: '10px',
  },
  label: {
    marginBottom: '6px',
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  layCanWrapper: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    fontSize: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },

  rateToggleWrapper: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '7px',
  },
  deleviredWasFine: {
    color: '#00BD2A',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.139 0.606404C11.7327 -0.0603189 12.7467 0.843469 12.1537 1.50953L5.13064 9.39991C4.88094 9.68014 4.45196 9.70538 4.17173 9.45569C3.05876 8.34272 1.90921 6.92031 0.844835 5.72504C0.251824 5.05898 1.26586 4.15519 1.85953 4.82192L4.62271 7.92646L11.1385 0.605916L11.139 0.606404Z' fill='%2300BD2A'/%3E%3C/svg%3E%0A")`,
      width: '13px',
      height: '10px',
      display: 'inline-block',
      backgroundSize: '13px 10px',
      backgroundRepeat: 'no-repeat',
    },
  },
  lateDelivery: {
    color: '#000',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 11M1 11L11 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
      width: '12px',
      height: '12px',
      display: 'inline-block',
      backgroundSize: '13px 12px',
      backgroundRepeat: 'no-repeat',
    },
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
}));
