import React, { FC, ChangeEvent } from 'react';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isDesktop } from 'react-device-detect';

interface PhoneInputProps {
  value: string;
  onChange: (phone: string) => void;
  error: boolean;
  name?: string;
  [key: string]: any;
}

export const PhoneInput: FC<PhoneInputProps> = ({
  name = 'phone',
  value,
  onChange,
  error,
  props,
}) => (
  <ReactPhoneInput
    specialLabel=""
    name={name}
    value={value}
    onChange={onChange}
    dropdownStyle={isDesktop ? { width: '420px' } : { height: '150px', width: '320px' }}
    inputStyle={{
      width: '100%',
      borderColor: error ? '#f44336' : '#fff',
    }}
    {...props}
  />
);
