import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useReactiveVar } from '@apollo/client';

import { userVar } from 'src/apollo/cache/cache';
import { OfferForm } from 'src/pages/shipowner/offer/components/OfferForm';
import { Header } from 'src/components/layout';
import { OPERATION_TYPES } from 'src/utils/constants';
import { useCreateOfferMutation, useGetMyVesselsQuery, useOrderQuery } from 'src/gql/';
import { setSuccessMesageNotification, setErrorNotification } from 'src/utils/helpers';
import * as ROUTES from 'src/utils/constants/routes';

export const ShipownerOfferCreatePage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<any>();
  const user = useReactiveVar(userVar);
  const { data: vesselsData } = useGetMyVesselsQuery({
    fetchPolicy: 'cache-and-network',
    variables: { limit: 100, offset: 0 },
  });
  const [createOffer] = useCreateOfferMutation({
    onCompleted: () => {
      setSuccessMesageNotification('Offer created');
      navigate(`/shipowner/order/${orderId}`);
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const {
    loading: orderLoading,
    data: orderData,
    error,
  } = useOrderQuery({
    variables: { id: orderId! },
  });
  return (
    <div>
      <Header type={'shipownerOffer'} />
      {!orderLoading && orderData && (
        <OfferForm
          mutation={createOffer}
          user={user}
          vessels={vesselsData?.myVessels?.vessels ?? []}
          orderId={orderId}
          type={OPERATION_TYPES.CREATE}
          order={orderData.order}
        />
      )}
    </div>
  );
};
