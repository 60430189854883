import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';

export interface I18n {
  path: string;
  paramValues?: any;
}

interface I18nTextProps {
  path: I18n['path'];
  html?: boolean;
  paramValues?: I18n['paramValues'];
  variant?: any;
}

export const I18nText: FC<I18nTextProps> = ({ path, html, paramValues, variant = 'inherit' }) =>
  html ? (
    <FormattedMessage id={path} values={paramValues}>
      {(txt: any) => <span dangerouslySetInnerHTML={{ __html: txt }} />}
    </FormattedMessage>
  ) : (
    <Typography variant={variant}>
      <FormattedMessage id={path} values={paramValues} />
    </Typography>
  );
