import {
  GetAdminsDocument,
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useEditAdminMutation,
  useGetAdminsQuery,
} from 'src/gql';
import { LIMIT } from 'src/utils/constants';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';

export const useAccountPage = () => {
  const {
    data: adminsData,
    loading: dataLoading,
    refetch,
    fetchMore,
  } = useGetAdminsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: LIMIT,
      offset: 0,
      roles: [],
    },
  });

  const [createAdmin] = useCreateAdminMutation({
    refetchQueries: [GetAdminsDocument],
    onCompleted: () => {
      setSuccessMesageNotification('User created');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const [editAdmin] = useEditAdminMutation({
    refetchQueries: [GetAdminsDocument],
    onCompleted: () => {
      setSuccessMesageNotification('User data edited');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const [deleteAdmin, { loading: deleteLoading }] = useDeleteAdminMutation({
    errorPolicy: 'all',
    refetchQueries: [GetAdminsDocument],
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: data => {
      setSuccessMesageNotification('User deleted');
    },
  });

  return {
    adminsData,
    dataLoading,
    refetch,
    fetchMore,
    createAdmin,
    editAdmin,
    deleteAdmin,
    deleteLoading,
  };
};
