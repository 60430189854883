import { useEffect } from 'react';

export const useMarineTrafficVesselSelected = (onSelected: (imo: string) => void) => {
  useEffect(() => {
    const handler = async ({ detail }: CustomEvent) => {
      onSelected(detail.imo);
    };

    window.addEventListener('onVesselSelected', handler);

    return () => {
      window.removeEventListener('onVesselSelected', handler);
    };
  }, [onSelected]);
};
