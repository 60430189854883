import { makeVar } from '@apollo/client';
import { User } from 'src/gql/__generated__';
import { LOCALE } from 'src/utils/constants';
import { getCookie } from 'src/utils/helpers';

export const userVar = makeVar<Partial<User>>({});
export const orderVar = makeVar<any>({
  canTime: new Date(Date.now()),
  layTime: new Date(Date.now()),
  startTime: new Date(Date.now()),
  closeTime: new Date(Date.now()),
  type: 0,
  cargo: '',
  comment: '',
  quantityMax: 0,
  quantityMin: 0,
  stowageFactorUnit: 0,
  wog: '',
  loadingPorts: [{ indexNumber: 1, portName: '', rate: '' }],
  dischargingPorts: [{ indexNumber: 1, portName: '', rate: '' }],
});

export const vesselsVar = makeVar<any>([]);
export const vesselCountVar = makeVar<number>(0);

export const initialVessel = {
  id: '',
  deadweight: 0,
  draft: 0,
  imo: 0,
  name: '',
  constructionYear: 0,
};

export const vesselVar = makeVar<any>(initialVessel);

export const monthlyPlanItemVar = makeVar<any>(null);

export const initialPort = {
  name: '',
};
export const portVar = makeVar<any>(initialPort);

export const localeVar = makeVar<'en' | 'ru'>((getCookie(LOCALE) as 'en' | 'ru') || 'ru');

export const inviteVar = makeVar<any>({});

export const invitedEmailsVar = makeVar<any>([]);
