import React, { FC } from 'react';
import { useIntl } from 'react-intl';
//ts doesn't exist
//@ts-ignore
import CountryFlag from 'react-country-flag';

import { makeStyles } from '@material-ui/core/styles';

type Language = { name: string; code: 'en' | 'ru'; flag: string };
const LANGUAGES: Array<Language> = [
  { name: 'Русский', code: 'ru', flag: 'RU' },
  { name: 'English', code: 'en', flag: 'GB' },
];
export const I18nSelect: FC<{ onClick: (language: Language) => void }> = ({ onClick }) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.selectWrapper}>
      {LANGUAGES.map((language: Language) => (
        <div key={language.code} className={classes.select}>
          <CountryFlag svg countryCode={language.flag} />
          <div className={classes.select} onClick={() => onClick(language)}>
            {intl.locale === language.code ? <b>{language.name}</b> : language.name}
          </div>
        </div>
      ))}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  selectWrapper: {
    width: '100%',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '5px',
  },
  select: {
    width: '100%',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    gap: '5px',
  },
}));
