import React, { FC, useState, useMemo } from 'react';

import { makeStyles, styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { MenuOption } from 'src/utils/types';
import { I18n, I18nText } from 'src/components/i18n';

interface MenuSelectProps {
  title: string | I18n;
  openTo?: 'up' | 'down';
  options: MenuOption[];
  selectButtonStyles?: any;
}

export const MenuSelect: FC<MenuSelectProps> = ({
  title,
  options,
  openTo = 'down',
  selectButtonStyles = {},
}) => {
  const classes = useStyles();
  const [showActions, setShowActions] = useState(false);

  const selectTitle = useMemo(
    () =>
      (title as I18n).path ? (
        <I18nText path={(title as I18n).path} paramValues={(title as I18n).paramValues} />
      ) : (
        <>{title}</>
      ),
    [title],
  );

  const arrowSide = useMemo(() => {
    if (openTo === 'down') {
      return !showActions ? 'down' : 'up';
    }
    if (openTo === 'up') {
      return !showActions ? 'up' : 'down';
    }
  }, [showActions]);

  const TitleButton = ({ styles, openTo = 'down', ...props }: any) => {
    const ButtonWithStyles = styled(Button)({ ...styles });

    return (
      <ButtonWithStyles
        variant="outlined"
        endIcon={arrowSide === 'down' ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        onClick={() => setShowActions(!showActions)}
        {...props}
      >
        {selectTitle}
      </ButtonWithStyles>
    );
  };

  return (
    <div className={classes.selectContainer}>
      <ClickAwayListener onClickAway={() => setShowActions(false)}>
        <div>
          <TitleButton className={classes.selectButton} styles={{ ...selectButtonStyles }} />

          {showActions && (
            <div
              className={classes.optionsWrapper}
              style={openTo === 'down' ? { top: '0' } : { bottom: '0' }}
            >
              {openTo === 'down' && <TitleButton className={classes.selectTitleOption} />}
              {options.map(
                (option: MenuOption, index: number) =>
                  !option.invisible && (
                    <Button
                      key={index}
                      className={classes.option}
                      onClick={async e => {
                        await option.onClick(e);
                        setShowActions(false);
                      }}
                    >
                      {(option.title as I18n).path ? (
                        <I18nText
                          path={(option.title as I18n).path}
                          paramValues={(option.title as I18n).paramValues}
                        />
                      ) : (
                        <>{option.title}</>
                      )}
                    </Button>
                  ),
              )}
              {openTo === 'up' && <TitleButton className={classes.selectTitleOption} />}
            </div>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  selectContainer: {
    position: 'relative',
  },
  selectButton: {
    color: '#fff',
    border: '2px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '8px',
    height: '40px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '10px 30px',
  },
  selectTitleOption: {
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    height: '38px',
    fontSize: '16px',
    textTransform: 'none',
  },
  optionsWrapper: {
    zIndex: 111,
    position: 'absolute',
    background: '#FFFFFF',
    border: '2px solid rgba(42, 57, 190, 0.55)',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
  },
  option: {
    justifyContent: 'flex-start',
    width: '100%',
    border: 'none',
    borderRadius: '8px',
    height: '40px',
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: '4px',
    textTransform: 'none',
    padding: '0 35px',
    textAlign: 'start',
  },
}));
