import React, { useCallback, FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { isDesktop, isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { OrderListItem } from 'src/components/items';
import {
  LIMIT,
  MY_VESSELS_VARIABLES,
  VESSEL_TYPES,
  ORDER_BY,
  ORDER_COLUMN,
} from 'src/utils/constants/';
import { EmptyText } from 'src/components/EmptyText/EmptyText';
import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { Filter } from 'src/components/Filter/Filter';
import { FilterMobile } from 'src/components/Filter/FilterMobile';
import { VesselFormPopup } from 'src/components/popups/VesselFormPopup/VesselFormPopup';
import { SortLabel } from 'src/components/SortLabel/SortLabel';
import { SortPanel } from 'src/components/SortPanel/SortPanel';

import {
  GetMyVesselsDocument,
  NewOrderAddedDocument,
  useCreateVesselMutation,
  useGetOrdersForShipownersQuery,
} from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { PopupDetails } from 'src/components/popups/PopupDetails/PopupDetails';
import { vesselCountVar } from 'src/apollo/cache/cache';
import { evolve, inc, pick, prepend } from 'ramda';

export const ShipownerOrderList: FC = () => {
  const classes = useStyles();
  const [showVesselForm, setShowVesselForm] = useState(false);
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
  const [details, setDetails] = useState<any>({});
  const [column, setColumn] = useState<string>(ORDER_COLUMN.STATUS);
  const [orderBy, setOrderBy] = useState(ORDER_BY.DESC);
  const [expanded, setExpanded] = useState<boolean>(true);

  const {
    loading,
    error,
    data: ordersForShipownersData,
    fetchMore,
    refetch,
    subscribeToMore,
  } = useGetOrdersForShipownersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: LIMIT,
      offset: 0,
      onlyActive: false,
      filters: {}, //@ts-ignore
      sorting: { orderBy: ORDER_BY.DESC, column: ORDER_COLUMN.STATUS },
    },
  });

  useEffect(() => {
    subscribeToMore({
      document: NewOrderAddedDocument,
      variables: {},
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data) return prev;
        //@ts-ignore
        const { newOrderAdded } = subscriptionData.data;
        return {
          ordersForShipowners: {
            ...prev.ordersForShipowners,
            orders: [newOrderAdded, ...prev.ordersForShipowners.orders],
          },
        };
      },
    });
  }, []);

  const sort = (column: any, orderBy: string) => {
    setOrderBy(orderBy);
    setColumn(column);
    //@ts-ignore
    refetch({ sorting: { orderBy, column } });
  };

  const [createVessel, { loading: formLoading }] = useCreateVesselMutation({
    errorPolicy: 'all',
    refetchQueries: [{ query: GetMyVesselsDocument, variables: MY_VESSELS_VARIABLES }],
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: data => {
      vesselCountVar(vesselCountVar() + 1);
      setSuccessMesageNotification('Vessel successfully added');
    },
  });
  // const { loading, error, data, fetchMore, refetch } = useGetMyOffersQuery({
  //   variables: { limit: LIMIT, offset: 0 },
  // });

  const onLoadMore = useCallback(async () => {
    await fetchMore({
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult?.ordersForShipowners) return prev;
        const { orders } = fetchMoreResult.ordersForShipowners;
        return {
          ordersForShipowners: {
            ...prev.ordersForShipowners,
            orders: [...prev.ordersForShipowners.orders, ...orders],
            hasNextPage: fetchMoreResult.ordersForShipowners.hasNextPage,
          },
        };
      },
      variables: {
        limit: LIMIT,
        offset: ordersForShipownersData?.ordersForShipowners.orders.length,
      },
    });
  }, [ordersForShipownersData]);

  return (
    <div>
      <div className={classes.filterWrapper}>
        <Container>
          <div className={classes.filterTitle}>
            <div>
              <I18nText path="menuLabel.filters" />
            </div>
            <IconButton
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </IconButton>
          </div>
          {expanded && (
            <>
              {isDesktop ? (
                <Filter classes={classes} query={refetch} />
              ) : (
                <FilterMobile classes={classes} query={refetch} />
              )}
            </>
          )}
        </Container>
      </div>

      {isMobile && (
        <Container>
          <SortPanel column={column} sort={sort} orderBy={orderBy} />
        </Container>
      )}
      {isDesktop && (
        <Container>
          <Grid container>
            <Grid xs={1} item>
              <I18nText path="text.sortBy" />
            </Grid>
            <Grid xs={11} item>
              <div className={classes.tableHeaderWrapper}>
                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.id"
                    sort={sort}
                    column={ORDER_COLUMN.ID}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.ID}
                  />
                </div>

                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.status"
                    sort={sort}
                    column={ORDER_COLUMN.STATUS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.STATUS}
                  />
                </div>

                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.quantity"
                    sort={sort}
                    column={ORDER_COLUMN.QUANTITY_MAX}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.QUANTITY_MAX}
                  />
                </div>

                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.loading"
                    sort={sort}
                    column={ORDER_COLUMN.LOADING_PORTS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.LOADING_PORTS}
                  />
                </div>

                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.discharging"
                    sort={sort}
                    column={ORDER_COLUMN.DISCHARGING_PORTS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.DISCHARGING_PORTS}
                  />
                </div>

                <div className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.laycan"
                    sort={sort}
                    column={ORDER_COLUMN.CAN_TIME}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.CAN_TIME}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}

      {ordersForShipownersData?.ordersForShipowners.orders.map((order: any) => (
        <OrderListItem
          key={order.id}
          setShowDetailsPopup={() => {
            setShowDetailsPopup(true);
            setDetails({ ...order });
          }}
          order={{ ...order }}
        />
      ))}

      {ordersForShipownersData?.ordersForShipowners.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={loading} onClick={() => onLoadMore()}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
      <VesselFormPopup
        open={showVesselForm}
        handleClose={() => setShowVesselForm(false)}
        mutation={createVessel}
        loading={formLoading}
        type={VESSEL_TYPES.CREATE}
      />

      <PopupDetails
        showPopup={showDetailsPopup}
        setShowPopup={() => {
          setShowDetailsPopup(false);
          setDetails({});
        }}
        details={details}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 75px',
  },
  fieldOnlyActiveWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  toggleWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  tableHeaderWrapper: {
    display: 'flex',
    marginBottom: '15px',
    gap: '35px',
  },
  tableHeader: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  noVessel: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '16px',
    color: 'rgba(0,0,0,0.3)',
    marginRight: '24px',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  labels: {
    margin: '0 0 0 15px',
  },
  filterWrapper: {
    marginTop: '25px',
  },
  filterTitle: {
    marginBottom: '20px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
    height: '40px',
  },
  button: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '100%',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
    width: '100%',
  },
  sortPanel: {
    marginBottom: '50px',
  },
}));
