import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useParams } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { Button } from 'src/components/buttons';
import { monthlyPlanItemVar } from 'src/apollo/cache/cache';
import { I18nText } from 'src/components/i18n';
import { OPERATION_TYPES } from 'src/utils/constants';
import { PopupWrapper } from 'src/components/popups/PopupWrapper/PopupWrapper';
import { Checkbox, ListItemText, MenuItem, Select } from '@material-ui/core';
import { COAL_MARK } from 'src/utils/constants/coal';
import { Port } from 'src/gql';

const initialState = {
  ports: [],
  coalMark: '',
  plannedTonnage: '',
};

const planItemSchema = yup.object().shape({
  ports: yup.array().of(yup.object()).required(),
  coalMark: yup.string().required(),
  plannedTonnage: yup.number().required(),
});

export const MonthlyPlanFormPopup: FC<any> = ({
  handleClose,
  mutation,
  loading,
  open,
  type,
  ports,
}) => {
  const classes = useStyles();
  const { id } = useParams<{ id?: string }>();

  const item = useReactiveVar(monthlyPlanItemVar);
  const formik = useFormik({
    initialValues: {
      ports: !!item ? item.ports : initialState.ports,
      coalMark: !!item ? item.coalMark : initialState.coalMark,
      plannedTonnage: !!item ? item.plannedTonnage : initialState.plannedTonnage,
    },
    onSubmit: values => {
      const data: any = {
        portIds: values.ports.map((el: any) => el.id),
        coalMark: values.coalMark,
        plannedTonnage: +values.plannedTonnage,
        planId: id,
      };
      if (type === OPERATION_TYPES.EDIT) {
        mutation({
          variables: {
            data: data,
            id: item.id,
          },
        });
        monthlyPlanItemVar(null);
        handleClose();
        return;
      }
      mutation({
        variables: {
          data: data,
        },
      });
      monthlyPlanItemVar(null);
      handleClose();
    },
    validationSchema: planItemSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <PopupWrapper open={open} classes={classes} handleClose={handleClose}>
      <div className={classes.popup}>
        <div className={classes.closeButton} onClick={handleClose} />
        <div className={classes.popupTitle}>
          {type !== OPERATION_TYPES.EDIT ? (
            <I18nText path="text.addPlanItem" />
          ) : (
            <I18nText path="text.edit" />
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <Grid container alignItems="center" spacing={3} direction="row">
            <Grid item sm={3} xs={12}>
              <InputLabel className={classes.label}>
                <I18nText path={'field.ports'} />
              </InputLabel>
            </Grid>

            <Grid item sm={9} xs={12}>
              <div className={classes.selectWrapper}>
                <Select
                  multiple
                  className={classes.select}
                  name="ports"
                  variant="outlined"
                  value={formik.values.ports}
                  onChange={event => {
                    formik.setFieldTouched('ports', false);
                    formik.setFieldValue('ports', event.target.value);
                  }}
                  inputProps={{
                    className: classes.textField,
                  }}
                  renderValue={selected => (
                    <div className={classes.chips}>
                      {(selected as Port[]).map((value, index) => (
                        <div key={value.name} className={classes.chip}>
                          {value.name}
                          {index + 1 !== (selected as string[]).length && ', '}
                        </div>
                      ))}
                    </div>
                  )}
                >
                  {(ports ?? []).map((port: any, index: number) => (
                    <MenuItem key={index} value={port}>
                      <Checkbox
                        color="primary"
                        checked={formik.values.ports.some((el: Port) => port.name === el.name)}
                      />
                      <ListItemText primary={port.name} />
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputLabel className={classes.label}>
                <I18nText path={'field.coalMark'} />
              </InputLabel>
            </Grid>
            <Grid item sm={9} xs={12}>
              <div className={classes.selectWrapper}>
                <Select
                  className={classes.select}
                  variant="outlined"
                  name="coalMark"
                  value={formik.values.coalMark}
                  onChange={event => {
                    formik.setFieldValue('coalMark', event.target.value);
                  }}
                >
                  {Object.values(COAL_MARK).map(value => (
                    <MenuItem key={value} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </Grid>
            <Grid item sm={3} xs={12}>
              <InputLabel className={classes.label}>
                <I18nText path={'field.plannedTonnage'} />
              </InputLabel>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                className={classes.textField}
                variant="outlined"
                name={'plannedTonnage'}
                value={formik.values.plannedTonnage}
                error={!!formik.errors.plannedTonnage && !!formik.touched.plannedTonnage}
                onChange={event => {
                  formik.setFieldTouched('plannedTonnage', false);
                  formik.setFieldValue('plannedTonnage', +event.target.value.replace(/\D/, ''));
                }}
                InputProps={{
                  className: classes.input,
                }}
              />
            </Grid>
          </Grid>

          <Grid container alignItems="center" direction="row" className={classes.buttons}>
            <Grid item className={classes.submitButtonContainer} xs={12}>
              <Button type="submit" disabled={loading}>
                {type === OPERATION_TYPES.CREATE && <I18nText path="button.addPlanItem" />}
                {type === OPERATION_TYPES.EDIT && <I18nText path="button.edit" />}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </PopupWrapper>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: '15px',
  },
  popup: {
    position: 'relative',
    padding: '50px 45px',
  },
  popupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  selectWrapper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    position: 'relative',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
  select: {
    '& .MuiSelect-select:focus': {
      borderRadius: '8px',
      backgroundColor: 'rgba(256, 256, 256)',
    },
    minWidth: '99%',
    maxWidth: '500px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
  },
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  mobilePopup: {
    position: 'fixed',
    overflowY: 'scroll',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
}));
