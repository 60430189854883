import React, { FC, ReactNode } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';

interface LabelProps {
  children: ReactNode;
}

export const Label: FC<LabelProps> = ({ children }) => {
  const classes = useStyles();

  return <InputLabel className={classes.label}>{children}</InputLabel>;
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '15px',
    textAlign: 'start',
    marginBottom: '5px',
    color: 'rgba(0,0,0,0.6)',
    whiteSpace: 'nowrap',
  },
}));
