import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { DateTimer } from 'src/components/DateTimer/DateTimer';
import { I18nText } from 'src/components/i18n';
import { Order } from 'src/gql';
import { RequiredNotNull } from 'src/utils/types';
import { ORDER_STATUSES } from 'src/utils/constants';

interface RenderOrderStatusProps {
  order: RequiredNotNull<Order>;
}

export const OrderStatus: FC<RenderOrderStatusProps> = ({ order }) => {
  const classes = useStyles();

  const closeTimeMs = +order.closeTime;
  const startTimeMs = +order.startTime;

  const now = new Date().getTime();

  if (order.state === ORDER_STATUSES.NOT_STARTED && closeTimeMs > now && startTimeMs > now) {
    return (
      <div className={classes.statusStartsIn}>
        <div>
          <I18nText path="order.item.statusStartsIn" /> <DateTimer time={startTimeMs} />
        </div>
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.ACTIVE && closeTimeMs > now && startTimeMs < now) {
    return (
      <div className={classes.statusActive}>
        <div>
          <I18nText path="order.item.statusActive" /> <DateTimer time={closeTimeMs} />
        </div>
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.CLOSED) {
    return (
      <div className={classes.statusStartsIn}>
        <div>
          <I18nText path="order.item.statusClosed" />
        </div>
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.WINNER_SELECTED) {
    return (
      <div className={classes.statusStartsIn}>
        <div>
          <I18nText path="order.item.statusWinnerSelected" />
        </div>
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.ONGOING) {
    return (
      <div className={classes.statusStartsIn}>
        <div>
          <I18nText path="order.item.statusOngoing" />
        </div>
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.FINISHED) {
    return (
      <div className={classes.statusFinished}>
        <I18nText path="order.item.statusFinished" />
      </div>
    );
  }

  if (order.state === ORDER_STATUSES.ARCHIVED) {
    return (
      <div className={classes.statusStartsIn}>
        <div>
          <I18nText path="order.item.statusArchived" />{' '}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.statusStartsIn}>
      <div>
        <I18nText path="order.item.statusClosed" />
      </div>
    </div>
  );

  return null;
};

const useStyles = makeStyles(theme => ({
  statusFinished: {
    color: '#2A39BE',
    fontSize: '17px',
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.1391 0.431111C11.7328 -0.235612 12.7468 0.668176 12.1538 1.33424L5.13076 9.22462C4.88106 9.50484 4.45208 9.53009 4.17186 9.2804C3.05888 8.16743 1.90933 6.74502 0.844957 5.54975C0.251946 4.88369 1.26598 3.9799 1.85965 4.64662L4.62283 7.75116L11.1386 0.430623L11.1391 0.431111Z' fill='%232A39BE'/%3E%3C/svg%3E%0A")`,
      width: '13px',
      height: '10px',
      display: 'inline-block',
      backgroundSize: '13px 10px',
      backgroundRepeat: 'no-repeat',
    },
  },
  statusActive: {
    display: 'flex',
    color: '#00BD2A',
    fontSize: '17px',
    textAlign: 'left',
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5.5' cy='5.30322' r='5' fill='%2300BD2A'/%3E%3C/svg%3E%0A")`,
      width: '11px',
      height: 'auto',
      display: 'inline-block',
      backgroundSize: '11px 11px',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'top',
      marginTop: '7px',
    },
  },
  statusStartsIn: {
    display: 'flex',
    color: '#FA9639',
    fontSize: '17px',

    textAlign: 'left',
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5.5' cy='5.30322' r='5' fill='%23FA9639'/%3E%3C/svg%3E%0A")`,
      width: '11px',
      height: 'auto',
      display: 'inline-block',
      backgroundSize: '11px 11px',
      backgroundRepeat: 'no-repeat',
      backgroundPositionY: 'top',
      marginTop: '7px',
    },
  },
}));
