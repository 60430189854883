import React, { FC } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { isDesktop } from 'react-device-detect';

import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import { KeyboardTimePicker } from '@material-ui/pickers/TimePicker';

import { I18nText } from 'src/components/i18n';
import { useChangeOrderClosingTimeMutation } from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { Button } from 'src/components/buttons';
import { PopupWrapper } from 'src/components/popups/PopupWrapper/PopupWrapper';

const timeSchema = yup.object().shape({
  closeTime: yup.string().required(),
});

export const NewClosingTimePopup: FC<any> = ({ open, handleClose, order }) => {
  const classes = useStyles();
  const [changeOrderClosingTime, { loading }] = useChangeOrderClosingTimeMutation({
    onCompleted: () => {
      setSuccessMesageNotification('Edit successful');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const formik = useFormik({
    initialValues: {
      closeTime: new Date(+order.closeTime),
    },
    onSubmit: values => {
      changeOrderClosingTime({
        variables: {
          //@ts-ignore
          newCloseTime: values.closeTime,
          id: order.id,
        },
      });

      handleClose();
    },
    validationSchema: timeSchema,
  });

  return (
    <PopupWrapper classes={classes} open={open} handleClose={handleClose}>
      <div className={classes.newTimePopup}>
        <div className={classes.closeButton} onClick={handleClose} />
        <div className={classes.popupTitle}>
          <I18nText path="text.newClosingTime" />
        </div>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container alignItems="center" spacing={1} direction="row">
              <Grid item xs={12} sm={2}>
                <InputLabel className={classes.label}>
                  <I18nText path="field.closingTime" />
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={5}>
                <KeyboardDatePicker
                  margin="normal"
                  inputVariant="outlined"
                  format="dd.MM.yyyy"
                  name="closeTime"
                  value={+formik.values.closeTime}
                  clearable
                  error={!!formik.errors.closeTime && !!formik.touched.closeTime}
                  InputProps={{
                    className: classes.input,
                  }}
                  onChange={date => {
                    formik.setFieldTouched('closeTime', false);
                    formik.setFieldValue('closeTime', date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5}>
                <KeyboardTimePicker
                  margin="normal"
                  name="closeTime"
                  inputVariant="outlined"
                  value={+formik.values.closeTime}
                  clearable
                  InputProps={{
                    className: classes.input,
                  }}
                  error={!!formik.errors.closeTime && !!formik.touched.closeTime}
                  onChange={date => {
                    formik.setFieldTouched('closeTime', false);
                    formik.setFieldValue('closeTime', date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" direction="row" className={classes.buttons}>
              <Grid item className={classes.buttonContainer} xs={12}>
                <Button type="submit" disabled={loading}>
                  <I18nText path="button.changeClosingTime" />
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </div>
    </PopupWrapper>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '950px',
    borderRadius: '15px',
  },
  newTimePopup: {
    position: 'relative',
    padding: '50px 15px',
  },
  popupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  mobilePopup: {
    position: 'fixed',
    overflowY: 'scroll',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
  },
}));
