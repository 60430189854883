import { ApolloClient, createHttpLink, HttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { AUTH_TOKEN, LOCALE } from 'src/utils/constants/cookies';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getCookie } from 'src/utils/helpers/cookies';
import { hostnameDev, hostnameProd, IS_DEV } from 'src/utils/constants';

export const protocol =
  window.location.hostname === 'localhost' ? 'http:' : window.location.protocol;
const wsProtocol = protocol === 'https:' ? 'wss' : 'ws';

const isLocal = window.location.hostname === 'localhost';

const baseUrl = IS_DEV
  ? `${isLocal ? 'http:' : protocol}//${hostnameDev}/graphql`
  : `${protocol}//${hostnameProd}/graphql`;

const wsUrl = IS_DEV
  ? `${isLocal ? 'ws' : wsProtocol}://${hostnameDev}/subscriptions`
  : `${wsProtocol}://${hostnameProd}/subscriptions`;

// console.log('protocol', protocol);

// const httpLink = createHttpLink({
//   uri: baseUrl,
// });

const baseHeaders = {
  authorization: '',
};

const LANGUAGE_HEADER = 'x-elga-language';

const token = getCookie(AUTH_TOKEN);
if (token) {
  baseHeaders.authorization = token ? `Bearer ${token}` : '';
}

export const getAuthHeaders = () => {
  const token = getCookie(AUTH_TOKEN);
  if (token) {
    baseHeaders.authorization = token ? `Bearer ${token}` : '';
  }

  return { ...baseHeaders, [LANGUAGE_HEADER]: getCookie(LOCALE) };
};

export const getHeaders = () => ({
  authorization: token ? `Bearer ${token}` : '',
  [LANGUAGE_HEADER]: getCookie(LOCALE),
});

function getLink() {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...getAuthHeaders(),
      },
    };
  });

  const directionalLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },

    new WebSocketLink({
      options: {
        reconnect: true,
        connectionParams: () => getAuthHeaders(),
      },
      uri: wsUrl,
    }),

    new HttpLink({
      uri: baseUrl,
    }),
  );

  return authLink.concat(directionalLink);
}

export const relink = () => {
  client.setLink(getLink());
};

export const client = new ApolloClient({
  link: getLink(),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
