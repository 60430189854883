import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import Grid from '@material-ui/core/Grid';

import { Input, DateInput, NumberInput, SkeletonInput } from 'src/components/inputs';
import { I18nText } from 'src/components/i18n';
import { Order, Port } from 'src/gql';
import { Popup } from 'src/components/popups/Popup/Popup';
import { Button } from 'src/components/buttons';
import { COAL_MARK, FORM_FIELDS, SELECT_TYPES } from 'src/utils/constants';
import { getInputProps } from 'src/utils/helpers';
import { Label, SkeletonLabel } from 'src/components/forms';
import { Select } from 'src/components/selects';
import {
  useMetadataFormPopup,
  MetadataFormValues,
} from './useMetadataFormPopup/useMetadataFormPopup';

interface MetadataFormPopupProps {
  handleClose: () => void;
  order: Order;
}

export const MetadataFormPopup: FC<MetadataFormPopupProps> = ({ handleClose, order }) => {
  const intl = useIntl();
  const { formLoading, contentLoading, formik, classes, ports } = useMetadataFormPopup({
    order,
    handleClose,
  });

  if (contentLoading || !ports) {
    return (
      <Popup handleClose={handleClose} title={intl.formatMessage({ id: 'popup.metadata.title' })}>
        {[...Array(Object.keys(formik.values).length)].map(() => (
          <>
            <Grid item xs={12} sm={12}>
              <SkeletonLabel />
            </Grid>
            <Grid item xs={12} sm={12}>
              <SkeletonInput />
            </Grid>
          </>
        ))}
      </Popup>
    );
  }

  return (
    <Popup handleClose={handleClose} title={intl.formatMessage({ id: 'popup.metadata.title' })}>
      <form onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.BL_DATE}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <DateInput
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.BL_DATE)}
            value={formik.values[FORM_FIELDS.BL_DATE]}
            onChange={date => {
              formik.setFieldTouched(FORM_FIELDS.BL_DATE, false);
              formik.setFieldValue(FORM_FIELDS.BL_DATE, date);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.BUYER}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Input
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.BUYER)}
            value={formik.values[FORM_FIELDS.BUYER]}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.BUYER, false);
              formik.setFieldValue(FORM_FIELDS.BUYER, event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.DEM_PRICE}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberInput
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.DEM_PRICE)}
            value={formik.values[FORM_FIELDS.DEM_PRICE]}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.DEM_PRICE, false);
              formik.setFieldValue(FORM_FIELDS.DEM_PRICE, event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.LOADING_PORT_ID}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Select
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.LOADING_PORT_ID)}
            value={formik.values[FORM_FIELDS.LOADING_PORT_ID]}
            options={ports as Port[]}
            type={SELECT_TYPES.PORTS}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.LOADING_PORT_ID, false);
              formik.setFieldValue(FORM_FIELDS.LOADING_PORT_ID, event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.DISCHARGING_PORT_ID}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Select
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.DISCHARGING_PORT_ID)}
            value={formik.values[FORM_FIELDS.DISCHARGING_PORT_ID]}
            options={ports as Port[]}
            type={SELECT_TYPES.PORTS}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.DISCHARGING_PORT_ID, false);
              formik.setFieldValue(FORM_FIELDS.DISCHARGING_PORT_ID, event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.FREIGHT_PRICE}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberInput
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.FREIGHT_PRICE)}
            value={formik.values[FORM_FIELDS.FREIGHT_PRICE]}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.FREIGHT_PRICE, false);
              formik.setFieldValue(FORM_FIELDS.FREIGHT_PRICE, event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Label>
            <I18nText path={`field.${FORM_FIELDS.PAYMENT_POSTPONEMENT_DAYS}`} />
          </Label>
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberInput
            allowDecimals={false}
            {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.PAYMENT_POSTPONEMENT_DAYS)}
            value={formik.values[FORM_FIELDS.PAYMENT_POSTPONEMENT_DAYS]}
            onChange={event => {
              formik.setFieldTouched(FORM_FIELDS.PAYMENT_POSTPONEMENT_DAYS, false);
              formik.setFieldValue(FORM_FIELDS.PAYMENT_POSTPONEMENT_DAYS, event.target.value);
            }}
          />
        </Grid>

        <Grid container>
          <Grid item xs={12} sm={12}>
            <Label>
              <I18nText path={`field.${FORM_FIELDS.PAYMENT_POSTPONEMENT_END}`} />
            </Label>
          </Grid>
          <Grid item xs={12} sm={12}>
            <DateInput
              {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.PAYMENT_POSTPONEMENT_END)}
              value={formik.values[FORM_FIELDS.PAYMENT_POSTPONEMENT_END]}
              onChange={date => {
                formik.setFieldTouched(FORM_FIELDS.PAYMENT_POSTPONEMENT_END, false);
                formik.setFieldValue(FORM_FIELDS.PAYMENT_POSTPONEMENT_END, date);
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Label>
              <I18nText path={`field.${FORM_FIELDS.TONNAGE}`} />
            </Label>
          </Grid>
          <Grid item xs={12} sm={12}>
            <NumberInput
              allowDecimals={false}
              {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.TONNAGE)}
              value={formik.values[FORM_FIELDS.TONNAGE]}
              onChange={event => {
                formik.setFieldTouched(FORM_FIELDS.TONNAGE, false);
                formik.setFieldValue(FORM_FIELDS.TONNAGE, event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Label>
              <I18nText path={`field.${FORM_FIELDS.COAL_MARK}`} />
            </Label>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Select
              {...getInputProps<MetadataFormValues>(formik, FORM_FIELDS.COAL_MARK)}
              value={formik.values[FORM_FIELDS.COAL_MARK]}
              options={Object.values(COAL_MARK)}
              type={SELECT_TYPES.COAL}
              onChange={event => {
                formik.setFieldTouched(FORM_FIELDS.COAL_MARK, false);
                formik.setFieldValue(FORM_FIELDS.COAL_MARK, event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button type="submit" disabled={formLoading}>
              <I18nText path="button.setMetadata" />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Popup>
  );
};
