import React, { FC, useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty, reject } from 'ramda';
import debounce from 'lodash.debounce';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

import { I18nText } from 'src/components/i18n';
import MenuItem from '@material-ui/core/MenuItem';
import { COMPANY_ROLES, UserRole } from 'src/utils/constants/roles';
import { BootstrapInput, EndInputAdornment } from 'src/components/Filter/Filter';
import { ListItemText } from '@material-ui/core';
import { getRoleTitle } from 'src/utils/helpers/roles';
import { useIntl } from 'react-intl';

const initialState: {
  roles: UserRole[] | '';
  registrationDate: Date | string;
} = {
  roles: [],
  registrationDate: '',
};

export const AccountFilter: FC<any> = ({ query }) => {
  const classes = useStyles();
  const { locale } = useIntl();
  const formik = useFormik({
    initialValues: {
      roles: initialState.roles,
      registrationDate: initialState.registrationDate,
    },
    onSubmit: values => {
      console.log(values);
    },
  });

  const filter = (values: typeof initialState) => {
    const usedFilters: Partial<typeof initialState> = reject(isEmpty)({
      ...values,
    });

    query({
      roles: usedFilters.roles,
    });
  };
  const filterDebounce = useCallback(debounce(filter, 1000), []);

  return (
    <Grid container spacing={3}>
      <Grid xs={2} sm={1} item className={classes.title}>
        <I18nText path="menuLabel.role" />
      </Grid>
      <Grid xs={10} sm={4} item>
        <Select
          className={classes.select}
          multiple
          name="roles"
          value={formik.values.roles}
          onChange={(event: any) => {
            formik.setFieldTouched('roles', false);
            formik.setFieldValue('roles', event.target.value);
            filterDebounce({ ...formik.values, roles: event.target.value });
          }}
          input={<BootstrapInput />}
          renderValue={selected => (
            <div className={classes.chips}>
              {(selected as string[]).map((value, index) => (
                <div key={value} className={classes.chip}>
                  {getRoleTitle(value, locale)}
                  {index + 1 !== (selected as string[]).length && ', '}
                </div>
              ))}
            </div>
          )}
        >
          {COMPANY_ROLES.map((role, index: number) => (
            <MenuItem key={index} value={role.name}>
              <Checkbox color="primary" checked={formik.values.roles.includes(role.name)} />
              <ListItemText primary={role.label[locale]} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(() => ({
  panelTools: {
    margin: '30px 0 35px',
  },
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
  title: {
    color: 'rgba(0,0,0,0.4)',
    display: 'flex',
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
