import React, { FC, useCallback } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { useNavigate } from 'react-router';

import { Container, Grid, makeStyles } from '@material-ui/core';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';

import { Button } from 'src/components/buttons';
import { Header } from 'src/components/layout';
import { I18nText } from 'src/components/i18n';
import { formatToYYYYMM, setErrorNotification } from 'src/utils/helpers';
import { useMonthlyPlansPage } from './hooks/useMonthlyPlansPage';
import { MonthlyPlansItem } from './components/MonthlyPlansItem';

export const MonthlyPlansPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { month, setMonth, createMonthlyPlan, deleteMonthlyPlan, monthlyPlans } =
    useMonthlyPlansPage();

  const createMonthlyPlanHandler = useCallback(async () => {
    if (!month) {
      return;
    }

    const { data, errors } = await createMonthlyPlan({
      variables: {
        data: {
          month: formatToYYYYMM(month),
        },
      },
    });
    if (!errors) navigate(`/monthly-plans/${data?.createMonthlyPlan.id}`);
  }, [month]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <Header type={'orders'} />
        <div className={classes.panelTools}>
          <Container>
            <Grid container spacing={1}>
              <Grid xs={7} md={3} item>
                <KeyboardDatePicker
                  views={['year', 'month']}
                  openTo="month"
                  inputVariant="outlined"
                  format="yyyy-MM"
                  inputProps={{
                    className: classes.field,
                  }}
                  className={classes.fieldsWrapper}
                  value={month}
                  name="planMonth"
                  onChange={date => {
                    setMonth(date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </Grid>
              <Grid xs={5} md={2} item>
                <Button fullWidth buttonVariant="main" onClick={createMonthlyPlanHandler}>
                  <I18nText path="button.createPlan" />
                </Button>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container>
          <Grid container spacing={1}>
            {monthlyPlans.map(monthlyPlan => (
              <MonthlyPlansItem
                key={monthlyPlan.month}
                monthlyPlan={monthlyPlan}
                deleteMonthlyPlan={deleteMonthlyPlan}
              />
            ))}
          </Grid>
        </Container>
      </div>
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
  },
  panelTools: {
    margin: '30px 0 55px',
  },
  date: {
    margin: '7px 0',
  },
  fieldsWrapper: {
    '& .MuiInputBase-root': { backgroundColor: '#fff' },
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    width: '100%',
    marginRight: '5px',
  },
  field: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '10px 7px',
    // height: '15px',
  },
  orderContainer: {
    position: 'relative',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    // marginBottom: '15px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  month: { fontSize: '14px' },
  label: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5px',
  },
}));
