import { useFormik } from 'formik';
import { filter, isEmpty } from 'ramda';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';

import {
  CoalMark,
  Order,
  Port,
  useUpdateOrderMetadataMutation,
  useGetPortsQuery,
  GetOrdersDocument,
} from 'src/gql';
import { setSuccessMesageNotification, setErrorNotification } from 'src/utils/helpers';

export type Maybe<T> = T | null;

interface useMetadataFormPopupParams {
  order: Order;
  handleClose: () => void;
}

export interface MetadataFormValues {
  blDate: Date;
  paymentPostponementEnd: Date;
  dischargingPortId: Port['id'] | null;
  loadingPortId: Port['id'] | null;
  coalMark: CoalMark;
  buyer: string;
  paymentPostponementDays: number;
  freightPrice: number;
  demPrice: number;
  tonnage: number;
}

const metadataSchema = yup.object().shape({
  blDate: yup.date().required(),
  paymentPostponementEnd: yup.date().required(),
  dischargingPortId: yup.mixed().required(),
  loadingPortId: yup.mixed().required(),
  coalMark: yup.mixed().required(),
  buyer: yup.string().required(),
  paymentPostponementDays: yup.number().required(),
  freightPrice: yup.number().required(),
  demPrice: yup.number().required(),
  tonnage: yup.number().required(),
});

export const useMetadataFormPopup = ({ handleClose, order }: useMetadataFormPopupParams) => {
  const classes = useStyles();
  const intl = useIntl();

  const {
    data: portsData,
    loading: portsLoading,
    error: portsError,
  } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [updateOrderMetadata, { loading: updateOrderMetadataLoading }] =
    useUpdateOrderMetadataMutation({
      refetchQueries: [GetOrdersDocument],
      onCompleted: () => {
        setSuccessMesageNotification(
          intl.formatMessage({ id: 'notification.updateOrderMetadata.success' }),
        );
        handleClose();
      },
      onError: error => {
        setErrorNotification(`${error}`);
      },
    });

  const formik = useFormik({
    initialValues: {
      //date fields
      blDate: order.blDate ? new Date(+order.blDate) : new Date(),
      paymentPostponementEnd: order.paymentPostponementEnd
        ? new Date(+order.paymentPostponementEnd)
        : new Date(),
      // selects fields
      dischargingPortId: order?.dischargingPortId || null,
      loadingPortId: order?.loadingPortId || null,
      coalMark: order.coalMark,
      //string fields
      buyer: order.buyer ?? '',
      //number fields
      paymentPostponementDays: order.paymentPostponementDays || 0,
      tonnage: order.tonnage,
      demPrice: order.demPrice,
      freightPrice: order.freightPrice,
    },
    validationSchema: metadataSchema,
    onSubmit: values => {
      updateOrderMetadata({
        variables: {
          orderId: order.id,
          data: filter((value: any) => !isEmpty(value), {
            blDate: new Date(values.blDate).toISOString(),
            paymentPostponementEnd: new Date(values.paymentPostponementEnd).toISOString(),
            dischargingPortId: values?.dischargingPortId,
            loadingPortId: values?.loadingPortId,
            coalMark: values.coalMark,
            buyer: values.buyer,
            paymentPostponementDays: +values.paymentPostponementDays,
            tonnage: +values.tonnage,
            demPrice: parseFloat(`${values.demPrice}`),
            freightPrice: parseFloat(`${values.freightPrice}`),
          }),
        },
      });
    },
  });

  return {
    ports: portsData?.ports,
    formLoading: updateOrderMetadataLoading,
    contentLoading: portsLoading || !!portsError,
    updateOrderMetadata,
    formik,
    classes,
  };
};

const useStyles = makeStyles(() => ({
  metadata: {
    marginBottom: '50px',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
}));
