export enum NavigationStatus {
  aground = 'Aground',
  aidToNavigation = 'Aid to Navigation',
  atAnchor = 'At Anchor',
  baseStation = 'Base Station',
  classB = 'Class B',
  constrainedByHerDraught = 'Constrained by her Draught',
  engagedInFishing = 'Engaged in Fishing',
  gpsTracker = 'GPS Tracker',
  infinity = 'Infinity',
  moored = 'Moored',
  navAidSartMob = 'Nav Aid - SART - MOB',
  notUnderCommand = 'Not Under Command',
  pushingAheadTowing = 'Pushing Ahead - Towing',
  restrictedManoeuvrability = 'Restricted Manoeuvrability',
  sarAircraft = 'SAR Aircraft',
  satC = 'Sat-C',
  stopped = 'Stopped',
  towingAstern = 'Towing Astern',
  underway = 'Underway',
  underwayBySail = 'Underway by Sail',
  underwayUsingEngine = 'Underway using Engine',
}

export const NAVIGATION_STATUSES = Object.values(NavigationStatus);
