import { FC, useRef } from 'react';
import { OSM } from 'ol/source';

import { TileLayer } from 'src/components/OpenLayers';
import { MarineTrafficVesselInfo } from 'src/utils/types';
import { useAreaPicker } from './useAreaPicker/useAreaPicker';

interface AreaPickerProps {
  setArea(data: any): void;
  vessels: MarineTrafficVesselInfo[];
  setSelectedVessel(vessel: MarineTrafficVesselInfo): void;
}

export const AreaPicker: FC<AreaPickerProps> = ({ setArea, vessels, setSelectedVessel }) => {
  const { map, mapRef } = useAreaPicker({ vessels, setArea, setSelectedVessel });
  const mapSource = useRef(new OSM()).current;

  return (
    <div ref={mapRef} className="ol-map">
      <TileLayer source={mapSource} map={map} />
    </div>
  );
};
