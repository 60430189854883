import type { FormikProps } from 'formik';
import { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { isEmpty, pathOr, reject } from 'ramda';
import xlsx from 'json-as-xlsx';

import type { MarineTrafficVesselInfo } from 'src/utils/types';
import { useMtGetVesselsInAreaMutation } from 'src/gql';
import { useMarineTrafficJob } from 'src/hooks';
import { filterShipsByType, getVesselsXlsxObject } from 'src/utils/helpers';

import { getLatLon } from './getLatLon';
import { useStyles } from '../VesselsInArea.style';

interface VesselsInAreaFormValues {
  dwtMin: string;
  dwtMax: string;
  status: $TSFixMe;
}

export const useVesselsInArea = () => {
  const classes = useStyles();

  const {
    result: marineTrafficJobData,
    inviteStatus,
    loading: marineTrafficJobLoading,
    mutation,
  } = useMarineTrafficJob({
    mutationHook: useMtGetVesselsInAreaMutation,
    jobIdExtractor: pathOr(null, ['mtGetVesselsInArea', 'jobId']),
  });

  const formik = useFormik({
    initialValues: {
      dwtMin: '',
      dwtMax: '',
      status: [],
    },
    onSubmit: () => {},
  });

  const vessels = useMemo<MarineTrafficVesselInfo[] | undefined>(
    () => marineTrafficJobData.result && filterShipsByType(marineTrafficJobData.result, 'Cargo'),
    [marineTrafficJobData.result],
  );

  const startSearchVessels = (
    area: number[][],
    values: FormikProps<VesselsInAreaFormValues>['values'],
  ) => {
    if (!area?.length) return;
    const { lat, lon } = getLatLon(area);

    mutation({
      variables: {
        lat,
        lon,
        ...reject(isEmpty)({
          ...values,
          ...(values.dwtMax !== '' && { dwtMax: +values.dwtMax }),
          ...(values.dwtMin !== '' && { dwtMin: +values.dwtMin }),
        }),
      },
    });
  };

  const vesselsXlsxObject = getVesselsXlsxObject(vessels);
  const dowloandVesselsXlsx = () => xlsx(vesselsXlsxObject.data, vesselsXlsxObject.settings);

  return {
    classes,
    dowloandVesselsXlsx,
    startSearchVessels,
    vessels: vessels ?? [],
    formik,
    inviteStatus,
    loading: marineTrafficJobLoading,
  };
};
