import { Store } from 'react-notifications-component';
import { isMobile } from 'react-device-detect';
import * as Sentry from '@sentry/react';

export const setErrorNotification = (error: string, duration: number = 15000) => {
  Store.addNotification({
    title: 'Service error',
    message: error,
    type: 'danger',
    insert: 'top',
    container: !isMobile ? 'top-right' : 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      onScreen: true,
      pauseOnHover: true,
    },
  });

  Sentry.captureException(error);
};

export const setSuccessMesageNotification = (message: string, duration: number = 15000) => {
  Store.addNotification({
    title: 'Success',
    message: message,
    type: 'success',
    insert: 'top',
    container: !isMobile ? 'top-right' : 'top-center',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: duration,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};
