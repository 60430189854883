import React, { useCallback, FC, useState } from 'react';
import { useNavigate } from 'react-router';
import { isDesktop, isMobile } from 'react-device-detect';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Box from '@material-ui/core/Box';

import { Order, useGetOrdersQuery } from 'src/gql';
import { OrderListItem } from 'src/components/items';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { Filter } from 'src/components/Filter/Filter';
import { FilterMobile } from 'src/components/Filter/FilterMobile';
import { LIMIT, ORDER_BY, ORDER_COLUMN } from 'src/utils/constants/';
import * as ROUTES from 'src/utils/constants/routes';
import { EmptyText } from 'src/components/EmptyText/EmptyText';
import { MetadataFormPopup, PopupDetails } from 'src/components/popups';
import { SortLabel } from 'src/components/SortLabel/SortLabel';
import { SortPanel } from 'src/components/SortPanel/SortPanel';

export const OrderList: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [column, setColumn] = useState<string>(ORDER_COLUMN.STATUS);
  const [orderBy, setOrderBy] = useState(ORDER_BY.DESC);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
  const [showMetadataFormPopup, setShowMetadataFormPopup] = useState<boolean>(false);

  const {
    loading,
    error,
    data: ordersData,
    fetchMore,
    refetch,
  } = useGetOrdersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: LIMIT,
      offset: 0,
      onlyActive: false,
      filters: {},
      //@ts-ignore
      sorting: { orderBy: ORDER_BY.DESC, column: ORDER_COLUMN.STATUS },
    },
  });

  const sort = (column: any, orderBy: string) => {
    setOrderBy(orderBy);
    setColumn(column);
    //@ts-ignore
    refetch({ sorting: { orderBy, column } });
  };

  const onLoadMore = useCallback(async () => {
    await fetchMore({
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult?.orders) return prev;
        const { orders } = fetchMoreResult.orders;
        return {
          orders: {
            ...prev.orders,
            orders: [...prev.orders.orders, ...orders],
            hasNextPage: fetchMoreResult.orders.hasNextPage,
          },
        };
      },
      variables: {
        limit: LIMIT,
        offset: ordersData?.orders.orders.length,
      },
    });
  }, [ordersData]);

  return (
    <Box>
      <Box className={classes.panelTools}>
        <Container>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} item className={classes.buttonsWrapper}>
              <Button onClick={() => navigate(ROUTES.ORDERS_CREATE)}>
                <I18nText path="button.create" />
              </Button>

              <Button buttonVariant="second" onClick={() => navigate(ROUTES.REPORTS)}>
                <I18nText path="button.openReport" />
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Box className={classes.filterWrapper}>
          <Container>
            <Box className={classes.filterTitle}>
              <Box>
                <I18nText path="menuLabel.filters" />
              </Box>
              <IconButton
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more"
              >
                {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Box>
            {expanded && (
              <>
                {isDesktop ? (
                  <Filter classes={classes} query={refetch} />
                ) : (
                  <FilterMobile classes={classes} query={refetch} />
                )}
              </>
            )}
          </Container>
        </Box>
      </Box>

      {isMobile && (
        <Container>
          <SortPanel column={column} sort={sort} orderBy={orderBy} />
        </Container>
      )}

      {isDesktop && (
        <Container>
          <Grid container>
            <Grid xs={12} item>
              <Box className={classes.tableHeaderWrapper}>
                <Box mr={1}>
                  <I18nText path="text.sortBy" />
                </Box>
                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.id"
                    sort={sort}
                    column={ORDER_COLUMN.ID}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.ID}
                  />
                </Box>

                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.status"
                    sort={sort}
                    column={ORDER_COLUMN.STATUS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.STATUS}
                  />
                </Box>

                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.quantity"
                    sort={sort}
                    column={ORDER_COLUMN.QUANTITY_MAX}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.QUANTITY_MAX}
                  />
                </Box>

                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.loading"
                    sort={sort}
                    column={ORDER_COLUMN.LOADING_PORTS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.LOADING_PORTS}
                  />
                </Box>

                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.discharging"
                    sort={sort}
                    column={ORDER_COLUMN.DISCHARGING_PORTS}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.DISCHARGING_PORTS}
                  />
                </Box>

                <Box className={classes.tableHeader}>
                  <SortLabel
                    path="menuLabel.laycan"
                    sort={sort}
                    column={ORDER_COLUMN.CAN_TIME}
                    orderBy={orderBy}
                    isSelected={column === ORDER_COLUMN.CAN_TIME}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}

      {!!ordersData &&
        ordersData.orders.orders.map((order: any) => (
          <OrderListItem
            key={order.id}
            setShowDetailsPopup={() => {
              setSelectedOrder({ ...order });
              setShowDetailsPopup(true);
            }}
            setShowMetadataPopup={() => {
              setSelectedOrder({ ...order });
              setShowMetadataFormPopup(true);
            }}
            order={{ ...order }}
          />
        ))}
      {ordersData?.orders.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={loading} onClick={() => onLoadMore()}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
      {selectedOrder && (
        <PopupDetails
          showPopup={showDetailsPopup}
          setShowPopup={() => {
            setShowDetailsPopup(false);
            setSelectedOrder(null);
          }}
          details={selectedOrder}
        />
      )}
      {selectedOrder && showMetadataFormPopup && (
        <MetadataFormPopup
          order={selectedOrder}
          handleClose={() => {
            setShowMetadataFormPopup(false);
            setSelectedOrder(null);
          }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 55px',
  },
  tableHeaderWrapper: {
    display: 'flex',
    marginBottom: '15px',
    gap: '35px',
  },
  tableHeader: {
    textAlign: 'left',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  labels: {
    margin: '0 0 0 15px',
  },
  filterWrapper: {
    marginTop: '25px',
  },
  filterTitle: {
    marginBottom: '20px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
    height: '40px',
  },
  button: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '100%',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
    width: '100%',
  },
  sortPanel: {
    marginBottom: '50px',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
  },
}));
