import React, { FC } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { I18nText } from 'src/components/i18n';
import { portVar } from 'src/apollo/cache/cache';

export const PortItem: FC<any> = ({ port, setEditShowPortForm }) => {
  const classes = useStyles();

  return (
    <div className={classes.portContainer}>
      <Grid
        container
        className={classes.gridContainer}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item className={classes.gridItem} xs={7}>
          <div className={classes.id}>{port.name}</div>
        </Grid>
        <Grid item className={classes.buttonsWrapper} xs={5}>
          <Button
            className={classes.button}
            onClick={() => {
              portVar(port);
              setEditShowPortForm(true);
            }}
            variant="outlined"
          >
            <I18nText path="button.edit" />
          </Button>
          {/* <Button
            className={classes.button}
            variant="outlined"
            disabled={loading}
            onClick={() => {
              deleteVessel({ variables: { id: port.id } });
              refetch();
            }}
          >
            <I18nText path="button.delete" />
          </Button> */}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  portContainer: {
    padding: '0px 5px',
  },
  gridContainer: {
    backgroundColor: 'white',
    height: '75px',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '0px 0px 0 35px ',
    marginBottom: '16px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  action: {
    border: '1px solid #ced4da',
    borderRadius: 4,
    padding: '2px 5px',
  },
  menuItem: { width: '50px', height: '20px' },
  openButton: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  detailsButton: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    textAlign: 'center',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  select: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
  },
  id: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  layCanWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  button: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    textAlign: 'center',
    height: '45px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 25px',
  },
}));
