export const RESET_PASSWORD = '/password/reset';
export const ORDERS = '/orders';
export const ORDER = `/order/:id`;
export const ORDERS_CREATE = '/orders/create';
export const ORDERS_EDIT_ID = '/orders/edit/:id';
export const ORDERS_EDIT = '/orders/edit';
export const ORDERS_COPY_ID = '/orders/copy/:id';
export const ORDERS_COPY = '/orders/copy';
export const PORTS = '/ports';
export const REPORTS = '/reports';
export const ACCOUNTS = '/accounts';
export const MARINE_TRAFFIC = '/marinetraffic';
export const MONTHLY_PLANS = '/monthly-plans';
export const MONTHLY_PLAN = '/monthly-plans/:id';
export const SUMMARY = '/summary';
export const MAP = '/map';
export const DISPLAY = '/display';

export const AUTHORIZATION_SHIPOWNER = `/auth`;
export const SHIPOWNER_ORDERS = `/shipowner/orders`;
export const SHIPOWNER_VESSELS = `/shipowner/vessels`;
export const SHIPOWNER_ORDER = `/shipowner/order/:orderId`;
export const SHIPOWNER_OFFER_CREATE = '/shipowner/offer/create/:orderId';
export const SHIPOWNER_OFFER_EDIT_ID = '/shipowner/offer/edit/:orderId/:offerId';
