import { map, pickAll } from 'ramda';

export const filterShipsByType = (ships: any[], type: string) =>
  ships.filter((ship: any) => ship['TYPE_SUMMARY'] === type);

export const shipFieldsLocale = {
  IMO: 'menuLabel.imo',
  SHIPNAME: 'menuLabel.name',
  OWNER_EMAIL: 'menuLabel.ownerEmail',
  MANAGER_EMAIL: 'menuLabel.managerEmail',
  TECHNICAL_MANAGER_EMAIL: 'menuLabel.techManagerEmail',
  FINANCIAL_OWNER_EMAIL: 'menuLabel.finManagerEmail',
  STATUS_NAME: 'menuLabel.status',
};

export const SHIP_TABLE_COLUMNS = [
  'IMO',
  'SHIPNAME',
  'OWNER_EMAIL',
  'MANAGER_EMAIL',
  'TECHNICAL_MANAGER_EMAIL',
  'FINANCIAL_OWNER_EMAIL',
  'STATUS_NAME',
] as const;

export const pickShipKeys = map(pickAll(SHIP_TABLE_COLUMNS));

export const getVesselsXlsxObject = (vessels: $TSFixMe) => {
  const settings = {
    fileName: `mcp_vessels_${new Date().toISOString().substring(0, 10)}`,
  };
  const data = [
    {
      sheet: 'Vessels',
      columns: [
        { label: 'IMO', value: 'IMO' },
        { label: 'SHIPNAME', value: 'SHIPNAME' },
        { label: 'OWNER_EMAIL', value: 'OWNER_EMAIL' },
        { label: 'MANAGER_EMAIL', value: 'MANAGER_EMAIL' },
        { label: 'TECHNICAL_MANAGER_EMAIL', value: 'TECHNICAL_MANAGER_EMAIL' },
        { label: 'FINANCIAL_OWNER_EMAIL', value: 'FINANCIAL_OWNER_EMAIL' },
        { label: 'STATUS_NAME', value: 'STATUS_NAME' },
      ],
      content: vessels,
    },
  ];
  return { settings, data };
};
