import React, { useCallback, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { AccountFilter } from './components/AccountFilter/AccountFilter';
import Container from '@material-ui/core/Container';
import { AccountListItem } from './components/AccountListItem/AccountListItem';
import { Header } from 'src/components/layout';
import { useAccountPage } from './hooks/useAccountPage';
import { AccountItemForm } from './components/AccountItemForm/AccountItemForm';

export const AccountsPage = () => {
  const classes = useStyles();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [expandedFilter, setExpandedFilter] = useState<boolean>(true);
  const [creating, setCreating] = useState<boolean>(false);
  const {
    adminsData,
    dataLoading,
    refetch,
    fetchMore,
    createAdmin,
    editAdmin,
    deleteAdmin,
    deleteLoading,
  } = useAccountPage();

  //   const onLoadMore = useCallback(async () => {
  //     await fetchMore({
  //       updateQuery(prev, { fetchMoreResult }) {
  //         if (!fetchMoreResult?.orders) return prev;
  //         const { orders } = fetchMoreResult.orders;
  //         return {
  //           orders: {
  //             ...prev.orders,
  //             orders: [...prev.orders.orders, ...orders],
  //             hasNextPage: fetchMoreResult.orders.hasNextPage,
  //           },
  //         };
  //       },
  //       variables: {
  //         limit: LIMIT,
  //         offset: adminsData?.orders.orders.length,
  //       },
  //     });
  //   }, [adminsData]);

  return (
    <div>
      <Header type={'orders'} />
      <div className={classes.panelTools}>
        {!creating && (
          <Container>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} item className={classes.buttonsWrapper}>
                <Button
                  buttonVariant="main"
                  onClick={() => {
                    setCreating(true);
                  }}
                >
                  <I18nText path="button.addNewMember" />
                </Button>
              </Grid>
            </Grid>
          </Container>
        )}
        {creating && (
          <AccountItemForm
            key={'acc-item-form'}
            mutation={createAdmin}
            setCreating={setCreating}
            setEditingIndex={setEditingIndex}
            disabled={dataLoading || deleteLoading}
            mode="create"
          />
        )}
        {!creating && (
          <div className={classes.filterWrapper}>
            <Container>
              <div className={classes.filterTitle}>
                <div>
                  <I18nText path="menuLabel.filters" />
                </div>
                <IconButton
                  onClick={() => setExpandedFilter(!expandedFilter)}
                  aria-expanded={expandedFilter}
                  aria-label="show more"
                >
                  {expandedFilter ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </div>
              {expandedFilter && (
                <>
                  <AccountFilter query={refetch} />
                </>
              )}
            </Container>
          </div>
        )}
      </div>
      {!!adminsData && (
        <>
          {adminsData.admins.admins.map((account: any, index: number) => {
            if (index === editingIndex) {
              return (
                <AccountItemForm
                  key={'acc-item-form'}
                  account={account}
                  mutation={editAdmin}
                  setCreating={setCreating}
                  setEditingIndex={setEditingIndex}
                  disabled={dataLoading || deleteLoading}
                  mode="edit"
                />
              );
            }
            return (
              <AccountListItem
                key={index}
                account={account}
                deleteAdmin={deleteAdmin}
                index={index}
                setEditingIndex={setEditingIndex}
                disabled={dataLoading || deleteLoading || creating}
              />
            );
          })}
        </>
      )}
      {adminsData?.admins.hasNextPage && (
        <Grid container alignItems="center" direction="row" className={classes.buttons}>
          <Grid item className={classes.buttonContainer} xs={12}>
            <Button disabled={dataLoading || deleteLoading} onClick={() => null}>
              <I18nText path="button.loadMore" />
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 31px',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '15px',
  },
  filterWrapper: {
    marginTop: '25px',
  },
  filterTitle: {
    marginBottom: '20px',
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center',
  },
}));
