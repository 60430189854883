import { FC, useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { getDDMMYYYY, getThousand } from 'src/utils/helpers';
import { I18nText } from 'src/components/i18n';
import { Order, PortPoint } from 'src/gql';
import { MenuSelect } from 'src/components/selects';
import { OrderStatus } from 'src/components/OrderStatus/OrderStatus';
import { RequiredNotNull } from 'src/utils/types';
import { Button, LinkButton } from 'src/components/buttons';
import { MenuOption } from 'src/utils/types';
import { checkAdminAccount } from 'src/utils/helpers/roles';
import { OrderRatingPopup } from 'src/components/popups';

import { useOrderListItem } from '../../useOrderListItem/useOrderListItem';

interface OrderListItemMobile {
  order: RequiredNotNull<Order>;
  setShowDetailsPopup: () => void;
  menuOptions: MenuOption[];
}

export const OrderListItemMobile: FC<OrderListItemMobile> = ({
  order,
  setShowDetailsPopup,
  menuOptions,
}) => {
  const { classes, user, navigateTo } = useOrderListItem();
  const [showRatingPopup, setShowRatingPopup] = useState<boolean>(false);

  return (
    <Container>
      <Box className={classes.orderContainer}>
        <Grid container alignItems="center">
          <Grid
            xs={12}
            sm={6}
            item
            className={classes.idWrapper}
            //@ts-ignore
            justifyContent={'flex-start'}
          >
            <Box className={classes.label}>
              <span className={classes.indexNumber}>{order.indexNumber}</span>
            </Box>
            <Box className={classes.statusWrapper}>
              <OrderStatus order={order} />
            </Box>
            {user.isAdmin && order.orderRating?.average && (
              <Box
                className={
                  order.orderRating?.average > 3 ? classes.deleviredWasFine : classes.lateDelivery
                }
                onClick={() => setShowRatingPopup(true)}
              >
                {order.orderRating?.average || 0} / 5 rate
              </Box>
            )}

            {user.isAdmin && showRatingPopup && (
              <OrderRatingPopup
                handleClose={() => setShowRatingPopup(false)}
                open={showRatingPopup}
                order={order}
              />
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.gridItem} xs={3}>
            <Box className={classes.label}>
              <I18nText path="menuLabel.quantity" />
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={9}>
            <Box>
              {getThousand(order.quantityMin)} - {getThousand(order.quantityMax)}{' '}
              <I18nText path="text.tonns" />
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={3}>
            <Box className={classes.label}>
              <I18nText path="menuLabel.loading" />
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={9}>
            <Box className={classes.portsWrapper}>
              {order.loadingPorts.map((port: PortPoint) => port.portName).join(', ')}
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={3}>
            <Box className={classes.label}>
              <I18nText path="menuLabel.discharging" />
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={9}>
            <Box className={classes.portsWrapper}>
              {order.dischargingPorts.map((port: PortPoint) => port.portName).join(', ')}
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={3}>
            <Box className={classes.label}>
              <I18nText path="menuLabel.laycan" />:
            </Box>
          </Grid>
          <Grid item className={classes.gridItem} xs={9}>
            <Box className={classes.layCanWrapper}>
              <Box>
                {getDDMMYYYY(order.layTime)} - {getDDMMYYYY(order.canTime)}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container className={classes.buttonContainer}>
          <Grid item className={classes.buttonsWrapper} xs={12} justifyContent={'flex-end'}>
            <Button buttonVariant="light" onClick={setShowDetailsPopup}>
              <I18nText path="button.details" />
            </Button>
            {user.isAdmin && (
              <MenuSelect
                selectButtonStyles={{
                  border: '2px solid rgba(42, 57, 190, 0.1)',
                  color: 'black',
                  height: '40px',
                  padding: '5px 15px',
                }}
                title={{ path: 'button.actions' }}
                openTo="up"
                options={menuOptions}
              />
            )}

            {!!user.roles && (checkAdminAccount(user.roles) || user.isShipowner) && (
              <LinkButton
                to={user.isAdmin ? `/order/${order.id}` : `/shipowner/order/${order.id}`}
                buttonVariant="second"
              >
                <I18nText path="button.open" />
              </LinkButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
