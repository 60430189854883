import React, { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { Header } from 'src/components/layout';
import { useSummaryPage } from './hooks/useSummaryPage';
import { OrderListItem } from 'src/components/items';
import { MetadataFormPopup, PopupDetails } from 'src/components/popups';
import { SummaryCard } from 'src/pages/summary/components/SummaryCard';
import { Order } from 'src/gql';
import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import * as ROUTES from 'src/utils/constants/routes';
import { checkShareholderAccount } from 'src/utils/helpers/roles';
import { userVar } from 'src/apollo/cache/cache';

export const SummaryPage = () => {
  const classes = useStyles();
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [showMetadataFormPopup, setShowMetadataFormPopup] = useState<boolean>(false);
  const { dashboard, growth } = useSummaryPage();

  return (
    <Box>
      <Header type={'orders'} />

      <>
        <Box className={classes.periodsContainer}>
          <Container>
            {!!growth && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'week'} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'month'} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'quarter'} />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
        {!!dashboard && (
          <>
            <Container>
              <Box className={classes.activeOrdersTitle}>
                <span className={classes.activeOrdersText}>
                  <I18nText path="text.activeFrachts" />: {dashboard.activeOrders.length}
                </span>
                {!!user.roles && checkShareholderAccount(user.roles) && (
                  <Button buttonVariant="second" onClick={() => navigate(ROUTES.REPORTS)}>
                    <I18nText path="button.openReport" />
                  </Button>
                )}
              </Box>
            </Container>
            {dashboard.activeOrders.map((order: any, index: number) => (
              <Box key={index}>
                <OrderListItem
                  order={order}
                  setShowDetailsPopup={() => {
                    setSelectedOrder({ ...order });
                    setShowDetailsPopup(true);
                  }}
                  setShowMetadataPopup={() => {
                    setSelectedOrder({ ...order });
                    setShowMetadataFormPopup(true);
                  }}
                />
              </Box>
            ))}
          </>
        )}
      </>

      {selectedOrder && (
        <PopupDetails
          showPopup={showDetailsPopup}
          setShowPopup={() => {
            setShowDetailsPopup(false);
            setSelectedOrder(null);
          }}
          details={selectedOrder}
        />
      )}
      {selectedOrder && showMetadataFormPopup && (
        <MetadataFormPopup
          order={selectedOrder}
          handleClose={() => {
            setShowMetadataFormPopup(false);
            setSelectedOrder(null);
          }}
        />
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  panelTools: {
    margin: '30px 0 30px',
  },
  activeOrdersTitle: {
    fontSize: '27px',
    marginBottom: '27px',
    fontWeight: 600,
  },
  activeOrdersText: {
    marginRight: '10px',
  },
  periodsContainer: {
    margin: '48px 0',
  },
  periodWrapper: {
    width: '380px',
    borderRadius: '16px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
    height: '167px',
    marginRight: '8px',
  },
}));
