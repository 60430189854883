import { useEffect, useRef } from 'react';

import { MarineTrafficVesselInfo } from 'src/utils/types';

import { useStyles } from '../VesselsTableRow.style';

interface useVesselsTableRowParams {
  vessel: MarineTrafficVesselInfo;
  isSelected?: boolean;
}

export const useVesselsTableRow = ({ isSelected, vessel }: useVesselsTableRowParams) => {
  const classes = useStyles({ isSelected });
  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isSelected || !rowRef.current) return;
    rowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }, [isSelected]);

  return { classes, rowRef };
};
