import React from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ORDER_BY, ORDER_COLUMN } from 'src/utils/constants/';

const ORDER_COLUMN_ARRAY = [
  { label: 'Status', value: ORDER_COLUMN.STATUS },
  { label: 'Laycan', value: ORDER_COLUMN.CAN_TIME },
  { label: 'Quantity', value: ORDER_COLUMN.QUANTITY_MAX },
  { label: 'Id', value: ORDER_COLUMN.ID },
  { label: 'Loading', value: ORDER_COLUMN.LOADING_PORTS },
  { label: 'Discharging', value: ORDER_COLUMN.DISCHARGING_PORTS },
];

export const SortPanel = ({
  orderBy,
  sort,
  column,
}: {
  column: string;
  sort: (column: string, orderBy: string) => void;
  orderBy: string;
}) => {
  const classes = useStyles();
  return (
    <Grid className={classes.sortPanel} container spacing={3}>
      <Grid xs={9} sm={4} item>
        <Select
          className={classes.select}
          variant="outlined"
          name={'type'}
          onChange={event => {
            sort(event.target.value as string, orderBy);
          }}
          value={column}
          renderValue={selected => (
            <div>{ORDER_COLUMN_ARRAY.find(el => el.value === selected)?.label}</div>
          )}
        >
          {ORDER_COLUMN_ARRAY.map(column => (
            <MenuItem key={column.value} value={column.value}>
              {column.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid xs={3} sm={1} item>
        {orderBy === ORDER_BY.DESC ? (
          <Button
            disabled={!column}
            className={classes.button}
            onClick={() =>
              sort(column as string, orderBy === ORDER_BY.DESC ? ORDER_BY.ASC : ORDER_BY.DESC)
            }
          >
            DESC
          </Button>
        ) : (
          <Button
            disabled={!column}
            className={classes.button}
            onClick={() =>
              sort(column as string, orderBy === ORDER_BY.DESC ? ORDER_BY.ASC : ORDER_BY.DESC)
            }
          >
            ASC
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
    height: '40px',
  },
  button: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '100%',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
    width: '100%',
  },
  sortPanel: {
    marginBottom: '50px',
  },
}));
