export enum UserRole {
  root = 'root',
  admin = 'admin',
  platformAdmin = 'platformAdmin',
  shipowner = 'shipowner',
  shareholder = 'shareholder',
}

export const COMMON_ADMIN_ROLES: string[] = [
  UserRole.admin,
  UserRole.platformAdmin,
  UserRole.root,
];
export const COMPANY_ROLES: { name: UserRole; label: Record<string, string> }[] = [
  {
    name: UserRole.root,
    label: { en: 'Root admin', ru: 'Глобальный администратор' },
  },
  { name: UserRole.admin, label: { en: 'Admin', ru: 'Администратор' } },
  {
    name: UserRole.platformAdmin,
    label: { en: 'Manager', ru: 'Менеджер платформы' },
  },
  {
    name: UserRole.shareholder,
    label: { en: 'Shareholder', ru: 'Акционер' },
  },
];
export const COMPANY_ROLES_ALLOW_CREATE = COMPANY_ROLES.filter(v => v.name !== UserRole.root);
