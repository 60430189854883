import React from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { Header } from 'src/components/layout';
import { I18nText } from 'src/components/i18n';
import { ORDERS } from 'src/utils/constants';
import { Button } from 'src/components/buttons';
import { subtractTimeFromNow } from 'src/utils/helpers';
import { useGetReportsQuery } from 'src/gql';
import { ReportFilter } from './components/ReportFilter';
import { ReportTable } from './components/ReportTable';

export const ReportsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      startDate: subtractTimeFromNow(5, 'year').toISOString(),
      endDate: new Date().toISOString(),
    },
    onSubmit: values => {
      console.log(values);
    },
  });
  const {
    data: reportData,
    loading,
    refetch,
  } = useGetReportsQuery({
    variables: {
      startDate: formik.values.startDate,
      endDate: formik.values.endDate,
    },
  });

  return (
    <div>
      <Header type={'orders'} />
      <Container className={classes.pageContainer}>
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} item className={classes.titleWrapper}>
            <Button
              onClick={() => navigate(-1)}
              startIcon={<ArrowBackIosIcon />}
              buttonVariant="dark"
            >
              <I18nText path="button.back" />
            </Button>
            <div className={classes.pageTitle}>
              <I18nText path="header.reports" />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container className={classes.filterContainer}>
        <ReportFilter refetch={refetch} formik={formik} />
      </Container>
      {!loading && (
        <Container className={classes.tableContainer}>
          <ReportTable reports={reportData?.reports.data} />
        </Container>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  pageContainer: {
    marginTop: '30px',
    marginBottom: '60px',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '20px',
  },
  filterContainer: {
    marginTop: '45px',
    marginBottom: '66px',
  },
  tableContainer: {
    width: '100%',
    height: '50vh',
    overflow: 'scroll',
  },
  pageTitle: {
    fontSize: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
