import { useEffect, useState } from 'react';
import { useGetDashboardGrowthDataQuery, useGetPortsQuery } from 'src/gql';
import { MINUTE } from 'src/utils/constants';

const POLLING_INTERVAL_MS = MINUTE * 5;

export const useDisplayPage = () => {
  const [mapRetriggerValue, setMapRetriggerValue] = useState<number>(Date.now());

  const {
    data: growthData,
    loading: growthLoading,
    refetch: growthRefetch,
  } = useGetDashboardGrowthDataQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: POLLING_INTERVAL_MS,
  });
  const { data: portsData } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const int = setInterval(() => {
      setMapRetriggerValue(Date.now());
    }, POLLING_INTERVAL_MS);

    return () => {
      clearInterval(int);
    };
  }, []);

  return {
    loading: growthLoading,
    growth: growthData?.dashboardFullGrowthData,
    growthRefetch,
    ports: portsData?.ports,
    mapRetriggerValue,
  };
};
