import React, { useMemo, FC, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { ReportData } from 'src/gql';
import { formatToDDMMYYYY, subtractTimeFromNow } from 'src/utils/helpers';

const initialState = {
  startDate: subtractTimeFromNow(5, 'year').toISOString(),
  endDate: new Date().toISOString(),
};

interface ReportTableProps {
  reports: ReportData['data'];
}

export const ReportTable: FC<ReportTableProps> = ({ reports = [] }) => {
  const classes = useStyles();
  const [columnIndex, setColumnIndex] = useState<number | null>(null);
  const columns = useMemo(() => reports[0], [reports]);
  const rows = useMemo(() => reports.slice(1), [reports]);

  return (
    <>
      <div className={classes.columnContainer}>
        {columns.map((column: string, index: number) => (
          <div
            onMouseMove={() => setColumnIndex(index)}
            onMouseOut={() => setColumnIndex(null)}
            className={columnIndex === index ? classes.selectedTitleColumn : classes.titleColumn}
          >
            {column}
          </div>
        ))}
      </div>
      <div>
        {rows.map((row: string[]) => (
          <div className={classes.dataRow}>
            {row.map((column: string, index: number) => (
              <div
                onMouseMove={() => setColumnIndex(index)}
                onMouseOut={() => setColumnIndex(null)}
                className={columnIndex === index ? classes.selectedDataColumn : classes.dataColumn}
              >
                {index === 1 ? formatToDDMMYYYY(column) : column}
              </div>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  titleColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#999999',
    fontSize: '12px',
    width: '100px',
  },
  selectedTitleColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    width: '100px',
  },
  columnContainer: {
    pointerEvents: 'none',
    width: 'fit-content',
    gap: '15px',
    display: 'flex',
    justifyContent: 'start',
    padding: '0 16px',
    marginBottom: '21px',
    position: 'sticky',
    top: '0',
    backgroundColor: '#EEEEEE',
    borderRadius: '0 0  10px 10px',
  },
  rowsContainer: {},
  dataRow: {
    pointerEvents: 'none',
    width: 'fit-content',
    gap: '15px',
    display: 'flex',
    justifyContent: 'start',
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    borderRadius: '16px',
    padding: '0 16px',
    marginBottom: '10px',
  },
  dataColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    width: '100px',
    padding: '25px 0',
  },
  selectedDataColumn: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    width: '100px',
    padding: '25px 0',
  },
}));
