import React, { FC } from 'react';
import { useFormik } from 'formik';
import { useReactiveVar } from '@apollo/client';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import { Button } from 'src/components/buttons';
import { portVar } from 'src/apollo/cache/cache';
import { I18nText } from 'src/components/i18n';
import { OPERATION_TYPES, VESSEL_TYPES } from 'src/utils/constants';
import { PopupWrapper } from 'src/components/popups/PopupWrapper/PopupWrapper';

const vesselSchema = yup.object().shape({
  name: yup.string().required(),
});

export const PortFormPopup: FC<any> = ({ handleClose, mutation, loading, open, type }) => {
  const classes = useStyles();
  const port = useReactiveVar(portVar);
  const formik = useFormik({
    initialValues: {
      name: port.name,
    },
    onSubmit: values => {
      const data: any = values;
      if (type === VESSEL_TYPES.EDIT) {
        mutation({
          variables: {
            data: data,
            portId: port.id,
          },
        });
        handleClose();
        return;
      }
      mutation({
        variables: {
          data: data,
        },
      });
      handleClose();
    },
    validationSchema: vesselSchema,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <PopupWrapper open={open} classes={classes} handleClose={handleClose}>
      <div className={classes.vesselPopup}>
        <div className={classes.closeButton} onClick={handleClose} />
        <div className={classes.vesselPopupTitle}>
          {type === OPERATION_TYPES.EDIT ? (
            <I18nText path="text.editPort" />
          ) : (
            <I18nText path="text.addNewPort" />
          )}
        </div>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <Grid container alignItems="center" spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <InputLabel className={classes.label}>
                  <I18nText path={'field.portName'} />
                </InputLabel>
              </Grid>
              <Grid item sm={9} xs={12}>
                <TextField
                  id="outlined-basic"
                  className={classes.textField}
                  variant="outlined"
                  name={'name'}
                  disabled={loading}
                  value={formik.values.name}
                  onChange={event => {
                    formik.setFieldTouched('name', false);
                    formik.setFieldValue('name', event.target.value);
                  }}
                  error={!!formik.errors.name && !!formik.touched.name}
                  helperText={!!formik.errors.name && !!formik.touched.name && formik.errors.name}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" direction="row" className={classes.buttons}>
              <Grid item className={classes.buttonAddVesselContainer} xs={12}>
                <Button type="submit" disabled={loading}>
                  {type === OPERATION_TYPES.EDIT ? (
                    <I18nText path="button.editPort" />
                  ) : (
                    <I18nText path="button.addPort" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </div>
    </PopupWrapper>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    maxWidth: '950px',
    borderRadius: '15px',
  },
  vesselPopup: {
    position: 'relative',
    padding: '50px 15px',
  },
  vesselPopupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  container: {
    width: 'auto',
    justifyContent: 'center',
  },
  textField: {
    width: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonAddVesselContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  mobilePopup: {
    position: 'fixed',
    overflowY: 'scroll',
    height: '100vh',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
  },
}));
