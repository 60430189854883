import React, { FC } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

interface MoneyInputProps {
  value: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  name?: string;
  [key: string]: any;
}

export const MoneyInput: FC<MoneyInputProps> = ({ name, value, error, onChange, ...props }) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      name={name}
      value={value}
      error={error}
      onChange={onChange}
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom,
        className: classes.input,
        ...props.InputProps,
      }}
    />
  );
};

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <CurrencyInput
      {...other}
      id="input-example"
      name="input-name"
      decimalsLimit={2}
      getInputRef={inputRef}
      decimalSeparator="."
      onValueChange={(value, name) =>
        onChange({
          target: { value, name },
        })
      }
    />
  );
}

const useStyles = makeStyles(() => ({
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
}));
