import React, { FC, useState, Dispatch } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Button } from 'src/components/buttons';
import { RateParam, rateRange } from '../useRatingPopup/useRatingPopup';
import { I18nText } from 'src/components/i18n';

interface RateItemProps {
  rateState: {
    [key in RateParam]: number;
  };
  setRateState: Dispatch<{
    [key in RateParam]: number;
  }>;
  title: string;
  param: RateParam;
}

export const RateItem: FC<RateItemProps> = ({ title, param, rateState, setRateState }) => {
  const classes = useStyles();
  return (
    <div className={classes.rateItem}>
      <div className={classes.rateTitle}>
        <I18nText path={title} />
      </div>
      <div className={classes.rateRange}>
        {rateRange.map(rate => (
          <Button
            onClick={() => setRateState({ ...rateState, [param]: rate })}
            buttonVariant={rate === rateState[param] ? 'main' : 'second'}
          >
            {rate}
          </Button>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  rateTitle: {
    marginBottom: '5px',
    fontSize: '15px',
  },
  rateItem: {
    marginBottom: '15px',
  },
  rateRange: {
    display: 'flex',
    gap: '5px',
  },
}));
