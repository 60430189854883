import React, { FC, ChangeEventHandler } from 'react';

import { Box, MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { BaseTextFieldProps } from '@material-ui/core/TextField';

import { SELECT_TYPES } from 'src/utils/constants';
import { Port } from 'src/gql';
import { useFormClasses } from 'src/hooks/useFormClasses';

interface SelectProps extends BaseTextFieldProps {
  options: string[] | number[] | Port[];
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  type: typeof SELECT_TYPES[keyof typeof SELECT_TYPES];
  [key: string]: any;
}

export const Select: FC<SelectProps> = ({ options, name, value, onChange, type, ...props }) => {
  const { classes } = useFormClasses();

  return (
    <Box>
      <TextField
        select
        variant="outlined"
        className={classes.textField}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
        inputProps={{
          className: classes.input,
          ...props.InputProps,
        }}
      >
        {type === SELECT_TYPES.COAL &&
          (options as string[]).map(value => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        {type === SELECT_TYPES.PORTS &&
          (options as Port[]).map(value => (
            <MenuItem key={value.name} value={value.id}>
              {value.name}
            </MenuItem>
          ))}
      </TextField>
    </Box>
  );
};
