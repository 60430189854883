import type { FC, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';

import { BootstrapInput, EndInputAdornment } from 'src/components/Filter/Filter';
import { I18nText } from 'src/components/i18n';
import { Button } from 'src/components/buttons';
import { Label } from 'src/components/forms';
import { NumberInput } from 'src/components/inputs';
import { NAVIGATION_STATUSES } from 'src/utils/constants';
import { getInputProps } from 'src/utils/helpers';
import { FORM_FIELDS } from 'src/utils/constants';
import { MarineTrafficVesselInfo } from 'src/utils/types';

import { AreaPicker } from '../AreaPicker/AreaPicker';
import { VesselsTable } from '../VesselsTable/VesselsTable';
import { SendInvitesButton } from '../SendInvitesButton';
import { useVesselsInArea } from './useVesselsInArea/useVesselsInArea';

interface VesselsInAreaFormValues {
  dwtMin: string;
  dwtMax: string;
  status: $TSFixMe;
}

export const VesselsInArea: FC<{ setShowInvitePopup: Dispatch<SetStateAction<boolean>> }> = ({
  setShowInvitePopup,
}) => {
  const {
    classes,
    dowloandVesselsXlsx,
    formik,
    loading,
    vessels,
    startSearchVessels,
    inviteStatus,
  } = useVesselsInArea();
  const [area, setArea] = useState<number[][]>([]);
  const [selectedVessel, setSelectedVessel] = useState<MarineTrafficVesselInfo | null>(null);

  return (
    <>
      <Box>
        <Box mb={2}>
          <Typography variant="h4">
            <I18nText path="menuLabel.areaVessels" />
          </Typography>
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={6} sm={3}>
            <Label>
              <I18nText path={`field.${FORM_FIELDS.DWT_MIN}`} />
            </Label>

            <NumberInput
              {...getInputProps<VesselsInAreaFormValues>(formik, FORM_FIELDS.DWT_MIN)}
              allowDecimals={false}
              value={formik.values[FORM_FIELDS.DWT_MIN]}
              onChange={event => {
                formik.setFieldTouched(FORM_FIELDS.DWT_MIN, false);
                formik.setFieldValue(FORM_FIELDS.DWT_MIN, event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Label>
              <I18nText path={`field.${FORM_FIELDS.DWT_MAX}`} />
            </Label>

            <NumberInput
              {...getInputProps<VesselsInAreaFormValues>(formik, FORM_FIELDS.DWT_MAX)}
              allowDecimals={false}
              value={formik.values[FORM_FIELDS.DWT_MAX]}
              onChange={event => {
                formik.setFieldTouched(FORM_FIELDS.DWT_MAX, false);
                formik.setFieldValue(FORM_FIELDS.DWT_MAX, event.target.value);
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Label>
              <I18nText path="field.status" />
            </Label>
            <Box className={classes.selectWrapper}>
              <Select
                className={classes.select}
                multiple
                name={FORM_FIELDS.STATUS}
                value={formik.values.status}
                onChange={event => {
                  formik.setFieldTouched(FORM_FIELDS.STATUS, false);
                  formik.setFieldValue(FORM_FIELDS.STATUS, event.target.value);
                }}
                input={
                  <BootstrapInput
                    endAdornment={
                      <EndInputAdornment
                        isSelect
                        classes={classes}
                        cond={!!formik.values.status.length}
                        onClick={() => {
                          formik.setFieldValue(FORM_FIELDS.STATUS, []);
                        }}
                      />
                    }
                  />
                }
                renderValue={selected => (
                  <Box className={classes.chips}>
                    {(selected as string[]).map((value, index) => (
                      <Box key={value} className={classes.chip}>
                        {value}
                        {index + 1 !== (selected as string[]).length && ', '}
                      </Box>
                    ))}
                  </Box>
                )}
              >
                {NAVIGATION_STATUSES.map((status: any, index: number) => (
                  <MenuItem key={index} value={status}>
                    <Checkbox
                      color="primary"
                      checked={formik.values.status.includes(status as never)}
                    />
                    <ListItemText primary={status} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>

          <Grid xs={12} sm={2} item alignItems="center">
            <Box alignItems="center" display="flex" height="100%">
              <Button
                fullWidth
                disabled={!area?.length}
                onClick={() =>
                  startSearchVessels(area, {
                    ...formik.values,
                  })
                }
              >
                <I18nText path="button.getVessels" />
              </Button>
            </Box>
          </Grid>
        </Grid>

        <AreaPicker setArea={setArea} vessels={vessels} setSelectedVessel={setSelectedVessel} />

        <Box mb={2}>
          <Typography variant="h5">
            <I18nText path="menuLabel.selectedAreaVessels" />
          </Typography>
        </Box>

        {vessels && !!vessels.length && (
          <Grid container spacing={4}>
            <Grid xs={12} sm={3} item alignItems="center">
              <SendInvitesButton
                disabled={!vessels?.length}
                setShowInvitePopup={setShowInvitePopup}
                vessels={vessels}
                fullWidth
              />
            </Grid>
            <Grid xs={12} sm={3} item alignItems="center">
              <Box alignItems="center" display="flex" height="100%">
                <Button disabled={!vessels?.length} fullWidth onClick={dowloandVesselsXlsx}>
                  <I18nText path="button.downloadReport" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}

        <Box>
          {loading ? (
            <Box>
              <I18nText path="text.loading" />
            </Box>
          ) : (
            <>
              {vessels && !!vessels.length && (
                <VesselsTable
                  vessels={vessels}
                  selectedVessel={selectedVessel}
                  inviteStatus={inviteStatus}
                  setShowInvitePopup={setShowInvitePopup}
                />
              )}
              {vessels && !vessels.length && (
                <Box>
                  <I18nText path="text.noSearchResult" />
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
