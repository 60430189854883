import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';

import { OrderForm } from 'src/pages/orders/components/OrderForm';
import { Header } from 'src/components/layout';
import { useOrderQuery } from 'src/gql';
import { DEFAULT_ORDER_FIELDS, OPERATION_TYPES } from 'src/utils/constants';
import { useEditOrderMutation, GetOrdersDocument } from 'src/gql/';
import { setSuccessMesageNotification, setErrorNotification } from 'src/utils/helpers';
import * as ROUTES from 'src/utils/constants/routes';

export const OrderEditPage = () => {
  const navigate = useNavigate();
  const { id } = useParams<any>();
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [editOrder] = useEditOrderMutation({
    refetchQueries: [GetOrdersDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Order edited');
      navigate(ROUTES.ORDERS);
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });
  const { loading, error, data } = useOrderQuery({
    variables: { id: id! },
  });

  return (
    <div>
      <Header type={'orderSteps'} stepNumber={stepNumber} />
      {!loading && data && (
        <OrderForm
          setStepNumber={setStepNumber}
          stepNumber={stepNumber}
          order={{ ...DEFAULT_ORDER_FIELDS, ...data.order }}
          type={OPERATION_TYPES.EDIT}
          mutation={editOrder}
        />
      )}
    </div>
  );
};
