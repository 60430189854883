import React, { FC } from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

import { I18nText } from 'src/components/i18n';
import { Cargo } from 'src/gql';

const orderTypes = [
  { value: 0, label: 'moloo' },
  { value: 1, label: 'chopt' },
];

const stowageUnits = [
  { value: 0, label: 'units.ftTn' },
  { value: 1, label: 'units.mTn' },
];

export const OrderFormFirstStep: FC<any> = ({ formik, cargos }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
        <Grid item xs={12}>
          <InputLabel className={classes.label}>
            <I18nText path="field.quantity" />
          </InputLabel>
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="outlined"
            name={'quantityMin'}
            value={formik.values.quantityMin}
            error={!!formik.errors.quantityMin && !!formik.touched.quantityMin}
            onChange={event => {
              formik.setFieldTouched('quantityMin', false);
              const value = !!+event.target.value ? +event.target.value.replace(/\D/, '') : '';
              formik.setFieldValue('quantityMin', value);
            }}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            variant="outlined"
            name={'quantityMax'}
            error={!!formik.errors.quantityMax && !!formik.touched.quantityMax}
            value={formik.values.quantityMax}
            onChange={event => {
              formik.setFieldTouched('quantityMax', false);
              const value = !!+event.target.value ? +event.target.value.replace(/\D/, '') : '';
              formik.setFieldValue('quantityMax', value);
            }}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            className={classes.select}
            variant="outlined"
            name={'type'}
            value={formik.values.type}
            error={!!formik.errors.type && !!formik.touched.type}
            onChange={event => {
              formik.setFieldTouched('type', false);
              formik.setFieldValue('type', event.target.value);
            }}
          >
            {orderTypes.map(type => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
        <Grid item xs={2}>
          <InputLabel className={classes.label}>
            <I18nText path="field.cargo" />
          </InputLabel>
        </Grid>
        <Grid item xs={10} sm={9}>
          <Select
            className={classes.select}
            variant="outlined"
            name={'cargo'}
            error={!!formik.errors.cargo && !!formik.touched.cargo}
            value={formik.values.cargo}
            onChange={event => {
              formik.setFieldTouched('cargo', false);
              formik.setFieldValue('cargo', event.target.value);
            }}
          >
            {cargos?.map((cargo: Cargo) => (
              <MenuItem key={cargo.id} value={cargo.name}>
                {cargo.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={2}>
          <InputLabel className={classes.label}>
            <I18nText path="field.stowageFactor" />
          </InputLabel>
        </Grid>
        <Grid item xs={10} sm={3}>
          <Select
            className={classes.select}
            variant="outlined"
            name={'stowageFactorUnit'}
            error={!!formik.errors.stowageFactorUnit && !!formik.touched.stowageFactorUnit}
            value={formik.values.stowageFactorUnit}
            onChange={event => {
              formik.setFieldTouched('stowageFactorUnit', false);
              formik.setFieldValue('stowageFactorUnit', event.target.value);
            }}
          >
            {stowageUnits.map(type => (
              <MenuItem key={type.value} value={type.value}>
                <I18nText path={type.label} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={2} sm={1}>
          <InputLabel className={classes.placeholder}>
            <I18nText path="placeholder.about" />
          </InputLabel>
        </Grid>
        <Grid item xs={10} sm={4}>
          <TextField
            className={classes.textField}
            variant="outlined"
            name="wog"
            error={!!formik.errors.wog && !!formik.touched.wog}
            value={formik.values.wog}
            onChange={event => {
              formik.setFieldTouched('wog', false);
              formik.setFieldValue('wog', event.target.value);
            }}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={2} sm={1}>
          <InputLabel className={classes.placeholder}>
            <I18nText path="menuLabel.wog" />
          </InputLabel>
        </Grid>
      </Grid>

      <Grid container alignItems="center" spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={2}>
          <InputLabel className={classes.label}>
            <I18nText path="field.laycan" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            margin="normal"
            inputVariant="outlined"
            format="dd.MM.yyyy"
            error={!!formik.errors.layTime && !!formik.touched.layTime}
            value={+formik.values.layTime}
            InputProps={{
              className: classes.input,
            }}
            name="layTime"
            onChange={date => {
              formik.setFieldTouched('layTime', false);
              formik.setFieldValue('layTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            margin="normal"
            format="dd.MM.yyyy"
            inputVariant="outlined"
            name="canTime"
            error={!!formik.errors.canTime && !!formik.touched.canTime}
            value={+formik.values.canTime}
            InputProps={{
              className: classes.input,
            }}
            onChange={date => {
              formik.setFieldTouched('canTime', false);
              formik.setFieldValue('canTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
      </Grid>

      <div className={classes.separationLine} />

      <Grid container alignItems="center" spacing={3}>
        {/* <Grid item xs={12} sm={2}>
          <InputLabel className={classes.label}>
            <I18nText path="field.startTime" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            margin="normal"
            inputVariant="outlined"
            name="startTime"
            clearable
            format="dd.MM.yyyy"
            error={!!formik.errors.startTime && !!formik.touched.startTime}
            value={+formik.values.startTime}
            InputProps={{
              className: classes.input,
            }}
            onChange={date => {
              formik.setFieldTouched('startTime', false);
              formik.setFieldValue('startTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardTimePicker
            margin="normal"
            name="startTime"
            clearable
            inputVariant="outlined"
            value={+formik.values.startTime}
            error={!!formik.errors.startTime && !!formik.touched.startTime}
            InputProps={{
              className: classes.input,
            }}
            onChange={date => {
              formik.setFieldTouched('startTime', false);
              formik.setFieldValue('startTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
        <Grid item xs={2} /> */}
        <Grid item xs={12} sm={2}>
          <InputLabel className={classes.label}>
            <I18nText path="field.closingTime" />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardDatePicker
            margin="normal"
            inputVariant="outlined"
            format="dd.MM.yyyy"
            name="closeTime"
            clearable
            value={+formik.values.closeTime}
            error={!!formik.errors.closeTime && !!formik.touched.closeTime}
            InputProps={{
              className: classes.input,
            }}
            onChange={date => {
              formik.setFieldTouched('closeTime', false);
              formik.setFieldValue('closeTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <KeyboardTimePicker
            margin="normal"
            name="closeTime"
            inputVariant="outlined"
            clearable
            value={+formik.values.closeTime}
            InputProps={{
              className: classes.input,
            }}
            error={!!formik.errors.closeTime && !!formik.touched.closeTime}
            onChange={date => {
              formik.setFieldTouched('closeTime', false);
              formik.setFieldValue('closeTime', date);
            }}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '16px',
    color: 'black',
    textAlign: 'start',
  },
  select: {
    width: '100%',
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  commentInput: {
    width: '100%',
    height: '100%',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
    '&::placeholder': {
      color: 'rgba(256, 256, 256, 0.3)',
    },
  },
  separationLine: {
    margin: '48px',
    opacity: 0.1,
    border: '1px solid #000000',
  },
  gridContainer: {
    marginBottom: '25px',
  },
  textField: {
    width: '100%',
  },
  placeholder: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
}));
