import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';

import {
  useGetOrderOffersQuery,
  useMarkOfferAsWinnerMutation,
  useOrderQuery,
  OfferCreatedDocument,
  useMarkOngoingOrderMutation,
  GetOrdersDocument,
} from 'src/gql';
import { LIMIT } from 'src/utils/constants';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';

export const useOfferList = () => {
  const classes = useStyles();
  const { id } = useParams<any>();

  const {
    loading: orderLoading,
    data: orderData,
    refetch,
  } = useOrderQuery({
    variables: { id: id! },
  });

  const {
    loading: orderOffersLoading,
    data: orderOffersData,
    fetchMore,
    subscribeToMore,
  } = useGetOrderOffersQuery({
    fetchPolicy: 'cache-and-network',
    variables: { limit: LIMIT, offset: 0, orderId: id! },
  });

  const [markOngoingOrder] = useMarkOngoingOrderMutation({
    refetchQueries: [GetOrdersDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Order is ongoing');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  const [markOfferAsWinner] = useMarkOfferAsWinnerMutation({
    onCompleted: () => {
      refetch({ id });
      setSuccessMesageNotification('Winner selected');
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  useEffect(() => {
    subscribeToMore({
      document: OfferCreatedDocument,
      variables: { orderId: id },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data) return prev;
        //@ts-ignore
        const { offerCreated } = subscriptionData.data;
        return {
          orderOffers: {
            ...prev.orderOffers,
            offers: [offerCreated, ...prev.orderOffers.offers],
          },
        };
      },
    });
  }, []);

  const loadMoreOrderOffers = useCallback(async () => {
    await fetchMore({
      //@ts-ignore
      updateQuery(prev, { fetchMoreResult }) {
        if (!fetchMoreResult) {
          return prev;
        }
        return {
          ...prev,
          offers: [...prev.orderOffers.offers],
        };
      },
      variables: {
        limit: LIMIT,
        offset: orderOffersData ? orderOffersData.orderOffers.offers.length : 0,
      },
    });
  }, [orderOffersData?.orderOffers.offers, fetchMore]);

  return {
    classes,
    loadMoreOrderOffers,
    markOngoingOrder,
    markOfferAsWinner,
    orderOffers: orderOffersData?.orderOffers,
    order: orderData?.order,
    loading: orderLoading || orderOffersLoading,
  };
};

const useStyles = makeStyles(theme => ({
  panelTools: {
    margin: '30px 0 60px',
  },
  fieldOnlyActiveWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  toggleWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  tableHeaderWrapper: {
    padding: '0px 15px',
    marginBottom: '18px',
  },
  tableHeader: {
    textAlign: 'center',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  backButton: {
    marginRight: '15px',
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    color: '#000000',
    textAlign: 'center',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  buttonWrapper: {
    display: 'flex',
    gap: '15px',
  },
  buttons: {
    marginTop: '50px',
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'end',
  },
  startButtonWrapper: {
    textAlign: 'end',
  },
  freightText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '20px',
  },
}));
