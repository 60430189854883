import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import { Header } from 'src/components/layout';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { EmbedMap } from 'src/components/MarineTrafficMap/EmbedMap';
import { ELGA_FLEET_ID } from 'src/utils/constants';
import { useMarineTrafficVesselSelected } from 'src/hooks';
import { useGetOrderByImoQuery } from 'src/gql';
import { OrderDetails } from 'src/components/OrderDetails';
import { SummaryCard } from 'src/pages/summary/components/SummaryCard';

import { useDisplayPage } from './hooks/useDisplayPage';

export const DisplayPage = () => {
  const classes = useStyles();
  const [vesselImo, setVesselImo] = useState<string | null>(null);
  const { data: orderByImoData } = useGetOrderByImoQuery({
    variables: {
      imo: +vesselImo!,
    },
    skip: !vesselImo,
  });

  useMarineTrafficVesselSelected(setVesselImo);
  const { growth, mapRetriggerValue } = useDisplayPage();

  return (
    <Box>
      <Header type={'orders'} />

      <Container>
        <Box className={classes.periodsContainer}>
          <Container>
            {!!growth && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'week'} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'month'} />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SummaryCard growth={growth} type={'quarter'} />
                </Grid>
              </Grid>
            )}
          </Container>
        </Box>
        <Box className={classes.panelTools}>
          <Box className={classes.mapWrapper}>
            <EmbedMap
              width="90%"
              height="500px"
              shownames="false"
              maptype="0"
              fleet_id={ELGA_FLEET_ID}
              retriggerValue={mapRetriggerValue}
            />
          </Box>
          <Box>
            {!!orderByImoData?.orderByImo && (
              <OrderDetails order={orderByImoData.orderByImo} onClose={() => setVesselImo(null)} />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  mapWrapper: {
    width: '100%',
    marginRight: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  panelTools: {
    display: 'flex',
    margin: '30px 0 0',
  },
  periodsContainer: {
    margin: '48px 0',
  },
}));
