import React, { FC, useCallback, useEffect } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useFormik } from 'formik';
import { pick } from 'ramda';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import { User } from 'src/gql';
import { RequiredNotNull } from 'src/utils/types';
import { I18nText } from 'src/components/i18n';
import Select from '@material-ui/core/Select';
import { COMPANY_ROLES, COMPANY_ROLES_ALLOW_CREATE, UserRole } from 'src/utils/constants/roles';
import { BootstrapInput, EndInputAdornment } from 'src/components/Filter/Filter';
import { Button } from 'src/components/buttons';
import { MenuSelect } from 'src/components/selects/MenuSelect/MenuSelect';
import { getDDMMYYYY } from 'src/utils/helpers';
import TextField from '@material-ui/core/TextField';
import { OPERATION_TYPES } from 'src/utils/constants';
import { useIntl } from 'react-intl';
import { getRoleTitle } from 'src/utils/helpers/roles';

interface AccountItemFormProps {
  account?: RequiredNotNull<User>;
  mutation: any;
  setCreating: React.Dispatch<React.SetStateAction<boolean>>;
  setEditingIndex: React.Dispatch<React.SetStateAction<number | null>>;
  disabled: boolean;
  mode: 'edit' | 'create';
}

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  roles: [],
};

const findChangedValues = (values: any, initialValues: any) => {
  return Object.keys(values).filter((key: string) => values[key] !== initialValues[key]);
};

export const AccountItemForm: FC<AccountItemFormProps> = ({
  account,
  mutation,
  setEditingIndex,
  setCreating,
  disabled,
  mode,
}) => {
  const classes = useStyles();
  const { locale } = useIntl();

  const formik = useFormik({
    initialValues: {
      email: account?.email || initialState.email,
      firstName: account?.firstName || initialState.firstName,
      lastName: account?.lastName || initialState.lastName,
      roles: account?.roles || initialState.roles,
      password: initialState.password,
    },
    enableReinitialize: true,
    onSubmit: values => {
      if (mode === OPERATION_TYPES.CREATE) {
        mutation({
          variables: { data: values },
        });
      }
      if (mode === OPERATION_TYPES.EDIT) {
        mutation({
          variables: {
            data: pick(findChangedValues(values, formik.initialValues), values),
            id: account?.id,
          },
        });
      }

      setCreating(false);
      setEditingIndex(null);
    },
  });

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <div className={classes.orderContainer}>
          <Grid container alignItems="center">
            <Grid
              xs={12}
              sm={6}
              item
              className={classes.idWrapper}
              //@ts-ignore
              justifyContent={isMobile ? 'flex-start' : 'flex-start'}
            >
              <div className={classes.label}>
                {account?.createdAt && (
                  <>
                    <I18nText path="menuLabel.registrationDate" />{' '}
                    <span className={classes.data}>{getDDMMYYYY(account.createdAt)}</span>
                  </>
                )}
                {!account?.createdAt && (
                  <span className={classes.data}>
                    <I18nText path="menuLabel.newUser" />
                  </span>
                )}
              </div>
            </Grid>
            {isDesktop && (
              <Grid item className={classes.buttonsWrapper} xs={6} justifyContent={'flex-end'}>
                <Button
                  buttonVariant="main"
                  type="submit"
                  disabled={disabled}
                  onClick={() => null}
                >
                  {mode === OPERATION_TYPES.CREATE && <I18nText path="button.create" />}
                  {mode === OPERATION_TYPES.EDIT && <I18nText path="button.edit" />}
                </Button>

                <Button
                  onClick={() => {
                    setCreating(false);
                    setEditingIndex(null);
                  }}
                  disabled={disabled}
                  buttonVariant="light"
                >
                  <I18nText path="button.cancel" />
                </Button>
              </Grid>
            )}
          </Grid>
          {isMobile && (
            <Grid container>
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="field.email" />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={9}>
                <TextField
                  variant="outlined"
                  name={'email'}
                  error={!!formik.errors.email && !!formik.touched.email}
                  value={formik.values.email}
                  onChange={(event: any) => {
                    formik.setFieldTouched('email', false);

                    formik.setFieldValue('email', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="field.firstName" />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={9}>
                <TextField
                  variant="outlined"
                  name={'firstName'}
                  error={!!formik.errors.firstName && !!formik.touched.firstName}
                  value={formik.values.firstName}
                  onChange={(event: any) => {
                    formik.setFieldTouched('firstName', false);

                    formik.setFieldValue('firstName', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="field.lastName" />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={9}>
                <TextField
                  variant="outlined"
                  name={'lastName'}
                  error={!!formik.errors.lastName && !!formik.touched.lastName}
                  value={formik.values.lastName}
                  onChange={(event: any) => {
                    formik.setFieldTouched('lastName', false);

                    formik.setFieldValue('lastName', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="field.password" />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={9}>
                <TextField
                  variant="outlined"
                  name={'password'}
                  type="password"
                  error={!!formik.errors.password && !!formik.touched.password}
                  value={formik.values.password}
                  onChange={(event: any) => {
                    formik.setFieldTouched('password', false);

                    formik.setFieldValue('password', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="menuLabel.role" />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={9}>
                <div className={classes.rolseWrapper}>
                  <Select
                    className={classes.select}
                    multiple
                    disabled={disabled}
                    name="roles"
                    value={formik.values.roles}
                    onChange={(event: any) => {
                      formik.setFieldTouched('roles', false);
                      formik.setFieldValue('roles', event.target.value);
                    }}
                    input={<BootstrapInput />}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value, index) => (
                          <div key={value} className={classes.chip}>
                            {getRoleTitle(value, locale)}
                            {index + 1 !== (selected as string[]).length && ', '}
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    {COMPANY_ROLES.map((role, index: number) => (
                      <MenuItem key={index} value={role.name}>
                        <Checkbox
                          color="primary"
                          checked={formik.values.roles.includes(role.name)}
                        />
                        <ListItemText primary={role.label[locale]} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            </Grid>
          )}
          {isDesktop && (
            <Grid container spacing={1}>
              <Grid item className={classes.gridItem} xs={3} md={2}>
                <div className={classes.label}>
                  <I18nText path="field.email" />
                </div>
                <TextField
                  variant="outlined"
                  name={'email'}
                  error={!!formik.errors.email && !!formik.touched.email}
                  value={formik.values.email}
                  onChange={(event: any) => {
                    formik.setFieldTouched('email', false);
                    formik.setFieldValue('email', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3} md={2}>
                <div className={classes.label}>
                  <I18nText path="field.firstName" />
                </div>
                <TextField
                  variant="outlined"
                  name={'firstName'}
                  error={!!formik.errors.firstName && !!formik.touched.firstName}
                  value={formik.values.firstName}
                  onChange={(event: any) => {
                    formik.setFieldTouched('firstName', false);
                    formik.setFieldValue('firstName', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3} md={2}>
                <div className={classes.label}>
                  <I18nText path="field.lastName" />
                </div>

                <TextField
                  variant="outlined"
                  name={'lastName'}
                  error={!!formik.errors.lastName && !!formik.touched.lastName}
                  value={formik.values.lastName}
                  onChange={(event: any) => {
                    formik.setFieldTouched('lastName', false);
                    formik.setFieldValue('lastName', event.target.value);
                  }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid>
              <Grid item className={classes.gridItem} xs={3} md={2}>
                <div className={classes.label}>
                  <I18nText path="field.password" />
                </div>

                <div className={classes.rolseWrapper}>
                  <TextField
                    variant="outlined"
                    name={'password'}
                    type="password"
                    placeholder="*****"
                    error={!!formik.errors.password && !!formik.touched.password}
                    value={formik.values.password}
                    onChange={(event: any) => {
                      formik.setFieldTouched('password', false);

                      formik.setFieldValue('password', event.target.value);
                    }}
                    InputProps={{
                      className: classes.input,
                    }}
                  />
                </div>
              </Grid>
              <Grid item className={classes.gridItem} xs={3} md={2}>
                <div className={classes.label}>
                  <I18nText path="menuLabel.role" />
                </div>

                <div className={classes.rolseWrapper}>
                  <Select
                    className={classes.select}
                    multiple
                    disabled={disabled}
                    name="roles"
                    value={formik.values.roles}
                    onChange={(event: any) => {
                      formik.setFieldTouched('roles', false);
                      formik.setFieldValue('roles', event.target.value);
                    }}
                    input={<BootstrapInput />}
                    renderValue={selected => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value, index) => (
                          <div key={value} className={classes.chip}>
                            {
                              COMPANY_ROLES_ALLOW_CREATE.find(el => el.name === value)?.label[
                                locale
                              ]
                            }
                            {index + 1 !== (selected as string[]).length && ', '}
                          </div>
                        ))}
                      </div>
                    )}
                  >
                    {COMPANY_ROLES_ALLOW_CREATE.map(role => (
                      <MenuItem key={role.name} value={role.name}>
                        <Checkbox
                          color="primary"
                          checked={formik.values.roles.includes(role.name)}
                        />
                        <ListItemText primary={role.label[locale]} />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <Grid container className={classes.buttonContainer}>
              <Grid
                item
                className={classes.buttonsWrapper}
                xs={12}
                justifyContent={'space-between'}
              >
                <Button
                  buttonVariant="main"
                  type="submit"
                  disabled={disabled}
                  onClick={() => null}
                >
                  {mode === OPERATION_TYPES.CREATE && <I18nText path="button.create" />}
                  {mode === OPERATION_TYPES.EDIT && <I18nText path="button.edit" />}
                </Button>

                <Button
                  onClick={() => {
                    setCreating(false);
                    setEditingIndex(null);
                  }}
                  disabled={disabled}
                  buttonVariant="light"
                >
                  <I18nText path="button.cancel" />
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </form>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  orderContainer: {
    position: 'relative',
    margin: '0 auto',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  buttonContainer: {
    marginTop: '36px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '16px',
  },
  rolseWrapper: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    minWidth: '160px',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
  },
  idWrapper: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center',
  },
  data: {
    color: '#000',
    fontSize: '15px',
  },
  statusWrapper: {
    marginLeft: '10px',
  },
  label: {
    marginBottom: '6px',
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  layCanWrapper: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    fontSize: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },

  rateToggleWrapper: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '7px',
  },
  deleviredWasFine: {
    color: '#00BD2A',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.139 0.606404C11.7327 -0.0603189 12.7467 0.843469 12.1537 1.50953L5.13064 9.39991C4.88094 9.68014 4.45196 9.70538 4.17173 9.45569C3.05876 8.34272 1.90921 6.92031 0.844835 5.72504C0.251824 5.05898 1.26586 4.15519 1.85953 4.82192L4.62271 7.92646L11.1385 0.605916L11.139 0.606404Z' fill='%2300BD2A'/%3E%3C/svg%3E%0A")`,
      width: '13px',
      height: '10px',
      display: 'inline-block',
      backgroundSize: '13px 10px',
      backgroundRepeat: 'no-repeat',
    },
  },
  lateDelivery: {
    color: '#000',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 11M1 11L11 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
      width: '12px',
      height: '12px',
      display: 'inline-block',
      backgroundSize: '13px 12px',
      backgroundRepeat: 'no-repeat',
    },
  },
  select: {
    height: '100%',
    width: '100%',
    fontSize: '16px',
    borderRadius: '8px',
    border: '2px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
  input: {
    borderRadius: '8px',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    background: 'white',
  },
}));
