import type { FC, Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { map, prop } from 'ramda';
import { useIntl } from 'react-intl';

import { ButtonProps } from '@material-ui/core';

import { Button } from 'src/components/buttons';
import { I18nText } from 'src/components/i18n';
import { useInviteOperatorsMutation } from 'src/gql';
import { setSuccessMesageNotification, setErrorNotification } from 'src/utils/helpers';
import { MarineTrafficVesselInfo, MarineTrafficVesselInfoGuaranteed } from 'src/utils/types';
import { inviteVar } from 'src/apollo/cache/cache';

interface SendInvitesButtonProps extends ButtonProps {
  vessels?: MarineTrafficVesselInfo[];
  setShowInvitePopup: Dispatch<SetStateAction<boolean>>;
}

export const SendInvitesButton: FC<SendInvitesButtonProps> = ({
  vessels,
  disabled,
  setShowInvitePopup,
  ...props
}) => {
  const handleInvite = async () => {
    if (!vessels) {
      return;
    }

    const ownerEmails: string[] = map(
      prop('OWNER_EMAIL'),
      vessels.filter(
        (vessel): vessel is MarineTrafficVesselInfoGuaranteed<'OWNER_EMAIL'> =>
          !!vessel['OWNER_EMAIL'],
      ),
    );
    const managerEmails: string[] = map(
      prop('MANAGER_EMAIL'),
      vessels.filter(
        (vessel): vessel is MarineTrafficVesselInfoGuaranteed<'MANAGER_EMAIL'> =>
          !!vessel['MANAGER_EMAIL'],
      ),
    );

    inviteVar({ emails: [...ownerEmails, ...managerEmails], mode: 'all' });
    setShowInvitePopup(true);
  };

  return (
    <Button {...props} disabled={disabled} onClick={handleInvite}>
      <I18nText path="button.inviteAll" />
    </Button>
  );
};
