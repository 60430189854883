import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import SortIcon from '@material-ui/icons/Sort';

import { I18nText } from 'src/components/i18n';
import { ORDER_BY } from 'src/utils/constants';

export const SortLabel = ({
  path,
  orderBy,
  isSelected = false,
  sort,
  column,
}: {
  column: string;
  path: string;
  orderBy: string;
  isSelected: boolean;
  sort: (column: string, orderBy: string) => void;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.labelContainer}>
      <div
        className={isSelected ? classes.selectedLabelWrapper : classes.labelWrapper}
        onClick={() =>
          sort(column, orderBy === ORDER_BY.DESC && isSelected ? ORDER_BY.ASC : ORDER_BY.DESC)
        }
      >
        <div>
          <I18nText path={path} />
        </div>
        {isSelected && (
          <div className={classes.sortIcon}>
            {orderBy === ORDER_BY.DESC ? (
              <SortIcon />
            ) : (
              <SortIcon className={classes.sortIconFlipped} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  labelContainer: { display: 'flex', cursor: 'pointer', height: '27px' },
  labelWrapper: { position: 'relative', display: 'flex', fontSize: '14px' },
  selectedLabelWrapper: { position: 'relative', display: 'flex', color: '#000', fontSize: '14px' },
  sortIcon: { marginLeft: '3px' },
  sortIconFlipped: { transform: 'scaleY(-1)' },
}));
