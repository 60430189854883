import { FC, Dispatch, SetStateAction, memo } from 'react';
import { useReactiveVar } from '@apollo/client';

import { Box } from '@material-ui/core';

import { shipFieldsLocale } from 'src/utils/helpers';
import { I18nText } from 'src/components/i18n';
import { MarineTrafficVesselInfo } from 'src/utils/types';
import { VesselsTableRow } from '../VesselsTableRow/VesselsTableRow';

import { useStyles } from './VesselsTable.style';
import { Maybe, Scalars } from 'src/gql';
import { invitedEmailsVar } from 'src/apollo/cache/cache';

export interface ShipsTableProps {
  vessels: MarineTrafficVesselInfo[];
  selectedVessel?: MarineTrafficVesselInfo | null;
  inviteStatus: Maybe<Scalars['JSON']>;
  setShowInvitePopup: Dispatch<SetStateAction<boolean>>;
}
export const VesselsTable: FC<ShipsTableProps> = memo(
  ({ vessels, selectedVessel, setShowInvitePopup, inviteStatus }) => {
    const classes = useStyles();
    const columns = [...Object.values(shipFieldsLocale)];
    const invitedEmails = useReactiveVar(invitedEmailsVar);
    return (
      <Box mt={2} className={classes.tableContainer}>
        <Box className={classes.columnContainer}>
          {columns.map((column: string, index: number) => (
            <Box key={column} className={classes.titleColumn}>
              <I18nText path={column} />
            </Box>
          ))}
          <Box key="invite" className={classes.titleInviteColumn}>
            <I18nText path="menuLabel.invite" />
          </Box>
        </Box>
        <Box mb={2}>
          {vessels.map(vessel => {
            console.log(
              '@invited',
              (!!vessel.OWNER_EMAIL && inviteStatus[vessel.OWNER_EMAIL]) ||
                (!!vessel.MANAGER_EMAIL && inviteStatus[vessel.MANAGER_EMAIL]),
            );
            return (
              <VesselsTableRow
                setShowInvitePopup={setShowInvitePopup}
                invited={
                  (!!vessel.OWNER_EMAIL && inviteStatus[vessel.OWNER_EMAIL]) ||
                  (!!vessel.MANAGER_EMAIL && inviteStatus[vessel.MANAGER_EMAIL]) ||
                  invitedEmails.includes(vessel.OWNER_EMAIL) ||
                  invitedEmails.includes(vessel.MANAGER_EMAIL)
                }
                isSelected={selectedVessel?.IMO === vessel.IMO}
                key={`${vessel.IMO}_${vessel.SHIPNAME}`}
                vessel={vessel}
              />
            );
          })}
        </Box>
      </Box>
    );
  },
);
