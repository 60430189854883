import { makeStyles } from '@material-ui/core/styles';

export const useFormClasses = () => {
  const classes = useStyles();
  return { classes };
};

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
    marginBottom: '10px',
    '& .MuiInputBase-root': { backgroundColor: '#fff' },
    '& .MuiFormHelperText-root': { margin: '2px 0 0' },
  },
  input: {
    background: 'white',
  },
}));
