import { useNavigate } from 'react-router';
import { useReactiveVar } from '@apollo/client';

import { makeStyles } from '@material-ui/core/styles';

import { userVar } from 'src/apollo/cache/cache';

export const useOrderListItem = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const user = useReactiveVar(userVar);

  function navigateTo(route: string, orderId: string) {
    navigate(`${route}/${orderId}`);
  }

  return { classes, user, navigateTo };
};

const useStyles = makeStyles(() => ({
  orderContainer: {
    position: 'relative',
    margin: '0 auto',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  buttonContainer: {
    marginTop: '36px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '16px',
  },
  portsWrapper: {
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
  },
  idWrapper: {
    display: 'flex',
    gap: '26px',
    alignItems: 'center',
  },
  data: {
    color: '#000',
    fontSize: '15px',
  },
  statusWrapper: {
    marginLeft: '10px',
  },
  label: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  layCanWrapper: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    minWidth: '340px',
    fontSize: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },

  rateToggleWrapper: {
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '7px',
  },
  deleviredWasFine: {
    color: '#00BD2A',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.139 0.606404C11.7327 -0.0603189 12.7467 0.843469 12.1537 1.50953L5.13064 9.39991C4.88094 9.68014 4.45196 9.70538 4.17173 9.45569C3.05876 8.34272 1.90921 6.92031 0.844835 5.72504C0.251824 5.05898 1.26586 4.15519 1.85953 4.82192L4.62271 7.92646L11.1385 0.605916L11.139 0.606404Z' fill='%2300BD2A'/%3E%3C/svg%3E%0A")`,
      width: '13px',
      height: '10px',
      display: 'inline-block',
      backgroundSize: '13px 10px',
      backgroundRepeat: 'no-repeat',
    },
  },
  lateDelivery: {
    color: '#000',
    fontSize: '17px',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      cursor: 'onClick',
      textDecoration: 'underline',
    },
    '&::before': {
      marginRight: '6px',
      content: '""',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 11M1 11L11 1' stroke='black' stroke-linecap='round'/%3E%3C/svg%3E%0A")`,
      width: '12px',
      height: '12px',
      display: 'inline-block',
      backgroundSize: '13px 12px',
      backgroundRepeat: 'no-repeat',
    },
  },
}));
