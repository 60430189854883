import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import { Order } from 'src/gql';
import { useOrderDetails } from 'src/hooks';
import { I18nText } from 'src/components/i18n';

interface PopupDetailsProps {
  details: Order;
  setShowPopup: any;
  showPopup: boolean;
}

export const PopupDetails: FC<PopupDetailsProps> = ({ details, setShowPopup, showPopup }) => {
  const classes = useStyles();

  const { orderDetails } = useOrderDetails({
    order: {
      ...details,
      loadingPorts: details.loadingPorts,
      dischargingPorts: details.dischargingPorts,
    } as any,
  });

  return (
    <Dialog PaperProps={{ className: classes.dialog }} onClose={setShowPopup} open={showPopup}>
      {showPopup && (
        <div className={classes.detailsPopup}>
          <div className={classes.closeButton} onClick={setShowPopup} />
          <div className={classes.detailsPopupTitle}>Order {details.indexNumber} details</div>
          <div className={classes.details}>{orderDetails}</div>
        </div>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  dialog: {
    borderRadius: '15px',
  },
  detailsPopupTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: '22px',
    marginBottom: '50px',
  },
  detailsPopup: {
    position: 'relative',
    padding: '50px 40px',
  },
  details: {
    marginBottom: '50px',
    fontSize: '20px',
    color: 'rgba(0, 0, 0, 0.4)',
  },
  closeButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '50px',
    right: '50px',
    width: '33px',
    height: '31px',
    backgroundSize: '33 31',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='33' height='31' viewBox='0 0 33 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline y1='-1' x2='41.0122' y2='-1' transform='matrix(-0.707107 0.707107 0.707107 0.707107 30.8076 2)' stroke='black' stroke-width='2'/%3E%3Cline x1='2.51472' y1='1.29289' x2='31.5147' y2='30.2929' stroke='black' stroke-width='2'/%3E%3C/svg%3E%0A")`,
  },
}));
