import React from 'react';

import { isDesktop } from 'react-device-detect';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

export const PopupWrapper = isDesktop
  ? ({ children, classes, open, handleClose }: any) => (
      <Dialog
        PaperProps={{ className: classes.dialog }}
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        {children}
      </Dialog>
    )
  : ({ children, classes, open }: any) => (
      <>{open && <div className={classes.mobilePopup}>{children}</div>}</>
    );
