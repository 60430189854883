import React, { FC } from 'react';

import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { I18nText } from 'src/components/i18n';
import { vesselVar } from 'src/apollo/cache/cache';
import { GetMyVesselsDocument, useDeleteVesselMutation } from 'src/gql';
import { setErrorNotification, setSuccessMesageNotification } from 'src/utils/helpers';
import { MY_VESSELS_VARIABLES } from 'src/utils/constants';

export const ShipownerVesselMobileItem: FC<any> = ({ vessel, setEditShowVesselForm, refetch }) => {
  const classes = useStyles();
  const [deleteVessel, { loading }] = useDeleteVesselMutation({
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: GetMyVesselsDocument,
        variables: MY_VESSELS_VARIABLES,
      },
    ],
    onError: error => {
      setErrorNotification(`${error}`);
    },
    onCompleted: data => {
      setSuccessMesageNotification('Vessel deleted');
    },
  });

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        className={classes.gridContainer}
        alignContent="center"
        justifyContent="center"
      >
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.label}>
            <I18nText path="menuLabel.name" />:
          </div>
          <div className={classes.id}>{vessel.name}</div>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.label}>
            <I18nText path="menuLabel.constructionYear" />:
          </div>
          <div>{vessel.constructionYear}</div>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.label}>
            <I18nText path="menuLabel.imo" />:
          </div>
          <div>{vessel.imo}</div>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.label}>
            <I18nText path="menuLabel.deadWeight" />:
          </div>
          <div>{vessel.deadweight}</div>
        </Grid>
        <Grid item className={classes.gridItem} xs={12}>
          <div className={classes.label}>
            <I18nText path="menuLabel.draft" />:
          </div>
          <div>{vessel.draft}m</div>
        </Grid>
        <Grid item className={classes.buttonsWrapper} xs={12}>
          <Button
            className={classes.button}
            onClick={() => {
              vesselVar(vessel);
              setEditShowVesselForm(true);
            }}
            disabled={loading}
            variant="outlined"
          >
            <I18nText path="button.edit" />
          </Button>
          <Button
            className={classes.button}
            variant="outlined"
            disabled={loading}
            onClick={() => {
              deleteVessel({ variables: { id: vessel.id } });
              refetch();
            }}
          >
            <I18nText path="button.delete" />
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 15px',
    marginBottom: '20px',
  },
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'fles-start',
    marginBottom: '15px',
    flexDirection: 'column',
  },
  openButton: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  detailsButton: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    textAlign: 'center',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  select: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
  },
  id: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  layCanWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  button: {
    border: '2px solid rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    textAlign: 'center',
    height: '55px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 33px',
  },
  label: {
    marginBottom: '5px',
    fontSize: '13px',
  },
}));
