import React, { FC, useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router';
import { isEmpty } from 'ramda';
import { useReactiveVar } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';

import { AuthPage } from 'src/pages/auth/AuthPage';
import * as ROUTES from 'src/utils/constants/routes';
import { OrdersPage } from 'src/pages/orders/OrdersPage';
import { OrderCreatePage } from 'src/pages/orders/create/OrderCreatePage';
import { vesselCountVar, userVar, vesselsVar } from 'src/apollo/cache/cache';
import { AUTH_TOKEN, LIMIT } from 'src/utils/constants';
import { adminTheme, shipOwnerTheme } from 'src/utils/helpers';
import { getCookie } from 'src/utils/helpers';
import { useGetMyVesselsQuery, useGetUserQuery, User } from 'src/gql';
import { OrderPage } from 'src/pages/order/OrderPage';
import { OrderEditPage } from 'src/pages/orders/edit/OrderEditPage';
import { OrderCopyPage } from 'src/pages/orders/copy/OrderCopyPage';
import { PortsPage } from 'src/pages/ports/PortsPage';

import { UserRole } from './utils/constants/roles';
import { ShipownerOrdersPage } from './pages/shipowner/orders/ShipownerOrdersPage';
import { ShipownerVesselsPage } from './pages/shipowner/vessels/ShipownerVesselsPage';
import { ShipownerOfferEditPage } from './pages/shipowner/offer/edit/ShipownerOfferEditPage';
import { ShipownerOfferCreatePage } from './pages/shipowner/offer/create/ShipownerOfferCreatePage';
import { ShipownerOrderPage } from './pages/shipowner/order/ShipownerOrderPage';
import { ReportsPage } from './pages/reports/ReportsPage';
import { AccountsPage } from './pages/accounts/AccountsPage';
import { MarineTrafficPage } from './pages/marinetraffic/MarineTrafficPage';
import { SummaryPage } from './pages/summary/SummaryPage';
import { MonthlyPlanPage } from './pages/monthlyPlan/MonthlyPlanPage';
import { MonthlyPlansPage } from './pages/monthlyPlans/MonthlyPlansPage';
import { MapPage } from './pages/map/MapPage';
import { DisplayPage } from './pages/display/DisplayPage';

import './App.css';

export const ROUTES_FOR_SHIPOWNER = [
  { route: ROUTES.SHIPOWNER_ORDERS, Component: ShipownerOrdersPage },
  { route: ROUTES.SHIPOWNER_VESSELS, Component: ShipownerVesselsPage },
  { route: ROUTES.SHIPOWNER_ORDER, Component: ShipownerOrderPage },
  { route: ROUTES.SHIPOWNER_OFFER_CREATE, Component: ShipownerOfferCreatePage },
  { route: ROUTES.SHIPOWNER_OFFER_EDIT_ID, Component: ShipownerOfferEditPage },
];

// todo fill in dashboard route
export const ROUTES_FOR_SHAREHOLDER = [
  { route: ROUTES.SUMMARY, Component: SummaryPage },
  { route: ROUTES.DISPLAY, Component: DisplayPage },
  { route: ROUTES.REPORTS, Component: ReportsPage },
];

export const ROUTES_FOR_PLATFORM_ADMIN = [
  ...ROUTES_FOR_SHAREHOLDER,
  { route: ROUTES.MAP, Component: MapPage },
  { route: ROUTES.ORDERS, Component: OrdersPage },
  { route: ROUTES.ORDERS_CREATE, Component: OrderCreatePage },
  { route: ROUTES.ORDERS_EDIT_ID, Component: OrderEditPage },
  { route: ROUTES.ORDERS_COPY_ID, Component: OrderCopyPage },
  { route: ROUTES.ORDER, Component: OrderPage },
  { route: ROUTES.PORTS, Component: PortsPage },
  { route: ROUTES.REPORTS, Component: ReportsPage },
  { route: ROUTES.MARINE_TRAFFIC, Component: MarineTrafficPage },
  { route: ROUTES.MONTHLY_PLANS, Component: MonthlyPlansPage },
  { route: ROUTES.MONTHLY_PLAN, Component: MonthlyPlanPage },
];

// todo fill in routes for user management
export const ROUTES_FOR_ADMIN = [
  ...ROUTES_FOR_PLATFORM_ADMIN,
  { route: ROUTES.ACCOUNTS, Component: AccountsPage },
];

export const ROUTES_FOR_ROLES = {
  [UserRole.shareholder]: ROUTES_FOR_SHAREHOLDER,
  [UserRole.shipowner]: ROUTES_FOR_SHIPOWNER,
  [UserRole.platformAdmin]: ROUTES_FOR_PLATFORM_ADMIN,
  [UserRole.admin]: ROUTES_FOR_ADMIN,
  [UserRole.root]: ROUTES_FOR_ADMIN,
};

const PlatformAdminRoutes: FC = () => (
  <ThemeProvider theme={adminTheme}>
    <Routes>
      {ROUTES_FOR_PLATFORM_ADMIN.map(({ route, Component }) => (
        <Route key={route} path={route} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to={ROUTES.ORDERS} />} />
    </Routes>
  </ThemeProvider>
);

const AdminRoutes: FC = () => (
  <ThemeProvider theme={adminTheme}>
    <Routes>
      {ROUTES_FOR_ADMIN.map(({ route, Component }) => (
        <Route key={route} path={route} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to={ROUTES.ORDERS} />} />
    </Routes>
  </ThemeProvider>
);

const ShareholderRoutes: FC = () => (
  <ThemeProvider theme={adminTheme}>
    <Routes>
      {ROUTES_FOR_SHAREHOLDER.map(({ route, Component }) => (
        <Route key={route} path={route} element={<Component />} />
      ))}
      <Route path="*" element={<Navigate to={ROUTES_FOR_SHAREHOLDER[0].route} />} />
    </Routes>
  </ThemeProvider>
);

const ShipOwnerRoutes: FC = () => {
  const { loading } = useGetMyVesselsQuery({
    variables: { limit: LIMIT, offset: 0 },
    onCompleted: data => {
      vesselsVar(data?.myVessels.vessels);
      vesselCountVar(data?.myVessels.total);
    },
  });
  if (loading) {
    return null;
  }
  return (
    <ThemeProvider theme={shipOwnerTheme}>
      <Routes>
        {ROUTES_FOR_SHIPOWNER.map(({ route, Component }) => (
          <Route path={route} element={<Component />} />
        ))}
        <Route path="*" element={<Navigate to={ROUTES.SHIPOWNER_ORDERS} />} />
      </Routes>
    </ThemeProvider>
  );
};

const AuthRoutes: FC = () => (
  <Routes>
    <Route path={ROUTES.AUTHORIZATION_SHIPOWNER} element={<AuthPage />} />
    <Route path="*" element={<Navigate to={ROUTES.AUTHORIZATION_SHIPOWNER} />} />
  </Routes>
);

const MainRoutes: FC<any> = () => {
  const user = useReactiveVar(userVar);
  if (user.roles?.includes(UserRole.root)) return <AdminRoutes />;
  if (user.roles?.includes(UserRole.admin)) return <AdminRoutes />;
  if (user.roles?.includes(UserRole.platformAdmin)) return <PlatformAdminRoutes />;
  if (user.roles?.includes(UserRole.shareholder)) return <ShareholderRoutes />;
  if (user.roles?.includes(UserRole.shipowner)) return <ShipOwnerRoutes />;
  return <></>;
};

export const AppAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const { loading, data, error } = useGetUserQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      const token = getCookie(AUTH_TOKEN);
      if (token && data && !loading) {
        setIsLoggedIn(true);
        userVar(data.me);
        setisLoading(false);
      }
    },
  });
  const user = useReactiveVar(userVar);
  useEffect(() => {
    if (!!error && !data) {
      setisLoading(false);
    }
  }, [error]);

  useEffect(() => {
    const token = getCookie(AUTH_TOKEN);
    if (token && !isEmpty(user)) {
      setIsLoggedIn(true);
    }
    if (isEmpty(user)) {
      setIsLoggedIn(false);
    }
  }, [user.id]);

  if (loading || isLoading) {
    return null;
  }
  return <AppRoutes isLoggedIn={isLoggedIn} />;
};

export const AppRoutes = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  return !isLoggedIn ? <AuthRoutes /> : <MainRoutes />;
};
