import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import { OrderForm } from 'src/pages/orders/components/OrderForm';
import { Header } from 'src/components/layout';
import { DEFAULT_ORDER_FIELDS, OPERATION_TYPES } from 'src/utils/constants';
import { useCreateOrderMutation, GetOrdersDocument } from 'src/gql/';
import { setSuccessMesageNotification, setErrorNotification } from 'src/utils/helpers';
import * as ROUTES from 'src/utils/constants/routes';

export const OrderCreatePage = () => {
  const navigate = useNavigate();
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [createOrder] = useCreateOrderMutation({
    refetchQueries: [GetOrdersDocument],
    onCompleted: () => {
      setSuccessMesageNotification('Order created');
      navigate(ROUTES.ORDERS);
    },
    onError: error => {
      setErrorNotification(`${error}`);
    },
  });

  return (
    <div>
      <Header type={'orderSteps'} stepNumber={stepNumber} />
      <OrderForm
        setStepNumber={setStepNumber}
        stepNumber={stepNumber}
        order={DEFAULT_ORDER_FIELDS}
        type={OPERATION_TYPES.CREATE}
        mutation={createOrder}
      />
    </div>
  );
};
