import React, { useCallback, useState } from 'react';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core';
import { Header } from 'src/components/layout';

import { EmbedMap } from 'src/components/MarineTrafficMap/EmbedMap';
import { ELGA_FLEET_ID } from 'src/utils/constants';
import { useMarineTrafficVesselSelected } from 'src/hooks';
import { useGetOrderByImoQuery } from 'src/gql';
import { OrderDetails } from 'src/components/OrderDetails';

export const MapPage = () => {
  const classes = useStyles();
  const [vesselImo, setVesselImo] = useState<string | null>(null);
  const { data: orderByImoData } = useGetOrderByImoQuery({
    variables: {
      imo: +vesselImo!,
    },
    skip: !vesselImo,
  });

  useMarineTrafficVesselSelected(setVesselImo);

  return (
    <div>
      <Header type={'orders'} />

      <Container>
        <div className={classes.panelTools}>
          <div className={classes.mapWrapper}>
            <EmbedMap
              width="100%"
              height="700px"
              shownames="false"
              maptype="0"
              fleet_id={ELGA_FLEET_ID}
            />
          </div>
          <div>
            {!!orderByImoData?.orderByImo && (
              <OrderDetails order={orderByImoData.orderByImo} onClose={() => setVesselImo(null)} />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  mapWrapper: {
    width: '100%',
    marginRight: '20px',
  },
  panelTools: {
    display: 'flex',
    margin: '30px 0',
  },
}));
