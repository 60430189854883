import React, { FC, useCallback } from 'react';
import { isEmpty, reject } from 'ramda';
import { useFormik } from 'formik';
import debounce from 'lodash.debounce';
import DateFnsUtils from '@date-io/date-fns';

import { Toggle } from 'src/components/toggles';
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker';
import InputAdornment from '@material-ui/core/InputAdornment';

import { I18nText } from 'src/components/i18n';
import { useGetPortsQuery } from 'src/gql';
import { Button } from 'src/components/buttons';

const initialState = {
  loadingPorts: [],
  dischargingPorts: [],
  quantityMin: '',
  quantityMax: '',
  layTime: '',
  canTime: '',
  onlyActive: false,
};

export const Filter: FC<any> = ({ query }) => {
  const classes = useStyles();

  const { data } = useGetPortsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const formik = useFormik({
    initialValues: {
      loadingPorts: initialState.loadingPorts,
      dischargingPorts: initialState.loadingPorts,
      quantityMin: initialState.quantityMin,
      quantityMax: initialState.quantityMax,
      layTime: initialState.layTime,
      canTime: initialState.canTime,
      onlyActive: initialState.onlyActive,
    },
    onSubmit: values => {
      console.log(values);
    },
  });

  const filter = (values: any) => {
    const { onlyActive, ...usedFilters }: any = reject(isEmpty)({
      ...values,
      quantityMin: !!values?.quantityMin ? +values?.quantityMin : values?.quantityMin,
      quantityMax: !!values?.quantityMax ? +values?.quantityMax : values?.quantityMax,
    });

    query({
      filters: usedFilters,
      onlyActive,
    });
  };

  const filterDebounce = useCallback(debounce(filter, 1000), []);

  return (
    <>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid sm={1} className={classes.gridItemDesktop}>
          <Box className={classes.labels}>
            <I18nText path="menuLabel.loading" />
          </Box>
        </Grid>
        <Grid sm={5}>
          <Box className={classes.selectWrapper}>
            <Select
              className={classes.select}
              multiple
              name="loadingPorts"
              value={formik.values.loadingPorts}
              onChange={event => {
                formik.setFieldTouched('loadingPorts', false);
                formik.setFieldValue('loadingPorts', event.target.value);
                filterDebounce({ ...formik.values, loadingPorts: event.target.value });
              }}
              input={
                <BootstrapInput
                  endAdornment={
                    <EndInputAdornment
                      isSelect
                      classes={classes}
                      cond={!!formik.values.loadingPorts.length}
                      onClick={() => {
                        formik.setFieldValue('loadingPorts', initialState.loadingPorts);
                        filter({
                          ...formik.values,
                          loadingPorts: initialState.loadingPorts,
                        });
                      }}
                    />
                  }
                />
              }
              renderValue={selected => (
                <Box className={classes.chips}>
                  {(selected as string[]).map((value, index) => (
                    <Box key={value} className={classes.chip}>
                      {value}
                      {index + 1 !== (selected as string[]).length && ', '}
                    </Box>
                  ))}
                </Box>
              )}
            >
              {(data?.ports ?? []).map((port: any, index: number) => (
                <MenuItem key={index} value={port.name}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.loadingPorts.includes(port.name as never)}
                  />
                  <ListItemText primary={port.name} />
                </MenuItem>
              ))}
            </Select>

            {/* {!!formik.values.loadingPorts.length && (
               <Box
               className={classes.closeButtonSelect}
               onClick={() => {
                 formik.setFieldValue('loadingPorts', initialState.loadingPorts);
                 filter({
                   ...formik.values,
                   loadingPorts: initialState.loadingPorts,
                 });
               }}
             />
            )} */}
          </Box>
        </Grid>

        <Grid sm={1} className={classes.gridItemDesktop}>
          <Box className={classes.labels}>
            <I18nText path="menuLabel.discharging" />{' '}
          </Box>
        </Grid>
        <Grid xs={8} sm={5}>
          <Box className={classes.selectWrapper}>
            <Select
              className={classes.select}
              multiple
              name="dischargingPorts"
              value={formik.values.dischargingPorts}
              onChange={event => {
                formik.setFieldTouched('dischargingPorts', false);
                formik.setFieldValue('dischargingPorts', event.target.value);
                filterDebounce({ ...formik.values, dischargingPorts: event.target.value });
              }}
              input={
                <BootstrapInput
                  endAdornment={
                    <EndInputAdornment
                      isSelect
                      classes={classes}
                      cond={!!formik.values.dischargingPorts.length}
                      onClick={() => {
                        formik.setFieldValue('dischargingPorts', initialState.dischargingPorts);
                        filter({
                          ...formik.values,
                          dischargingPorts: initialState.dischargingPorts,
                        });
                      }}
                    />
                  }
                />
              }
              renderValue={selected => (
                <Box className={classes.chips}>
                  {(selected as string[]).map((value, index) => (
                    <Box key={value} className={classes.chip}>
                      {value}
                      {index + 1 !== (selected as string[]).length && ', '}
                    </Box>
                  ))}
                </Box>
              )}
            >
              {(data?.ports ?? []).map((port: any, index: number) => (
                <MenuItem key={index} value={port.name}>
                  <Checkbox
                    color="primary"
                    checked={formik.values.dischargingPorts.includes(port.name as never)}
                  />
                  <ListItemText primary={port.name} />
                </MenuItem>
              ))}
            </Select>
            {/* {!!formik.values.dischargingPorts.length && (
            <Box
              className={classes.closeButtonSelect}
              onClick={() => {
                formik.setFieldValue('dischargingPorts', initialState.dischargingPorts);
                filter({
                  ...formik.values,
                  dischargingPorts: initialState.dischargingPorts,
                });
              }}
            />
          )} */}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid xs={3} sm={1} className={classes.gridItemDesktop}>
          {' '}
          <Box className={classes.labels}>
            <I18nText path="menuLabel.quantity" />
          </Box>
        </Grid>
        <Grid xs={9} sm={5} className={classes.dataGrid}>
          {/* {!!filters.quantityMin && !!filters.quantityMax && (
            <Box className={classes.dataWrapper}>
              <Box onClick={() => setOpenQuantityPopup(true)}>
                {filters.quantityMin} - {filters.quantityMax}{' '}
              </Box>
              <Box
                className={classes.closeButton}
                onClick={() =>
                  setFilters({
                    ...filters,
                    quantityMin: initialState.quantityMin,
                    quantityMax: initialState.quantityMax,
                  })
                }
              />
            </Box>
          )}
          {!filters.quantityMin && !filters.quantityMax && (
            <Box className={classes.buttonWrapper}>
              {!openQuantityPopup && (
                <Button
                  color="primary"
                  onClick={() => setOpenQuantityPopup(true)}
                  className={classes.button}
                >
                  Set
                </Button>
              )}
            </Box>
          )} */}
          <TextField
            variant="outlined"
            name={'quantityMin'}
            inputProps={{ className: classes.field }}
            InputProps={{
              endAdornment: (
                <EndInputAdornment
                  classes={classes}
                  cond={!!formik.values.quantityMin}
                  onClick={() => {
                    formik.setFieldValue('quantityMin', initialState.quantityMin);
                    filter({
                      ...formik.values,
                      quantityMin: initialState.quantityMin,
                    });
                  }}
                />
              ),
            }}
            className={classes.fieldsWrapper}
            value={formik.values.quantityMin}
            onChange={event => {
              formik.setFieldTouched('quantityMin', false);
              formik.setFieldValue('quantityMin', event.target.value);
              filterDebounce({ ...formik.values, quantityMin: event.target.value });
            }}
          />
          <TextField
            variant="outlined"
            name={'quantityMax'}
            inputProps={{ className: classes.field }}
            InputProps={{
              endAdornment: (
                <EndInputAdornment
                  classes={classes}
                  cond={!!formik.values.quantityMax}
                  onClick={() => {
                    formik.setFieldValue('quantityMax', initialState.quantityMax);
                    filter({
                      ...formik.values,
                      quantityMax: initialState.quantityMax,
                    });
                  }}
                />
              ),
            }}
            className={classes.fieldsWrapper}
            value={formik.values.quantityMax}
            onChange={event => {
              formik.setFieldTouched('quantityMax', false);
              formik.setFieldValue('quantityMax', event.target.value);
              filterDebounce({ ...formik.values, quantityMax: event.target.value });
            }}
          />
          {/* {!!formik.values.quantityMin && !!formik.values.quantityMax && (
            <Box
              className={classes.closeButtonSelect}
              onClick={() => {
                formik.setFieldValue('quantityMax', initialState.quantityMax);
                formik.setFieldValue('quantityMin', initialState.quantityMin);
                filter({
                  ...formik.values,
                  quantityMax: initialState.quantityMax,
                  quantityMin: initialState.quantityMin,
                });
              }}
            />
          )} */}
          {/* {openQuantityPopup && (
            <QuantityFillterPopup
              onClose={setOpenQuantityPopup}
              setFilters={setFilters}
              filters={filters}
            />
          )} */}
        </Grid>

        <Grid xs={3} sm={1} className={classes.gridItemDesktop}>
          {' '}
          <Box className={classes.labels}>
            <I18nText path="menuLabel.laycan" />
          </Box>
        </Grid>
        <Grid xs={9} sm={5} className={classes.dataGrid}>
          {/* {!!filters.layTime && !!filters.canTime && (
            <Box className={classes.dataWrapper}>
              <Box onClick={() => setOpenTimePopup(true)}>
                {getDDMMYYYY(filters.layTime)} - {getDDMMYYYY(filters.canTime)}{' '}
              </Box>
              <Box
                className={classes.closeButton}
                onClick={() =>
                  setFilters({
                    ...filters,
                    layTime: initialState.layTime,
                    canTime: initialState.canTime,
                  })
                }
              />
            </Box>
          )}
          {!filters.layTime && !filters.canTime && (
            <Box className={classes.buttonWrapper}>
              {!openTimePopup && (
                <Button
                  color="primary"
                  onClick={() => setOpenTimePopup(true)}
                  className={classes.button}
                >
                  Set
                </Button>
              )}
            </Box>
          )} */}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd.MM.yyyy"
              clearable
              inputProps={{
                className: classes.field,
              }}
              className={classes.fieldsWrapper}
              value={!!formik.values.layTime ? formik.values.layTime : null}
              name="layTime"
              onChange={date => {
                formik.setFieldTouched('layTime', false);
                formik.setFieldValue('layTime', date);
                filter({ ...formik.values, layTime: date });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <KeyboardDatePicker
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="canTime"
              clearable
              inputProps={{
                className: classes.field,
              }}
              className={classes.fieldsWrapper}
              value={!!formik.values.canTime ? formik.values.canTime : null}
              onChange={date => {
                formik.setFieldTouched('canTime', false);
                formik.setFieldValue('canTime', date);
                filter({ ...formik.values, canTime: date });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider>
          {/* {!!formik.values.layTime && !!formik.values.canTime && (
            <Box
              className={classes.closeButtonSelect}
              onClick={() =>
                setFilters({
                  ...filters,
                  layTime: initialState.layTime,
                  canTime: initialState.canTime,
                })
              }
            />
          )} */}
          {/* {openTimePopup && (
            <LayCanFillterPopup
              onClose={setOpenTimePopup}
              setFilters={setFilters}
              filters={filters}
            />
          )} */}
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid xs={7} sm={7} item />
        <Grid xs={7} sm={3} item>
          <Box className={classes.toggleContainer}>
            <Box className={classes.labels}>
              <I18nText path="field.onlyActive" />
            </Box>
            <Box className={classes.toggleWrapper}>
              <Toggle
                checked={formik.values.onlyActive}
                onChange={(event: any) => {
                  formik.setFieldValue('onlyActive', event.target.checked);
                  filter({ ...formik.values, onlyActive: event.target.checked });
                }}
              />
            </Box>
          </Box>
        </Grid>{' '}
        <Grid xs={12} sm={2} item className={classes.buttonContainer}>
          <Box>
            <Button
              buttonVariant="second"
              onClick={() => {
                formik.resetForm();
                query({
                  onlyActive: false,
                  filters: {},
                });
              }}
            >
              <I18nText path="button.clearFilters" />
            </Button>
          </Box>{' '}
        </Grid>{' '}
      </Grid>
    </>
  );
};

export const EndInputAdornment: FC<any> = ({ cond, classes, onClick, isSelect = false }) => {
  if (cond) {
    return (
      <InputAdornment position="end">
        <Box
          className={classes.deleteButton}
          style={isSelect ? { marginRight: '25px' } : {}}
          onClick={onClick}
        />
      </InputAdornment>
    );
  }
  return <></>;
};

const QuantityFillterPopup = ({ onClose, setFilters, filters }: any) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      quantityMin: filters.quantityMin,
      quantityMax: filters.quantityMax,
    },
    onSubmit: values => {
      setFilters({ ...filters, ...values });
      onClose(false);
    },
  });

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.popup}>
          <Box className={classes.fieldsContainer}>
            <TextField
              variant="outlined"
              name={'quantityMin'}
              className={classes.field}
              value={formik.values.quantityMin}
              onChange={formik.handleChange}
            />
            <TextField
              variant="outlined"
              name={'quantityMax'}
              className={classes.field}
              value={formik.values.quantityMax}
              onChange={formik.handleChange}
            />
          </Box>

          <Button type="submit" color="primary" className={classes.button}>
            Set
          </Button>
        </Box>
      </form>
    </ClickAwayListener>
  );
};

const LayCanFillterPopup = ({ onClose, setFilters, filters }: any) => {
  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      layTime: filters.layTime || new Date(),
      canTime: filters.canTime || new Date(),
    },
    onSubmit: values => {
      setFilters({ ...filters, ...values });
      onClose(false);
    },
  });

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose(false);
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box className={classes.popup}>
            <KeyboardDatePicker
              inputVariant="outlined"
              format="dd.MM.yyyy"
              className={classes.date}
              value={formik.values.layTime}
              name="layTime"
              onChange={date => {
                formik.setFieldTouched('layTime', false);
                formik.setFieldValue('layTime', date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
            <KeyboardDatePicker
              format="dd.MM.yyyy"
              inputVariant="outlined"
              name="canTime"
              className={classes.date}
              value={formik.values.canTime}
              onChange={date => {
                formik.setFieldTouched('canTime', false);
                formik.setFieldValue('canTime', date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />

            <Button type="submit" color="primary" className={classes.button}>
              Set
            </Button>
          </Box>{' '}
        </MuiPickersUtilsProvider>
      </form>
    </ClickAwayListener>
  );
};

export const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontSize: '16px',
    padding: '10px 26px 10px 12px',
    width: '100%',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  labels: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: 'rgba(0,0,0,0.4)',
  },
  chips: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    width: '100%',
  },
  chip: { marginRight: '8px' },
  gridContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  gridItemDesktop: { position: 'relative' },
  gridItemMobile: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    borderRadius: '8px',
    boxShadow: 'none',
    height: '35px',
    fontSize: '16px',
    textTransform: 'none',
    padding: '0 20px',
  },
  popup: {
    width: '220px',
    position: 'absolute',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.15)',
    borderRadius: '8px',
    top: '60px',
    left: '0',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 13px',
  },
  fieldsContainer: {
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  fieldsWrapper: {
    '& .MuiInputBase-root': { backgroundColor: '#fff' },
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
    width: '50%',
    marginRight: '5px',
  },
  field: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    padding: '10px 7px',
    // height: '15px',
  },
  dataWrapper: {
    backgroundColor: 'rgba(42, 57, 190, 0.12)',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px 14px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  closeButtonSelect: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    right: '0',
    width: '11px',
    backgroundPosition: 'center',
    marginLeft: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '11px 12px',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.585975' y1='1.2253' x2='10.479' y2='11.1184' stroke='black'/%3E%3Cline y1='-0.5' x2='13.9909' y2='-0.5' transform='matrix(-0.707107 0.707107 0.707107 0.707107 10.7969 1.57861)' stroke='black'/%3E%3C/svg%3E%0A")`,
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    right: '0',
    width: '11px',
    height: '12px',
    backgroundPosition: 'center',
    marginLeft: '10px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '11px 12px',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='0.585975' y1='1.2253' x2='10.479' y2='11.1184' stroke='black'/%3E%3Cline y1='-0.5' x2='13.9909' y2='-0.5' transform='matrix(-0.707107 0.707107 0.707107 0.707107 10.7969 1.57861)' stroke='black'/%3E%3C/svg%3E%0A")`,
  },
  date: {
    marginBottom: '7px',
  },
  selectWrapper: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    position: 'relative',
  },
  select: {
    '& .MuiSelect-select:focus': {
      borderRadius: '8px',
      backgroundColor: 'rgba(256, 256, 256)',
    },
    minWidth: '99%',
    maxWidth: '500px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    border: '1px solid rgba(0, 0, 0, 0.11)',
  },
  dataGrid: {
    height: '35px',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  toggleWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
    marginLeft: '15px',
  },
  fieldOnlyActiveWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: '#6B6B6B',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    cursor: 'pointer',
    width: '15px',
    height: '14px',
    backgroundSize: '15 14',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cline x1='1.7139' y1='1.00655' x2='14.3537' y2='13.6463' stroke='%23FF0000'/%3E%3Cline y1='-0.5' x2='17.8753' y2='-0.5' transform='matrix(-0.707107 0.707107 0.707107 0.707107 13.9995 1.36011)' stroke='%23FF0000'/%3E%3C/svg%3E%0A")`,
  },
}));
