import React, { FC } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker, KeyboardDatePickerProps } from '@material-ui/pickers/DatePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';

import { useFormClasses } from 'src/hooks/useFormClasses';

interface DateInputProps extends KeyboardDatePickerProps {
  value: Date;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  name?: string;
  [key: string]: any;
}

export const DateInput: FC<DateInputProps> = ({ value, onChange, name, ...props }) => {
  const { classes } = useFormClasses();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        format="dd.MM.yyyy"
        inputVariant="outlined"
        className={classes.textField}
        inputProps={{ className: classes.input }}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};
