import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { isDesktop, isMobile } from 'react-device-detect';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { getDDMMYYYY } from 'src/utils/helpers';
import { I18nText } from 'src/components/i18n';
import { Offer, OfferRate, useOfferUpdatedSubscription } from 'src/gql';
import { Button } from 'src/components/buttons';
import * as ROUTES from 'src/utils/constants/routes';
import { useOfferDetails } from 'src/hooks/useOfferDetails';
import { GradeData } from 'src/components/GradeData/GradeData';

interface OfferListItemProps {
  offer: Offer;
  markOfferAsWinner?: any;
  isWinner: boolean;
}

export const OfferListItem: FC<OfferListItemProps> = ({
  offer: initialOffer,
  markOfferAsWinner,
  isWinner,
}) => {
  const classes = useStyles();
  const [offer, setOffer] = useState(initialOffer);
  useOfferUpdatedSubscription({
    variables: { offerId: offer.id },
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data?.offerUpdated) {
        return;
      }

      setOffer(subscriptionData.data.offerUpdated);
    },
  });
  const { offerDetails } = useOfferDetails({ offer });

  return (
    <Container>
      <div className={isWinner ? classes.winnerOfferContainer : classes.offerContainer}>
        <Grid container spacing={2}>
          <Grid xs={12} md={8} item className={classes.idWrapper}>
            <div className={classes.indexNumber}>
              <span className={classes.data}>{offer.indexNumber}</span>
            </div>
            <div className={classes.indexNumber}>
              <span className={classes.data}>{offer.carrier}</span>
            </div>
            {!!offer.rating && !!offer.rating?.ordersCount && (
              <div className={classes.rating}>
                <span>
                  {offer.rating.average || 0} / 5 ({offer.rating.ordersCount}{' '}
                  <I18nText path="text.deliveries" />)
                </span>
              </div>
            )}
          </Grid>
          {isDesktop && !isWinner && !!markOfferAsWinner && (
            <Grid item className={classes.buttonsWrapper} md={4} justifyContent={'flex-end'}>
              <Button
                onClick={() =>
                  markOfferAsWinner({
                    variables: {
                      offerId: offer.id,
                    },
                  })
                }
                buttonVariant="second"
              >
                <I18nText path="button.setWinner" />
              </Button>
            </Grid>
          )}
        </Grid>
        {isMobile && (
          <Grid container>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.proposal" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div>{offerDetails}</div>
            </Grid>
            {!!offer.eta && (
              <>
                <Grid item className={classes.gridItem} xs={3}>
                  <div className={classes.label}>
                    <I18nText path="menuLabel.eta" />
                  </div>
                </Grid>
                <Grid item className={classes.gridItem} xs={9}>
                  <div>{getDDMMYYYY(offer.eta)}</div>
                </Grid>
              </>
            )}

            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.draft" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div>{offer.draft}</div>
            </Grid>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.deadWeight" />
              </div>
            </Grid>
            <Grid item className={classes.gridItem} xs={9}>
              <div>{offer.deadweight}</div>
            </Grid>
            <Grid item className={classes.gridItem} xs={12}>
              <div className={classes.label}>
                <I18nText path="menuLabel.portRates" />
              </div>
              <div className={classes.portsWrapper}>
                {offer.rates.map((rate: OfferRate) => (
                  <div className={classes.rateWrapper}>
                    <div className={classes.rateLabel}>{rate.port?.name}</div>{' '}
                    <div>
                      <I18nText path="menuLabel.demRate" />:{' '}
                      <span className={classes.rateLabel}>{rate.demRate}</span>
                    </div>
                    <div>
                      <I18nText path="menuLabel.freightRate" />:{' '}
                      <span className={classes.rateLabel}>{rate.freightRate}</span>
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {isDesktop && (
          <Grid container>
            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.proposal" />
              </div>
              <div>{offerDetails}</div>
            </Grid>
            {!!offer.eta && (
              <Grid item className={classes.gridItem} xs={3}>
                <div className={classes.label}>
                  <I18nText path="menuLabel.eta" />
                </div>
                <div>{getDDMMYYYY(offer.eta)}</div>
              </Grid>
            )}

            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.draft" />
              </div>
              <div>{offer.draft}</div>
            </Grid>

            <Grid item className={classes.gridItem} xs={3}>
              <div className={classes.label}>
                <I18nText path="menuLabel.deadWeight" />
              </div>
              <div>{offer.deadweight}</div>
            </Grid>

            <Grid item className={classes.gridItem} xs={12}>
              <div className={classes.label}>
                <I18nText path="menuLabel.portRates" />
              </div>
              <div className={classes.portsWrapper}>
                {offer.rates.map((rate: OfferRate) => (
                  <div className={classes.rateWrapper}>
                    <div className={classes.rateLabel}>{rate.port?.name}</div>{' '}
                    <I18nText path="menuLabel.demRate" />:{' '}
                    <span className={classes.rateLabel}> {rate.demRate} </span>
                    <I18nText path="menuLabel.freightRate" />:{' '}
                    <span className={classes.rateLabel}> {rate.freightRate} </span>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
        {isMobile && !isWinner && !!markOfferAsWinner && (
          <Grid container className={classes.buttonContainer}>
            <Grid
              item
              className={classes.buttonsWrapper}
              sm={12}
              xs={12}
              md={12}
              justifyContent={'flex-end'}
            >
              <Button
                fullWidth
                onClick={() =>
                  markOfferAsWinner({
                    variables: {
                      offerId: offer.id,
                    },
                  })
                }
                buttonVariant="second"
              >
                <I18nText path="button.setWinner" />
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  offerContainer: {
    position: 'relative',
    margin: '0 auto',
    border: '1px solid #fff',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '20px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  winnerOfferContainer: {
    border: '1px solid #00BD2A',
    position: 'relative',
    margin: '0 auto',
    borderRadius: '16px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    padding: '24px 16px',
    marginBottom: '15px',
    background: '#fff',
  },
  buttonContainer: {
    marginTop: '36px',
  },
  gridItem: {
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexDirection: 'column',
    marginTop: '16px',
  },
  portsWrapper: {
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    minWidth: '160px',
  },
  idWrapper: {
    display: 'flex',
    gap: '20px',
  },
  data: {
    color: '#000',
    fontSize: '15px',
  },
  statusWrapper: {
    marginLeft: '17px',
  },
  label: {
    marginBottom: '6px',
    color: 'rgba(0,0,0,0.4)',
    fontSize: '14px',
  },
  layCanWrapper: {
    display: 'flex',
    textAlign: 'left',
    flexDirection: 'column',
  },
  buttonsWrapper: {
    fontSize: '16px',
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  progressBarCircularWrapper: {
    marginTop: '10px',
    display: 'flex',
    gap: '10px',
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
  },
  finePercentage: {
    color: '#00BD2A',
  },
  normalPercentage: {
    color: '#000',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
    display: 'flex',
    alignItems: 'center',
  },
  rating: {
    display: 'flex',
    alignItems: 'center',
  },
  rateLabel: {
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '10px',
  },
  rateWrapper: {
    marginBottom: '15px',
  },
}));
