import React from 'react';

import { Header } from '../../components/layout/Header/Header';
import { PortsList } from './PortsList';

export const PortsPage = () => {
  return (
    <div>
      <Header type={'orders'} />
      <PortsList />
    </div>
  );
};
