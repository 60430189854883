import React from 'react';

import { OrderList } from './components/OrderList';
import { Header } from '../../components/layout/Header/Header';

export const OrdersPage = () => {
  return (
    <div>
      <Header type={'orders'} />
      <OrderList />
    </div>
  );
};
