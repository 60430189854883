import { makeStyles } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { GetOrderByImoQuery } from 'src/gql';
import { useBoolean, useNavigateTo } from 'src/hooks';
import { getDDMMYYYY, getThousand } from 'src/utils/helpers';
import { Button } from '../buttons';
import { I18nText } from '../i18n';
import { PopupDetails } from '../popups';

const Entry: FC<{ title: string }> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.entry}>
      <div className={classes.label}>
        <I18nText path={title} />
      </div>
      <div>{children}</div>
    </div>
  );
};

export const OrderDetails: FC<{
  order: NonNullable<GetOrderByImoQuery['orderByImo']>;
  onClose(): void;
}> = ({ order, onClose }) => {
  const classes = useStyles();
  const [showPopup, { on: onShowPopup, off: offShowPopup }] = useBoolean(false);
  const navigateTo = useNavigateTo();

  const handleOrderNavgiation = useCallback(() => {
    navigateTo(`/order/${order.id}`);
  }, [order, navigateTo]);

  return (
    <div>
      {/* Порты погрузки/выгрузки, дата погрузки, тоннаж */}

      <div className={classes.indexNumber}>
        <I18nText path="menuLabel.orderDetails" paramValues={{ orderIndex: order.indexNumber }} />
        <Button className={classes.cross} onClick={onClose}>
          ✕
        </Button>
      </div>
      {!!order.loadingPort && <Entry title="field.loadingPort">{order.loadingPort.name}</Entry>}
      {!!order.dischargingPort && (
        <Entry title="field.dischargingPort">{order.dischargingPort.name}</Entry>
      )}
      {order.winnerOffer?.eta && (
        <Entry title="menuLabel.loadingDate">{getDDMMYYYY(order.winnerOffer.eta)}</Entry>
      )}
      {typeof order.tonnage === 'number' && (
        <Entry title="menuLabel.tonnage">
          {getThousand(order.tonnage)} <I18nText path="text.tonns" />
        </Entry>
      )}

      <div className={classes.buttonRow}>
        <Button
          className={classes.buttonSided}
          onClick={handleOrderNavgiation}
          buttonVariant="second"
        >
          <I18nText path="button.open" />
        </Button>
        <Button onClick={onShowPopup} buttonVariant="second">
          <I18nText path="button.details" />
        </Button>
      </div>
      <PopupDetails showPopup={showPopup} setShowPopup={offShowPopup} details={order as any} />
    </div>
  );
};

const useStyles = makeStyles(() => ({
  entry: {
    marginBottom: '10px',
  },
  label: {
    fontSize: '15px',
    textAlign: 'start',
    color: 'rgba(0,0,0,0.6)',
  },
  indexNumber: {
    fontSize: '20px',
    fontWeight: 500,
    color: '#000',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cross: { padding: '0', minWidth: '40px' },
  buttonRow: {
    display: 'flex',
  },
  buttonSided: {
    marginRight: '15px',
  },
}));
