import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { ReactNotifications } from 'react-notifications-component';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { AppAuth } from './App.routes';

import 'react-notifications-component/dist/theme.css';

import { localeVar } from './apollo/cache/cache';
import { IntlProvider, getMessages } from 'src/utils/helpers';

Sentry.init({
  dsn: 'https://e44537c3245141a883b3777fc7bdbfb8@o987209.ingest.sentry.io/5944053',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

export const App = () => {
  const locale = useReactiveVar(localeVar);
  return (
    <>
      <ReactNotifications />
      <IntlProvider locale={locale} key={locale} messages={getMessages(locale)}>
        <AppAuth />
      </IntlProvider>
    </>
  );
};
