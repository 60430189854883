export const getLatLon = (area: number[][]): { lat: number[]; lon: number[] } => {
  // extract area [lat, lng]
  // for area - lefttop -> clockwise
  const leftTop = area[0];
  const rightBottom = area[2];

  // for drawn polygon
  // const leftTop = area[area.length - 2];
  // const rightBottom = area[1];

  const lat = [leftTop[0], rightBottom[0]];
  const lon = [leftTop[1], rightBottom[1]];
  return { lat, lon };
};
