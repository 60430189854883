import React from 'react';

import { Header } from 'src/components/layout';
import { ShipownerVesselList } from './ShipownerVesselsList';

export const ShipownerVesselsPage = () => {
  return (
    <div>
      <Header type={'shipwownerNavigation'} />
      <ShipownerVesselList />
    </div>
  );
};
