import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  dataRow: ({ isSelected }: $TSFixMe) => ({
    width: 'fit-content',
    gap: '15px',
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'start',
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.06)',
    borderRadius: '16px',
    border: '2px solid white',
    borderColor: isSelected ? 'rgba(42, 57, 190, 0.8)' : '#FFFFFF',
    padding: '0 16px',
    marginBottom: '10px',
  }),
  dataColumn: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    wordBreak: 'break-word',
    width: '130px',
    padding: '25px 0',
  },
  dataInviteColumn: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    color: '#000',
    fontSize: '12px',
    wordBreak: 'break-word',
    width: '150px',
    padding: '25px 0',
  },
  tableContainer: {
    width: '100%',
    height: '30vh',
    overflow: 'scroll',
  },
}));
