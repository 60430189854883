import { makeStyles } from '@material-ui/core/styles';

const defaultStyles = {
  height: '40px',
  fontSize: '15px',
  lineHeight: '20px',
  padding: '10px 25px',
  boxShadow: 'none',
  borderRadius: '8px',
  textTransform: 'none',
  transition: 'all .2s ease-in-out',
} as const;

export const useStyles = makeStyles(() => ({
  main: {
    ...defaultStyles,
    color: '#fff',
    background: 'rgba(42, 57, 190, 0.8)',
    '&:hover': {
      boxShadow: 'none',
      background: 'rgba(42, 57, 190, 0.9)',
      opacity: '1',
    },
  },
  second: {
    ...defaultStyles,
    color: '#2A39BE',
    backgroundColor: 'rgba(42, 57, 190, 0.1)',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(42, 57, 190, 0.2)',
      opacity: '1',
    },
  },
  dark: {
    ...defaultStyles,
    color: '#000000',
    textAlign: 'center',
    '&:hover': {
      boxShadow: 'none',
      opacity: '1',
    },
  },
  light: {
    ...defaultStyles,
    color: '#000000',
    background: '#fff',
    '&:hover': {
      boxShadow: 'none',
      background: '#fff',
      opacity: '1',
    },
  },
}));
