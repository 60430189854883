import { compose, join, map, prop } from 'ramda';
import { PortPoint } from 'src/gql';
import { OPERATION_TYPES } from '../constants';

export const getPorts = (ports: PortPoint[], type: string) => {
  if (type === OPERATION_TYPES.EDIT) {
    return ports.map((port: PortPoint) => ({
      indexNumber: port.indexNumber,
      portName: port.portName,
      rate: port.rate,
      id: port.id,
    }));
  }
  return ports.map((port: PortPoint) => ({
    indexNumber: port.indexNumber,
    portName: port.portName,
    rate: port.rate,
  }));
};

export const getPortsList = compose(join(', '), map(prop('portName')));
