import React from 'react';
import { useMemo } from 'react';

import { getDDMMYYYY } from 'src/utils/helpers';
import { Offer } from 'src/gql';
import { I18nText } from 'src/components/i18n';

export const useOfferDetails = ({ offer }: { offer: Offer }) => {
  const offerDetails = useMemo(() => {
    return (
      <>
        <div>
          {!!offer.eta &&
            getDDMMYYYY(
              (typeof offer.eta === 'string' ? new Date(+offer.eta) : offer.eta).getTime(),
            )}
        </div>
        <div>{offer.vessel?.name}</div>
        <div>
          E/Q <I18nText path="menuLabel.abt" /> {offer.estimatedQuantity}
        </div>
      </>
    );
  }, [offer]);

  return {
    offerDetails,
  };
};
