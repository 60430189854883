import type { FC } from 'react';
import { useEffect } from 'react';
import { Tile as OlTile } from 'ol/layer';
import { Map } from 'ol';

interface Props {
  zIndex?: number;
  map: Map | null;
  source: any;
}

export const TileLayer: FC<Props> = ({ source, zIndex = 0, map }) => {
  useEffect(() => {
    if (!map) return;

    let tileLayer = new OlTile({
      source,
      zIndex,
    });
    map.addLayer(tileLayer);
    tileLayer.setZIndex(zIndex);
    return () => {
      if (map) {
        map.removeLayer(tileLayer);
      }
    };
  }, [map]);

  return null;
};
